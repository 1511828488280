import { hasPermission } from './AllowedTo';
import { format } from 'date-fns';
import { history } from 'redux/store';
import CryptoJS from 'crypto-js';
import { endOfMonth, startOfMonth, subQuarters } from 'date-fns';

export const QUERY_TYPE_MAP = {
  INVOICE: 'invoice',
  BILL: 'bill',
};

export const FEES = 25;

export const isLoggedIn = () => {
  const token = retrieveAndDecryptFromLocalStorage('app-session');
  const tokenExp = parseInt(retrieveAndDecryptFromLocalStorage('exp-app-session'));
  return !!(token && tokenExp);
};

export const isTokenExp = () => {
  const tokenExp = parseInt(retrieveAndDecryptFromLocalStorage('exp-app-session'));
  return !!(Date.now() >= tokenExp);
};

const permissionsMap = [
  {
    view: 'dashView',
    paths: '/',
    scope: ['org-*', 'dash-*', 'dash-view'],
  },
  {
    view: 'reportView',
    paths: '/reports',
    scope: ['org-*', 'dash-*', 'dash-view'],
  },
  {
    view: 'requestView',
    paths: '/requests/payments',
    scope: [
      'reimbursement-*',
      'reimbursement-view',
      'reimbursement-create',
      'reimbursement-edit',
      'transaction-*',
      'transaction-view',
    ],
  },
  {
    view: 'transactionView',
    paths: '/transactions/payments',
    scope: [
      'transaction-*',
      'transaction-view',
      'transaction-create',
      'transaction-edit',
      'batch-transaction-*',
      'batch-transaction-view',
      'batch-transaction-create',
      'batch-transaction-edit',
    ],
  },
  {
    view: 'accountView',
    paths: '/accounts',
    scope: ['org-*', 'dash-*', 'dash-view'],
  },
  {
    view: 'expenseView',
    paths: '/expenses/budgets',
    scope: ['budget-*', 'budget-view', 'budget-create', 'budget-edit'],
  },
  {
    view: 'expenseView',
    paths: '/expenses/vendors',
    scope: ['vendor-*', 'vendor-view', 'vendor-create', 'vendor-edit'],
  },
  {
    view: 'expenseView',
    paths: '/expenses/bills',
    scope: ['invoice-*', 'invoice-view', 'invoice-create', 'invoice-edit'],
  },
  {
    view: 'cardView',
    paths: '/cards',
    scope: ['card-*', 'card-view', 'card-create', 'card-edit'],
  },

  {
    view: 'invoiceView',
    paths: '/receivables/invoices',
    scope: ['invoice-*', 'invoice-view', 'invoice-create', 'invoice-edit'],
  },
  {
    view: 'categoriesView',
    paths: '/compliances/rules',
    scope: ['org-*', 'dash-*', 'dash-view'],
  },
  {
    view: 'invoiceView',
    paths: '/receivables/customers',
    scope: ['customer-*', 'customer-view', 'customer-create', 'customer-edit'],
  },
  {
    view: 'complianceView',
    paths: '/compliances/policies',
    scope: ['policy-*', 'policy-view', 'policy-create', 'policy-edit'],
  },
  {
    view: 'categoriesView',
    paths: '/compliances/categories',
    scope: ['org-*', 'dash-*', 'dash-view'],
  },
  {
    view: 'teamView',
    paths: '/teams/people',
    scope: [
      'employee-*',
      'employee-view',
      'employee-create',
      'employee-edit',
      'team-*',
      'team-view',
      'team-create',
      'team-edit',
      'role-*',
      'role-view',
      'role-create',
      'role-edit',
    ],
  },
];
export const getUserLandingPage = ({
  dob,
  company,
  status,
  permissions,
  companyStatus,
  onboardingStatus,
  isAdmin,
}) => {
  const viewDash = hasPermission({ permissions, scopes: permissionsMap[0].scope });
  const viewPath = permissionsMap.find(
    (perm) =>
      perm.view !== 'dashView' && hasPermission({ permissions, scopes: perm.scope }),
  )?.paths;

  const redirectPath = retrieveAndDecryptFromLocalStorage('redirectPath');
  if (redirectPath && !['/find-company', '/company-solution'].includes(redirectPath)) {
    localStorage.removeItem('redirectPath');
    localStorage.removeItem('lastLogin');
    return `${redirectPath}`;
  } else {
    if (!company) return '/find-company';
    if (
      (companyStatus && ['pending', 'rejected'].includes(onboardingStatus)) ||
      (!isAdmin && status !== 'active')
    )
      return '/get-started';
    if (companyStatus && ['approved'].includes(onboardingStatus) && isAdmin && !dob)
      return '/get-started/admin-kyb';
    if (viewDash) return '/';
    if (status === 'active') return viewPath || '/settings/profile';
  }
};

export const getFromAndTo = (date) => {
  const [{ startDate, endDate } = {}] = Array.isArray(date) ? date : [date];
  const finalStartDate = startDate || new Date();
  const finalEndDate = endDate || finalStartDate.addDays(30);
  const from = format(new Date(finalStartDate), 'yyyy-MM-dd') + ' 00:00:00';
  const to = format(new Date(finalEndDate), 'yyyy-MM-dd') + ' 23:59:59';
  return {
    from,
    to,
  };
};

export const numFormatter = (number, fromThousand = false, rounded = false) => {
  if (Math.abs(number) >= 1000000000) {
    const formattedNumber = number / 1000000000;
    const truncatedNumber = Math.trunc(formattedNumber * 1000) / 1000;
    return (
      (rounded ? Math.round(truncatedNumber || 0) : truncatedNumber)?.toLocaleString(
        'en',
      ) + 'B'
    );
  } else if (Math.abs(number) >= 1000000) {
    const formattedNumber = number / 1000000;
    const truncatedNumber = Math.trunc(formattedNumber * 1000) / 1000;
    return (
      (rounded ? Math.round(truncatedNumber || 0) : truncatedNumber)?.toLocaleString(
        'en',
      ) + 'M'
    );
  } else if (Math.abs(number) >= 1000 && fromThousand) {
    const formattedNumber = number / 1000;
    const truncatedNumber = Math.trunc(formattedNumber * 100) / 100;
    return (
      (rounded ? Math.round(truncatedNumber || 0) : truncatedNumber)?.toLocaleString(
        'en',
      ) + 'K'
    );
  }
  return number?.toLocaleString('en');
};

export const formatNumThousandSeparator = (number) => {
  if (isNaN(Number(number))) return null;
  const formattedNum = Number(number)?.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedNum;
};

export function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

/**
 *
 * @param {String} url: the url string from the location object
 * @param {String} key: the key you want to search for in the string
 */
export const getQueryParams = (url, key) => {
  return new URLSearchParams(url).get(key);
};

/**
 *
 * @param {String} url: the url string from the location object
 * @param {String} keys: the key you want to search for in the string
 */
export const deleteQueryParamsByKey = (url, keys) => {
  const searchParams = new URLSearchParams(url);
  for (const key of keys) {
    searchParams.delete(key);
  }
  const baseUrl = url.split('?')[0];
  const updatedURL = baseUrl + '?' + searchParams.toString();

  if (updatedURL === '?') return history.push(location.pathname);
  return history.push(location.pathname + updatedURL);
};

export const statusOptions = [
  { value: 'deleted', label: 'Delete' },
  { value: 'Invited', label: 'invited' },
  { value: 'Active', label: 'active' },
  { value: 'Inactive', label: 'inactive' },
];

export const updateStatus = (data, title, value) => {
  const updatedData = data.map((item) => {
    if (item.title === title) {
      const updatedList = item.list.map((status) => {
        if (status.value === value) {
          return { ...status, isSelected: true };
        } else {
          return status;
        }
      });
      return { ...item, list: updatedList };
    } else {
      return item;
    }
  });
  return updatedData;
};

export const getDateRange = (val) => {
  Date.prototype.addDays = function (days) {
    // Add days to given date
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  const today = new Date();

  switch (val) {
    case 'Today':
      return { val, range: [{ startDate: today, endDate: today }] };
    case 'Last 7 days':
      return {
        val,
        range: [{ startDate: today.addDays(-7), endDate: today }],
      };
    case 'Last 30 days':
      return {
        val,
        range: [{ startDate: today.addDays(-30), endDate: today }],
      };
    case 'This Month':
      const startMonth = startOfMonth(today);
      const endMonth = endOfMonth(today);
      return {
        val,
        range: [{ startDate: startMonth, endDate: endMonth }],
      };
    case 'Last Month':
      const lastMonth = new Date(`${today.getFullYear()}-${today.getMonth()}-01`);
      const endLastMonthDate = endOfMonth(lastMonth);
      return {
        val,
        range: [{ startDate: lastMonth, endDate: endLastMonthDate }],
      };
    case 'This Quarter':
      const thisQuarterStarts = startOfQuarter(today);
      const endOfQuarter = endOfQuarter(today);
      return {
        val,
        range: [{ startDate: thisQuarterStarts, endDate: endOfQuarter }],
      };
    case 'Last Quarter':
      const lastQuarterDate = subQuarters(today, 1);
      return {
        val,
        range: [
          {
            startDate: startOfQuarter(lastQuarterDate),
            endDate: endOfQuarter(lastQuarterDate),
          },
        ],
      };
    case 'Last Semester':
      const lastSemester = subQuarters(today, 2);
      return {
        val,
        range: [
          {
            startDate: startOfQuarter(lastSemester),
            endDate: endOfQuarter(lastSemester),
          },
        ],
      };
    default:
      return { val, range: date };
  }
};

export const filterSubLinks = (data, link) => {
  const matchingSubLink = data
    .filter((item) => item.subLink)
    .flatMap((item) => item.subLink)
    .find((subItem) => subItem?.link?.split('/')[1] === link);

  const index = matchingSubLink
    ? data.findIndex((item) => item.subLink && item.subLink.includes(matchingSubLink))
    : -1;

  return index !== -1 ? [index] : null;
};

const secretKey = process.env.REACT_APP_ENCRYPT_SECRET_KEY;

// Function to encrypt and save data to localStorage
export const encryptAndSaveToLocalStorage = (key, data) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey,
    ).toString();
    localStorage.setItem(key, encryptedData);
  } catch (error) {
    console.error('Error persisting key');
  }
};

// Function to retrieve and decrypt data from localStorage
export const retrieveAndDecryptFromLocalStorage = (key) => {
  try {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(
        CryptoJS.enc.Utf8,
      );
      return JSON.parse(decryptedData);
    }
    return null;
  } catch (error) {
    return null;
  }
};

/**
 * Find duplicates in an array
 * @param {*} rows the array with the columns
 * @param {*} properties ["name", "email", "createdAt", "etc..."]
 * @returns array of integers
 */
export const findDuplicates = (rows, properties) => {
  const hashMap = {};
  const duplicatesIndexes = new Set();

  rows.forEach((row, index) => {
    // Create a unique key based on the criteria for identifying duplicates
    let key = '';
    properties.forEach(
      (property, index) => (key += `${index === 0 ? '' : '|'}${row[property]}`),
    );

    // Check if this combination has already been seen
    if (hashMap[key]) {
      duplicatesIndexes.add(+hashMap[key]).add(index); // If so, it's a duplicate
    } else {
      hashMap[key] = `${index}`; // Otherwise, store it in the hash map
    }
  });

  // this holds the indexes of the duplicates to be used to highlight them in the table
  return [...duplicatesIndexes];
};

export const hiddenChatRoutes = [
  '/receivables/invoices/create',
  '/expenses/bills/create',
  '/transactions/batch-payment',
];

import { ArrowLeft, ChevronDown } from 'assets/icons/index';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import { toastError } from 'components/UI/toast';
import useTextCounter from 'hooks/useTextCounter';
import { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getBalances } from 'redux/actions/BudgetsAction';
import { getCompanyLogoAsset } from 'redux/actions/CompaniesAction';
import {
  createInvoiceAction,
  fetchAllInvoices,
  fetchScheduledInvoice,
  fetchSingleInvoice,
  fetchSingleScheduledInvoice,
  updateInvoice,
  updateScheduledInvoice,
} from 'redux/actions/InvoiceAction';

import { RESET_FLAGS_INVOICE } from 'redux/reducers/InvoiceReducer';
import { MAXLENGTH, checkForEmptyValue } from 'utils';
import {
  bankLabelAliaser,
  getAvailableBalance,
  groupSourceOptions,
  recurrenceOption,
} from 'utils/helper';
import { PreviewInvoice } from './PreviewInvoice';
import CustomerInfo from './components/CustomersInfo';
import FooterRow from './components/FooterRow';
import InfoRow from './components/InfoRow';
import ProductsRow from './components/ProductsRow';
import './index.scss';

import { FullPageLoader } from 'components/UI/Loading';
import { Modal } from 'react-bootstrap';
import AddCustomerModal from './components/AddCustomerModal';
import ScheduleInvoice from './components/ScheduleInvoice';

import { Skeleton } from 'antd';
import { addDays, endOfMonth, endOfWeek, setHours, setMinutes } from 'date-fns';
import dayjs from 'dayjs';
import { getQueryParams } from 'utils/utility';

const MAX_LENGTH = MAXLENGTH?.invoiceNoteLength;

const terms = [
  { value: 0, label: 'Due on receipt' },
  { label: 'No due date' },
  { value: null, label: 'Due on specific date' },
  { value: 15, label: 'Due in 15 days' },
  { value: 30, label: 'Due in 30 days' },
  { value: 45, label: 'Due in 45 days' },
  { value: 60, label: 'Due in 60 days' },
  { value: 90, label: 'Due in 90 days' },
];

const instalmentOptions = [
  { value: 2, label: '2 instalments' },
  { value: 3, label: '3 instalments' },
  { value: 4, label: '4 instalments' },
];

const CreateInvoice = () => {
  const location = useLocation();
  const { push, goBack } = useHistory();
  const dispatch = useDispatch();
  const currencyOptions = [
    { label: 'Nigerian naira (NGN)', value: 'NGN' },
    { label: 'US dollar (USD)', value: 'USD' },
  ];

  const [originalInvoiceData, setOriginalInvoiceData] = useState();
  const [originalInstalmentPayload, setOriginalInstalmentPayload] = useState();
  const [invoiceData, setInvoiceData] = useState({
    invoiceNumber: '',
    purchaseOrder: '',
    customer: null,
    currency: { label: 'Nigerian naira (NGN)', value: 'NGN' },
    companyDetails: '',
    issuedDate: new Date(),
    dueDate: new Date(),
    startDate: '',
    expiryDate: '',
    repeats: recurrenceOption[1],
    shouldSplitTax: false,
    products: [
      {
        name: '',
        quantity: 1,
        unitPrice: '',
        description: '',
      },
    ],
    vat: '',
    discount_value: '',
    logo: null,
    description: '',
    account: null,
  });
  const [preview, setPreview] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isCustomerModal, setIsCustomerModal] = useState(false);
  const [discountType, setDiscountType] = useState('percentage');
  const [isRecurring, setIsRecurring] = useState(false);
  const [isInstalment, setIsInstalment] = useState(false);
  const [termsValue, setTermsValue] = useState(terms[0]);
  const [attachments, setAttachments] = useState([]);
  const [instalmentPayload, setInstalmentPayload] = useState({
    type: 'percentage',
    option: instalmentOptions[0],
    payments: [
      { amount: '', dueDate: '' },
      { amount: '', dueDate: '' },
    ],
  });

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const {
    createInvoice: { loading, success },
    fetchInvoice: {
      loading: isSingleLoading,
      success: invoiceSuccess,
      data: singleInvoiceData,
    },
    updateInvoice: { success: updateInvoiceSucces, loading: updateInvoiceLoading },
    fetchSingleScheduledInvoice: {
      loading: isScheduleLoading,
      success: scheduleSuccess,
      data: scheduledData,
    },
    updateScheduledInvoice: {
      success: updateScheduleSucces,
      loading: updateScheduleLoading,
    },
  } = useSelector(({ invoices }) => invoices);

  const {
    getCompanyAsset: { data: logoAsset },
    getCompany: { data: companyData = {} },
    updateCompany: { success: updateSuccess },
  } = useSelector(({ companies }) => companies);

  const isAmount = useMemo(() => {
    return instalmentPayload.type === 'amount';
  }, [instalmentPayload.type]);

  const isEdit = getQueryParams(location?.search, 'isEdit');
  const duplicate = getQueryParams(location?.search, 'duplicate');
  const recurring =
    getQueryParams(location?.search, 'isRecurring') || location.state?.isRecurring;
  const customerPayload = location?.state?.customerPayload;

  const isLoading = isSingleLoading || isScheduleLoading;
  const singleInvoice = recurring ? scheduledData : singleInvoiceData;

  const getCompanyLogo = (logo) => {
    dispatch(getCompanyLogoAsset(logo?.code));
  };

  useEffect(() => {
    if (isEdit) {
      if (recurring) {
        dispatch(fetchSingleScheduledInvoice({ code: isEdit }));
      } else {
        dispatch(fetchSingleInvoice({ code: isEdit }));
      }
    }

    if (recurring) {
      setIsRecurring(true);
      invoiceData.dueDate = '';
    }
  }, [isEdit, recurring]);

  useEffect(() => {
    if (duplicate) dispatch(fetchSingleInvoice({ code: duplicate }));
  }, [duplicate]);

  useEffect(() => {
    if (singleInvoice && !location?.state?.isNew) {
      const {
        invoiceProducts = [],
        discount_type = '',
        discount,
        installments = [],
      } = singleInvoice;

      const data = {
        invoiceNumber: singleInvoice.code,
        shouldSplitTax: !!singleInvoice.shouldSplitTax,
        purchaseOrder: singleInvoice.title || '',
        currency: currencyAliaser(singleInvoice.currency),
        dueDate: singleInvoice.due_date ? new Date(singleInvoice.due_date) : null,
        products: invoiceProducts.map((product) => {
          return {
            code: product?.code,
            name: product?.product?.name,
            quantity: product?.quantity,
            unitPrice: product?.product?.price / 100,
            description: product?.product?.description,
          };
        }),
        vat: singleInvoice.vat,
        discount_value: discount_type === 'amount' ? discount / 100 : discount,
        description: singleInvoice.description || '',
        customer: duplicate
          ? null
          : {
              label: singleInvoice?.customer?.name,
              value: singleInvoice?.customer,
            },
        account: accountAliaser(singleInvoice.settlementAccount),
        ...(recurring && {
          startDate: singleInvoice.startDate ? new Date(singleInvoice.startDate) : null,
          expiryDate: singleInvoice.expiryDate
            ? new Date(singleInvoice.expiryDate)
            : null,
        }),
      };

      const instalmentData = {
        type: installments[0]?.type,
        option: instalmentOptionAliaser(installments.length),
        payments: installments.map((instalment) => {
          return {
            amount:
              instalment?.type === 'percentage'
                ? instalment?.percentage
                : instalment?.amount / 100,
            dueDate: new Date(instalment?.due_date),
            code: instalment?.code,
          };
        }),
      };

      setDiscountType(discount_type);
      setIsInstalment(!!installments.length);

      setInstalmentPayload(instalmentData);
      setOriginalInstalmentPayload(JSON.parse(JSON.stringify(instalmentData)));

      setTermsValue(termsAliaser(singleInvoice?.terms));
      setInvoiceData({ ...invoiceData, ...data });
      setOriginalInvoiceData(JSON.parse(JSON.stringify(data)));
    }
  }, [invoiceSuccess, scheduleSuccess, singleInvoice]);

  useEffect(() => {
    if (updateInvoiceSucces) {
      push('/receivables/invoices');
      dispatch(fetchAllInvoices());
      dispatch({ type: RESET_FLAGS_INVOICE, blockType: 'createInvoice' });
    }
  }, [updateInvoiceSucces]);

  useEffect(() => {
    if (updateScheduleSucces) {
      push('/receivables/recurring-invoices');
      dispatch(fetchScheduledInvoice());
      dispatch({ type: RESET_FLAGS_INVOICE, blockType: 'createInvoice' });
    }
  }, [updateScheduleSucces]);

  useEffect(() => {
    if (companyData?.logo) getCompanyLogo(companyData?.logo);
    if (companyData?.description || customerPayload) {
      setInvoiceData({
        ...invoiceData,
        companyDetails: companyData?.description,
        customer: customerPayload,
      });
    }
  }, [companyData, updateSuccess, customerPayload]);

  const handleDiscountType = (value) => {
    setDiscountType(value);
    setInvoiceData({ ...invoiceData, discount_value: '' });
  };

  const currencyAliaser = (code) => {
    const { value = '', label = '' } =
      currencyOptions.find((currency) => currency.value === code) || {};
    return { value, label };
  };

  const termsAliaser = (val) => {
    const { value = '', label = '' } = terms.find((term) => term.value === val) || {};
    return { value, label };
  };

  const instalmentOptionAliaser = (value) => {
    if (value) {
      const option = instalmentOptions.find((option) => option.value === value);
      return option;
    }
  };

  const accountAliaser = (account) => {
    const {
      accountName,
      number: accountNumber,
      bankName: bank,
      bankCode,
    } = account?.bankAccount || {};

    const value = {
      accountName,
      accountNumber,
      bank,
      currency: account?.currency,
      value: account?.code,
    };

    return {
      ...value,
      label: bankLabelAliaser({ ...account, bankName: bank, bankCode }, 30),
    };
  };

  const onChangeProductInfo = (index, event) => {
    const { name, value, rawValue } = event.target;

    let products = invoiceData.products;
    let selectedProduct = products[index];
    const val = rawValue ? rawValue : value;

    let newValue = name === 'quantity' ? value.replace(/\D/g, '').slice(0, 5) : val;

    selectedProduct[name] =
      name === 'quantity' || name === 'unitPrice'
        ? !val
          ? null
          : newValue
        : !val
        ? ''
        : val;
    setInvoiceData({
      ...invoiceData,
      products,
    });
  };

  const onHandleAddnew = () => {
    setInvoiceData({
      ...invoiceData,
      products: [
        ...invoiceData.products,
        {
          name: '',
          quantity: 1,
          unitPrice: '',
        },
      ],
    });
  };

  const onHandelRowRemove = (index) => {
    const newRow = invoiceData?.products.filter((item, itemIndex) => itemIndex !== index);

    setInvoiceData({
      ...invoiceData,
      products: newRow,
    });
  };

  const onPercentageChange = ({ name, value }) => {
    setInvoiceData({
      ...invoiceData,
      [name]: value,
    });
  };

  // Calculate total and discounts
  let subTotal = invoiceData.products.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.quantity * currentValue.unitPrice;
  }, 0);

  const discount =
    discountType === 'percentage'
      ? Number((invoiceData?.discount_value / 100) * subTotal).toFixed(2)
      : !isNaN(invoiceData?.discount_value)
      ? invoiceData?.discount_value
      : 0;

  const newTotal = subTotal - discount;
  const vat = ((Number(invoiceData?.vat) / 100) * newTotal).toFixed(2);
  const totalAmount = (newTotal + Number(vat)).toFixed(2);

  //
  const onChange = (event) => {
    const { name, value, rawValue } = event.target;
    const val = rawValue ? rawValue : value;

    let subtotalWithVat = subTotal + Math.round(Number(invoiceData?.vat) / 100);

    if (name === 'discount_value' && discountType !== 'percentage') {
      let calculatedVal = Math.min(subtotalWithVat, parseFloat(val)).toString();

      return setInvoiceData({
        ...invoiceData,
        [name]: calculatedVal,
      });
    } else {
      return setInvoiceData({
        ...invoiceData,
        [name]: name === 'vat' ? Number(!val ? 0 : val) : val,
      });
    }
  };

  const handleEdit = (isSend) => {
    const isValid = handleValidation();
    if (!isValid) return;

    const editedProperties = {};
    const excludedProperties = [
      'account',
      'companyDetails',
      'issuedDate',
      'logo',
      'discount_value',
      'startDate',
      'expiryDate',
      'repeats',
      'currency',
    ];

    for (const key in invoiceData) {
      if (!excludedProperties.includes(key)) {
        if (key === 'products') {
          const products = invoiceData[key] || [];
          const editedProducts = products
            .map((product, index) => {
              let editedProduct = { code: product.code };
              const originalProduct = originalInvoiceData[key]?.[index];

              if (!originalProduct) return product;

              for (const prop in product) {
                if (product[prop] !== originalProduct?.[prop]) {
                  editedProduct[prop] = product[prop];
                }
              }
              return editedProduct;
            })
            .filter((product) => Object.keys(product).length);

          if (editedProducts.length) {
            editedProperties[key] = editedProducts;
          }

          // Extra Check for deletions
          if (originalInvoiceData?.products?.length > invoiceData?.products?.length) {
            editedProperties[key] = invoiceData?.products || [];
          }
        } else if (invoiceData[key] !== originalInvoiceData[key]) {
          editedProperties[key] = invoiceData[key];
        }
      }
    }

    const { dueDate, customer, purchaseOrder, ...updatedProperties } = editedProperties;
    if (isSend) updatedProperties.status = recurring ? 'active' : 'pending';
    if (purchaseOrder) updatedProperties.title = purchaseOrder;

    const installmentData = {
      type: instalmentPayload.type,
      payments: handleEditInstalment(),
    };

    const finalPayload = {
      ...updatedProperties,
      discount:
        discountType === 'amount'
          ? invoiceData.discount_value * 100
          : invoiceData.discount_value || 0,
      discount_type: discountType || 'percentage',
      customer: invoiceData.customer?.value?.code,
      due_date: dueDate,
      code: isEdit,
      products: updatedProperties.products.map((product) => {
        if (product.hasOwnProperty('unitPrice')) {
          return {
            ...product,
            unitPrice: product.unitPrice * 100,
          };
        }
        return product;
      }),
      currency: updatedProperties.currency?.value,
      installments: isInstalment ? installmentData : undefined,
      ...(recurring && {
        scheduleInvoice: {
          recurring,
          schedule: invoiceData.repeats?.value,
          startDate: {
            date: dayjs(invoiceData.startDate)?.format('YYYY-MM-DD'),
            timestamp: dayjs(invoiceData.startDate)?.format('HH:mm:ss'),
          },
          expiryDate: {
            date: dayjs(invoiceData.expiryDate)?.format('YYYY-MM-DD'),
            timestamp: dayjs(invoiceData.expiryDate)?.format('HH:mm:ss'),
          },
        },
      }),
    };

    if (recurring) {
      dispatch(updateScheduledInvoice(finalPayload));
    } else {
      dispatch(updateInvoice(finalPayload));
    }
  };

  const handleEditInstalment = () => {
    const changes = instalmentPayload.payments.reduce((acc, currentPayment, index) => {
      const originalPayment = originalInstalmentPayload.payments[index];

      Object.keys(currentPayment).forEach((key) => {
        if (
          index >= originalInstalmentPayload.payments.length ||
          currentPayment[key] !== originalPayment[key]
        ) {
          if (!acc[index]) {
            acc[index] = { code: currentPayment.code };
          }
          acc[index][key] = currentPayment[key];
        }
      });

      return acc;
    }, {});

    const formattedChanges = Object.values(changes).map((instalment) => {
      if (instalment.amount)
        return {
          code: instalment.code,
          due_date: instalment.dueDate,
          [instalmentPayload.type]:
            instalmentPayload.type === 'percentage'
              ? +instalment.amount
              : +instalment.amount * 100,
        };

      return {
        code: instalment.code,
        due_date: instalment.dueDate,
      };
    });

    return formattedChanges;
  };

  const onHandleSubmit = (isDraft, schedule) => {
    setIsButtonToggle(false);

    const isValid = handleValidation();
    if (!isValid) return;
    if (isEdit) return handleEdit(true);
    if (isRecurring) schedule = invoiceData.startDate;

    const date = dayjs(schedule);
    const startDate = {
      date: dayjs(schedule)?.format('YYYY-MM-DD'),
      timestamp: dayjs(schedule)?.format('HH:mm:ss'),
    };

    const expiryDate = {
      date: dayjs(invoiceData?.expiryDate)?.format('YYYY-MM-DD'),
      timestamp: dayjs(invoiceData?.expiryDate)?.format('HH:mm:ss'),
    };

    const scheduleInvoice = {
      recurring: isRecurring,
      startDate,
      ...(isRecurring && { expiryDate }),
      schedule: isRecurring
        ? invoiceData.repeats?.value
        : {
            minutes: date.minute(),
            hours: date.hour(),
            dayOfMonth: date.date(),
            month: date.format('MMMM').toLowerCase(),
            dayOfWeek: date.format('dddd').toLowerCase(),
          },
    };

    const instalmentData = {
      type: instalmentPayload.type,
      payments: instalmentPayload.payments?.map((payment) => {
        return {
          [instalmentPayload.type]:
            instalmentPayload.type === 'percentage'
              ? +payment.amount
              : +payment.amount * 100,
          due_date: payment.dueDate,
        };
      }),
    };

    const payload = {
      isDraft,
      terms: termsValue?.value,
      installments: isInstalment ? instalmentData : undefined,
      scheduleInvoice: schedule ? scheduleInvoice : undefined,
      customer: invoiceData.customer?.value?.code,
      settlementAccount: invoiceData.account?.value,
      description: invoiceData?.description ? invoiceData?.description : undefined,
      vat: invoiceData?.vat ? invoiceData?.vat : undefined,
      due_date: invoiceData?.dueDate ? invoiceData?.dueDate : undefined,
      discount:
        discountType !== 'percentage'
          ? Number(invoiceData?.discount_value * 100)
          : Number(invoiceData?.discount_value),
      discount_type: discountType,
      shouldSplitTax: invoiceData.shouldSplitTax,
      products: invoiceData?.products.map(({ code, description, ...product }) => {
        return {
          ...product,
          ...(description && { description }),
          unitPrice: product.unitPrice * 100,
          currency: invoiceData?.currency?.value,
        };
      }),
      attachments: attachments.map(({ assetCode }) => assetCode),
    };

    if (invoiceData?.purchaseOrder) {
      payload.title = invoiceData?.purchaseOrder;
    }

    delete payload.internationalFormat;
    dispatch(createInvoiceAction(payload));
  };

  const handlePaymentSwitch = () => {
    setInstalmentPayload((prevState) => ({
      ...prevState,
      payments: prevState.payments.map(() => ({
        amount: '',
        dueDate: '',
      })),
    }));
  };

  const handleValidation = () => {
    const errors = [];
    const { currency, issuedDate, products, customer } = invoiceData;

    if (!customer?.value) {
      errors.push('Please select a customer');
    }
    if (!currency?.value) {
      errors.push('Please select a currency');
    }
    if (!issuedDate) {
      errors.push('Please enter an issued date');
    }
    if (!products[0]?.name && !products[0]?.quantity && !products[0]?.unitPrice) {
      errors.push('Please enter the product details');
    }
    if (!invoiceData?.account) {
      errors.push('Please select a settlement account');
    }

    if (isRecurring) {
      if (!invoiceData?.startDate) {
        errors.push('Please select a start date');
      }

      if (!invoiceData?.expiryDate) {
        errors.push('Please select an end date');
      }
    }

    if (isInstalment) {
      const isPaymentValid = instalmentPayload?.payments?.every(
        (item) => item?.amount && item?.dueDate,
      );

      if (!isPaymentValid) {
        errors.push('Please set instalment settings');
      }

      if (amountSum < checkValue) {
        errors.push(
          isAmount
            ? 'Amount sum should equal invoice total'
            : 'Percentile values should sum up to 100',
        );
      }
    }

    const isDiscount =
      (discountType === 'percentage' && Number(invoiceData?.discount_value) === 100) ||
      (discountType !== 'percentage' && totalAmount === 0);
    if (totalAmount <= 0 && !isDiscount) {
      errors.push('Total amount must not be less than zero');
    }

    const payload = {
      products: invoiceData?.products?.map(({ description, ...rest }) => ({
        ...rest,
      })),
    };

    if (checkForEmptyValue(payload)) {
      errors.push('Please fill all Product details');
    }

    if (errors.length) {
      toastError(errors[0]);
      return false;
    }

    return true;
  };

  const onHandlePreview = () => {
    const isValid = handleValidation();
    if (isValid) setPreview(!preview);
  };

  const checkValue = isAmount ? totalAmount : 100;
  const amountSum = instalmentPayload.payments.reduce(
    (total, payment) => total + Number(payment.amount || 0),
    0,
  );

  const previewData = {
    ...invoiceData,
    customer: invoiceData?.customer?.value,
    logo: logoAsset?.asset?.url,
    discount: discount * 100,
    vatValue: +vat,
    totalAmount: +totalAmount,
    subTotal: subTotal,
    instalmentPayload,
  };

  useEffect(() => {
    if (!loading && success) {
      dispatch({ type: RESET_FLAGS_INVOICE, blockType: 'createInvoice' });

      const path = isRecurring
        ? '/receivables/recurring-invoices'
        : '/receivables/invoices';
      const action = isRecurring ? fetchScheduledInvoice() : fetchAllInvoices();

      dispatch(action);
      push(path);
    }
  }, [loading, success]);

  const handleBack = () => {
    dispatch({ type: RESET_FLAGS_INVOICE, blockType: 'createInvoice' });
    goBack();
  };

  const handleDescriptionChange = (value) => {
    setInvoiceData({
      ...invoiceData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    invoiceData?.description,
    MAX_LENGTH,
    handleDescriptionChange,
  );

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 30);
      setBudgetList(() => [...available_balance, ...budget]);
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search = '', loadedOptions, { page }) {
    setLoadPage(page);

    const filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    const filteredAccounts = filteredOption.filter(
      (item) => item?.type === 'balance' && !item?.subaccount && item?.currency === 'NGN',
    );

    const filteredBudgets = filteredOption.filter(
      (item) => item?.type === 'budget' && item?.currency === 'NGN',
    );

    const groupedOptions = groupSourceOptions([...filteredAccounts, ...filteredBudgets]);

    return {
      options: groupedOptions,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const getAccount = (value) => {
    setInvoiceData({
      ...invoiceData,
      account: value,
    });
  };

  const onToggleRecipient = (event, type) => {
    setInvoiceData({
      ...invoiceData,
      [type]: event.target.checked,
    });
  };

  const handleCustom = () => {
    setIsCustom(true);
    setIsButtonToggle(false);
  };

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const Actions = () => {
    const currentDate = new Date();

    const setTime = (day, hour, minute) => {
      return setMinutes(setHours(day, hour), minute);
    };

    const nextDayAtNine = setTime(addDays(currentDate, 1), 9, 0);
    const endOfWeekAtTwelve = setTime(endOfWeek(currentDate), 0, 0);
    const endOfMonthAtTwelve = setTime(endOfMonth(currentDate), 0, 0);

    return (
      <div className="actions-dialog">
        <div className="text-xs px-4 text-gray py-1">Save and send later</div>
        <div className="actionLink" onClick={() => onHandleSubmit(false, nextDayAtNine)}>
          Tomorrow at 9:00 AM
        </div>

        <div
          className="actionLink"
          onClick={() => onHandleSubmit(false, endOfWeekAtTwelve)}
        >
          End of this week
        </div>
        <div
          className="actionLink"
          onClick={() => onHandleSubmit(false, endOfMonthAtTwelve)}
        >
          End of this month
        </div>
        <div className="actionLink border-top" onClick={handleCustom}>
          Custom
        </div>
      </div>
    );
  };

  const InvoiceForm = (
    <div className="my-2 pb-4 border-top overview-holder">
      <CustomerInfo
        isLoading={isLoading}
        getCustomerValue={onChange}
        values={invoiceData}
        handleCustomerModal={setIsCustomerModal}
      />
      <InfoRow
        isLoading={isLoading}
        terms={terms}
        termsValue={termsValue}
        setTermsValue={setTermsValue}
        values={invoiceData}
        getInfoValue={onChange}
        currencyOptions={currencyOptions}
        isRecurring={isRecurring}
        setIsRecurring={setIsRecurring}
      />
      <ProductsRow
        isLoading={isLoading}
        onRemoveRow={onHandelRowRemove}
        values={invoiceData}
        getProductValue={onChangeProductInfo}
        onHandleAddnew={onHandleAddnew}
        discountType={discountType}
        handleDiscountType={handleDiscountType}
        onPercentageChange={onPercentageChange}
        subTotal={subTotal}
        getFooterValue={onChange}
        totalAmount={totalAmount}
        vat={vat}
      />
      <FooterRow
        isInstalment={isInstalment}
        setIsInstalment={setIsInstalment}
        isLoading={isLoading}
        values={invoiceData}
        getDescription={handleCharChange}
        totalAmount={totalAmount}
        max_length={MAX_LENGTH}
        text={text}
        charCount={charCount}
        loadingBalances={loadingBalances}
        loadOptions={loadOptions}
        getAccount={getAccount}
        instalmentOptions={instalmentOptions}
        instalmentPayload={instalmentPayload}
        setInstalmentPayload={setInstalmentPayload}
        isAmount={isAmount}
        amountSum={amountSum}
        checkValue={checkValue}
        handlePaymentSwitch={handlePaymentSwitch}
        getFooterValue={onChange}
        setAttachments={setAttachments}
      />
      <div className="w-100 d-flex justify-content-end">
        {isLoading ? (
          <div className="d-flex gap-3">
            {Array.from({ length: 3 }).map((_, i) => (
              <Skeleton.Button
                key={i}
                active
                size={12}
                style={{ borderRadius: '4px', height: 40, width: 100 }}
              />
            ))}
          </div>
        ) : (
          <div className="d-flex gap-1 gap-md-3 right-side position-relative">
            <div
              className="add-button add-custom w-100"
              onClick={() => push('/receivables/invoices')}
            >
              Cancel
            </div>

            <button
              className="add-button tab-hover-effect add-custom w-100 min-w-fit"
              onClick={() => (isEdit ? handleEdit() : onHandleSubmit(true))}
              disabled={updateInvoiceLoading}
            >
              Save as draft
            </button>

            <CustomButton className="add-button" onClick={onHandlePreview}>
              Preview invoice
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="position-relative">
      <Container className="my-4 py-2 create__invoice-container">
        <div
          className="back-click mb-3"
          onClick={preview ? () => setPreview(!preview) : handleBack}
        >
          <ArrowLeft color="#D28B28" height="10" width="10" />
          Back
        </div>
        <div className="d-flex justify-content-between flex-wrap">
          <div>
            {preview ? (
              <>
                <h1 className="headerText m-0">Send invoice</h1>

                <h6 className="subtext">
                  Invoice has been created. You can email it to your customer immediately
                  or schedule it to send later.
                </h6>
              </>
            ) : (
              <>
                <h1 className="headerText m-0">
                  {' '}
                  {isEdit ? 'Edit' : 'Create new'} {isRecurring ? 'recurring' : ''}{' '}
                  invoice
                </h1>
                <h6 className="subtext">
                  Fill in the details below to craft your invoice.
                </h6>
              </>
            )}
          </div>

          <div className="d-flex gap-1 gap-md-3 right-side position-relative">
            {isLoading ? (
              <div className="d-flex gap-3">
                {Array.from({ length: 3 }).map((_, i) => (
                  <Skeleton.Button
                    key={i}
                    active
                    size={12}
                    style={{ borderRadius: '4px', height: 40, width: 100 }}
                  />
                ))}
              </div>
            ) : (
              <>
                <button
                  className="add-button add-custom"
                  onClick={
                    preview ? onHandlePreview : () => push('/receivables/invoices')
                  }
                >
                  {preview ? 'Edit' : 'Cancel'}
                </button>

                <button
                  className="add-button tab-hover-effect add-custom min-w-fit"
                  onClick={() => (isEdit ? handleEdit() : onHandleSubmit(true))}
                  disabled={updateInvoiceLoading}
                >
                  Save as draft
                </button>

                {!preview ? (
                  <CustomButton
                    className="add-button"
                    onClick={onHandlePreview}
                    disabled={loading}
                  >
                    Preview invoice
                  </CustomButton>
                ) : (
                  <div className="d-flex">
                    <button
                      disabled={loading}
                      onClick={() => onHandleSubmit(false)}
                      className={`${
                        isRecurring && 'is-plain'
                      } px-3 nowrap dropdown-btn action-btn text-white`}
                    >
                      Save and send
                    </button>

                    {!isRecurring && (
                      <CustomPopover
                        zIndex="1"
                        content={<Actions />}
                        showPopover={isButtonToggle}
                        clickOutside={handleButtonToggle}
                      >
                        <button className="dropdown-btn" onClick={handleButtonToggle}>
                          <ChevronDown
                            color="#ffffff"
                            className={`toggle-icon ${isButtonToggle && 'is-toggled'}`}
                          />
                        </button>
                      </CustomPopover>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {preview ? (
          <PreviewInvoice
            preview={preview}
            data={previewData}
            isRecurring={isRecurring}
            isEdit={isEdit}
            handleEdit={handleEdit}
            onSubmit={onHandleSubmit}
            loading={loading}
            onHandlePreview={onHandlePreview}
            actions={Actions}
            isInstalment={isInstalment}
            updateInvoiceLoading={updateInvoiceLoading}
          />
        ) : (
          InvoiceForm
        )}
      </Container>
      <Modal
        show={isCustom}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ScheduleInvoice
          onClose={() => setIsCustom(false)}
          loading={loading}
          handleCreate={onHandleSubmit}
        />
      </Modal>

      <Modal
        show={isCustomerModal}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <AddCustomerModal
          isOpen={isCustomerModal}
          handleClose={() => setIsCustomerModal(false)}
        />
      </Modal>

      {loading || updateInvoiceLoading || updateScheduleLoading ? (
        <FullPageLoader />
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default CreateInvoice;

import { Timeline } from 'antd';
import { capitalizeFirstLetter } from 'utils/helper';
import ImgCard from 'components/UI/ImgCard';
import { motion, AnimatePresence } from 'motion/react';
import {
  Target02,
  DotsVertical,
  CheckIcon,
  ShieldTick,
  TransactionsIcon,
  CurrencyDollar,
  BankIcon,
  Wallet01,
  GridIcon,
  Building,
  FlipForward,
  Send03,
  LayoutGrid02,
  BankNoteO1Icon,
  CreditCardDown,
  ReceiptCheck,
  File06,
} from 'assets/icons';
import CurrencyFormat from 'react-currency-format';
import classNames from 'classnames';

const FlowChart = ({ data = {}, users = [] }) => {
  const { rules = [], approvers = [] } = data;

  const timeline = [
    ...rules,
    ...approvers.map((approver, index) => {
      return {
        ...approver,
        key: index + 1,
        type: 'approvals',
        reviewers: approver?.reviewers.map((code, i) => {
          const user = users[index].find((u) => u?.code === code);
          return {
            code,
            name: `${user?.firstName || ''} ${user?.lastName || ''}`,
          };
        }),
      };
    }),
  ];

  const animationVariants = {
    initial: { y: 20 },
    animate: { y: 0 },
    exit: { y: -20 },
  };

  const aliaseType = (type = '', key) => {
    if (type === 'when transaction') return 'When';

    if (type === 'approvals') {
      const isLast = key === approvers.length;
      return key === 1 ? 'It requires an approval from' : isLast ? 'Then' : 'Then, from';
    }

    return capitalizeFirstLetter(type);
  };

  const aliaseIcons = (key = '', rank) => {
    const approvalLength = approvers.length;
    const checkIcon = <CheckIcon width="14" height="14" stroke="#A0A09E" />;

    const ICON_MAP = {
      payments: <Send03 />,
      budget: <Wallet01 />,
      or: <DotsVertical />,
      batch: <LayoutGrid02 />,
      invoice: <ReceiptCheck />,
      amount: <CurrencyDollar />,
      reimbursements: <FlipForward />,
      'when transaction': <Target02 />,
      'card request': <CreditCardDown />,
      'fund request': <BankNoteO1Icon stroke="#5C5C5A" />,
      bill: <File06 height="16" width="16" stroke="#5C5C5A" />,
      vendor: <Building height="16" width="16" stroke="#5C5C5A" />,
      account: <BankIcon width="16" height="16" stroke="#5C5C5A" />,
      category: <GridIcon height="16" width="16" stroke="#5C5C5A" />,
      transaction: <TransactionsIcon width="16" height="16" stroke="#5C5C5A" />,
      'all transactions': <TransactionsIcon width="16" height="16" stroke="#5C5C5A" />,
      approvals:
        approvalLength > 1 && rank === approvalLength ? (
          <ShieldTick width="20" height="20" stroke="#A0A09E" />
        ) : (
          checkIcon
        ),
      and: (
        <span style={{ fontWeight: 400, fontSize: '1.125rem', color: '#A0A09E' }}>&</span>
      ),
    };

    return ICON_MAP[key.toLowerCase()] || checkIcon;
  };

  const IconPill = ({ text }) => {
    const aliasedText = text === 'type' ? 'transaction' : text;

    return (
      <div className="icon-pill">
        <span className="icon"> {aliaseIcons(aliasedText)} </span>
        <span className="text"> {capitalizeFirstLetter(aliasedText)} </span>
      </div>
    );
  };

  return (
    <div>
      <Timeline>
        <AnimatePresence mode="sync">
          {timeline.map((item, index) => {
            const isLast = timeline.length - 1 === index;
            const isAny = item?.threshold === 'any' || item?.threshold === 1;
            const sortDataPoint = (condition, target) =>
              item?.[condition]?.selectedValue?.[target] || '';

            const threeLabel = sortDataPoint('condition-three', 'label');
            const conditionOneValue = sortDataPoint('condition-one', 'value');
            const multipleItems = item?.['condition-three']?.selectedValue;
            const isMultiple =
              sortDataPoint('condition-two', 'value').indexOf('any') > -1;

            return (
              <motion.div
                key={item.key}
                variants={animationVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{
                  duration: 0.4,
                  ease: [0.4, 0, 0.2, 1],
                }}
                layout="position"
              >
                <Timeline.Item
                  className={classNames('fade-in-fast', {
                    'ant-timeline-item-last ': isLast,
                  })}
                  dot={aliaseIcons(item.type, item?.key)}
                >
                  <section>
                    <span className="timeline-header">
                      {aliaseType(item.type, item.key)}
                    </span>

                    <div
                      className={classNames('timeline-text mt-2', {
                        'gap-2': isMultiple,
                      })}
                    >
                      {item.type === 'approvals' ? (
                        <>
                          <div className="review-region ">
                            {item.reviewers.map((reviewer, i) => (
                              <div key={i} className="reviewer-holder">
                                <div className="fade-in-fast">
                                  <ImgCard initials={reviewer?.name?.charAt(0)} />
                                </div>

                                <span className="name">
                                  {capitalizeFirstLetter(reviewer?.name)}
                                </span>

                                {item?.reviewers?.length > 1 &&
                                  i !== item?.reviewers?.length - 1 && (
                                    <span className="inner-text">
                                      {isAny ? 'or' : 'and'}
                                    </span>
                                  )}

                                {approvers.length > 1 &&
                                  item?.key === approvers.length &&
                                  i === item?.reviewers?.length - 1 && (
                                    <span className="inner-text">must approve</span>
                                  )}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          {!!conditionOneValue && (
                            <div className="condition-one fade-in-fast me-1">
                              <IconPill text={conditionOneValue} />
                            </div>
                          )}
                          <div className="condition-two fade-in-fast me-1">
                            <span>{item?.['condition-two']?.selectedValue?.label}</span>
                          </div>

                          <div
                            className={classNames('condition-three fade-in-fast', {
                              'w-50': Array.isArray(multipleItems),
                            })}
                          >
                            {conditionOneValue === 'type' && !!threeLabel ? (
                              <div className="fade-in-fast">
                                <IconPill text={threeLabel} />
                              </div>
                            ) : Array.isArray(multipleItems) ? (
                              <div className="review-region ">
                                {(isMultiple ? multipleItems : [multipleItems[0]]).map(
                                  (item, i) =>
                                    item && (
                                      <div key={i} className="reviewer-holder">
                                        <div className="fade-in-fast">
                                          <ImgCard initials={item?.label?.charAt(0)} />
                                        </div>
                                        <span className="name">
                                          {capitalizeFirstLetter(item?.label)}
                                        </span>

                                        {multipleItems.length > 1 &&
                                          i !== multipleItems.length - 1 && (
                                            <span className="inner-text">or</span>
                                          )}
                                      </div>
                                    ),
                                )}
                              </div>
                            ) : conditionOneValue === 'amount' ? (
                              item?.['condition-three']?.amount && (
                                <div className="d-flex align-items-center gap-2">
                                  <div>
                                    <ImgCard initials="₦" />
                                  </div>
                                  <span className="text">
                                    <CurrencyFormat
                                      prefix={'NGN'}
                                      value={Number(
                                        item?.['condition-three']?.amount,
                                      )?.toFixed(2)}
                                      displayType="text"
                                      thousandSeparator={true}
                                      isNumericString
                                    />
                                  </span>
                                </div>
                              )
                            ) : (
                              !!threeLabel && (
                                <>
                                  <div className="fade-in-fast">
                                    <ImgCard initials={threeLabel?.charAt(0)} />
                                  </div>
                                  <span className="text"> {threeLabel} </span>
                                </>
                              )
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                </Timeline.Item>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </Timeline>
    </div>
  );
};

export default FlowChart;

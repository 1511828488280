import Table from 'components/Table';
import React, { useEffect, useState } from 'react';
import EditModal from './EditModal';
import { Modal } from 'react-bootstrap';
import ConfirmDialog from 'components/ConfirmDialog';
import Loading from '../Loading';

const PreviewUpload = ({
  tableColumns = [],
  title = '',
  description = '',
  handleCancel,
  data = [],
  setTableData,
  confirmData,
  loading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const remapData = data?.map((item) => ({ ...item, modify: true }));

  const pageObj = {
    perPage: 10,
    total: data.length,
  };

  const pageCounts = Math.ceil(Number(pageObj.total) / Number(pageObj.perPage));

  useEffect(() => {
    if (pageCounts === 1) setCurrentPage(1);
  }, [pageCounts]);

  const hasMore = currentPage < pageCounts;
  const startIndex = (currentPage - 1) * pageObj.perPage;
  const endIndex = startIndex + pageObj.perPage;
  const currentItems = remapData.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const closeModal = () => {
    setIsOpen(!isOpen);
  };

  const handleModify = (data) => {
    setSelectedRow(data);
    closeModal();
  };

  const handleDelete = (data) => {
    setSelectedRow(data);
    setIsDelete(true);
  };

  const handleDeleteClick = () => {
    let newData = [...data];
    const newTableData = newData.filter((item) => item.id !== selectedRow.id);

    setTableData(newTableData);
    setIsDelete(false);
  };

  if (!data.length) return;
  return (
    <div>
      <div className="border mt-4 rounded-3">
        <div className="section-title border-0 px-4">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
        <div className="upload-preview_table">
          <Table
            columns={tableColumns}
            data={currentItems}
            hasCheckBox={false}
            pagination
            hasMore={hasMore}
            currentPage={currentPage}
            nextPage={() => goToPage(currentPage + 1)}
            previousPage={() => goToPage(currentPage - 1)}
            totalPage={pageCounts}
            onDeleteClick={handleDelete}
            onEditClick={handleModify}
          />
        </div>
      </div>
      <div className="mt-4 border-top py-4 d-flex w-100">
        <div className="ms-auto d-flex gap-3">
          <button className="btn border sm" disabled={loading} onClick={handleCancel}>
            Cancel
          </button>
          <button className="btn black sm" disabled={loading} onClick={confirmData}>
            {loading ? <Loading color="#D28B28" size={18} /> : 'Confirm'}
          </button>
        </div>
      </div>

      <EditModal
        handleClose={closeModal}
        isOpen={isOpen}
        selectedRow={selectedRow}
        setTableData={setTableData}
        tableData={data}
        tableColumns={tableColumns}
      />

      <Modal
        show={isDelete}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title="Delete item from list"
          subTitle="Are you sure you want to delete this item? This action cannot be undone."
          onConfirm={handleDeleteClick}
          onCancel={() => setIsDelete(null)}
        />
      </Modal>
    </div>
  );
};

export default PreviewUpload;

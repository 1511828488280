import { LinkOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import MonoConnect from '@mono.co/connect.js';
import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  LayersTwoIcon,
  PlusDropDownIcon,
} from 'assets/icons';

import { SendFundsButton, ActiveButton } from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import { toastSuccess } from 'components/UI/toast';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleAction } from 'redux/actions/ToggleAction';
import AllowedTo from 'utils/AllowedTo';

const Header = ({
  setMonoCode,
  openModal,
  openDirectDebit,
  openSwapModal,
  canViewDash,
}) => {
  const [isButtonToggle, setIsButtonToggle] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const openCreateAccountModal = (type) => {
    openModal(type);
  };

  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => {
        // setIsFunding(false);
      },
      onSuccess: ({ code }) => {
        setMonoCode(code);
        monoInstance.close();
        toastSuccess('Account linking in progress...');
      },
      key: process.env.REACT_APP_MONO_CONNECT_KEY,
    });

    monoInstance.setup();
    return monoInstance;
  }, []);

  const linkAccount = () => {
    monoConnect.open();
  };

  const handleButtonToggle = (index) => {
    setIsButtonToggle(index);
  };

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const Actions = ({ type = 1 }) => {
    if (type === 1)
      return (
        <div className="actions-dialog">
          <div
            className="actionLink"
            onClick={() => {
              handleButtonToggle();
              openCreateAccountModal();
            }}
          >
            <PlusOutlined
              style={{
                verticalAlign: 0,
                fontSize: 14,
                paddingRight: 5,
              }}
            />
            Create an account
          </div>
          <div
            className="actionLink"
            onClick={() => {
              handleButtonToggle();
              openCreateAccountModal(2);
            }}
          >
            <PlusOutlined
              style={{
                verticalAlign: 0,
                fontSize: 14,
                paddingRight: 5,
              }}
            />
            Create a subaccount
          </div>

          <div
            className="actionLink"
            onClick={() => {
              handleButtonToggle();
              linkAccount();
            }}
          >
            <LinkOutlined
              style={{
                verticalAlign: 0,
                fontSize: 16,
                paddingRight: 0,
              }}
            />
            <span className="me-2 ">Link external account</span>
          </div>
        </div>
      );
    if (type === 2) {
      return (
        <div className="actions-dialog">
          <div
            className="actionLink"
            onClick={() => {
              openSwapModal();
              handleButtonToggle(0);
            }}
          >
            <span className="me-2 " style={{ fontSize: '0.875rem' }}>
              Move funds
            </span>
          </div>
          <div
            className="actionLink"
            onClick={() => {
              openDirectDebit();
              handleButtonToggle(0);
            }}
          >
            <span className="me-2 " style={{ fontSize: '0.875rem' }}>
              Setup direct debit
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>

        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>

        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  return (
    <div className="d-md-flex align-items-center w-100 justify-content-between pt-3 pb-3">
      <div className="my-2 my-lg-0 header-region">
        <div>
          <h1 className="page-title">Accounts</h1>
        </div>

        <div className="subText">
          <p className="m-0">
            Manage your cash accounts, subaccounts, external bank accounts in one place
          </p>
        </div>
      </div>

      {canViewDash && (
        <div className="me-auto space-x-4 p-0 d-flex w-100">
          <div className="p-0 d-flex flex-md-row flex-column w-100">
            <div className="ms-auto mb-md-0 mb-2 me-md-3 me-0 account-x-fund">
              <CustomPopover
                zIndex="1"
                content={<Actions type={1} />}
                showPopover={isButtonToggle === 1}
                clickOutside={() => handleButtonToggle(0)}
              >
                <ActiveButton
                  text="Add an account"
                  variant="light"
                  position="left"
                  onClick={() => handleButtonToggle(1)}
                  icon={<PlusDropDownIcon className="me-2" />}
                  customClass="add-custom d-flex align-items-center cursor w-100"
                />
              </CustomPopover>
            </div>

            <div>
              <AllowedTo scopes={['transaction-*', 'transaction-create']}>
                <CustomPopover
                  zIndex="1"
                  content={<Actions type={3} />}
                  showPopover={isButtonToggle === 2}
                  clickOutside={() => handleButtonToggle(0)}
                  customClassName="me-3"
                >
                  <SendFundsButton onClick={() => handleButtonToggle(2)} />
                </CustomPopover>
              </AllowedTo>
            </div>
          </div>

          <div className="w-0">
            <CustomPopover
              zIndex="1"
              content={<Actions type={2} />}
              showPopover={isButtonToggle === 3}
              clickOutside={() => handleButtonToggle(0)}
              customClassName="ms-auto"
            >
              <div
                onClick={() => handleButtonToggle(3)}
                className="add-custom d-flex align-items-center justify-content-center border-0 cursor min-w-fit w-fit"
              >
                <MoreOutlined style={{ fontSize: '130%' }} />
              </div>
            </CustomPopover>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

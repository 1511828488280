import { PlusOutlined } from '@ant-design/icons';
import { EditPencilIcon, ToggleIcon, TrashIcon } from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import {
  CategorizationStatusType,
  PolicyStatusType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import ExpensesEmptyStateData from 'pages/Expenses/expensesEmptystateData';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  deleteCategorizationRule,
  fetchCategorizationRules,
  fetchSingleCategorizationRule,
  updateCategorizationRule,
} from 'redux/actions/CategoryAction';
import { getPolicies } from 'redux/actions/PoliciesAction';
import { RESET_BLOCK_CATEGORY } from 'redux/reducers/CategoryReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { buildCategorizationTableData, capitalizeFirstLetter } from 'utils/helper';
import { categorizationRulesColumns } from 'utils/mockData';
import EditCategorizationRule from './EditCategorizationRule';
import SearchLoader from './Components/SearchLoader';

const CategoryCategorizationRules = ({ handleSelect, queryParams, tabKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { permissions } = allPermissions();
  const canCreate = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-create'],
  });
  const canView = hasPermission({
    permissions,
    scopes: ['policy-view'],
  });
  const canEdit = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-edit'],
  });
  const canDelete = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-delete'],
  });
  const [filterData, setFilterData] = useState([...CategorizationStatusType]);
  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const [singleCategorizationRule, setSingleCategorizationRule] = useState(null);
  const [filteredQuery, setFilteredQuery] = useState({
    ...queryParams,
    status: 'active',
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const {
    fetchCategorizationRules: {
      data: { categorizationRules = [], meta = {} } = {},
      loading,
    },
    deleteCategorizationRule: { success: deleteSuccess, loading: deleteLoading },
    updateCategorizationRule: { loading: editLoading, success: editSuccess },
  } = useSelector(({ categories }) => categories);

  const { page = 1, total, hasMore = false, perPage = 50, nextPage = 2 } = meta;

  const toggleHandler = () => {
    history.push(
      `/compliances/categories/${queryParams?.category}/categorization-rules/create`,
    );
  };

  useEffect(() => {
    if (canView && tabKey === 'categorization-rules')
      dispatch(fetchCategorizationRules({ ...filteredQuery }));

    return () => {
      if (filtered)
        if (canView) {
          dispatch(fetchCategorizationRules({ ...filteredQuery }));
        }
    };
  }, [filtered]);

  const handleFilter = (query) => {
    dispatch(fetchCategorizationRules({ ...filteredQuery, ...query }));
    setFilteredQuery({ ...filteredQuery, ...query });
    isFiltered.current = !!Object.keys(query).length;
  };

  const clearFilters = () => {
    setFilteredQuery({ ...queryParams });
    setFilterData([...PolicyStatusType]);
  };

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue && canView) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchCategorizationRules({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canView) {
      delete filteredQuery.search;
      dispatch(fetchCategorizationRules({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleRowClick = ({ categorizationData }) => {
    handleEditClick({ categorizationData });
  };

  const handleEditClick = ({ categorizationData }) => {
    setSingleCategorizationRule(categorizationData);
    setShowEditModal(true);
  };

  useEffect(() => {
    if (deleteSuccess || editSuccess) {
      handleCloseModal();
      setShowEditModal(false);
      dispatch({
        type: RESET_BLOCK_CATEGORY,
        blockType: 'fetchSingleCategorizationRule',
      });
      dispatch(fetchCategorizationRules({ status: 'active', ...filteredQuery }));
    }
  }, [deleteSuccess, editSuccess]);

  const handleActionClick = () => {
    if (data?.code) {
      if (actionType === 'delete')
        dispatch(
          deleteCategorizationRule({
            code: data.code,
            categoryCode: queryParams?.category,
          }),
        );
      if (actionType === 'disable')
        dispatch(
          updateCategorizationRule({
            code: data.code,
            categoryCode: queryParams?.category,
            status: 'inactive',
          }),
        );
      if (actionType === 'enable')
        dispatch(
          updateCategorizationRule({
            code: data.code,
            categoryCode: queryParams?.category,
            status: 'active',
          }),
        );
    }
  };

  const handleOpenModal = (actionType, data) => {
    setActionType(actionType);
    setData(data.categorizationData);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setData(null);
    setActionType('');
    setOpenModal(false);
  };

  const handlePreviousPage = (page) => {
    dispatch(getPolicies({ perPage, page, status: 'active', ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getPolicies({ perPage, page, status: 'active', ...filteredQuery }));
  };

  const actionHandler = (event, type, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (type === 'edit') handleEditClick(data);
    if (type === 'delete') handleOpenModal(type, data);
    if (type === 'disable') handleOpenModal(type, data);
    if (type === 'enable') handleOpenModal(type, data);
    setIsPopoverOpen(true);
  };

  const Actions = ({ list: selectedData }) => {
    const {
      categorizationData: { status },
    } = selectedData;
    return (
      <div className="actions-dialog">
        {canEdit && (
          <>
            {!['deleted', 'archived'].includes(status) && (
              <div
                className="actionLink"
                onClick={(event) => actionHandler(event, 'edit', selectedData)}
              >
                <EditPencilIcon />
                Edit
              </div>
            )}

            {canEdit && !['deleted', 'archived'].includes(status) && (
              <div
                className="actionLink"
                onClick={(event) =>
                  actionHandler(
                    event,
                    `${
                      selectedData?.categorizationData?.status === 'active'
                        ? 'disable'
                        : 'enable'
                    }`,
                    selectedData,
                  )
                }
              >
                <ToggleIcon />
                {selectedData?.categorizationData?.status === 'active'
                  ? 'Disable'
                  : 'Enable'}
              </div>
            )}
          </>
        )}

        {canDelete && status !== 'deleted' && (
          <div
            className="actionLink svg-danger text-danger"
            onClick={(event) => actionHandler(event, 'delete', selectedData)}
          >
            <TrashIcon width="16" height="16" className="me-0" />
            Delete
          </div>
        )}
      </div>
    );
  };

  const categorizationList = () => {
    return (
      <>
        {!categorizationRules.length && !loading ? (
          <div>
            {filtered ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no categorization rules for this filter"
                  bodyText="Alter filter to see categorization rules."
                  withButton={false}
                />
              </div>
            ) : (
              <ExpensesEmptyStateData
                createCategorization={toggleHandler}
                handleSelect={handleSelect}
                showAction={canCreate}
              />
            )}
          </div>
        ) : (
          <Container className="px-0">
            <Row className="py-2">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={categorizationRulesColumns}
                  data={buildCategorizationTableData(categorizationRules)}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  onRowClick={handleRowClick}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  type="budgets"
                  modify={canEdit || canDelete || canCreate}
                  loading={loading}
                  hasAvatar={false}
                />
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  };

  const show =
    !!categorizationRules?.length || (filtered && !categorizationRules?.length);

  return (
    <div className="budgets-wrapper position-relative mb-5">
      <Container className="policy-page">
        {loading && !filtered ? (
          <SearchLoader hasAddbtn={true} />
        ) : (
          <TopBar
            showBarSearch={show}
            searchVal={search}
            setSearchVal={setSearch}
            withOutSearch
            inputPlaceholder="search for rules"
            filterData={filterData}
            handleFilterSelect={(updateVal) => {
              setFilterData(updateVal);
            }}
            withDate
            exportLoading={true}
            showFilter={show}
            clearFilters={clearFilters}
            handleFilterApply={handleFilter}
            addIcon={show}
            customAddButton={
              <button onClick={toggleHandler} className="add-button add-action">
                <PlusOutlined
                  style={{
                    verticalAlign: 0,
                    fontSize: 14,
                    paddingLeft: 5,
                  }}
                />
                <span className="ms-1">Create new rule</span>
              </button>
            }
            addOnClick={toggleHandler}
          />
        )}

        {categorizationList()}
      </Container>

      {showEditModal && (
        <EditCategorizationRule
          singleCategorizationRule={singleCategorizationRule}
          setShowEditModal={setShowEditModal}
        />
      )}

      <Modal
        show={openModal}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(actionType)} category rule`}
          subTitle={`Are you sure you want to ${actionType} this category rule? This action cannot be undone.`}
          onConfirm={handleActionClick}
          loading={editLoading || deleteLoading}
          onCancel={handleCloseModal}
          isDeleteDialog={actionType !== 'enable'}
          actionBtnText={`${capitalizeFirstLetter(actionType)}`}
        />
      </Modal>
    </div>
  );
};

export default CategoryCategorizationRules;

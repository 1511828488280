import React, { useEffect, useState } from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import AccordionCard from 'components/UI/AccordionCard';
import CustomInput from 'components/UI/CustomInput';
import CustomButton from 'components/UI/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  logout,
  updateUserPasswordAction,
  update_user_profile,
} from '../../redux/actions/AuthAction';
import 'react-phone-number-input/style.css';
import {
  getInternationalFormat,
  validateNigeriaNumber,
  validatePassword,
} from '../../utils/helper';
import { useHistory } from 'react-router-dom';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import { toastError, toastSuccess } from 'components/UI/toast';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import { addYears, format } from 'date-fns';
import dayjs from 'dayjs';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Switch } from 'antd';

const UserProfile = () => {
  const {
    user,
    updatedUser: { loading, success },
    updateUserPassword,
  } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;
  const [activeKey, setActiveKey] = useState('0');
  const [openPinModal, setOpenPinModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSelect = (key) => {
    if (activeKey === key) return setActiveKey('999999999');
    setActiveKey(key);
  };

  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    localFormat: '',
    internationalFormat: '',
    countryCode: '',
    email: '',
    dob: '',
  });

  const [changePassword, setChangePassword] = useState({
    current_password: '',
    new_password: '',
    confirm_password: '',
  });

  const [changePin, setChangePin] = useState({
    pin: '',
    newPin: '',
    confirmPin: '',
    mismatch: false,
  });

  const onLogout = () => {
    localStorage.clear();
    dispatch(logout());
  };
  const handleChangePersonalInfo = ({ target: { name, value } }) => {
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const handleChangePassword = ({ target: { name, value } }) => {
    setChangePassword({ ...changePassword, [name]: value });
  };
  const handleChangePin = ({ target: { name, value } }) => {
    setChangePin({ ...changePin, [name]: String(value).substring(0, 4) });
  };

  useEffect(() => {
    if (
      changePin.newPin &&
      changePin.confirmPin &&
      changePin.newPin.length === 4 &&
      changePin.confirmPin.length === 4 &&
      changePin.newPin !== changePin.confirmPin
    ) {
      setChangePin({ ...changePin, mismatch: true });
    }
    if (
      !(changePin.newPin && changePin.confirmPin) ||
      changePin.newPin === changePin.confirmPin
    )
      setChangePin({ ...changePin, mismatch: false });
  }, [changePin.newPin, changePin.confirmPin]);

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setPersonalInfo({ ...personalInfo, internationalFormat, localFormat, countryCode });
  };

  const handleChangeDate = (value) => {
    setPersonalInfo({ ...personalInfo, dob: format(value, 'yyyy-MM-dd') });
  };

  const handleSubmit = () => {
    if (activeKey === '0') {
      if (!personalInfo.firstName) return toastError('Please enter your first name');
      if (!personalInfo.lastName) return toastError('Please enter your last name');

      if (
        !isValidPhoneNumber(personalInfo.internationalFormat) ||
        !validateNigeriaNumber(personalInfo.internationalFormat)
      )
        return toastError('Please enter a valid phone number');

      if (!personalInfo.email) return toastError('Please enter your email');
      if (!personalInfo.dob) return toastError('Please enter your date of birth');
      // const code = personalInfo.countryCode?.substring(1);
      const payloadData = {
        firstName: personalInfo.firstName,
        middleName: personalInfo.middleName || null,
        lastName: personalInfo.lastName,
        dob: personalInfo.dob,
        phoneNumber: {
          countryCode: String(personalInfo.countryCode),
          localFormat: personalInfo.localFormat,
        },
      };
      dispatch(update_user_profile(payloadData));
    }
    if (activeKey === '1') {
      const payloadData = {};
      if (
        changePassword.current_password ||
        changePassword.new_password ||
        changePassword.confirm_password
      ) {
        if (!changePassword.current_password)
          return toastError('Please enter your current password');
        if (!changePassword.new_password)
          return toastError('Please enter your new password');
        if (!changePassword.confirm_password)
          return toastError('Please confirm the password');
        if (changePassword.new_password !== changePassword.confirm_password)
          return toastError('Your password does not match.');
        if (!validatePassword(changePassword.new_password))
          return toastError('Please enter a stronger password');
        payloadData.old_password = changePassword.current_password;
        payloadData.password = changePassword.new_password;
      }
      if (changePin.pin || changePin.newPin || changePin.pin) {
        if (userData?.user.hasPin && !changePin.pin)
          return toastError('Please enter your current pin');
        if (!changePin.newPin) return toastError('Please enter your new pin');
        if (!changePin.confirmPin) return toastError('Please confirm the pin');
        if (userData?.user.hasPin) payloadData.pin = changePin.pin;
        payloadData.newPin = changePin.newPin;
      }
      dispatch(update_user_profile(payloadData));
    }
  };

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      const {
        firstName,
        dob,
        lastName,
        middleName,
        email,
        phoneNumber = {},
      } = userData?.user;
      setPersonalInfo({
        ...personalInfo,
        firstName: firstName || '',
        middleName: middleName || '',
        lastName: lastName || '',
        dob: dob || '',
        localFormat: phoneNumber?.localFormat || '',
        countryCode: phoneNumber?.countryCode || '',
        internationalFormat:
          getInternationalFormat(phoneNumber?.countryCode, phoneNumber?.localFormat) ||
          '',
        email: email || '',
      });
    }
  }, [userData]);

  useEffect(() => {
    if (success) {
      toastSuccess('Your profile has been updated successfully');
      setChangePassword({
        current_password: '',
        new_password: '',
        confirm_password: '',
      });
    }
    if (updateUserPassword.success) {
      toastSuccess('Your password has been updated successfully');
      window.setTimeout(function () {
        onLogout();
        history.push('/login', true);
      }, 1000);
    }
  }, [success, updateUserPassword.success]);
  const handlePinSetting = (checked) => {
    setOpenPinModal(checked);
    if (!checked) dispatch(update_user_profile({ pin: '' }));
  };

  return (
    <Row className="mb-5">
      <Col md={6} className="form-normal">
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
          <AccordionCard
            eventKey="0"
            activeKey={activeKey}
            title="Personal Information"
            handleClick={handleSelect}
            isLarge
          >
            <Row>
              <Col md={8}>
                <Row className="mb-3">
                  <CustomInput
                    type="text"
                    label="First name"
                    placeholder="Enter First name"
                    name="firstName"
                    value={personalInfo.firstName}
                    onChange={handleChangePersonalInfo}
                  />
                </Row>
                <Row className="mb-3">
                  <CustomInput
                    type="text"
                    label="Middle name (optional)"
                    placeholder="Enter Middle name (optional)"
                    name="middleName"
                    onChange={handleChangePersonalInfo}
                    value={personalInfo.middleName}
                  />
                </Row>
                <Row className="mb-3">
                  <CustomInput
                    type="text"
                    label="Last name"
                    placeholder="Enter Last name"
                    name="lastName"
                    onChange={handleChangePersonalInfo}
                    value={personalInfo.lastName}
                  />
                </Row>
                <Row className="mb-3">
                  <CustomPhoneNumberInput
                    label="Mobile number"
                    placeholder="Enter your mobile number"
                    onChange={(localFormat, international, countryCode) =>
                      handlePhoneNumberChange(localFormat, international, countryCode)
                    }
                    value={personalInfo.internationalFormat}
                  />
                </Row>

                <Row className="mb-3">
                  <CustomInput
                    type="email"
                    label="Email"
                    placeholder="Enter Email"
                    name="email"
                    onChange={handleChangePersonalInfo}
                    value={personalInfo.email}
                    disabled
                  />
                </Row>

                <Row className="mb-3">
                  <CustomDatePicker
                    label="Date of Birth *"
                    onChange={handleChangeDate}
                    peekNextMonth
                    id="dob"
                    name="dob"
                    maxDate={addYears(new Date(), -18)}
                    showMonthDropdown
                    showYearDropdown
                    disabled={!!userData?.user?.dob}
                    dropdownMode="select"
                    placeholderText="Date of Birth"
                    selected={personalInfo?.dob && dayjs(personalInfo?.dob).toDate()}
                  />
                </Row>
              </Col>
            </Row>
          </AccordionCard>

          {/******************** Security  ***************/}
          <AccordionCard
            eventKey="1"
            activeKey={activeKey}
            title="Security"
            handleClick={handleSelect}
            isLarge
          >
            <Row className="mb-3">
              <section className="d-flex justify-content-between align-center mt-4">
                <p className="fw-bold">Enable pin code to protect your payments</p>
                <Switch
                  className="switch-default"
                  checked={userData?.user.hasPin || openPinModal}
                  onChange={handlePinSetting}
                  disabled={false}
                />
              </section>
              {(userData?.user.hasPin || openPinModal) && (
                <section>
                  <Row>
                    {user.hasPin && (
                      <Row className="mb-3">
                        <CustomInput
                          type="password"
                          label="Current pin"
                          placeholder="Enter current pin"
                          name="pin"
                          onChange={handleChangePin}
                          value={changePin.pin}
                        />
                      </Row>
                    )}
                    <Row className="mb-3">
                      <CustomInput
                        type="password"
                        label="New pin"
                        placeholder="Enter new pin"
                        name="newPin"
                        onChange={handleChangePin}
                        value={changePin.newPin}
                      />
                    </Row>
                    <Row className="mb-3">
                      <CustomInput
                        type="password"
                        label="Confirm pin"
                        placeholder="Confirm pin"
                        name="confirmPin"
                        onChange={handleChangePin}
                        value={changePin.confirmPin}
                      />
                      {changePin.mismatch && (
                        <p className="mt-2">The two codes do not match please verify</p>
                      )}
                    </Row>
                  </Row>
                </section>
              )}
            </Row>
            <section>
              {' '}
              <p className="fw-bold">Change your password below</p>
            </section>
            <Row className="mb-3">
              <CustomInput
                type="password"
                label="Current password"
                placeholder="Enter current password"
                name="current_password"
                onChange={handleChangePassword}
                value={changePassword.current_password}
              />
            </Row>
            <Row className="mb-3">
              <CustomInput
                type="password"
                label="New password"
                placeholder="Enter new password"
                name="new_password"
                onChange={handleChangePassword}
                value={changePassword.new_password}
              />
            </Row>
            <Row className="mb-3">
              <CustomInput
                type="password"
                label="Confirm password"
                placeholder="Enter confirm password"
                name="confirm_password"
                onChange={handleChangePassword}
                value={changePassword.confirm_password}
              />
            </Row>
          </AccordionCard>
        </Accordion>

        <Row className="mt-2">
          <Col md={8}>
            <CustomButton
              loading={loading}
              disabled={changePin?.mismatch || loading}
              onClick={handleSubmit}
              fullWidth={true}
            >
              Save changes
            </CustomButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default UserProfile;

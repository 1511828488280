export const GET_APIKEY_REQUEST = 'GET_APIKEY_REQUEST';
export const GET_APIKEY_SUCCESS = 'GET_APIKEY_SUCCESS';
export const GET_APIKEY_ERROR = 'GET_APIKEY_ERROR';

export const DELETE_APIKEY_REQUEST = 'DELETE_APIKEY_REQUEST';
export const DELETE_APIKEY_SUCCESS = 'DELETE_APIKEY_SUCCESS';
export const DELETE_APIKEY_ERROR = 'DELETE_APIKEY_ERROR';
export const RESET_BLOCK_APIKEY = 'RESET_BLOCK_APIKEY';

export const RESET_FLAGS_APIKEY = 'RESET_FLAGS_APIKEY';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getApiKey: { ...block, data: {} },
  deleteApiKey: { ...block, data: [] },
};

export const ApiKeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APIKEY_REQUEST:
      return { ...state, getApiKey: { ...state.getApiKeys, loading: true } };
    case GET_APIKEY_SUCCESS:
      return {
        ...state,
        getApiKey: {
          ...state.getApiKey,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_APIKEY_ERROR:
      return {
        ...state,
        getApiKey: { ...state.getApiKey, loading: false, error: action.error },
      };

    case DELETE_APIKEY_REQUEST:
      return {
        ...state,
        deleteApiKey: { ...state.deleteApiKey, loading: true },
      };
    case DELETE_APIKEY_SUCCESS:
      return {
        ...state,
        deleteApiKey: {
          ...state.deleteApiKey,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_APIKEY_ERROR:
      return {
        ...state,
        deleteApiKey: {
          ...state.deleteApiKey,
          loading: false,
          error: action.error,
        },
      };
    //reset block with flag and data
    case RESET_BLOCK_APIKEY:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    default:
      return state;
  }
};

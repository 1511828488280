import {
  DeleteIcon,
  HintAlertIcon,
  MailIcon,
  NigeriaCrest,
  PlusIcon,
  ShieldAlertIcon,
  TrashIcon,
  UserEditIcon,
  UserPlusIcon,
} from 'assets/icons';
import ModalContainer from 'components/ModalContainer';
import Table from 'components/Table';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import ServerFileUpload from 'components/UI/FileUpload/ServerFileUpload';
import { toastError } from 'components/UI/toast';
import { addYears } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompany,
  getCountries,
  getInvitedDirectors,
  getState,
  inviteDirector,
  uploadDirectorInfo,
} from 'redux/actions/CompaniesAction';
import {
  buildDirectorsTableData,
  idType,
  removeEmptyString,
  validateNigeriaNumber,
} from 'utils/helper';
import { OwnersColumn } from 'utils/mockData';
import '../../styles.scss';

const BusinessOwner = () => {
  const [open, setOpen] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [businessOwner, setBusinessOwner] = useState({});
  const [businessOwnerDocument, setBusinessOwnerDocument] = useState({});

  const dispatch = useDispatch();
  const {
    getCompany: { loading: loadingCompany, data: companyData },
    inviteDirector: { loading: loadingInvite, success: successInvite },
    getCountry: { data: countryData = {}, loading: loadingCountry },
    getState: { data: states = {}, success: successState, loading: loadingState },
    uploadDirectorInfo: { loading: directorLoading, success: directorSuccess },
    getInvitedDirectors: { loading: directorsLoading, data: directors },
  } = useSelector(({ companies }) => companies);

  const [formList, setFormList] = useState([{ fullname: '', email: '' }]);

  const addForm = () => {
    setFormList([...formList, { fullname: '', email: '' }]);
  };

  const updateForm = (index, name, value) => {
    const updatedFormList = formList?.map((form, i) =>
      i === index ? { ...form, [name]: value } : form,
    );
    setFormList(updatedFormList);
  };

  const removeForm = (index) => {
    const updatedFormList = formList?.filter((_, i) => i !== index);
    setFormList(updatedFormList);
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setBusinessOwner({
      ...businessOwner,
      internationalFormat,
      localFormat,
      countryCode,
    });
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setBusinessOwner({ ...businessOwner, [name]: value });
  };

  useEffect(() => {
    if (businessOwner.percentageOwned > 100)
      setBusinessOwner({ ...businessOwner, percentageOwned: 100 });
  }, [businessOwner.percentageOwned]);

  const handleChangeDate = (value) => {
    setBusinessOwner({
      ...businessOwner,
      dob: dayjs(value).format('YYYY-MM-DD'),
    });
  };

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData
        ?.filter((item) => item.name === 'NIGERIA')
        ?.map(({ code: value, name: label }) => ({
          value,
          label,
          isDisabled: label !== 'NIGERIA' ? true : false,
        }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  useEffect(() => {
    if (!countryData?.length) dispatch(getCountries());
    if (companyData?.code && !directors?.length)
      dispatch(getInvitedDirectors(companyData?.code));
  }, [companyData?.code]);

  const getCountryStates = async (value) => {
    dispatch(getState(value.value));
  };

  useEffect(() => {
    if (directorSuccess) {
      setOpen(false);
      dispatch(getInvitedDirectors(companyData?.code));
      dispatch(
        getCompany({
          code: companyData?.code,
          includeDocuments: true,
          includeSteps: true,
        }),
      );
    }
    if (successInvite) {
      setOpenInvite(false);
      dispatch(getInvitedDirectors(companyData?.code));
    }
  }, [successInvite, directorSuccess]);

  const handleUploadDirectorInfo = () => {
    if (!businessOwner.percentageOwned)
      return toastError('Please enter percentage owned');
    if (+businessOwner.percentageOwned < 5)
      return toastError('Percentage owned must be at least 5%');
    if (!businessOwner.firstName) return toastError('Please enter first name');
    if (!businessOwner.lastName) return toastError('Please enter last name');
    if (!businessOwner.dob) return toastError('Please enter date of birth');
    if (!businessOwner.bvn) return toastError('Please enter BVN');
    if (!businessOwner.email) return toastError('Please enter email');
    if (
      !isValidPhoneNumber(String(businessOwner?.internationalFormat)) ||
      !validateNigeriaNumber(String(businessOwner?.internationalFormat))
    )
      return toastError('Please enter a valid phone number');

    if (!businessOwner.country) return toastError('Please select country');
    if (!businessOwner.state) return toastError('Please select state');
    if (!businessOwner.address) return toastError('Please enter address');
    if (!businessOwnerDocument.utilityBill)
      return toastError('Please upload a copy of the proof of address');

    if (!businessOwner.idType) return toastError('Please select ID type');
    if (!businessOwner.idNumber)
      return toastError(`Please enter ${businessOwner.idType.value} number`);

    if (!businessOwnerDocument.idCopy)
      return toastError('Please upload a copy of the ID');

    const payload = {
      code: companyData?.code,
      firstName: businessOwner.firstName,
      lastName: businessOwner.lastName,
      email: businessOwner.email,
      dob: businessOwner.dob,
      bvn: businessOwner.bvn,
      percentageOwned: businessOwner.percentageOwned,
      phoneNumber: {
        countryCode: businessOwner.countryCode,
        localFormat: businessOwner.localFormat,
      },
      address: {
        country: businessOwner.country.value,
        state: businessOwner.state?.value,
        city: businessOwner.city,
        street: businessOwner.address,
      },
      documents: {
        idType: businessOwner.idType.value,
        idNumber: businessOwner.idNumber,
        idCopy: businessOwnerDocument.idCopy,
        utilityBill: businessOwnerDocument.proofOfAddress,
      },
    };
    dispatch(uploadDirectorInfo(payload));
  };

  const handleSendInvite = () => {
    if (!formList[0].fullname) return toastError('Please enter full name');
    if (!formList[0].email) return toastError('Please enter email');

    dispatch(
      inviteDirector({ code: companyData?.code, directors: removeEmptyString(formList) }),
    );
  };

  const CharLength = { nin: 11, ip: 11, vi: 9, dl: 12 };
  return (
    <div className="business-owner-container">
      <div className="title">
        <h3>Business owners</h3>
        <p>Please provide information for anyone who owns 70% or more of the business.</p>
      </div>
      <div className="space-y-2 border-bottom bg-transparent pb-3 bg-transparent">
        <div className="business-owner-action ">
          <div>
            <UserEditIcon />
          </div>
          <div>
            <h6>Add owner details manually</h6>
            <p>Quickly input business owner details</p>
          </div>
          <button onClick={() => setOpen(true)} className="btn border xxs ms-auto">
            Add details
          </button>
        </div>
        {/*  */}
        <div className="business-owner-action">
          <div>
            <UserPlusIcon />
          </div>
          <div>
            <h6>Invite owners to provide details</h6>
            <p>Send a secure Bujeti link to request details</p>
          </div>
          <button onClick={() => setOpenInvite(true)} className="btn border xxs ms-auto">
            Request details
          </button>
        </div>
      </div>

      <div className="mt-3">
        {!!directors?.length ? (
          <Table
            columns={OwnersColumn}
            data={buildDirectorsTableData(directors)}
            hasCheckBox={false}
          />
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center p-4">
            <h5>You have no invited directors</h5>
            <p>All director invited will appear here!</p>
          </div>
        )}
      </div>

      <Modal
        show={open}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ModalContainer
          lg
          onCancel={() => setOpen(false)}
          onConfirm={handleUploadDirectorInfo}
          loading={directorLoading}
          actionBtnText="Save"
          title="Add business owner"
          subTitle="Please provide information for anyone who owns 10% or more of the business."
        >
          <div className="border-bottom bg-transparent pb-3">
            <Row className="mb-2">
              <CustomInput
                type="text"
                label="Percentage owned"
                placeholder="Ex. 10%"
                name="percentageOwned"
                onChange={handleInputChange}
                value={businessOwner.percentageOwned}
                isNumber={false}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength="3"
              />
            </Row>
          </div>

          <div className="pt-4 border-bottom bg-transparent pb-3">
            <h6 className="text-md p-0 m-0 fw-semibold">Personal details</h6>

            <Row className="mb-2 mt-2 align-items-center">
              <CustomInput
                type="text"
                label="First name"
                placeholder="Enter first name"
                name="firstName"
                onChange={handleInputChange}
                value={businessOwner.firstName}
                md={6}
              />

              <CustomInput
                type="text"
                label="Last name"
                placeholder="Enter last name"
                name="lastName"
                onChange={handleInputChange}
                value={businessOwner.lastName}
                md={6}
              />
            </Row>

            <Row className="mb-2">
              <CustomDatePicker
                label="Date of birth"
                name="dob"
                onChange={handleChangeDate}
                maxDate={addYears(new Date(), -18)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="DD / MM / YYYY"
                selected={businessOwner.dob && dayjs(businessOwner.dob).toDate()}
              />
            </Row>

            <Row className="mb-2">
              <CustomInput
                type="text"
                label="Bank Verification Number (BVN)"
                placeholder="11234567890"
                name="bvn"
                onChange={handleInputChange}
                value={businessOwner.bvn}
                maxLength="11"
              />
              <Form.Text className="text-muted gap-1 d-flex align-items-center">
                <ShieldAlertIcon />
                <span className="text-xs">
                  Your BVN is used for identify verification and secured with encryption.
                </span>
              </Form.Text>
            </Row>
          </div>

          <div className="pt-4 border-bottom bg-transparent pb-3">
            <h6 className="text-md p-0 m-0 fw-semibold">Contact information</h6>

            <Row className="mb-2 mt-2">
              <CustomPhoneNumberInput
                label="Phone number"
                placeholder="Enter your mobile number"
                onChange={(localFormat, international, countryCode) =>
                  handlePhoneNumberChange(localFormat, international, countryCode)
                }
                value={businessOwner.internationalFormat}
              />
            </Row>

            <Row className="mb-2">
              <CustomInput
                type="text"
                label="Enter your email"
                placeholder="joe@mail.com"
                name="email"
                onChange={handleInputChange}
                value={businessOwner.email}
              />
            </Row>

            <Row className="mb-2">
              <CustomSelect
                label="Country"
                name="country"
                placeholder="Select country"
                onChange={(value) => {
                  setBusinessOwner({ ...businessOwner, country: value });
                  getCountryStates(value);
                }}
                value={businessOwner.country}
                options={generateCountry}
                isLoading={loadingCountry}
                isCountry
              />
            </Row>
            <Row className="mb-2">
              <CustomSelect
                label="State"
                name="state"
                placeholder="Select country"
                onChange={(value) => {
                  setBusinessOwner({ ...businessOwner, state: value });
                }}
                value={businessOwner.state}
                options={generateState}
                isLoading={loadingState}
              />
            </Row>

            <Row className="mb-2">
              <CustomInput
                label="City"
                name="city"
                placeholder="Enter city"
                onChange={handleInputChange}
                value={businessOwner.city}
              />
            </Row>

            <Row className="mb-2">
              <CustomInput
                label="Home address"
                name="address"
                placeholder="Enter home address"
                onChange={handleInputChange}
                value={businessOwner.address}
              />
            </Row>

            <Row className="mb-2">
              <ServerFileUpload
                label="Proof of Address"
                supportType="JPEG, PNG, and PDF formats, up to 5 MB."
                uploadedFile={businessOwnerDocument.utilityBill}
                onChange={({ assetCode } = {}) =>
                  setBusinessOwnerDocument({
                    ...businessOwnerDocument,
                    utilityBill: assetCode,
                  })
                }
                setUploadingFile={setUploadingFile}
                uploadingFile={uploadingFile}
                name={`owner-proof-address`}
              />

              <Form.Text className="text-muted gap-1 d-flex align-items-start flex-column">
                <div className="p-0 m-0 d-flex gap-1 align-items-start pb-2 text-xs">
                  <div>
                    <HintAlertIcon />
                  </div>
                  Upload a copy of your bank statement, utility bill, phone bill, tax
                  assessment, or any government-issued document.
                </div>

                <ul className="py-0 my-0 text-xs">
                  <li> All documents must be less than 3 months old</li>
                  <li>
                    And must include the name of the identified individual or business.
                  </li>
                </ul>
              </Form.Text>
            </Row>
          </div>

          <div className="pt-4 pb-3">
            <h6 className="text-md p-0 m-0 fw-semibold">Identity verification</h6>

            <Row className="mb-2 mt-2">
              <CustomSelect
                label="Document type"
                name="idType"
                placeholder="Select document type"
                onChange={(value) =>
                  setBusinessOwner({ ...businessOwner, idType: value })
                }
                value={businessOwner.idType}
                options={idType}
              />
            </Row>

            <Row className="mb-2">
              <CustomInput
                type="text"
                label="ID number"
                placeholder="11234567890"
                name="idNumber"
                maxLength={CharLength[businessOwner?.idType?.value]}
                onChange={handleInputChange}
                value={businessOwner.idNumber}
                disabled={!businessOwner?.idType}
              />
            </Row>

            <Row className="mb-2">
              <ServerFileUpload
                label="Upload ID"
                supportType="JPEG, PNG, and PDF formats, up to 5 MB."
                uploadedFile={businessOwnerDocument.utilityBill}
                onChange={({ assetCode } = {}) =>
                  setBusinessOwnerDocument({
                    ...businessOwnerDocument,
                    idCopy: assetCode,
                  })
                }
                setUploadingFile={setUploadingFile}
                uploadingFile={uploadingFile}
                name={`owner-doc-id`}
              />
            </Row>
          </div>
        </ModalContainer>
      </Modal>

      <Modal
        show={openInvite}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ModalContainer
          lg
          onCancel={() => setOpenInvite(false)}
          actionBtnText={
            <span className="d-flex align-items-center gap-2">
              <MailIcon width={18} height={18} stroke="#fff" /> Send email
            </span>
          }
          onConfirm={handleSendInvite}
          loading={loadingInvite}
          disabled={uploadingFile}
          title="Request business owner details"
          subTitle="Send a secure Bujeti link to request details. Pro tip: Give them a heads-up first."
        >
          {formList.map((item, index) => (
            <div key={index} className="d-flex align-items-center">
              <Row className="mb-2 gap-md-0 gap-2 align-items-center w-100">
                <CustomInput
                  type="text"
                  label="Full name"
                  placeholder="Enter full name"
                  name={`${item.name}-${index}`}
                  onChange={(value) => updateForm(index, 'fullname', value.target.value)}
                  value={item.fullname}
                  md={6}
                />

                <CustomInput
                  type="text"
                  label="Email"
                  placeholder="Enter email"
                  name={`${item.email}-${index}`}
                  onChange={(value) => updateForm(index, 'email', value.target.value)}
                  value={item.email}
                  md={6}
                />
              </Row>

              {index > 0 && (
                <DeleteIcon
                  className="cancel-business-owner cursor ms-3 mt-4"
                  onClick={() => removeForm(index)}
                />
              )}
            </div>
          ))}
          <div
            onClick={addForm}
            className="d-flex align-items-center gap-2 text-sm text-grey mt-3 cursor"
          >
            <PlusIcon stroke="#57534E" /> Add another
          </div>
        </ModalContainer>
      </Modal>
    </div>
  );
};

export default BusinessOwner;

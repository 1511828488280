import ConfirmDialog from 'components/ConfirmDialog';
import Modal from 'components/UI/Modal';
import { useEffect, useRef, useState } from 'react';
import { Modal as Dialog } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import {
  fetchSingleInvoice,
  fetchSingleScheduledInvoice,
  markInvoiceAsPaid,
  shareInvoiceAction,
  updateInvoice,
} from 'redux/actions/InvoiceAction';

import { EyeOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Avatar, Skeleton, Timeline } from 'antd';
import {
  CoinsStacked,
  CopyLink,
  EditIcon,
  KebabIcon,
  PencilIcon,
  SquareCheckBox,
  TimelineCheckIcon,
  TimelineDot,
  XcloseIcon,
} from 'assets/icons';

import CurrencyFormat from 'react-currency-format';
import {
  capitalizeFirstLetter,
  getColor,
  getCurrency,
  invoiceStatusAliaser,
  transactionAcceptedFiles,
} from 'utils/helper';
import { getQueryParams } from 'utils/utility';

import ImgCard from 'components/UI/ImgCard';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import BadgeType from 'components/UI/Table/BadgeType';
import { toastSuccess } from 'components/UI/toast';
import DownloadPdfComponent from '../components/DownloadPdfComponent';
import InvoiceAsset from './InvoiceAsset';
import { useMediaQuery } from 'react-responsive';
import LoadingReciept from 'components/UI/CustomDrawer/components/LoadingReciept';
import NewReceiptUpload from 'components/UI/CustomDrawer/components/NewReceiptUpload';

const InvoiceDetailModal = ({
  code,
  isOpen,
  handleClose,
  copyLink,
  handleDelete,
  companyLogo,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isRecurring = getQueryParams(location?.search, 'recurring');
  const pdfDownloadRef = useRef(null);

  const {
    getCompany: { data: companyData },
  } = useSelector(({ companies }) => companies);

  const PAYMENT_TYPE_ENUM = {
    PARTIAL: 'partial',
    COMPLETE: 'complete',
  };

  const [visible, setVisible] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [instalmentCode, setInstalmentCode] = useState('');
  const [actionModifier, setActionModifier] = useState(true);

  const {
    fetchInvoice: { loading: invoiceLoading, data: invoiceData },
    shareInvoice: { loading: isSharing, success: shareSuccess },
    fetchSingleScheduledInvoice: { loading: isLoading, data: scheduledData },
    markInvoiceAsPaid: { loading: isMarking, success: markedAsPaid },
  } = useSelector(({ invoices }) => invoices);

  const loading = invoiceLoading || isLoading;
  const data = isRecurring ? scheduledData : invoiceData;
  const { installments: payments, timeline = [] } = data || {};

  useEffect(() => {
    fetchInvoiceDetail();
  }, []);

  const fetchInvoiceDetail = () => {
    if (!code) return;
    if (isRecurring) {
      dispatch(fetchSingleScheduledInvoice({ code }));
    } else {
      dispatch(fetchSingleInvoice({ code: `${code}?includeTimeline=${true}` }));
    }
  };

  useEffect(() => {
    if (code) {
      fetchInvoiceDetail();
    }
  }, [code]);

  useEffect(() => {
    if (shareSuccess) {
      handleClose();
    }
  }, [shareSuccess]);

  useEffect(() => {
    if (markedAsPaid && code) {
      resetModification();
      fetchInvoiceDetail();
    }
  }, [markedAsPaid, code]);

  if (!isOpen) {
    return <div />;
  }

  const handleAmount = (payment) => {
    const { type, percentage, amount } = payment;
    if (type === 'percentage') {
      return (((percentage / 100) * data.amount) / 100).toFixed(2);
    }

    return amount / 100;
  };

  const handleDraft = (type = '') => {
    history.push({
      pathname: '/receivables/invoices/create',
      state: { isRecurring },
      search: `?${type ? 'duplicate' : 'isEdit'}=${code}`,
    });
  };

  const handleConfirmDialog = (type, code = '') => {
    setVisible(false);
    setActionModifier(true);
    setPaymentType(type);
    setInstalmentCode(code);
  };

  const hadleMarkasPaid = () => {
    dispatch(
      markInvoiceAsPaid(code, {
        ...(paymentType === PAYMENT_TYPE_ENUM.PARTIAL && {
          installments: [instalmentCode],
        }),
      }),
    );
  };

  const handleCloseDrawer = (event) => {
    const buttonId = event?.currentTarget?.id === 'close-icon';
    if (actionModifier && !buttonId) return;

    handleClose();
    resetModification();
  };

  const sortLink = () => {
    const windowLink = window.location.href;
    if (windowLink.includes('localhost') || windowLink.includes('dashboard-staging')) {
      return `https://dashboard-staging.bujeti.com/collections/invoices/details/${data?.hash}`;
    } else {
      return `https://www.bujeti.com/invoices/${data?.hash}`;
    }
  };

  const resetModification = () => {
    setPaymentType('');
    setActionModifier(true);
  };

  const SkeletonLoader = () => (
    <section>
      <div className="d-flex align-items-center justify-content-between mb-4 pb-1 invoice-details-pad">
        <Skeleton.Input active size={24} style={{ borderRadius: '4px' }} />
        <Skeleton.Avatar active size={14} />
      </div>

      <div className="customer-details-region mt-3">
        <Skeleton.Avatar active size={48} />
        <Skeleton.Input
          className="pt-4"
          active
          size={24}
          style={{ borderRadius: '4px', width: '14rem' }}
        />
        <Skeleton.Input
          className="pt-2"
          active
          size={12}
          style={{ borderRadius: '4px', width: '7rem', minWidth: '7rem' }}
        />

        <Skeleton.Input
          className="pt-4"
          style={{ borderRadius: '40px', width: '4rem', minWidth: '4rem', height: 32 }}
        />
      </div>
      <section
        className={`invoice-detail-holder mt-4 pb-5 ${isRecurring ? 'minHeight' : ''}`}
      >
        {Array.from({ length: 3 }).map((_, i) => (
          <div key={i} className="detail">
            <Skeleton.Input
              style={{ borderRadius: '4px', width: '6rem', minWidth: '6rem', height: 12 }}
            />

            <Skeleton.Input
              className="ms-auto"
              style={{ borderRadius: '4px', width: '6rem', minWidth: '6rem', height: 12 }}
            />
          </div>
        ))}

        <div className="mt-2 d-flex gap-3 w-100">
          <Skeleton.Input
            block
            style={{ borderRadius: '8px', width: '100%', height: 32 }}
          />
          <Skeleton.Input
            block
            style={{ borderRadius: '8px', width: '100%', height: 32 }}
          />
        </div>

        <div className="timeline-region pt-4 mt-4">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton className="mb-3" key={index} loading active avatar />
          ))}
        </div>

        <section className="payment-schedule-region mt-4">
          <Skeleton.Input
            className="mb-3"
            active
            size={24}
            style={{ borderRadius: '4px' }}
          />

          {Array.from({ length: 5 }).map((_, i) => (
            <div className="schedule-holder mb-4" key={i}>
              <div className="d-flex align-items gap-3">
                <Skeleton.Avatar active size={40} />

                <div>
                  <Skeleton.Input
                    style={{
                      borderRadius: '8px',
                      width: '6rem',
                      minWidth: '6rem',
                      height: 12,
                    }}
                  />

                  <div>
                    <Skeleton.Input
                      style={{
                        borderRadius: '8px',
                        width: '9rem',
                        minWidth: '9rem',
                        height: 12,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="ms-auto d-flex flex-column">
                <div>
                  <Skeleton.Input
                    style={{
                      borderRadius: '8px',
                      width: '7rem',
                      minWidth: '7rem',
                      height: 12,
                    }}
                  />
                </div>

                <Skeleton.Input
                  style={{
                    borderRadius: '8px',
                    width: '4rem',
                    minWidth: '4rem',
                    height: 12,
                  }}
                />
              </div>
            </div>
          ))}
        </section>
      </section>
      <div className="footer-region invoice-details-pad w-100">
        <Skeleton.Input
          active
          style={{ borderRadius: '8px', width: '100%', minWidth: 230, height: 40 }}
        />
        <Skeleton.Input
          active
          style={{ borderRadius: '8px', width: '100%', minWidth: 230, height: 40 }}
        />
      </div>
    </section>
  );

  const InvoiceDetail = (data) => {
    const handleAction = (type) => {
      let url = sortLink();

      if (type === 'copy') {
        navigator.clipboard.writeText(url);
        toastSuccess('Copied to clipboard successfully!');
      } else {
        sessionStorage.setItem('isPreview', JSON.stringify('true'));
        window.open(url, '_blank');
      }
    };

    const sendReminder = () => {
      return dispatch(shareInvoiceAction({ method: 'post', code: data?.code }));
    };

    const actionHandler = (event, type, value) => {
      event?.stopPropagation();
      event?.preventDefault();

      setVisible(false);
      setActionModifier(false);

      if (type === 'copy-link') {
        copyLink(value?.hash);
      }

      if (type === 'delete') {
        handleDelete(value?.code, value?.type);
      }
    };

    const Actions = ({ data }) => {
      return (
        <div className="actions-dialog" style={{ marginTop: '-5px' }}>
          {!['draft', 'verifying', 'paid', 'declined'].includes(data?.status) && (
            <div
              className="actionLink mx-1"
              onClick={() => handleConfirmDialog(PAYMENT_TYPE_ENUM.COMPLETE)}
            >
              <SquareCheckBox
                style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
              />{' '}
              Mark as Paid
            </div>
          )}

          {data?.status === 'draft' && (
            <div className="actionLink mx-1" onClick={() => handleDraft()}>
              <EditIcon
                stroke="#79716B"
                width={16}
                height={16}
                style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
              />{' '}
              Edit draft
            </div>
          )}

          {
            <div className="actionLink mx-1" onClick={() => handleDraft('duplicate')}>
              <FileDoneOutlined
                stroke="#79716B"
                width={16}
                height={16}
                style={{ fontSize: '16px', marginLeft: '-9px', marginRight: -'1px' }}
              />{' '}
              Duplicate invoice
            </div>
          }

          {data?.type === 'schedule' ||
          ['draft', 'declined', 'verifying'].includes(data?.status) ? null : (
            <div
              className="actionLink mx-1"
              onClick={(event) => actionHandler(event, 'copy-link', data)}
            >
              <CopyLink
                style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
              />{' '}
              Copy invoice link
            </div>
          )}

          {/* {canDeleteInvoice && (
            <div
              className="actionLink text-danger"
              onClick={(event) => actionHandler(event, 'delete', data)}
            >
              <DeleteOutlined
                style={{ fontSize: '16px', marginLeft: '-8px', marginRight: '-3px' }}
              />{' '}
              Delete invoice
            </div>
          )} */}
        </div>
      );
    };

    const handleClick = () => {
      setVisible(!visible);

      if (!visible) {
        setActionModifier(true);
      } else {
        setActionModifier(false);
      }
    };

    return loading ? (
      <SkeletonLoader />
    ) : (
      <>
        <div className="information-wrapper">
          <div className="d-flex justify-content-between mb-4 pb-1 invoice-details-pad">
            <h2 className="card-title invoice-details-header w-100">Invoice details</h2>

            {!isRecurring && !['declined'].includes(data?.status) && (
              <CustomPopover
                content={<Actions data={data} />}
                showPopover={visible}
                clickOutside={() => setVisible(false)}
              >
                <div className="more-outline cursor pt-1" onClick={() => handleClick()}>
                  <KebabIcon />
                </div>
              </CustomPopover>
            )}
          </div>

          <div className="customer-details-region">
            <div className="d-flex justify-content-center">
              <ImgCard size="xlarge" initials={data?.customer?.name?.charAt(0)} />
            </div>

            <span className="invoice-amount">
              <CurrencyFormat
                prefix={getCurrency(data?.currency)}
                value={(data?.amount / 100).toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
            </span>
            <span className="customer"> {data?.customer?.name} </span>

            <div className="mt-4">
              <BadgeType
                value={{
                  value: invoiceStatusAliaser(data?.status, data?.type),
                  isStatus: true,
                }}
                color={getColor(invoiceStatusAliaser(data?.status, data?.type))}
              />
            </div>
          </div>

          <section
            className={`invoice-detail-holder mt-4 ${isRecurring ? 'minHeight' : ''}`}
          >
            <div className="detail">
              <span className="header">Created by</span>
              <div className=" ms-auto d-flex align-items-center gap-2">
                <div>
                  <ImgCard initials={data?.user?.firstName.charAt(0)} />
                </div>

                <span className="value">{`${data?.user?.firstName} ${data?.user?.lastName}`}</span>
              </div>
            </div>

            <div className="detail">
              <span className="header">Date </span>
              <span className="value ms-auto">
                {' '}
                {dayjs(data?.created_at).format('DD MMM, YYYY')}
              </span>
            </div>

            {data?.ScheduledInvoice ? (
              <div className="detail">
                <span className="header">Scheduled </span>
                <span className="value ms-auto">
                  {' '}
                  {dayjs(data?.ScheduledInvoice?.startDate).format('DD MMM, YYYY h:mm A')}
                </span>
              </div>
            ) : null}

            {isRecurring && data?.schedule ? (
              <>
                <div className="detail">
                  <span className="header">Scheduled </span>
                  <span className="value ms-auto">
                    {' '}
                    {dayjs(data?.schedule?.nextDate).format('DD MMM, YYYY h:mm A')}
                  </span>
                </div>

                {data?.type === 'recurring' && (
                  <div className="detail">
                    <span className="header">End date </span>
                    <span className="value ms-auto">
                      {' '}
                      {dayjs(data?.expiryDate).format('DD MMM, YYYY h:mm A')}
                    </span>
                  </div>
                )}

                {data?.schedule?.occurrence && data?.type === 'recurring' && (
                  <div className="detail">
                    <span className="header">Frequency </span>
                    <span className="value ms-auto">
                      {capitalizeFirstLetter(data?.schedule?.occurrence ?? '')}
                    </span>
                  </div>
                )}
              </>
            ) : null}

            {/* Actions Here */}
            <div className="mt-2 d-flex gap-3">
              {!isRecurring ? (
                <button className="action-button" onClick={() => handleAction('preview')}>
                  <EyeOutlined style={{ color: '#57534E' }} />
                  Preview
                </button>
              ) : null}

              {data?.status === 'draft' && (
                <button className="action-button" onClick={() => handleDraft()}>
                  <PencilIcon stroke="#57534E" width="16" height="16" />
                  Edit
                </button>
              )}
            </div>

            {/* timeline */}
            {timeline.length ? (
              <div className={`my-4 timeline-region ${!payments?.length ? 'pb-5' : ''}`}>
                <Timeline>
                  {timeline.map((item, index) => (
                    <TimelineItem
                      key={index}
                      timeline={item}
                      isLast={timeline.length - 1 === index}
                    />
                  ))}
                </Timeline>
              </div>
            ) : null}

            <section className="payment-schedule-region mt-4 pb-5">
              <h4 className="header pb-3">Payment schedule</h4>

              {!!payments?.length ? (
                payments.map((payment, index) => (
                  <div className="schedule-holder mb-4" key={index}>
                    <div className="d-flex align-items-center gap-3 w-50">
                      <Avatar
                        className="plain"
                        size={40}
                        icon={<CoinsStacked />}
                        style={{ background: '#F5F5F4' }}
                      />
                      <div>
                        <span className="head">Payment {index + 1}</span>
                        <span className="due">
                          Due on {dayjs(payment?.due_date).format('DD MMM, YYYY')}
                        </span>
                      </div>
                    </div>

                    {['pending', 'partial'].includes(data?.status) &&
                      payment?.status === 'pending' && (
                        <p
                          className={`pay-action ${isMarking ? 'is-disabled' : ''}`}
                          onClick={() =>
                            handleConfirmDialog(PAYMENT_TYPE_ENUM.PARTIAL, payment?.code)
                          }
                        >
                          Mark as paid
                        </p>
                      )}

                    <div className="ms-auto w-25 d-flex flex-column align-items-end">
                      <span className="head">
                        <CurrencyFormat
                          prefix={getCurrency('NGN')}
                          value={handleAmount(payment)}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      </span>
                      <span
                        className={`status ${payment?.status === 'paid' ? 'paid' : ''}`}
                      >
                        {payment?.status !== 'paid' ? 'Unpaid' : 'Paid'}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-center mt-4 flex-column align-items-center justify-content-center ">
                  <Avatar
                    size={40}
                    icon={<CoinsStacked />}
                    style={{ background: '#F5F5F4' }}
                  />
                  <p
                    className="mt-3"
                    style={{ color: '#79716b', fontWeight: 500, fontSize: '1rem' }}
                  >
                    No payment scheduled{' '}
                  </p>
                </div>
              )}
            </section>
            <section className="payment-schedule-region details-view mt-4 pb-5">
              <h4 className="header pb-3">Attachments</h4>
              <div className="container d-flex gap-2 overflow-x-scroll">
                {invoiceLoading && <LoadingReciept />}
                <NewReceiptUpload
                  receiptAssets={data?.attachments}
                  viewImgUrl={viewImgUrl}
                  addMore={true}
                  removeFile={!data?.attachments?.length}
                  acceptedFile={transactionAcceptedFiles}
                  onChange={(value) => {
                    if (!value.length) return;
                    dispatch(
                      updateInvoice({
                        code: data?.code,
                        attachments: value?.map(({ assetCode }) => assetCode),
                      }),
                    );
                  }}
                />
              </div>
            </section>
          </section>

          {/* Footer Region */}
          {!isRecurring &&
          !['draft', 'paid', 'verifying', 'declined'].includes(data?.status) ? (
            <div className="footer-region invoice-details-pad">
              <button
                className="add-button add-custom w-100"
                onClick={() => handleAction('copy')}
              >
                Copy link
              </button>

              <CustomButton
                style={{ fontWeight: 500 }}
                className="customButton add-button btn btn-primary"
                onClick={sendReminder}
                loading={isSharing}
                disabled={isSharing}
              >
                Send reminder
              </CustomButton>
            </div>
          ) : null}

          <Dialog
            show={!!paymentType}
            centered
            dialogClassName="custom-dialog"
            className="custom-dialog"
          >
            <ConfirmDialog
              title={`Mark as ${paymentType === 'complete' ? 'fully' : ''} paid`}
              subTitle={`Are you sure you want to mark this ${
                paymentType === 'complete' ? 'invoice' : 'instalment'
              } as paid?`}
              isDeleteDialog={false}
              loading={isMarking}
              onConfirm={() => hadleMarkasPaid()}
              onCancel={() => resetModification()}
            />
          </Dialog>
        </div>
      </>
    );
  };

  const viewImgUrl = (asset) => {
    // setSelectedAsset(asset);
    // setAssetViewer(true);
  };

  const TimelineItem = ({ timeline, isLast }) => {
    const renderTimelineItem = () => {
      switch (timeline?.type) {
        case 'creation':
          return (
            <Timeline.Item
              className={isLast ? 'ant-timeline-item-last' : ''}
              dot={<TimelineCheckIcon />}
            >
              <div>
                <span className="timeline-header">{timeline?.title}</span>
                <span className="timeline-text">
                  <div>
                    <ImgCard initials={timeline?.performedBy?.firstName?.charAt(0)} />
                  </div>

                  {`${timeline?.performedBy?.firstName} ${timeline?.performedBy?.lastName}`}

                  <span className="timeline-text small" style={{ paddingTop: '3px' }}>
                    {dayjs(timeline?.performedAt).format('DD MMM YYYY, h:mm A')}
                  </span>
                </span>
              </div>
            </Timeline.Item>
          );

        case 'pending_approval':
          return (
            <Timeline.Item
              className={isLast ? 'ant-timeline-item-last' : ''}
              dot={
                timeline?.status === 'pending' ? <TimelineDot /> : <TimelineCheckIcon />
              }
            >
              <div>
                <span className="timeline-header">{timeline?.title} from</span>

                <>
                  {timeline?.approvers?.length > 1 ? (
                    <section className="d-flex align-items-center flex-wrap gap-2">
                      {timeline?.approvers.map((user, index) => (
                        <div key={index} className="d-flex align-items-center gap-2">
                          <div>
                            <ImgCard initials={user?.firstName?.charAt(0)} />
                          </div>

                          <span className="timeline-text">
                            {`${user?.firstName} ${user?.lastName}`}{' '}
                          </span>
                          {timeline?.approvers?.length - 1 !== index && (
                            <span style={{ fontWeight: 500, color: '#79716b' }}>
                              {' '}
                              {timeline?.condition === 'any' ? 'or' : 'and'}{' '}
                            </span>
                          )}
                        </div>
                      ))}
                    </section>
                  ) : (
                    <div className="d-flex align-items-center gap-2">
                      <div>
                        <ImgCard
                          initials={timeline?.approvers[0]?.firstName?.charAt(0)}
                        />
                      </div>

                      <span className="timeline-text">
                        {' '}
                        {`${timeline?.approvers[0]?.firstName} ${timeline?.approvers[0]?.lastName}`}{' '}
                      </span>
                    </div>
                  )}
                </>
              </div>
            </Timeline.Item>
          );

        case 'approval_action':
          return (
            <Timeline.Item
              className={isLast ? 'ant-timeline-item-last' : ''}
              dot={
                timeline?.status === 'pending' ? <TimelineDot /> : <TimelineCheckIcon />
              }
            >
              <div>
                <span className="timeline-header">{timeline?.title}</span>
                <span className="timeline-text">
                  <div>
                    <ImgCard initials={timeline?.performedBy?.firstName?.charAt(0)} />
                  </div>
                  {`${timeline?.performedBy?.firstName} ${timeline?.performedBy?.lastName}`}

                  <span className="timeline-text small" style={{ paddingTop: '3px' }}>
                    {dayjs(timeline?.performedAt).format('DD MMM YYYY, h:mm A')}
                  </span>
                </span>
              </div>
            </Timeline.Item>
          );

        case 'schedule':
        case 'pending_installment':
        case 'overdue_installment':
          return (
            <Timeline.Item
              className={isLast ? 'ant-timeline-item-last' : ''}
              dot={
                timeline?.status === 'pending' ? <TimelineDot /> : <TimelineCheckIcon />
              }
            >
              <div>
                <span className="timeline-header">{timeline?.title} </span>

                <div className="d-flex align-items-center gap-2">
                  <span className="timeline-text small">{timeline?.description}</span>
                </div>
              </div>
            </Timeline.Item>
          );

        case 'paid_installment':
          return (
            <Timeline.Item
              className={isLast ? 'ant-timeline-item-last' : ''}
              dot={
                timeline?.status === 'pending' ? <TimelineDot /> : <TimelineCheckIcon />
              }
            >
              <div>
                <span className="timeline-header">{timeline?.title}</span>
                <span className="timeline-text">
                  <div>
                    <ImgCard initials={timeline?.performedBy?.name?.charAt(0)} />
                  </div>
                  {`${timeline?.performedBy?.name}`}

                  <span className="timeline-text small" style={{ paddingTop: '3px' }}>
                    {dayjs(timeline?.performedAt).format('DD MMM YYYY, h:mm A')}
                  </span>
                </span>
              </div>
            </Timeline.Item>
          );

        default:
          return null;
      }
    };

    return renderTimelineItem();
  };

  const assetData = {
    title: data?.code,
    component: <InvoiceAsset data={data} loading={loading} isRecurring={isRecurring} />,
  };

  const handleDownloadPdf = () => {
    pdfDownloadRef?.current?.click();
  };

  const closeDrawer = (event) => {
    event?.preventDefault();

    if (isRecurring) {
      const queryParams = new URLSearchParams(location?.search);
      queryParams.delete('recurring');

      history.push({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    }

    handleCloseDrawer(event);
  };

  return (
    <section>
      <Modal
        hasAsset
        hasDownload={!isRecurring}
        show={isOpen}
        loading={loading}
        assetData={assetData}
        onClose={handleCloseDrawer}
        modalStyle={{ padding: 0 }}
        handleAssetDownload={() => handleDownloadPdf()}
      >
        <div className="content py-4">
          <div className="card-modal-header invoice-details-pad">
            <button
              id="close-icon"
              style={{ background: 'inherit', border: 'none' }}
              className="d-flex align-items-center cursor modal-close-icon"
              onClick={(event) => closeDrawer(event)}
            >
              <XcloseIcon width="16" height="16" stroke="#57534E" />
              <span
                className="ps-1"
                style={{ color: '#57534E', fontWeight: 500, fontSize: '0.75rem' }}
              >
                Close
              </span>
            </button>
          </div>

          <div className="card-modal-body invoice">{InvoiceDetail(data)}</div>
        </div>
      </Modal>

      <DownloadPdfComponent
        companyData={companyData}
        pdfDownloadRef={pdfDownloadRef}
        invoiceSelected={{ details: data }}
        handleDownloadPdf={handleDownloadPdf}
        companyLogo={{ ...companyLogo, logo: companyData?.logoUrl }}
      />
    </section>
  );
};

export default InvoiceDetailModal;

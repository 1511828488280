import classNames from 'classnames';
import { Skeleton, Space } from 'antd';
import { AntInput } from 'components/UIHooks/AntDesign';
import { motion, AnimatePresence } from 'motion/react';

import NoData from 'components/NoData';
import { ChevronDown, SearchIcon, CopyIcon, XcloseIcon } from 'assets/icons';
import CustomButton from 'components/UI/CustomButton';
import { toastSuccess } from 'components/UI/toast';
import copy from 'copy-text-to-clipboard';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBankAccount,
  getCompanyStats,
  getDollarAccount,
} from 'redux/actions/CompaniesAction';
import NotificationModal from '../../../NotificationModal/NotificationModal';
import Loading from '../../../UI/Loading';
import '../styles.scss';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getBalances } from 'redux/actions/BudgetsAction';

import { AccountCard } from 'pages/Cash/Overview/components/AccountCard';

const AccountInfo = ({
  toggleHandler,
  setStep = () => null,
  setSelectStep = () => null,
  setActiveBankCard = () => null,
  isMultiple,
  title,
  hideLink,
  handleBtnClick,
  setTryAgain,
  subtTitle,
  account,
  accountType,
  handleBack,
}) => {
  const dispatch = useDispatch();

  const [searchVal, setSearchVal] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [fetchingAccountDetails, setFetchingAccountDetails] = useState(true);
  const [fetchingAccountDetailsCounter, setFetchingAccountDetailsCounter] = useState(0);

  const {
    getBankAccount: { loading, data: getBank, error, success: getBankSuccess },
    getStatistics: { success, data: statisticData = {} },
    getDollarAccount: { data: dollarAccount, success: getDollarAccountSuccess },
  } = useSelector(({ companies }) => companies);

  const {
    getBalances: {
      data: { balances = [] },
      loading: isLoading,
    },
  } = useSelector(({ budgets }) => budgets);

  const loadingState = loading || isLoading;

  const filteredAccount = useMemo(
    () =>
      balances.filter(
        ({ name, accountName, bankName }) =>
          (name ?? '').toLowerCase().includes(searchVal.toLowerCase()) ||
          (accountName ?? '').toLowerCase().includes(searchVal.toLowerCase()) ||
          (bankName ?? '').toLowerCase().includes(searchVal.toLowerCase()),
      ),
    [searchVal, balances],
  );

  const { permissions } = allPermissions();

  const canViewDash = hasPermission({
    permissions,
    scopes: ['dash-view'],
  });

  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (!success || !statisticData) return;
    const { chart = {} } = statisticData;
    if (chart.Fri?.amount) {
      setStep(4);
      setSelectStep(4);
      setActiveBankCard(4);
      return;
    }
    setShowModal(true);
  }, [success]);

  useEffect(() => {
    if (error) {
      setActiveBankCard(0);
      handleBack();
    }
  }, [error]);

  useEffect(() => {
    if (
      (getBankSuccess && fetchingAccountDetailsCounter < 2) ||
      (getDollarAccountSuccess && fetchingAccountDetailsCounter < 2)
    ) {
      if (
        (getBank === undefined && accountType !== 'USD') ||
        (dollarAccount === undefined && accountType === 'USD')
      ) {
        setFetchingAccountDetailsCounter(fetchingAccountDetailsCounter + 1);
        setTimeout(() => {
          if (accountType !== 'USD') {
            dispatch(getBankAccount({ code: data.user.company.code }));
          } else {
            dispatch(getDollarAccount({ amount: 10000 }));
          }
        }, 5000);
      } else {
        setFetchingAccountDetails(false);
      }
    }

    if (fetchingAccountDetailsCounter > 1) {
      setTryAgain(true);
    }
  }, [getBankSuccess, getDollarAccountSuccess]);

  useEffect(() => {
    if (accountType === 'USD') {
      dispatch(getDollarAccount({ amount: 10000 }));
    } else {
      dispatch(getBankAccount({ code: data.user.company.code }));
    }

    if (isMultiple && !balances.length) {
      dispatch(getBalances());
    }
  }, []);

  const [initialState, setInitialState] = useState([
    {
      title: 'Bank Name',
      value: '',
    },
    {
      title: 'Account name',
      value: '',
    },
    {
      title: 'Bank code',
      value: '',
    },
    {
      title: 'Account number',
      value: '',
    },
    {
      title: 'Reference ID',
      value: '',
    },
  ]);

  const getDollarAccountDetails = () => {
    const { account_name, account_number, bank_name, reference, routing_number } =
      dollarAccount;
    setInitialState([
      {
        title: 'Bank Name',
        value: bank_name,
      },
      {
        title: 'Account name',
        value: account_name,
      },
      {
        title: 'Bank code',
        value: routing_number,
      },
      {
        title: 'Account number',
        value: account_number,
      },
      {
        title: 'Reference ID',
        value: reference,
      },
    ]);
  };

  const singleAccount = account || getBank;
  const displayItems = isMultiple ? filteredAccount : [singleAccount];
  const accountItem = accountType === 'USD' ? initialState : displayItems;

  const onClickHandler = () => {
    if (handleBtnClick) return handleBtnClick();
    localStorage.setItem('setStep', JSON.stringify(3));
    if (canViewDash) dispatch(getCompanyStats({ id: data.user?.company?.code }));
  };

  const openBalance = (code) => {
    if (isMultiple) {
      if (code === activeCode) return setActiveCode('');
      setActiveCode(code);
    }
  };

  const copyItem = (value) => {
    if (!value) return;
    copy(value);
    toastSuccess(`Copied successfully`);
  };

  const containerVariants = {
    hidden: {
      height: 0,
      opacity: 0,
    },

    visible: {
      height: 'auto',
      opacity: 1,
      transition: {
        duration: 0.4,
        ease: 'easeOut',
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },

    exit: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    },
  };

  const itemVariants = {
    hidden: {
      y: 20,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },

    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: 'easeOut',
      },
    },
  };

  const toggleModal = () => setShowModal((pre) => !pre);

  const [activeCode, setActiveCode] = useState(singleAccount?.code || '');

  const Loader = () => (
    <div className="justify-content-center pt-5">
      {Array.from({ length: 6 }).map((_, i) => (
        <motion.div
          key={i}
          className="account w-100 mb-3"
          style={{ justifyContent: 'start', alignItems: 'start' }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.4,
            ease: [0.4, 0, 0.2, 1],
            delay: 0.2 + i * 0.1,
          }}
        >
          <Skeleton.Avatar active size={40} shape="circle" className="mr-4" />

          <div className="">
            <Space direction="vertical" style={{ width: '100%', gap: 1 }}>
              {Array.from({ length: 2 }).map((_, index) => (
                <Skeleton.Input
                  key={index}
                  active
                  size="small"
                  className={index === 0 ? 'text-left' : ''}
                  style={{
                    width: 150,
                    borderRadius: 4,
                    height: index === 0 ? 14 : 12,
                  }}
                />
              ))}
            </Space>
          </div>

          <Skeleton.Input
            className="ms-auto align-self-center"
            active
            size="small"
            style={{ width: 150, borderRadius: 4, height: 14 }}
          />
        </motion.div>
      ))}
    </div>
  );
  return (
    <div className="information-wrapper fund-deposit">
      <div
        className={classNames('selectFundsWrapper send-funds', {
          'mb-5': !isMultiple,
        })}
      >
        <h2 className="caption w-100">{title ? title : 'Account details'}</h2>
        {subtTitle && <p className="sub-text">{subtTitle}</p>}
      </div>
      {loadingState ? (
        <Loader />
      ) : (
        <>
          {isMultiple && (
            <div className="search-box pt-3">
              <AntInput
                className="input"
                placeholder="Search accounts"
                value={searchVal}
                onChange={({ target: { value } }) => setSearchVal(value)}
                prefix={<SearchIcon stroke="#A9A29D" className="searchIcon" />}
              />
            </div>
          )}

          <div className="list-wrapper pb-3">
            {accountItem.map((data) => {
              const isActive = data?.code === activeCode;
              const aliaseIcon = isActive ? (
                <XcloseIcon width="18" height="18" stroke="#A0A09E" />
              ) : (
                <ChevronDown />
              );

              const sections = [
                {
                  header: 'Bank name',
                  value: data?.bankName,
                  onClick: () =>
                    copyItem(
                      'Bank name:\t\t' +
                        data?.bankName +
                        '\n' +
                        'Account number:\t\t' +
                        data?.number,
                    ),
                },
                {
                  header: 'Account name',
                  value: data?.name,
                  onClick: () => copyItem(data?.name),
                },
                {
                  header: 'Account number',
                  value: data?.number,
                  onClick: () => copyItem(data?.number),
                },
              ];

              return (
                <div key={data?.code} className="mb-3">
                  <AccountCard
                    {...data}
                    openBalance={openBalance}
                    customClass={isActive ? 'is-expanded' : ''}
                    customIcon={isMultiple ? aliaseIcon : <></>}
                  />

                  {isActive && (
                    <AnimatePresence mode="popLayout">
                      {isActive && (
                        <motion.div
                          key={data?.code}
                          className="details-holder deposit-funds"
                          variants={containerVariants}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                        >
                          {sections.map((section, index) => (
                            <motion.section
                              key={section.header}
                              className="segment"
                              variants={itemVariants}
                            >
                              <div>
                                <p className="header">{section.header}</p>
                                <p className="value">{section.value}</p>
                              </div>
                              <span
                                className={classNames('copy-item icon-animate', {
                                  colored: index === 0,
                                })}
                                onClick={section.onClick}
                              >
                                <CopyIcon
                                  stroke={index === 0 ? '#D28B28' : '#A0A09E'}
                                  width="16"
                                  height="16"
                                />
                                {index === 0 && 'Copy'}
                              </span>
                            </motion.section>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  )}
                </div>
              );
            })}

            {!filteredAccount?.length && searchVal && (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no account for this filter"
                  bodyText="Alter filter to see accounts"
                  withButton={false}
                />
              </div>
            )}
          </div>
          {
            // TODO check the Prototype after this is clicked (based on API response) a notification will be shown, check
            // the prototype I sent
          }
          {!isMultiple && (
            <CustomButton className="mb-4" fullWidth={true} onClick={onClickHandler}>
              I&apos;ve sent the money
            </CustomButton>
          )}
          {!hideLink && (
            <p className="pt-4 text-center cursor" onClick={toggleHandler}>
              Send later
            </p>
          )}
        </>
      )}
    </div>
  );
};
export default AccountInfo;

import { ArrowLeftOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomTextarea from 'components/UI/CustomTextarea';
import ExchangeRate from 'components/UI/ExchangeRate';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import useTextCounter from 'hooks/useTextCounter';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { currencyConversionRate } from 'redux/actions/BudgetsAction';
import { topUpCard } from 'redux/actions/CardAction';
import { createRequests } from 'redux/actions/RequestsAction';
import { MAXLENGTH } from 'utils';
import { getCurrency } from 'utils/helper';

const TopUpCard = ({
  cardDetails,
  handleIsUpdate,
  closeHandler,
  IsOtherPopoverOpen = () => null,
}) => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState({});

  const [dollarWallet, setDollarWallet] = useState({
    amount: null,
    currency: 'USD',
  });

  const {
    topUpCard: { success, loading: topUpLoading },
  } = useSelector(({ card }) => card);

  const {
    createRequest: { loading: loadingCreateRequest, success: successCreateRequest },
  } = useSelector(({ requests }) => requests);

  const {
    getCurrencyConversionRate: {
      data: currencyExchange = {},
      loading: loadingConversion,
    },
  } = useSelector(({ budgets }) => budgets);

  const {
    card: { budget, code, currency },
  } = cardDetails;

  useEffect(() => {
    setCardData({ amount: '', currency });
  }, []);

  useEffect(() => {
    return () => {
      setCardData({ amount: '' });
      handleIsUpdate();
    };
  }, [budget]);

  useEffect(() => {
    if (cardData.currency === 'USD' && Number(dollarWallet.amount) > 0) {
      dispatch(
        currencyConversionRate({
          baseCurrency: 'ngn',
          targetCurrency: cardData?.currency?.toLowerCase(),
          amount: cardData?.amount,
        }),
      );
    }
  }, [dollarWallet]);

  // Budget Balance Left
  const BalaceLeft = () => {
    return (
      <p>{`${getCurrency(budget?.currency)}${numeral(budget?.available / 100).format(
        '0,0.00',
      )}`}</p>
    );
  };
  //

  const handleTopUp = () => {
    if (!cardData?.amount) return toastError('Please enter an amount');
    if (+cardData?.amount <= 0) return toastError('Please enter a valid amount');
    if (+cardData?.amount > budget?.available / 100)
      return toastError('Budget amount exceeded');
    if (+cardData.amount < 10 && cardData.currency === 'USD')
      return toastError('Please enter a minimum amount of $10');
    if (currencyExchange.amount > budget?.available / 100 && cardData.currency === 'USD')
      return toastError('Budget amount exceeded');
    const payload = {
      amount: cardData?.amount * 100,
      budget: budget?.code,
      code,
    };
    dispatch(topUpCard(payload));
  };

  const requestFund = () => {
    if (!cardData.amount) return toastError('Kindly provide an amount');

    const payload = {
      type: 'top_up',
      card: code,
      amount: cardData.amount * 100,
      currency: cardData.currency,
      description:
        cardData?.requestReason || budget.name + ' balance is lower than needed amount',
    };
    dispatch(createRequests(payload));
  };

  const handleRequestReasonChange = ({ target }) => {
    setCardData({
      ...cardData,
      requestReason: target.value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    cardData?.requestReason,
    MAXLENGTH.requestDescription,
    handleRequestReasonChange,
  );

  useEffect(() => {
    if (success || successCreateRequest) {
      handleIsUpdate();
      closeHandler();
    }
  }, [success, successCreateRequest]);

  if (loadingCreateRequest) return <Loading />;

  return (
    <div className="content px-md-0 py-md-0 px-3 py-4">
      <div className="card-modal-header">
        <div className="d-flex align-items-center cursor" onClick={handleIsUpdate}>
          <ArrowLeftOutlined />
          <span className="ps-1">Back</span>
        </div>
      </div>

      <div className="card-modal-body">
        <div className="information-wrapper">
          <h2 className="card-title w-100">Top up your card</h2>
          <Row className="mb-3 mt-3">
            <CustomInput
              label="How much?"
              placeholder="0.00"
              type="number"
              isAmount
              otherCurrency={true}
              name="amount"
              onChange={({ target: { rawValue } }) => {
                setCardData({ ...cardData, amount: rawValue });
                setDollarWallet({ ...dollarWallet, amount: rawValue });
              }}
              balance={BalaceLeft()}
              balanceTitle={'Available in budget:'}
              value={cardData.amount}
              setIsOtherPopoverOpen={IsOtherPopoverOpen}
              editCurrency={false}
              existAction={cardData.currency}
            />
            {cardData.currency === 'USD' && cardData.amount && (
              <ExchangeRate
                exchangeRate={currencyExchange.rate ? currencyExchange.rate : 0}
                amount={cardData.amount}
                loading={loadingConversion}
              />
            )}
            {Number(cardData.amount) * 100 < budget?.available ? null : (
              <>
                <span className="mt-2 text-sm text-error">
                  Your balance is lower than the amount, you can request for the funds
                  below.
                </span>

                <Row className="mb-2">
                  <CustomTextarea
                    label="Describe your need"
                    name="description"
                    placeholder="Enter a description of the request"
                    onChange={handleRequestReasonChange}
                    value={cardData.requestReason}
                    maxLength={MAXLENGTH.requestDescription}
                    showCounter={true}
                    charCount={charCount}
                  />
                </Row>
              </>
            )}
          </Row>

          <div className="modal-footer mt-3">
            <CustomButton
              onClick={handleIsUpdate}
              disabled={topUpLoading}
              fullWidth={true}
              className="custom-button ghost-button"
            >
              Cancel
            </CustomButton>
            <CustomButton
              fullWidth={true}
              className="custom-button primary-button"
              onClick={
                cardData.amount * 100 < budget?.available ? handleTopUp : requestFund
              }
              disabled={topUpLoading || loadingConversion}
              loading={topUpLoading}
            >
              {cardData.amount * 100 < budget?.available ? `Top up` : `Request for funds`}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUpCard;

import { CsvIcon } from 'assets/icons';
import { format } from 'date-fns';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter, getFormattedDate } from 'utils/helper';

const DownloadExcelStatement = ({ handleButtonToggle, handler, output = 'Excel' }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const querys = {
    source: searchParams.get('source'),
    type: searchParams.get('type'),
    startDate: searchParams.get('startDate'),
    endDate: searchParams.get('endDate'),
  };

  const {
    downloadStatement: {
      loading,
      success,
      data: { transactions = [], summary = {} } = {},
    } = {},
  } = useSelector(({ accountStatement }) => accountStatement);

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const forSpecificBusiness = ['cmp_T238e2bV5kFUCvNXN'].includes(companyData.code);
  const source =
    querys.source.startsWith('bdg') && transactions.length
      ? transactions[0].source
      : querys.source;

  const { user: { data = {} } = {} } = useSelector(({ auth }) => auth);
  const generateAccountStatement = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Account Statement');
    const currencyFormat = '[$₦-101]#,##0.00';
    const date = new Date();

    // Set header
    worksheet.mergeCells('A1:H1'); // Merge cells to span 3 columns
    const headerRow = worksheet.getRow(1);
    const headerCell = headerRow.getCell(1);
    headerCell.value = `Account Statement`;
    headerCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'B35309' }, // Orange color
    };
    headerCell.font = { bold: true, size: 25 };
    headerCell.font.color = { argb: 'FFFFFF' };
    headerCell.alignment = { horizontal: 'center' };

    // Add account and date
    worksheet.mergeCells('A3:B3'); // Merge cells for account label
    const accountLabelRow = worksheet.getRow(3);
    const accountLabelCell = accountLabelRow.getCell(1);
    accountLabelCell.value = data?.user?.company?.name;
    accountLabelCell.font = { bold: true, size: 18 };
    accountLabelCell.font.color = { argb: 'B35309' };

    worksheet.mergeCells('A4:B4'); // Merge cells for account value
    const accountValueRow = worksheet.getRow(4);
    const accountValueCell = accountValueRow.getCell(1);
    accountValueCell.font = { size: 12 };
    accountValueCell.value = summary?.accountNumber;
    accountValueCell.alignment = { horizontal: 'left' };

    worksheet.mergeCells('D3:E3'); // Merge cells for date label
    const dateLabelRow = worksheet.getRow(3);
    const dateLabelCell = dateLabelRow.getCell(4);
    dateLabelCell.value = 'Date';
    dateLabelCell.font = { bold: true, size: 16 };
    dateLabelCell.font.color = { argb: 'B35309' };

    worksheet.mergeCells('D4:E4'); // Merge cells for date value
    const dateValueRow = worksheet.getRow(4);
    const dateValueCell = dateValueRow.getCell(4);
    dateValueCell.font = { bold: true, size: 12 };
    dateValueCell.value =
      querys.startDate && querys.endDate
        ? querys.startDate + ' - ' + querys.endDate
        : format(date, 'yyyy-MM-dd');

    // Skip two rows
    worksheet.addRow([]);
    worksheet.addRow([]);

    // Set Summary
    worksheet.mergeCells('A7:B7'); // Merge cells to span 3 columns
    const summaryRow = worksheet.getRow(7);
    const summaryCell = summaryRow.getCell(1);
    summaryCell.value = 'Summary';

    summaryCell.font = { bold: true, size: 16 };
    summaryCell.font.color = { argb: '000000' };

    // Skip two rows
    worksheet.addRow([]);

    // Add opening/closing balance
    worksheet.mergeCells('A9:B9'); // Merge cells for opening label
    const OpeningBalanceLabelRow = worksheet.getRow(9);
    const OpeningBalanceLabelCell = OpeningBalanceLabelRow.getCell(1);
    OpeningBalanceLabelCell.value = 'Opening Balance';
    OpeningBalanceLabelCell.font = { bold: true, size: 16 };
    OpeningBalanceLabelCell.font.color = { argb: '000000' };

    worksheet.mergeCells('A10:B10'); // Merge cells for opening value
    const OpeningBalanceValueRow = worksheet.getRow(10);
    const OpeningBalanceValueCell = OpeningBalanceValueRow.getCell(1);
    OpeningBalanceValueCell.value = summary?.openingBalance / 100;
    OpeningBalanceValueCell.numFmt = currencyFormat;
    OpeningBalanceValueCell.font = { size: 12 };
    OpeningBalanceValueCell.alignment = { horizontal: 'left' };

    worksheet.mergeCells('D9:E9'); // Merge cells for closing label
    const ClosingBalanceLabelRow = worksheet.getRow(9);
    const ClosingBalanceLabelCell = ClosingBalanceLabelRow.getCell(4);
    ClosingBalanceLabelCell.value = 'Closing Balance';
    ClosingBalanceLabelCell.font = { bold: true, size: 16 };
    ClosingBalanceLabelCell.font.color = { argb: '000000' };

    worksheet.mergeCells('D10:E10'); // Merge cells for closing value
    const ClosingBalanceValueRow = worksheet.getRow(10);
    const ClosingBalanceValueCell = ClosingBalanceValueRow.getCell(4);
    ClosingBalanceValueCell.value = summary?.closingBalance / 100;
    ClosingBalanceValueCell.numFmt = currencyFormat;
    ClosingBalanceValueCell.font = { size: 12 };
    ClosingBalanceValueCell.alignment = { horizontal: 'left' };

    // Skip one rows
    worksheet.addRow([]);

    // Add money in/out
    worksheet.mergeCells('A12:B12'); // Merge cells for money in label
    const MoneyInLabelRow = worksheet.getRow(12);
    const MoneyInLabelCell = MoneyInLabelRow.getCell(1);
    MoneyInLabelCell.value = 'Money In';
    MoneyInLabelCell.font = { bold: true, size: 16 };
    MoneyInLabelCell.font.color = { argb: '000000' };

    worksheet.mergeCells('A13:B13'); // Merge cells for money in value
    const MoneyInValueRow = worksheet.getRow(13);
    const MoneyInValueCell = MoneyInValueRow.getCell(1);
    MoneyInValueCell.value = summary?.moneyIn / 100;
    MoneyInValueCell.numFmt = currencyFormat;
    MoneyInValueCell.font = { size: 12 };
    MoneyInValueCell.alignment = { horizontal: 'left' };

    worksheet.mergeCells('D12:E12'); // Merge cells for money out label
    const MoneyOutLabelRow = worksheet.getRow(12);
    const MoneyOutLabelCell = MoneyOutLabelRow.getCell(4);
    MoneyOutLabelCell.value = 'Money Out';
    MoneyOutLabelCell.font = { bold: true, size: 16 };
    MoneyOutLabelCell.font.color = { argb: '000000' };

    worksheet.mergeCells('D13:E13'); // Merge cells for money out value
    const MoneyOutValueRow = worksheet.getRow(13);
    const MoneyOutValueCell = MoneyOutValueRow.getCell(4);
    MoneyOutValueCell.value = summary?.moneyOut / 100;
    MoneyOutValueCell.numFmt = currencyFormat;
    MoneyOutValueCell.font = { size: 12 };
    MoneyOutValueCell.alignment = { horizontal: 'left' };

    worksheet.addRow([]); // Add an empty row after opening balance
    if (forSpecificBusiness) worksheet.addRow([]); // Add an empty row after opening balance

    const sheetHeader = forSpecificBusiness
      ? [
          'Date',
          'Source',
          'From',
          'To',
          'Debit',
          'Credit',
          'Balance',
          'Category',
          'Remarks',
        ]
      : [
          'Date',
          'Description',
          'Amount(Credit/Debit)',
          'Type',
          'Source',
          'From',
          'To',
          'Balance After',
          'Category',
        ];

    worksheet.addRow(sheetHeader);

    // Apply fill color to all rows
    const headerFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'B35309' }, // Orange color
    };
    const headerFont = { bold: true, color: { argb: 'FFFFFF' } };
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.row === 15) {
          // Check if it is the header row
          cell.fill = headerFill;
          cell.font = headerFont;
        }
      });
      row.height = 20;
    });

    transactions.forEach((item) => {
      const credit = item.type.toLowerCase() === 'credit' && item?.amount;
      const debit = item.type.toLowerCase() === 'debit' && item?.amount;

      const sheetRowData = forSpecificBusiness
        ? [
            getFormattedDate(item?.created_at),
            item?.source,
            item?.from,
            item?.to,
            debit / 100 || '',
            credit / 100 || '',
            item?.balanceAfter / 100 || '',
            (item?.categoryName ?? item?.category) || 'uncategorized',
            item?.description,
          ]
        : [
            getFormattedDate(item?.created_at),
            item?.description,
            item?.amount / 100,
            capitalizeFirstLetter(item?.type),
            item?.source,
            item?.from,
            item?.to,
            item?.balanceAfter / 100 || '',
            (item?.categoryName ?? item?.category) || 'uncategorized',
          ];

      const row = worksheet.addRow(sheetRowData);
      row.height = 20; // Set the custom height for each row
    });

    // Apply currency format to Amount columns
    worksheet.getColumn(3).numFmt = currencyFormat;
    worksheet.getColumn(8).numFmt = currencyFormat;

    // Auto-fit column widths
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 0;
        maxLength = Math.max(maxLength, columnLength);
      });

      column.width = 20;
    });

    // Auto-fit column widths
    worksheet.eachRow((row) => {
      row.commit();
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(
        blob,
        `statement-${source}-${querys.type}${
          querys.startDate && querys.endDate
            ? '-' + querys.startDate + '-' + querys.endDate
            : ''
        }.xlsx`,
      );
    });
  };

  return (
    <div
      className="actionLink"
      onClick={() => {
        generateAccountStatement();
        handleButtonToggle();
      }}
    >
      <CsvIcon height="16" width="16" /> Download as {output}
    </div>
  );
};

export default DownloadExcelStatement;

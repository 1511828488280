import { ReactComponent as GrayBgCalender } from 'assets/icons/background-calender.svg';
import { CancelICon } from 'assets/icons/index';
import classNames from 'classnames';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomSelect from 'components/UI/CustomSelect';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { recurrencePaymentOption } from 'utils/helper';
import './styles.scss';
import { toastError } from 'components/UI/toast';

const initailState = {
  pick: undefined,
};

const DeadlinePicker = ({ onClose, loading, onDatePicked, extraChild }) => {
  const [paymentData, setPaymentData] = useState(initailState);

  // handle submit
  const handleSubmit = () => {
    if (!paymentData.pick) return toastError('Please pick a date');

    const startDateObject = dayjs(paymentData.pick);
    const startDateFormatted = startDateObject.format('YYYY-MM-DD');
    const startTimestamp = startDateObject.format('HH:mm:ss');

    const scheduleTransaction = {
      pick: {
        date: startDateFormatted,
        timestamp: startTimestamp,
      },
    };

    onDatePicked({ ...scheduleTransaction });
  };
  //

  const formatOptionLabel = (props) => {
    const { value, label, details, isDisabled } = props;
    return (
      <div className={classNames('format-recurrence-option')}>
        <h1 className={classNames({ ['text-muted']: isDisabled })}>{label}</h1>
        <span className={classNames({ ['text-muted']: isDisabled })}>{details}</span>
      </div>
    );
  };

  const handleDateChange = (date, name) => {
    const currentHours = getHours(new Date());
    const currentMinutes = getMinutes(new Date());
    const value = setMinutes(setHours(date, currentHours), currentMinutes + 5);
    return setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      [name]: value,
    }));
  };

  return (
    <div>
      <div className="mandate-modal-overlay" onClick={onClose} />
      <div className="mandate-modal-wrapper position-relative">
        <div className="mandate-modal-body invoice payment-schedule">
          <i onClick={onClose} className="cancel-icon">
            <CancelICon width="10" height="10" />
          </i>
          <div className="w-100">
            <div className="mt-0 w-100 mandate-wrapper ">
              <div className="p-4">
                <h4 className="text-start header">Set a deadline</h4>
              </div>

              <div className="payment__schedule-wrapper rounded-0 px-4">
                <div className="form-normal w-100 mx-0 px-0">
                  <div className="mb-2 mx-0 px-0">
                    <CustomDatePicker
                      wrapperClass="px-0 mx-0"
                      label=""
                      onChange={(value) => handleDateChange(value, 'pick')}
                      dateFormat="MMMM d, yyyy h:mm"
                      name="startDate"
                      peekNextMonth
                      minDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Pick your deadline"
                      selected={paymentData.pick && dayjs(paymentData.pick).toDate()}
                    />
                  </div>

                  {extraChild}
                </div>
              </div>

              <div className="px-4 py-3 d-flex justify-content-end gap-3 ms-auto w-50">
                <button disabled={loading} className="cancel-button " onClick={onClose}>
                  Cancel
                </button>

                <button
                  className="confirm-button"
                  // loading={loading}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeadlinePicker;

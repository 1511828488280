import { useState, useRef, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import AllowedTo, { returnPermissions } from 'utils/AllowedTo';

import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';

export const RenderMenu = ({
  lists,
  isSubLink,
  openDropdowns = [],
  locationArray,
  fileterd,
  toggleDropdown,
  permissions,
  stats,
  setIsOpen,
}) => {
  const history = useHistory();

  const Badger = (tag, value, activeRoute = false) => {
    switch (tag) {
      case 'requests':
        if (!activeRoute && value?.pendingRequests) {
          return (
            <span className="get-started-percentage is-pulse">
              {value?.pendingRequests}
              <span className="floating-ball"></span>
            </span>
          );
        }
        return null;

      case 'get-started':
        return (
          <span
            className={classNames('get-started-percentage', {
              ['is-active']: activeRoute,
              'is-filled': activeRoute,
            })}
            style={{ '--fill-percentage': `${value?.onboarding}%` }}
          >
            {value?.onboarding}%
          </span>
        );

      default:
        return null;
    }
  };
  const renderMenuItems = (lists, isSubLink) => {
    return lists?.map((item, index) => {
      const checkMatchedLinks = returnPermissions({
        permissions,
        scopes: item?.permissions,
      });

      const uniqueItems = [
        ...new Set(checkMatchedLinks.map((item) => item.split('-')[0])),
      ];

      if (item.links) {
        const findLink = item?.links.find((obj) => uniqueItems.includes(obj.permission));
        item.link = findLink?.link;
      }

      const [closingIndex, setClosingIndex] = useState(null);
      const [activeTab, setActiveTab] = useState(location.pathname);

      const handleToggleDropdown = (index, item) => {
        setActiveTab(item.subLink[0].link);

        if (openDropdowns.includes(index)) {
          setClosingIndex(index);

          setTimeout(() => {
            setClosingIndex(null);
            toggleDropdown(index);
          }, 0);
        } else {
          toggleDropdown(index);
        }
      };

      const linkLocation = item?.link?.split('/')[1];
      const activeRoute = locationArray[1] === linkLocation;

      const Icon = item?.icon;
      const BadgeIcon = item?.badge;

      const isOpen = openDropdowns?.includes(index);

      const handleTabClick = (subItem) => {
        setActiveTab(subItem.link);
        setTimeout(() => {
          history.push(subItem.link);
        }, 300);
      };

      return (
        <div
          key={index}
          className={classNames(
            'nav-item-wrap',
            {
              isActive: locationArray[1] === linkLocation,
            },
            {
              closing: closingIndex === index,
            },
          )}
          data-closing-index={closingIndex}
          id={item.name.replace(/\s/g, '-').toLowerCase()}
        >
          {item.subLink ? (
            <>
              <AllowedTo scopes={item.permissions}>
                <div
                  className={classNames(
                    'd-flex justify-content-between cursor',
                    'isMenu-not-active',
                    {
                      'isMenu-active': !isOpen && fileterd.includes(index),
                    },
                  )}
                  onClick={() => {
                    handleToggleDropdown(index, item);
                    if (item.subLink && item.subLink.length > 0) {
                      history.push(item.subLink[0].link);
                    }
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: item?.icon ? '8px' : '6px' }}
                  >
                    <div>
                      {item?.icon && (
                        <Icon
                          fill={activeRoute ? '#5E9900' : ''}
                          stroke={activeRoute ? '#5E9900' : ''}
                          height="20"
                          width="20"
                        />
                      )}
                    </div>
                    <span>{item.name}</span>
                  </div>

                  <div className={`chevron-wrapper ${isOpen ? 'active' : ''}`}>
                    <span className="chevron-arrow"></span>
                  </div>
                </div>
              </AllowedTo>

              {/* Render submenu items */}
              {(isOpen || closingIndex === index) && (
                <div
                  className={classNames('mini-tab-bar fade-in', {
                    // open: isOpen,
                    // close: closingIndex === index && !isOpen,
                  })}
                >
                  {item?.subLink.map((subItem, i) => {
                    const SubBadgeIcon = subItem?.badge;

                    return (
                      <AllowedTo key={i + subItem.name} scopes={subItem.permissions}>
                        <div
                          className={classNames(
                            'tab-item d-flex align-items-center w-100',
                            {
                              active: activeTab === subItem.link,
                              isLast: i === item?.subLink.length - 1,
                            },
                          )}
                          data-closing-index={closingIndex}
                          onClick={() => {
                            handleTabClick(subItem);
                            setIsOpen && setIsOpen(false);
                          }}
                        >
                          <span>{subItem.name}</span>
                          {SubBadgeIcon &&
                            typeof SubBadgeIcon === 'object' &&
                            SubBadgeIcon}
                          {SubBadgeIcon && typeof SubBadgeIcon === 'function' && (
                            <SubBadgeIcon className="ms-auto" />
                          )}
                        </div>
                      </AllowedTo>
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            <>
              {item.permissions ? (
                <AllowedTo scopes={item.permissions}>
                  <NavLink
                    to={item.link}
                    onClick={() => {
                      toggleDropdown(closingIndex);
                      setIsOpen && setIsOpen(false);
                    }}
                    activeClassName={classNames(
                      {
                        ['isMenu-active']: locationArray[1] === linkLocation,
                      },
                      {
                        ['ml-40']: isSubLink,
                      },
                    )}
                    className={classNames(
                      'isMenu-not-active d-flex justify-content-between',
                      {
                        ['isMenu-active']:
                          locationArray[1] === linkLocation && !isSubLink,
                      },
                      {
                        ['ml-40']: isSubLink,
                      },
                    )}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: item?.icon ? '8px' : '6px' }}
                    >
                      <div>
                        {item?.icon && (
                          <>
                            {item.tag === 'treasury' ? (
                              <Icon
                                fill={activeRoute ? '#5E9900' : '#E0E0E0'}
                                stroke={activeRoute ? '#5E9900' : ''}
                              />
                            ) : item.tag === 'get-started' ? (
                              <Icon
                                height="24"
                                width="24"
                                fill={activeRoute ? '#D28B28' : ''}
                                stroke={activeRoute ? '#D28B28' : ''}
                              />
                            ) : (
                              <Icon
                                height="20"
                                width="20"
                                fill={activeRoute ? '#5E9900' : ''}
                                stroke={activeRoute ? '#5E9900' : ''}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <span>{item.name}</span>
                    </div>
                    {BadgeIcon && <BadgeIcon />}
                    {Badger(item.tag, stats, activeRoute)}
                  </NavLink>
                </AllowedTo>
              ) : (
                <NavLink
                  to={item.link}
                  onClick={(e) => {
                    item?.action && item?.action(e);
                    setIsOpen && setIsOpen(false);
                  }}
                  activeClassName={classNames(
                    {
                      ['isMenu-active']: locationArray[1] === linkLocation,
                    },
                    {
                      ['ml-40']: isSubLink,
                    },
                  )}
                  className={classNames(
                    'isMenu-not-active d-flex justify-content-between',
                    {
                      ['isMenu-active']: locationArray[1] === linkLocation && !isSubLink,
                    },
                    {
                      ['ml-40']: isSubLink,
                    },
                  )}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: item?.icon ? '8px' : '6px' }}
                  >
                    <div>
                      {item?.icon && (
                        <Icon
                          height="20"
                          width="20"
                          fill={activeRoute ? '#5E9900' : ''}
                          stroke={activeRoute ? '#5E9900' : ''}
                        />
                      )}
                    </div>
                    <span>{item.name}</span>
                  </div>
                  {BadgeIcon && <BadgeIcon />}
                  {Badger(item.tag, stats, activeRoute)}
                </NavLink>
              )}
            </>
          )}
        </div>
      );
    });
  };

  // return renderMenuItems(lists, isSubLink);
  return (
    <div className="menu-container">
      <div className="indicator"></div>
      {renderMenuItems(lists, isSubLink)}
    </div>
  );
};

import { useEffect, useState } from 'react';

export const scrollHook = (parentRef, scaleSize = 80) => {
  const [fontSize, setFontSize] = useState('2.5rem');
  const [iconSize, setIconSize] = useState(4);
  const [scrollSize, setScrollSize] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = parentRef.current.scrollTop;
      const newSize = Math.max(1.2, Math.min(2.5, 2.5 - scrollY / scaleSize));
      const newIconSize = Math.max(2.7, Math.min(4, 4 - scrollY / scaleSize));
      const parentHeight = parentElement.scrollHeight - parentElement.clientHeight; // Total scrollable height
      const scrollPercentage = (scrollY / parentHeight) * 100; // Calculate scroll percentage

      setFontSize(`${newSize}rem`);
      setIconSize(newIconSize);

      setScrollSize(scrollPercentage);
    };

    const parentElement = parentRef.current;
    if (parentElement) {
      parentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (parentElement) {
        parentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return { fontSize, iconSize, scrollSize, setScrollSize };
};

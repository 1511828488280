import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/UI/Modal';
import CustomPopover from 'components/UI/Popover';
import CustomButton from 'components/UI/CustomButton';
import BadgeType from 'components/UI/Table/BadgeType';
import { toastSuccess } from 'components/UI/toast';
import AssetViewer from 'components/AssetViewer';

import dayjs from 'dayjs';
import {
  capitalizeFirstLetter,
  getColor,
  getCurrency,
  invoiceStatusAliaser,
  convertNaNToZero,
} from 'utils/helper';

import { getAssets } from 'redux/actions/AssetsAction';
import { payBill, markBillAsPaid } from 'redux/actions/BillAction';

import copy from 'copy-text-to-clipboard';
import { Skeleton, Timeline, Avatar } from 'antd';
import ImgCard from 'components/UI/ImgCard';
import CurrencyFormat from 'react-currency-format';
import {
  XcloseIcon,
  CopyIcon,
  TimelineCheckIcon,
  TimelineDot,
  ArrowUpRightIcon,
  ChevronDown,
  SendIcon,
  CoinsStacked,
} from 'assets/icons';
import { allPermissions } from 'utils/AllowedTo';
import { fetchSingleBill, fetchSingleScheduledBill } from 'redux/actions/BillAction';

const SkeletonLoader = () => (
  <section>
    <div className="d-flex align-items-center justify-content-between mb-4 pb-1 invoice-details-pad">
      <Skeleton.Input active size={24} style={{ borderRadius: '4px' }} />
      <Skeleton.Avatar active size={14} />
    </div>

    <div className="customer-details-region mt-3">
      <Skeleton.Avatar active size={48} />
      <Skeleton.Input
        className="pt-4"
        active
        size={24}
        style={{ borderRadius: '4px', width: '14rem' }}
      />
      <Skeleton.Input
        className="pt-2"
        active
        size={12}
        style={{ borderRadius: '4px', width: '7rem', minWidth: '7rem' }}
      />

      <Skeleton.Input
        className="pt-4"
        style={{ borderRadius: '40px', width: '4rem', minWidth: '4rem', height: 32 }}
      />
    </div>

    <section className="invoice-detail-holder mt-4 pb-5">
      {Array.from({ length: 4 }).map((_, i) => (
        <div key={i} className="detail">
          <Skeleton.Input
            style={{ borderRadius: '4px', width: '6rem', minWidth: '6rem', height: 12 }}
          />

          <Skeleton.Input
            className="ms-auto"
            style={{ borderRadius: '4px', width: '6rem', minWidth: '6rem', height: 12 }}
          />
        </div>
      ))}

      <section className="payment-schedule-region mt-4">
        {Array.from({ length: 2 }).map((_, i) => (
          <div className="schedule-holder mb-2" key={i}>
            <div className="d-flex align-items gap-3">
              <div>
                <Skeleton.Input
                  style={{
                    borderRadius: '8px',
                    width: '6rem',
                    minWidth: '6rem',
                    height: 12,
                  }}
                />
              </div>
            </div>

            <div className="ms-auto d-flex flex-column">
              <div>
                <Skeleton.Input
                  style={{
                    borderRadius: '8px',
                    width: '7rem',
                    minWidth: '7rem',
                    height: 12,
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </section>

      <div className="timeline-region pt-4 mt-4">
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton className="mb-3" key={index} loading active avatar />
        ))}
      </div>

      <section className="payment-schedule-region mt-4">
        {Array.from({ length: 3 }).map((_, i) => (
          <div className="schedule-holder mb-2" key={i}>
            <div className="d-flex align-items gap-3">
              <div>
                <Skeleton.Input
                  style={{
                    borderRadius: '8px',
                    width: '6rem',
                    minWidth: '6rem',
                    height: 12,
                  }}
                />
              </div>
            </div>

            <div className="ms-auto d-flex flex-column">
              <div>
                <Skeleton.Input
                  style={{
                    borderRadius: '8px',
                    width: '7rem',
                    minWidth: '7rem',
                    height: 12,
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </section>
    </section>
    <div className="footer-region invoice-details-pad w-100">
      <Skeleton.Input
        active
        style={{ borderRadius: '8px', width: '100%', minWidth: 230, height: 40 }}
      />
      <Skeleton.Input
        active
        style={{ borderRadius: '8px', width: '100%', minWidth: 230, height: 40 }}
      />
    </div>
  </section>
);

const Details = ({ data = {}, loading, isSchedule, isPermittedToPayBill }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const {
    timeline = [],
    billProducts: products = [],
    installments: payments = [],
  } = data;
  const hasPartialPayment = data?.minAmountDue;

  const {
    payBill: { loading: isPaying },
    markBillAsPaid: { loading: isMarking },
  } = useSelector(({ bills }) => bills);

  const copyLink = (link) => {
    copy(link);
    toastSuccess(' copied succesfully');
  };

  const handleSource = () => {
    if (data?.balance) {
      history.push(`/accounts/${data?.balance?.code}`);
    } else {
      history.push(`/expenses/budgets/${data?.budget?.code}/overview`);
    }
  };

  const handlePayBill = (isPartial = false) => {
    const amount = isPartial ? data?.minAmountDue : data?.balanceDue;
    const payload = { code: data?.code, amount };
    dispatch(payBill(payload));
  };

  const handleBillAction = (action) => {
    const payload = { code: data?.code };

    if (action === 'pay') {
      if (data?.status !== 'partial' && hasPartialPayment) return setVisible(!visible);
      handlePayBill();
    } else if (action === 'mark') {
      dispatch(markBillAsPaid(payload));
    }
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        {data?.minAmountDue && (
          <div
            className="actionLink bill-action-link gap-8"
            onClick={() => handlePayBill(true)}
          >
            <SendIcon stroke="#BFBFBD" height="16" width="16" />
            Pay
            <span>
              <CurrencyFormat
                prefix={getCurrency(data?.currency)}
                value={(data?.minAmountDue / 100).toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
            </span>
          </div>
        )}

        <div
          className="actionLink bill-action-link gap-8"
          onClick={() => handlePayBill()}
        >
          <SendIcon stroke="#BFBFBD" height="16" width="16" />
          Pay full amount
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div>
          <div className="information-wrapper">
            <div className="d-flex justify-content-between mb-4 pb-1 invoice-details-pad">
              <h2 className="card-title invoice-details-header w-100">Bill details</h2>
            </div>
          </div>

          <div className="customer-details-region">
            <div className="d-flex justify-content-center">
              <ImgCard size="xlarge" initials={data?.vendor?.name?.charAt(0)} />
            </div>

            <span className="invoice-amount">
              <CurrencyFormat
                prefix={getCurrency(data?.currency)}
                value={(data?.amount / 100).toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
            </span>

            <span
              className="customer cursor"
              onClick={() =>
                history.push(`/expenses/vendors/profile/${data?.vendor?.code}`)
              }
            >
              {data?.vendor?.name}
            </span>

            <div className="mt-4">
              <BadgeType
                value={{ value: invoiceStatusAliaser(data?.status, data?.type) }}
                color={getColor(invoiceStatusAliaser(data?.status, data?.type))}
              />
            </div>
          </div>

          <section className="invoice-detail-holder mt-4">
            <section>
              <div className="detail">
                <span className="header">Requested by</span>
                <div className=" ms-auto d-flex align-items-center gap-2">
                  <div>
                    <ImgCard initials={data?.user?.firstName?.charAt(0)} />
                  </div>

                  <span className="value">{`${data?.user?.firstName} ${data?.user?.lastName}`}</span>
                </div>
              </div>
              <div className="detail">
                <span className="header"> Creation date </span>
                <span className="value ms-auto">
                  {' '}
                  {dayjs(data?.created_at).format('DD MMM, YYYY')}
                </span>
              </div>

              {data?.schedule?.hasNext && (
                <div className="detail">
                  <span className="header"> Next Bill date </span>
                  <span className="value ms-auto">
                    {' '}
                    {dayjs(data?.schedule?.nextDate).format('DD MMM, YYYY')}
                  </span>
                </div>
              )}

              <div className="detail">
                <span className="header"> Due date </span>
                <span className="value ms-auto">
                  {' '}
                  {dayjs(data?.dueDate).format('DD MMM, YYYY')}
                </span>
              </div>

              <div className="detail">
                <span className="header"> Method </span>
                <span className="value ms-auto">
                  {capitalizeFirstLetter(data?.paymentMethod) || '-'}
                </span>
              </div>

              <div className="detail">
                <span className="header"> Unique identifier/reference </span>
                <span className="value ms-auto">{data?.reference}</span>
              </div>

              <div className="detail">
                <span className="header"> Unique ID </span>
                <span
                  className="value d-flex align-items-center ms-auto gap-1"
                  style={{ color: '#D28B28' }}
                  onClick={() => copyLink(data?.code)}
                >
                  <span style={{ borderBottom: '1px solid #D28B28' }}>{data?.code}</span>

                  <span className="icon-animate">
                    <CopyIcon stroke="#D28B28" height="16" />
                  </span>
                </span>
              </div>
            </section>

            {/* Bill Items here */}
            <section className="bill-detail__items mt-4">
              {products.map((item, i) => (
                <div
                  key={item?.code}
                  className={`item-holder ${i === products?.length - 1 ? 'mb-0' : ''}`}
                >
                  {item?.product && (
                    <span className="item">
                      {item?.product?.name}

                      <span className="badge"> {`Qty ${item?.quantity}`} </span>
                    </span>
                  )}

                  <span className="value">
                    <CurrencyFormat
                      prefix={getCurrency(item?.currency)}
                      value={(item?.product?.price / 100).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </span>
                </div>
              ))}
            </section>

            {/* Collectible Details here */}
            <section className="bill-detail__items mt-4">
              <div className="item-holder">
                <span className="item" style={{ fontWeight: 600, color: '#212120' }}>
                  Total amount
                </span>

                <span className="value">
                  <CurrencyFormat
                    style={{ fontWeight: 700 }}
                    prefix={getCurrency(data?.currency)}
                    value={(data?.amount / 100).toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </span>
              </div>

              {data?.minAmountDue ? (
                <div className="item-holder">
                  <span className="item">Partial payment</span>

                  <span className="value">
                    <CurrencyFormat
                      prefix={getCurrency(data?.currency)}
                      value={(data?.minAmountDue / 100).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </span>
                </div>
              ) : null}

              <div className="item-holder mb-0">
                <span className="item">Balance</span>

                <span className="value">
                  <CurrencyFormat
                    prefix={getCurrency(data?.currency)}
                    value={(data?.balanceDue / 100).toFixed(2)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </span>
              </div>
            </section>

            {/* Timeline here */}
            {timeline.length ? (
              <div className="my-4 timeline-region">
                <Timeline>
                  {timeline.map((item, index) => (
                    <TimelineItem
                      key={index}
                      timeline={item}
                      isLast={timeline.length - 1 === index}
                    />
                  ))}
                </Timeline>
              </div>
            ) : null}

            <section className="bill-detail__items mt-4 pt-4">
              {data?.category && (
                <div className="item-holder">
                  <span className="item" style={{ fontSize: '0.875rem' }}>
                    Expense category
                  </span>
                  <span className="value">{data?.category?.name}</span>
                </div>
              )}

              {data?.paymentMethod && (
                <div className="item-holder">
                  <span className="item" style={{ fontSize: '0.875rem' }}>
                    Source of funds
                  </span>

                  <span className="value d-flex align-items-center gap-1 ">
                    {data[data?.paymentMethod]?.name || ''}

                    <span style={{ cursor: 'pointer' }} onClick={() => handleSource()}>
                      <ArrowUpRightIcon width="16" height="16" stroke="#D28B28" />
                    </span>
                  </span>
                </div>
              )}
            </section>

            {/* Payment History  */}
            {!!payments.length && (
              <section className="bill-detail__items unset-height pt-4">
                <h4 className="header pb-3">Payment history</h4>

                {[...payments].reverse().map((payment, index) => (
                  <div className="schedule-holder mb-4" key={payment?.code}>
                    <div className="d-flex align-items-center gap-3 w-50">
                      <Avatar
                        className="plain"
                        size={40}
                        icon={<CoinsStacked />}
                        style={{ background: '#F5F5F4' }}
                      />
                      <div>
                        <span className="head">Payment {index + 1}</span>
                        <span className="due">
                          {payment?.status !== 'paid' ? 'Due' : 'Paid'} on{' '}
                          {dayjs(
                            payment?.[
                              payment?.status === 'paid' ? 'paidOn' : 'dueDate'
                            ] || new Date(),
                          ).format('DD MMM, YYYY')}
                        </span>
                      </div>
                    </div>

                    <div className="ms-auto w-25 d-flex flex-column align-items-end">
                      <span className="head">
                        <CurrencyFormat
                          prefix={getCurrency('NGN')}
                          value={convertNaNToZero((payment?.amount / 100).toFixed(2))}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      </span>
                      <span
                        className={`status ${payment?.status === 'paid' ? 'paid' : ''}`}
                      >
                        {payment?.status !== 'paid' ? 'Unpaid' : 'Paid'}
                      </span>
                    </div>
                  </div>
                ))}
              </section>
            )}
          </section>

          {!isSchedule &&
            !['declined', 'draft', 'paid', 'verifying'].includes(data?.status) &&
            isPermittedToPayBill && (
              <div className="footer-region justify-content-end px-4">
                <CustomButton
                  customClass
                  className={'add-custom add-button request-btn gap-2 px-2'}
                  onClick={() => handleBillAction('mark')}
                  loading={isMarking}
                  disabled={isMarking || isPaying}
                >
                  <span className="ms-1" style={{ fontWeight: 500, color: '#57534E' }}>
                    Mark as paid
                  </span>
                </CustomButton>

                <div className="d-flex">
                  <CustomPopover
                    zIndex="1"
                    showPopover={visible}
                    content={<Actions />}
                    placement="top-start"
                    customClass="multi-entity bill-action"
                    clickOutside={() => setVisible(false)}
                  >
                    <CustomButton
                      loading={isPaying}
                      className="add-button gap-2"
                      disabled={isPaying || isMarking}
                      onClick={() => handleBillAction('pay')}
                    >
                      <span> Pay bill</span>

                      {hasPartialPayment && !['partial'].includes(data?.status) && (
                        <span>
                          <ChevronDown
                            color="#ffffff"
                            className={`toggle-icon ${visible && 'is-toggled'}`}
                          />
                        </span>
                      )}
                    </CustomButton>
                  </CustomPopover>
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

const BillDetail = ({ isOpen, handleClose, code, isSchedule = false }) => {
  const dispatch = useDispatch();
  const { isPermittedToPayBill } = allPermissions();

  const {
    fetchSingleBill: { loading: billLoading, success: billSuccess, data: singleBill },
    fetchSingleScheduledBill: {
      loading: isLoading,
      success: singleSuccess,
      data: scheduledBill,
    },
    payBill: { success: isPaySuccess },
    markBillAsPaid: { success: isMarkSuccess },
  } = useSelector(({ bills }) => bills);

  const loading = billLoading || isLoading;
  const bill = isSchedule ? scheduledBill : singleBill;
  const { code: uploadedAsset = '' } = bill?.uploads[0] || {};

  const {
    getAsset: { data: { asset = {} } = {}, loading: assetLoading },
  } = useSelector(({ assets }) => assets);

  useEffect(() => {
    if (uploadedAsset) dispatch(getAssets(uploadedAsset));
  }, [code, uploadedAsset]);

  useEffect(() => {
    if (code) {
      if (isSchedule) {
        dispatch(fetchSingleScheduledBill({ code }));
      } else {
        dispatch(fetchSingleBill({ code: `${code}?includeTimeline=${true}` }));
      }
    }
  }, [code, isPaySuccess, isMarkSuccess]);

  if (!isOpen) return null;

  const assetData = {
    component: (
      <>
        {assetLoading || loading ? (
          <section
            className="invoice-preview detail d-flex flex-column mt-5"
            style={{ background: 'inherit', height: 'auto' }}
          >
            <Skeleton.Image size={140} active style={{ width: '100%', height: '90vh' }} />
          </section>
        ) : (
          <AssetViewer
            data={[asset]}
            selectedAsset={asset}
            singleData={asset}
            containerClass="bill-asset__holder"
          />
        )}
      </>
    ),
  };

  return (
    <div>
      <Modal
        show={isOpen}
        onClose={handleClose}
        hasAsset={loading ? true : bill?.hasFile}
        modalStyle={{ padding: 0 }}
        loading={loading || assetLoading}
        customAsset={assetData?.component}
      >
        <div className="content py-4">
          <div className="card-modal-header invoice-details-pad">
            <button
              id="close-icon"
              style={{ background: 'inherit', border: 'none' }}
              className="d-flex align-items-center cursor modal-close-icon"
              onClick={(event) => handleClose()}
            >
              <XcloseIcon width="16" height="16" stroke="#57534E" />
              <span
                className="ps-1"
                style={{ color: '#57534E', fontWeight: 500, fontSize: '0.75rem' }}
              >
                Close
              </span>
            </button>
          </div>

          <div className="card-modal-body invoice">
            <Details
              data={bill}
              loading={loading}
              isSchedule={isSchedule}
              isPermittedToPayBill={isPermittedToPayBill}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const TimelineItem = ({ timeline, isLast }) => {
  const renderTimelineItem = () => {
    switch (timeline?.type) {
      case 'creation':
        return (
          <Timeline.Item
            className={isLast ? 'ant-timeline-item-last' : ''}
            dot={<TimelineCheckIcon />}
          >
            <div>
              <span className="timeline-header">{timeline?.title}</span>
              <span className="timeline-text">
                <div>
                  <ImgCard initials={timeline?.performedBy?.firstName?.charAt(0)} />
                </div>

                {`${timeline?.performedBy?.firstName} ${timeline?.performedBy?.lastName}`}

                <span className="timeline-text small" style={{ paddingTop: '3px' }}>
                  {dayjs(timeline?.performedAt).format('DD MMM YYYY, h:mm A')}
                </span>
              </span>
            </div>
          </Timeline.Item>
        );

      case 'pending_approval':
        return (
          <Timeline.Item
            className={isLast ? 'ant-timeline-item-last' : ''}
            dot={timeline?.status === 'pending' ? <TimelineDot /> : <TimelineCheckIcon />}
          >
            <div>
              <span className="timeline-header">{timeline?.title} from</span>

              <>
                {timeline?.approvers?.length > 1 ? (
                  <section className="d-flex align-items-center flex-wrap gap-2">
                    {timeline?.approvers.map((user, index) => (
                      <div key={index} className="d-flex align-items-center gap-2">
                        <div>
                          <ImgCard initials={user?.firstName?.charAt(0)} />
                        </div>
                        <span className="timeline-text">
                          {`${user?.firstName} ${user?.lastName}`}{' '}
                        </span>
                        {timeline?.approvers?.length - 1 !== index && (
                          <span style={{ fontWeight: 500, color: '#79716b' }}>
                            {' '}
                            {timeline?.condition === 'any' ? 'or' : 'and'}{' '}
                          </span>
                        )}
                      </div>
                    ))}
                  </section>
                ) : (
                  <div className="d-flex align-items-center gap-2">
                    <div>
                      <ImgCard initials={timeline?.approvers[0]?.firstName?.charAt(0)} />
                    </div>
                    <span className="timeline-text">
                      {' '}
                      {`${timeline?.approvers[0]?.firstName} ${timeline?.approvers[0]?.lastName}`}{' '}
                    </span>
                  </div>
                )}
              </>
            </div>
          </Timeline.Item>
        );

      case 'approval_action':
        return (
          <Timeline.Item
            className={isLast ? 'ant-timeline-item-last' : ''}
            dot={timeline?.status === 'pending' ? <TimelineDot /> : <TimelineCheckIcon />}
          >
            <div>
              <span className="timeline-header">{timeline?.title}</span>
              <span className="timeline-text">
                <div>
                  <ImgCard initials={timeline?.performedBy?.firstName?.charAt(0)} />
                </div>

                {`${timeline?.performedBy?.firstName} ${timeline?.performedBy?.lastName}`}

                <span className="timeline-text small" style={{ paddingTop: '3px' }}>
                  {dayjs(timeline?.performedAt).format('DD MMM YYYY, h:mm A')}
                </span>
              </span>
            </div>
          </Timeline.Item>
        );

      case 'schedule':
      case 'pending_installment':
      case 'overdue_installment':
      case 'pending_payment':
        return (
          <Timeline.Item
            className={isLast ? 'ant-timeline-item-last' : ''}
            dot={timeline?.status === 'pending' ? <TimelineDot /> : <TimelineCheckIcon />}
          >
            <div>
              <span className="timeline-header">{timeline?.title} </span>

              <div className="d-flex align-items-center gap-2">
                <span className="timeline-text small">{timeline?.description}</span>
              </div>
            </div>
          </Timeline.Item>
        );

      case 'paid_installment':
        return (
          <Timeline.Item
            className={isLast ? 'ant-timeline-item-last' : ''}
            dot={timeline?.status === 'pending' ? <TimelineDot /> : <TimelineCheckIcon />}
          >
            <div>
              <span className="timeline-header">{timeline?.title}</span>
              <span className="timeline-text">
                <div>
                  <ImgCard initials={timeline?.performedBy?.name?.charAt(0)} />
                </div>

                {`${timeline?.performedBy?.name}`}

                <span className="timeline-text small" style={{ paddingTop: '3px' }}>
                  {dayjs(timeline?.performedAt).format('DD MMM YYYY, h:mm A')}
                </span>
              </span>
            </div>
          </Timeline.Item>
        );

      default:
        return null;
    }
  };

  return renderTimelineItem();
};

export default BillDetail;

import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import CustomButton from 'components/UI/CustomButton';
import CustomTextarea from 'components/UI/CustomTextarea';
import Success from 'components/UI/ModalSaving/Success';
import { toastError } from 'components/UI/toast';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import { declineReimbursements } from 'redux/actions/ReimbursementsAction';

const DeclineReimbursement = ({
  setStep,
  isLoaded,
  selectReimbursement,
  hideHeader,
  setSelectReimbursement,
  kebabAction = false,
  onClose,
  hideTitle,
}) => {
  if (!selectReimbursement) return <div />;
  const [value, setValue] = useState('');

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  const dispatch = useDispatch();
  const {
    declineReimbursement: { loading },
  } = useSelector(({ reimbursement }) => reimbursement);

  const {
    reviewRequest: { loading: loadingApproval },
  } = useSelector(({ approval }) => approval);

  const { reimbursementsData: { code = '', approvalRequest = {} } = {} } =
    selectReimbursement || {};

  const { code: requestCode = '' } = approvalRequest || {};

  const handleSubmit = () => {
    if (!value) return toastError('Enter Description');

    const payload = {
      note: value,
      code,
    };
    if (approvalRequest && requestCode) {
      return dispatch(reviewRequest({ code: requestCode, status: 'declined' }));
    }
    dispatch(declineReimbursements(payload));
  };

  const handleCloseDecline = () => {
    setValue('');
    if (onClose) onClose();
    if (hideHeader) hideHeader(false);
    if (setStep) setStep(1);
    if (setSelectReimbursement) setSelectReimbursement(selectReimbursement);
  };

  const infoCard = () => {
    return (
      <div
        className={classNames('information-wrapper decline-request-container', {
          'py-0 my-0': hideTitle,
        })}
      >
        {!hideTitle && (
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="color-grey-900 text-ds-xs font-semibold">Decline request</h2>
          </div>
        )}
        <Row className="mb-3">
          <CustomTextarea
            row={5}
            label="Reason for declining"
            name="reason"
            onChange={handleValueChange}
            value={value}
            placeholder="Specify the reason for the decline"
          />
        </Row>

        <div
          className="position-fixed bottom-0 card-modal-footer bg-white px-4 py-2 border-top w-100"
          style={{ right: 0 }}
        >
          <div className="d-flex align-items-center gap-2 justify-content-end">
            <CustomButton
              withoutBg
              onClick={handleCloseDecline}
              disabled={loading || loadingApproval}
              className="base-button text-sm font-medium border  black-transparent"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={handleSubmit}
              disabled={loading || loadingApproval}
              loading={loading || loadingApproval}
              className="base-button text-sm font-medium dark-button"
            >
              Decline
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!hideTitle && (
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleCloseDecline}>
            {kebabAction ? <CloseOutlined /> : <ArrowLeftOutlined />}
            <span className="ps-1">{kebabAction ? 'Close' : 'Back'}</span>
          </div>
        </div>
      )}

      {isLoaded ? (
        <div className="px-4">
          <Success
            title="Thank you!"
            message="Your rejection has been taken into account and the applicant will be informed"
          />
        </div>
      ) : (
        infoCard()
      )}
    </div>
  );
};

export default DeclineReimbursement;

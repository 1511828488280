import {
  ArrowLeftOutlined,
  CloseOutlined,
  PushpinOutlined,
  StopOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Progress, Skeleton } from 'antd';
import {
  DotsVertical,
  ArrowTop,
  CreditCardPlus,
  CreditCardLock,
  ClockRewind,
  DeleteIcon,
  TrashIcon,
  UserIcon02,
} from 'assets/icons';

import ConfirmDialog from 'components/ConfirmDialog';
import DeleteDialog from 'components/DeleteDialog';

import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import ImgCard from 'components/UI/ImgCard';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import CustomPopover from 'components/UI/Popover';
import { toastError } from 'components/UI/toast';
import { useEffect, useRef, useState } from 'react';
import { Modal as BootstrapModal, Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { budgetCard, currencyConversionRate } from 'redux/actions/BudgetsAction';
import {
  deleteCard,
  freezeUnfreezeCard,
  getCard,
  getDefaultPin,
  getMyCardsAction,
  getSingleCard,
  loadUnloadCard,
  reassignCard,
  setNewPinAction,
} from 'redux/actions/CardAction';
import {
  capitalizeFirstLetter,
  getCurrency,
  getFormattedDate,
  truncateText,
} from 'utils/helper';
import { numFormatter } from 'utils/utility';
import CardView from '../../components/CardView';
import TopUpCard from './TopUpCard';
import './styles.scss';
import ViewDefualtPin from './CardsFormModal/PhysicalCardSection/ViewDefualtPin';
import EditCardSettings from './CardsFormModal/EditCardSettings';
import classNames from 'classnames';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import CustomSelect from 'components/UI/CustomSelect';
import { useDebounce } from 'hooks/useDebounce';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import ExchangeRate from 'components/UI/ExchangeRate';

const CardsTableModal = ({ selectCards, setSelectCards, toggleHandler }) => {
  if (!selectCards) return <div />;

  const dispatch = useDispatch();
  const { budgetId } = useParams();
  const history = useHistory();
  const { permissions, isAdmin } = allPermissions();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [editCardSetting, setEditCardSetting] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  // const [deleteGateRendering, setDeleteGateRendering] = useState(false);
  const cardNumberRef = useRef(null);
  const cvvNumberRef = useRef(null);
  const pinRef = useRef(null);
  const copyPinRef = useRef(null);
  const [isBack, setIsBack] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [getPin, setGetPin] = useState(false);
  const [startInterval, setStartInterval] = useState(false);

  // const [isPinReady, setIsPinReady] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);
  const [newPin, setNewPin] = useState({
    visible: false,
    pin: null,
    oldPin: null,
    confirm_pin: null,
  });
  const [holder, setHolder] = useState({});
  const [search, setSearch] = useState(null);
  const debouncedValue = useDebounce(search, 300);

  const query = new URLSearchParams(location.search);

  const canViewBeneficiary = hasPermission({
    permissions,
    scopes: ['employee-view'],
  });

  const closeHandler = () => {
    if (!isPopoverOpen && !isDelete) {
      if (!isOtherPopoverOpen) setSelectCards(null);
      if (toggleHandler) toggleHandler();
    }
  };

  const {
    getSingleCard: { data = {}, loading, error },
    loadUnloadCard: { success, loading: liquidateLoading },
    freezeUnfreezeCard: { loading: isTogglingBlockState, success: blockStateStatus },
    setCardPin: { loading: isSettingPin, success: isPinSuccess },
    deleteCard: { loading: isDeleteLoading, success: isDeleteSuccess },
    getDefaultPin: { data: dataPin },
    reassignCard: { loading: reassigning, success: reassigned },
  } = useSelector(({ card }) => card);

  const {
    getCurrencyConversionRate: {
      data: currencyExchange = {},
      loading: loadingConversion,
    },
  } = useSelector(({ budgets }) => budgets);

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const payerCode = query.get('payer');

  const initVGS = () => {
    const { token, cardId } = data?.card;
    if (loading) return;

    const options = {
      htmlWrapper: 'text',
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };

    const show = VGSShow.create(
      process.env.REACT_APP_VAULT_ID,
      (state) => {},
    ).setEnvironment(process.env.REACT_APP_VAULT_ENV);

    const cardNumber = show.request({
      name: 'pan-text',
      path: '/cards/' + cardId + '/secure-data/number',
      jsonPathSelector: 'data.number',
      ...options,
    });

    const cvv = show.request({
      name: 'cvv-text',
      path: '/cards/' + cardId + '/secure-data/cvv2',
      jsonPathSelector: 'data.cvv2',
      ...options,
    });

    const pin = show.request({
      name: 'pin-text',
      path: '/cards/' + cardId + '/secure-data/defaultPin',
      jsonPathSelector: 'data.defaultPin',
      ...options,
    });

    const inlineStyle = {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 500,
      fontFamily: 'Inter, sans-serif',
      fontStyle: 'normal',
      color: '#1C1917',
      display: 'block',
      textAlign: 'right',
    };

    const buttonInlineStyle = {
      fontSize: '18px',
      width: '100%',
      height: '48px',
      color: '#fff',
      position: 'fixed !important',
      top: '0',
      bottom: '12px',
      background: '#24262A !important',
      borderRadius: '10px',
      cursor: 'pointer',
    };

    cardNumber.render(cardNumberRef.current, inlineStyle);
    cvv.render(cvvNumberRef.current, inlineStyle);

    const copyPinButton = show.copyFrom(
      pin,
      { text: 'Update PIN to activate card' },
      (status) => {
        navigator.clipboard
          .readText()
          .then((clipText) => setNewPin({ ...newPin, oldPin: clipText, visible: true }));
      },
    );

    if (pin) {
      try {
        pin.render(pinRef.current, inlineStyle);
      } catch (errorPin) {}
    }

    pin.on('revealSuccess', function () {
      // setIsPinReady(true);
      try {
        copyPinButton.render(copyPinRef.current, buttonInlineStyle);
      } catch (errorPin) {}
    });
  };

  useEffect(() => {
    if (selectCards) {
      dispatch(getSingleCard({ id: selectCards.code }));
    }
  }, [selectCards]);

  useEffect(() => {
    if (data && data?.card?.secDepositor === 'vgs') {
      // setDeleteGateRendering(true);
      initVGS();
    } else {
      // setDeleteGateRendering(false);
    }
  }, [data]);

  useEffect(() => {
    if (isBack) {
      // setDeleteGateRendering(true);
      initVGS();
    } else {
      // setDeleteGateRendering(false);
    }
  }, [isBack]);

  const isCardLock = data?.card?.status?.toLowerCase() === 'blocked';

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const handleCardLock = () => {
    const payload = {
      code: data?.card?.code,
      type: isCardLock ? 'unblock' : 'block',
    };
    dispatch(freezeUnfreezeCard(payload));
    setIsPopoverOpen(false);
    setIsDelete(false);
    closeHandler();
  };

  const handleIsUpdate = () => {
    setIsUpdate(!isUpdate);
  };

  const handleCloseSetting = () => {
    setEditCardSetting(!editCardSetting);
  };

  const handleCardLoadUnload = () => {
    const payload = {
      code: data?.card?.code,
    };
    if (actionType === 'delete') return dispatch(deleteCard(data?.card?.code));
    if (actionType === 'liquidate') return dispatch(loadUnloadCard(payload));
    if (actionType === 'reassign') {
      payload.beneficiary = holder?.beneficiary?.value;
      return dispatch(reassignCard(payload));
    }
  };

  const handleCardLoadUnloadModal = (type) => {
    setActionType(type);
    setIsDelete(!isDelete);
  };

  useEffect(() => {
    if (success || isDeleteSuccess || reassigned) {
      setIsDelete(false);
      setSelectCards(null);
      if (budgetId) {
        dispatch(budgetCard({ budget: budgetId }));
      } else {
        dispatch(getCard());
        dispatch(getMyCardsAction({ owner: userData?.user?.code, payer: payerCode }));
      }
    }
  }, [success, isDeleteSuccess, reassigned]);

  useEffect(() => {
    if (!loading && error) {
      closeHandler();
    }
  }, [loading, error]);

  useEffect(() => {
    if (!isTogglingBlockState && blockStateStatus) {
      dispatch(getCard());
      dispatch(getMyCardsAction());
      closeHandler();
    }
  }, [isTogglingBlockState, blockStateStatus]);

  useEffect(() => {
    if (canViewBeneficiary) dispatch(getBeneficiaries());
  }, []);
  const viewCardTransactions = (cardCode) => {
    const params = new URLSearchParams();
    params.append('card', cardCode);
    history.push({
      pathname: '/transactions/payments',
      search: params.toString(),
    });
  };

  const handleOtherPopover = (val) => {
    setIsOtherPopoverOpen(val);
  };

  const onHandlePinChange = (event) => {
    const { name, value } = event.target;
    if (value.length > 4) {
      return false;
    }
    setNewPin({ ...newPin, [name]: value });
  };

  const onHandlePinSubmit = () => {
    const { confirm_pin, pin, oldPin } = newPin;
    if (!pin || !confirm_pin) return toastError('Please all complete pin');
    if (pin !== confirm_pin) return toastError("You pin doesn't match");
    const payload = {
      code: data?.card?.code,
      oldPin,
      newPin: pin,
    };
    dispatch(setNewPinAction(payload));
  };

  useEffect(() => {
    if (!isSettingPin && isPinSuccess) {
      setNewPin({ ...newPin, visible: false });
      dispatch(getSingleCard({ id: selectCards.code }));
    }
  }, [isSettingPin, isPinSuccess]);

  const interval = useRef(null);
  useEffect(() => {
    interval.current = setInterval(() => {
      if (data?.card?.amount && actionType === 'liquidate') {
        if (data?.card?.currency === 'USD') {
          dispatch(
            currencyConversionRate({
              baseCurrency: data?.card?.currency?.toLowerCase(),
              targetCurrency: 'ngn',
              amount: data?.card?.amount / 100,
            }),
          );
        }
      }
    }, 120000);

    return () => {
      clearInterval(interval.current);
    };
  }, [startInterval, actionType, error]);

  useEffect(() => {
    if (data?.card?.amount && actionType === 'liquidate' && !startInterval) {
      if (data?.card?.currency === 'USD') {
        dispatch(
          currencyConversionRate({
            baseCurrency: data?.card?.currency.toLowerCase(),
            targetCurrency: 'ngn',
            amount: data?.card?.amount / 100,
          }),
        );
      }
      setStartInterval(!startInterval);
    }
  }, [error, actionType]);

  const goBack = () => {
    setNewPin({ ...newPin, visible: false });
    setIsBack(true);
  };

  const handleGetPin = () => {
    dispatch(getDefaultPin(selectCards.code));
    setGetPin(true);
  };

  const closeDefaultPin = () => {
    setSelectCards(null);
    if (toggleHandler) toggleHandler();
  };

  const handleBlockDialog = () => {
    setIsBlock(true);
    setIsPopoverOpen(false);
  };

  const actionButtons = [
    {
      text: isVisible ? 'Conceal' : 'Reveal',
      icon: isVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />,
      action: () => {
        setIsVisible(!isVisible);
      },
    },

    {
      text: 'Top up',
      icon: <CreditCardPlus stroke="#57534E" />,
      action: () => {
        handleIsUpdate();
      },
    },

    {
      text: isCardLock ? 'Unblock' : 'Block',
      icon: <CreditCardLock stroke="#57534E" />,
      action: () => {
        isCardLock ? handleCardLock() : handleBlockDialog();
      },
    },
  ];

  const {
    getBeneficiaries: { loading: loadingBeneficiaries, data: beneficiariesData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { beneficiaries = [] } = beneficiariesData;

  const beneficiaryList = beneficiaries.map(({ user, code }) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: code,
  }));
  useEffect(() => {
    const foundBeneficiaries = beneficiaryList?.find((option) =>
      option?.label?.toLowerCase().includes(debouncedValue?.toLowerCase()),
    );
    if (!foundBeneficiaries && debouncedValue && canViewBeneficiary) {
      dispatch(getBeneficiaries({ search: debouncedValue?.toLowerCase() }));
    }
  }, [debouncedValue]);

  const onMenuCloseBeneficiary = () => {
    if (debouncedValue && canViewBeneficiary) dispatch(getBeneficiaries());
  };

  const handleSelectChange = (val) => setSearch(val);

  const handleChange = ({ name, value }) => {
    setHolder({
      [name]: value,
    });
  };

  const Actions = () => {
    return (
      <div className="actions-dialog z-3">
        <div
          className="actionLink"
          onClick={() => {
            setEditCardSetting(true);
            handleTogglePopover();
          }}
        >
          <SettingOutlined /> Edit card settings
        </div>
        {data?.card?.amount > 0 && (
          <div
            className="actionLink"
            onClick={() => {
              handleCardLoadUnloadModal('liquidate');
              handleTogglePopover();
            }}
          >
            <StopOutlined /> Liquidate card
          </div>
        )}

        {data?.card?.type === 'physical' && (
          <div
            className="actionLink"
            onClick={() => {
              handleGetPin();
              handleTogglePopover();
            }}
          >
            <PushpinOutlined /> Get default pin
          </div>
        )}

        {isAdmin && (
          <div
            className="actionLink"
            onClick={() => {
              handleCardLoadUnloadModal('reassign');
              handleTogglePopover();
            }}
          >
            <UserIcon02 width="16" height="16" stroke="#57534E" className="me-1" />{' '}
            Reassign card
          </div>
        )}
        <div
          className="actionLink svg-danger text-danger"
          onClick={() => {
            handleCardLoadUnloadModal('delete');
            handleTogglePopover();
          }}
        >
          <TrashIcon height="16" width="16" className="ms-1" /> Delete card
        </div>
      </div>
    );
  };
  const columnSize = data.brand === 'verve' ? 4 : 6;

  const SkeletalLoader = () => (
    <div>
      <Skeleton.Input
        active
        size={24}
        className="pb-3 mt-3"
        style={{ borderRadius: '4px' }}
      />
      <div className="card-holder w-100" style={{ height: '330px' }}>
        <Skeleton.Button
          active
          size={218}
          className="w-100"
          style={{ borderRadius: '8px' }}
        />
        <div className="budget-region mt-3">
          <span className="text">
            <Skeleton.Input active style={{ height: '12px', borderRadius: '4px' }} />
          </span>

          <Skeleton.Input active style={{ height: '12px', borderRadius: '4px' }} />
        </div>
        <Skeleton.Input
          active
          style={{ height: '12px', borderRadius: '100px' }}
          className="w-100 pt-1"
        />
        <div className="budget-region">
          <span className="text">
            <Skeleton.Input active style={{ height: '12px', borderRadius: '4px' }} />
          </span>

          <Skeleton.Input active style={{ height: '12px', borderRadius: '4px' }} />
        </div>
        <div className="mt-4 pt-2">
          {Array.from({ length: 4 }).map((_, index) => (
            <div className="pan-details" key={index}>
              <span className="caption">
                <Skeleton.Input
                  active
                  size="small"
                  style={{ height: '12px', borderRadius: '4px' }}
                />
              </span>
              <span className="value">
                <Skeleton.Input
                  active
                  size="small"
                  style={{ height: '12px', borderRadius: '4px' }}
                  className="w-25"
                />
              </span>
            </div>
          ))}
          <div className="action-buttons  gap-2 mt-4 pt-1">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton.Button
                key={index}
                active
                size={32}
                style={{ borderRadius: '8px', width: '144px' }}
              />
            ))}
          </div>
        </div>

        <div className="mt-4 transactions-holder">
          {Array.from({ length: 3 }).map((_, index) => (
            <div className="transaction" key={index}>
              <div className="description">
                <div
                  style={{
                    height: '40px',
                    width: '40px',
                    background: '#E8E8E8',
                    borderRadius: '100%',
                  }}
                ></div>

                <div style={{ width: '50%' }}>
                  <Skeleton.Input
                    active
                    style={{ height: '12px', borderRadius: '4px' }}
                  />
                  <Skeleton.Input
                    active
                    style={{ height: '12px', borderRadius: '4px' }}
                  />
                </div>
              </div>

              <div style={{ width: '35%' }}>
                <Skeleton.Input active style={{ height: '12px', borderRadius: '4px' }} />
                <Skeleton.Input active style={{ height: '12px', borderRadius: '4px' }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const SelectedCardDetail = (
    <div className="content px-md-0 py-md-0 px-3 py-4">
      {loading ? (
        <SkeletalLoader />
      ) : (
        Object.keys(data).length > 0 && (
          <>
            <div className="card-modal-header">
              <div className="d-flex align-items-center cursor" onClick={closeHandler}>
                <CloseOutlined style={{ color: '#57534E' }} />
                <span className="ps-2 close-text">Close</span>
              </div>
            </div>

            <div className="card-modal-body">
              <div
                className={classNames('information-wrapper', {
                  ['disabled-owners-input']: data?.card?.requiresPinChange,
                })}
                style={{ opacity: data?.card?.requiresPinChange ? '40%' : '100%' }}
              >
                <div className="name-holder w-100">
                  <h2 className="pt-1">{data?.card?.name}</h2>

                  {data?.card?.status !== 'inactive' && (
                    <div>
                      <CustomPopover
                        content={<Actions />}
                        showPopover={isPopoverOpen}
                        clickOutside={handleTogglePopover}
                      >
                        <div onClick={handleTogglePopover} className="cursor">
                          <DotsVertical />
                        </div>
                      </CustomPopover>
                    </div>
                  )}
                </div>

                <div className="card-region-holder">
                  <div className="card-holder">
                    <CardView
                      viewType="view"
                      card={data.card}
                      cardType={data?.card?.type}
                      requirePin={data?.card?.requiresPinChange}
                    />

                    <div className="budget-region mt-3">
                      <span className="text">Available to spend</span>
                      <span style={{ color: '#D28B28' }}>
                        <CurrencyFormat
                          prefix={getCurrency(data?.card?.currency)}
                          value={numFormatter(
                            (data?.card?.amount - data?.card?.spent) / 100,
                          )}
                          displayType="text"
                          thousandSeparator={true}
                          isNumericString
                        />
                      </span>
                    </div>

                    <Progress
                      percent={(data?.card?.spent / data?.card?.amount) * 100}
                      showInfo={false}
                      strokeColor="#D28B28"
                      trailColor="#E7E5E4"
                    />

                    <div className="budget-region">
                      <span className="text">Active budget</span>
                      <a
                        href={
                          data?.card?.budget?.status !== 'deleted' &&
                          `/expenses/budgets/${data?.card?.budget?.code}/overview`
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="d-flex align-items-center gap-1"
                        style={{ color: '#1C1917', cursor: 'pointer' }}
                      >
                        {data?.card?.budget?.name} <ArrowTop />
                      </a>
                    </div>
                  </div>

                  <div className="mt-4">
                    {/* Number Here */}
                    <div className="pan-details">
                      <span className="caption">Card number</span>
                      <span
                        className={`value ${!isVisible && 'd-none'}`}
                        ref={cardNumberRef}
                      ></span>

                      {!isVisible && (
                        <span className="value">{`•••• ${
                          data?.card?.last_4 ?? ''
                        }`}</span>
                      )}
                    </div>

                    {/* Expiry Date Here */}
                    <div className="pan-details">
                      <span className="caption">Expiry date</span>
                      <span className="value">
                        {data.card?.exp_month ?? '••'}/{data.card?.exp_year ?? '••'}
                      </span>
                    </div>

                    {/* CVC Here */}
                    <div className="pan-details">
                      <span className="caption">CVV</span>

                      <span
                        className={`value ${!isVisible && 'd-none'}`}
                        ref={cvvNumberRef}
                      ></span>

                      {!isVisible && <span className="value">••• </span>}
                    </div>

                    {/* Pin Here */}
                    <div className="pan-details">
                      <span className="caption">PIN</span>

                      <span
                        className={`value ${!isVisible && 'd-none'}`}
                        ref={pinRef}
                      ></span>

                      {!isVisible && <span className="value">•••• </span>}
                    </div>

                    <div className="action-buttons mt-4 pt-1">
                      {actionButtons.map(({ icon, text, action }) => (
                        <button
                          key={text}
                          className="details-btn tab-hover-effect"
                          onClick={action}
                        >
                          {icon}
                          {text}
                        </button>
                      ))}
                    </div>
                  </div>

                  {/* Recent Transactions */}

                  <div className="mt-4 transactions-holder">
                    <span className="caption">Recent transactions</span>

                    {data?.card?.transactions?.length ? (
                      <>
                        {data?.card?.transactions?.map((transaction) => (
                          <div className="transaction" key={transaction.code}>
                            <div className="description">
                              <div className="icon">
                                <ImgCard
                                  initials={transaction?.payer?.firstName?.charAt(0)}
                                />
                              </div>
                              <div>
                                <span className="text"> {transaction?.narration} </span>
                                <span className="sub-text">
                                  {truncateText(transaction?.description, 0, 30)}
                                </span>
                              </div>
                            </div>

                            <div style={{ width: '40%' }}>
                              <span className="text text-end">
                                <CurrencyFormat
                                  prefix={getCurrency(data?.card?.currency)}
                                  value={numFormatter(transaction?.amount / 100)}
                                  displayType="text"
                                  thousandSeparator={true}
                                  isNumericString
                                />
                              </span>
                              <span className="sub-text text-end">
                                {getFormattedDate(transaction?.createdAt)}
                              </span>
                            </div>
                          </div>
                        ))}

                        <div className="mt-auto pb-4">
                          <button
                            className="details-btn large tab-hover-effect w-100"
                            onClick={() => viewCardTransactions(data?.card?.code)}
                          >
                            <ClockRewind />
                            See all transactions
                          </button>
                        </div>
                      </>
                    ) : (
                      <p className="no-record">
                        {`You haven't made a payment with this card yet`}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {data?.card?.requiresPinChange && (
              <footer className="card-footer selectedCard">
                <div className="footer__action">
                  <button className="vgsButton" ref={copyPinRef}></button>
                </div>
              </footer>
            )}
          </>
        )
      )}
    </div>
  );

  const SetNewPin = (
    <div className="content">
      <div className="card-modal-header">
        <div className="d-flex align-items-center cursor" onClick={goBack}>
          <ArrowLeftOutlined />

          <span className="ps-1">Back</span>
        </div>
      </div>
      <div className="card-modal-body">
        <div className="information-wrapper">
          <h2 className="card-title w-100 mb-3">Update card PIN</h2>
        </div>
        <Row>
          <Col xs={12}>
            <CustomInput
              label={'Old PIN'}
              placeholder="Enter new PIN"
              type="number"
              name="pin"
              onChange={onHandlePinChange}
              value={newPin?.oldPin}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <CustomInput
              label={'New PIN'}
              placeholder="Enter new PIN"
              type="number"
              name="pin"
              onChange={onHandlePinChange}
              value={newPin?.pin}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <CustomInput
              label={'Confirm new PIN'}
              placeholder="Confirm new PIN"
              type="number"
              name="confirm_pin"
              onChange={onHandlePinChange}
              value={newPin?.confirm_pin}
            />
          </Col>
        </Row>
      </div>
      <footer className="d-flex card-footer pin-footer">
        <div className="footer__action">
          <CustomButton className="cancel button" onClick={goBack}>
            Cancel
          </CustomButton>
          <CustomButton className="continue button" onClick={onHandlePinSubmit}>
            {isSettingPin ? <Loading size={20} color="#d28b28" /> : 'Continue'}
          </CustomButton>
        </div>
      </footer>
    </div>
  );

  return (
    <>
      <Modal show={selectCards} onClose={closeHandler}>
        {getPin ? (
          <ViewDefualtPin dataActivate={dataPin} onDone={closeDefaultPin} />
        ) : isUpdate ? (
          <TopUpCard
            IsOtherPopoverOpen={handleOtherPopover}
            cardDetails={data}
            handleIsUpdate={handleIsUpdate}
            closeHandler={closeHandler}
          />
        ) : editCardSetting ? (
          <EditCardSettings cardDetails={data} closeHandler={handleCloseSetting} />
        ) : newPin?.visible ? (
          SetNewPin
        ) : (
          SelectedCardDetail
        )}
      </Modal>

      <BootstrapModal
        show={isDelete}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(actionType)} ${data?.card?.name}`}
          subTitle={
            <>
              {actionType !== 'reassign' && (
                <>
                  Are you sure you want to {actionType} this card? <br />
                  This action cannot be undone.{' '}
                  {data?.card?.currency === 'USD' && actionType === 'liquidate' && (
                    <>
                      <br /> <br /> All the funds allocated to this card will be returned
                      to you based on this exchange rate.
                      <br />
                      <ExchangeRate
                        exchangeRate={currencyExchange.rate ? currencyExchange.rate : 0}
                        amount={data?.card?.amount / 100}
                        loading={loadingConversion}
                      />
                    </>
                  )}
                </>
              )}

              {actionType === 'reassign' && (
                <>
                  <CustomSelect
                    label="Who are you reassigning this card to?"
                    placeholder="New card holder"
                    name="holder"
                    options={beneficiaryList}
                    onChange={(value) => handleChange({ name: 'beneficiary', value })}
                    value={holder.value}
                    onInputChange={(value) => handleSelectChange(value, 'holder')}
                    isDisabled={false}
                    isLoading={loadingBeneficiaries}
                    onMenuClose={onMenuCloseBeneficiary}
                  />
                </>
              )}
            </>
          }
          onConfirm={handleCardLoadUnload}
          loading={
            actionType === 'delete' ? isDeleteLoading : liquidateLoading || reassigning
          }
          onCancel={() => handleCardLoadUnloadModal()}
          isDeleteDialog={actionType === 'delete'}
        />
      </BootstrapModal>

      <BootstrapModal
        show={isBlock}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title="Block card"
          subTitle="Are you sure you want to block this card?"
          dialogIcon={<CreditCardLock stroke="#D92D20" />}
          onConfirm={() => handleCardLock()}
          onCancel={() => setIsBlock(false)}
          isDeleteDialog
          actionBtnText="Block"
          loading={isTogglingBlockState}
        />
      </BootstrapModal>
    </>
  );
};
export default CardsTableModal;

import { EditPencilIcon } from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import Table from 'components/Table';
import CustomButton from 'components/UI/CustomButton';
import RoleParagraphs from 'components/UI/CustomRoleSelect/RoleParagraphTruncateor';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  buildBulkBeneficiariesPayload,
  bulkCreateBeneficiaries,
  filterBeneficiaries,
} from 'redux/actions/BeneficiariesAction';
import { getRoles } from 'redux/actions/RolesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import {
  buildTemporaryBeneficiariesTableData,
  capitalizeFirstLetter,
} from 'utils/helper';
import { temporaryColumnsBeneficiaries } from 'utils/mockData';
import { read, utils } from 'xlsx';
import { TrashIcon } from '../../assets/icons';
import { ReactComponent as FileIcon } from '../../assets/icons/File.svg';
import { ReactComponent as UploadDialogIcon } from '../../assets/icons/upload-cloud.svg';
import '../BatchPayment/styles.scss';
import BulkMembersModal from './BulkMembersModal';

const BulkAction = ({ rows, unSetRows, selectAllRow }) => {
  const history = useHistory();
  const [confirmModal, setConfirmModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [checkall, setCheckAll] = useState(true);
  const dispatch = useDispatch();

  const {
    bulkCreateBeneficiaries: { loading: isLoading, success: isSuccess },
    bulkBeneficiariesPayload,
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      unSetRows();
      dispatch(buildBulkBeneficiariesPayload({ ids: [], cancel: false, payload: [] }));
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setConfirmModal(false);
      dispatch(buildBulkBeneficiariesPayload({ cancel: false, payload: [] }));
      dispatch(filterBeneficiaries(true));
      history.push('/teams/people');
    }
  }, [isSuccess]);

  useEffect(() => {
    setCheckAll(true);
  }, [bulkBeneficiariesPayload.data]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (rows.length === 0 && checkall) {
      timeoutRef.current = setTimeout(() => {
        selectAllRow();
        setCheckAll(false);
      }, 300);
    }
  }, [rows]);

  useEffect(() => {
    if (!bulkBeneficiariesPayload.cancel) {
      setCancelModal(false);
    }
  }, [bulkBeneficiariesPayload.cancel]);

  const handleConfirm = () => {
    const payload = rows.map(({ beneficiaryData: option }) => ({
      ...option,
      manager: option.manager?.code,
      role: option.role?.code,
      team: option.team?.code,
      id: undefined,
    }));
    dispatch(bulkCreateBeneficiaries(payload));
  };

  const handleCancel = () => {
    const ids = rows.map(({ beneficiaryData: option }) => option.id);
    dispatch(buildBulkBeneficiariesPayload({ ids, cancel: true }));
    setCancelModal(false);
  };

  return (
    <>
      {!!rows.length && (
        <div className="batch-footer position-sticky bottom-0 right-0 bg-black border-top h-auto w-100 bg-white px-2 py-3 d-flex justify-content-between align-items-center">
          <div>
            Total selected {rows.length}
            {rows.length > 1 ? ' members' : ' member'}
          </div>
          <div className="d-flex gap-2">
            <CustomButton
              className="base-button dark-button font-medium text-sm"
              onClick={() => setConfirmModal(true)}
            >
              Save
            </CustomButton>
          </div>
        </div>
      )}
      <Modal
        show={confirmModal}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`Please confirm your upload`}
          subTitle={`You are about to upload ${rows.length} ${
            rows.length > 1 ? 'members' : 'member'
          }.`}
          onConfirm={handleConfirm}
          onCancel={() => setConfirmModal(false)}
          isDeleteDialog={false}
          dialogIcon={<UploadDialogIcon />}
          actionBtnText="Confirm"
          loading={isLoading}
        />
      </Modal>
      <Modal
        show={cancelModal}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter('cancel')}`}
          subTitle={`Are you sure you want to cancel ${rows.length} ${
            rows.length > 1 ? 'members' : 'member'
          } creation?`}
          onConfirm={handleCancel}
          onCancel={() => {
            setCancelModal(false);
          }}
          isDeleteDialog={true}
          actionBtnText="Confirm"
        />
      </Modal>
    </>
  );
};

const MemberCreationUpload = () => {
  const dispatch = useDispatch();
  const initial = {
    id: 1,
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    team: '',
    manager: '',
  };
  const initialMeta = {
    perPage: 30,
    totalPage: 0,
    currentPage: 1,
    startIndex: 0,
    endIndex: 30,
  };
  const [meta, setMeta] = useState(initialMeta);
  const [singleMember, setSingleMember] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isFile, setSetFile] = useState({});
  const [fileData, setFileData] = useState(null);
  const [externalUpload, setExternalUpload] = useState(false);
  const [removeFile, setRemoveFile] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);
  const { perPage } = meta;
  const [jsonFile, setJsonFile] = useState([]);
  const { permissions } = allPermissions();
  const canEdit = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-edit', 'employee-create'],
  });

  const tableColumn = temporaryColumnsBeneficiaries.filter((item) =>
    canEdit ? item : item.Header !== 'Actions',
  );

  const {
    getRoles: { data: rolesData = [], loading: isFetchingRoles },
  } = useSelector(({ roles }) => roles);

  const {
    bulkBeneficiariesPayload: { data: members, cancel, ids },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const readUploadFile = (files, fileName) => {
    if (files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);

        setJsonFile(json);

        setFileData({
          fileName: files?.name,
          fileSize: files?.size ? Math.round(files.size / 1024) : null,
        });
      };
      reader.readAsArrayBuffer(files);
    } else {
      dispatch(
        buildBulkBeneficiariesPayload({
          payload: [],
        }),
      );
    }

    setSetFile(!!fileName);
  };

  useEffect(() => {
    if (!isFetchingRoles && !rolesData?.length) dispatch(getRoles());
    else rolesOptionMapper(rolesData);
  }, [rolesData, isFetchingRoles]);

  useEffect(() => {
    if (!!roleOptions.length) {
      const useRole = roleOptions.filter(
        (option) => option.label.toLowerCase() === 'employee',
      );

      const fullDetails = jsonFile.map((object, index) => {
        return {
          ...initial,
          id: index + 1,
          role: useRole?.[0] ?? '',
          firstName: object['Firstname'],
          lastName: object['Lastname'],
          email: object['email'],
        };
      });

      dispatch(
        buildBulkBeneficiariesPayload({
          payload: fullDetails,
        }),
      );
    }
  }, [jsonFile, roleOptions]);

  useEffect(() => {
    if (members.length) {
      const totalPage = Math.ceil((members.length + 1) / perPage);
      setMeta((prevValue) => ({ ...prevValue, totalPage }));
      setTableData(
        buildTemporaryBeneficiariesTableData(
          members.slice(meta.startIndex, meta.endIndex),
        ),
      );
    }
  }, [members, meta.startIndex, meta.endIndex]);

  useEffect(() => {
    if (cancel) {
      const filteredMemebers = members.filter((option) => !ids.includes(option.id));
      dispatch(
        buildBulkBeneficiariesPayload({
          ids: [],
          cancel: false,
          payload: filteredMemebers,
        }),
      );
    }
  }, [cancel]);

  const rolesOptionMapper = (data) => {
    return setRoleOptions(
      data?.map((datum) => {
        return {
          label: datum?.name,
          value: (
            <>
              <p>{datum?.description ?? <RoleParagraphs datum={datum} />}</p>
            </>
          ),
          code: datum?.code,
        };
      }),
    );
  };

  const handleNextPage = () => {
    setMeta((prevValue) => ({
      ...prevValue,
      currentPage: prevValue.currentPage + 1,
      startIndex: prevValue.endIndex,
      endIndex: prevValue.endIndex + perPage,
    }));
  };

  const handlePreviousPage = () => {
    setMeta((prevValue) => ({
      ...prevValue,
      currentPage: prevValue.currentPage - 1,
      startIndex: prevValue.startIndex - perPage,
      endIndex: prevValue.endIndex - perPage,
    }));
  };

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setActionType(type);
    if (type?.toLowerCase() === 'edit') {
      setSingleMember(value?.beneficiaryData);
    } else if (type?.toLowerCase() === 'cancel') {
      const filteredMemebers = members.filter(
        (option) => option.id !== value?.beneficiaryData.id,
      );
      dispatch(buildBulkBeneficiariesPayload({ payload: filteredMemebers }));
    }
  };

  const Actions = ({ list }) => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'edit', list)}
        >
          <EditPencilIcon /> Edit
        </div>
        <div
          className="actionLink svg-danger text-danger action-link-svg"
          onClick={(event) => actionHandler(event, 'cancel', list)}
        >
          <TrashIcon stroke="#79716B" width="16" height="16" className="mr-4" /> Delete
        </div>
      </div>
    );
  };

  const UploadedFileComponent = ({ isFileUploading, uploadedFileName, handleRemove }) => {
    return (
      <div className="py-3 px-2 rounded d-flex justify-content-between uploaded-file-container">
        {isFileUploading ? (
          <div className="d-flex">
            <Loading color="#D28B28" size={20} />
            <div className="ms-3 placeholder-loading">
              <div className="mb-2"></div>
              <div className="mb-4"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex">
              <FileIcon />
              <div className="ms-3">
                <p className="mb-1 uploaded-file-container-name">{uploadedFileName}</p>
                {fileData?.fileSize && (
                  <p className="uploaded-file-container-details">
                    {fileData.fileSize} KB – 100% uploaded
                  </p>
                )}
              </div>
            </div>
            <div onClick={() => handleRemove()}>
              <TrashIcon />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="position-relative">
      <Container className="pt-2 pb-5 batch_payment-container">
        <div className="mt-2">
          <Row className="w-100 flex align-items-center">
            <Col xs={12} lg={8}>
              <FileUpload
                supportType=".CSV or .XLSX file"
                cloud={false}
                acceptedFile={{ 'text/html': ['.xlsx', '.csv'] }}
                onChange={({ file, fileName }) => {
                  readUploadFile(file, fileName);
                }}
                externalUpload={externalUpload}
                removeFile={removeFile}
                setRemoveFile={setRemoveFile}
                uploadedFileComponent={UploadedFileComponent}
              />
            </Col>
          </Row>
        </div>
      </Container>
      {!!members.length && (
        <Container className="px-0">
          <h5>Member review</h5>
          <Row className="pt-2 pb-4">
            <Col xs={12} className="mb-4">
              <Table
                columns={tableColumn}
                data={tableData}
                pagination
                currentPage={meta.currentPage}
                nextPage={() => handleNextPage()}
                previousPage={() => handlePreviousPage()}
                totalPage={meta.totalPage}
                popoverAction={Actions}
                popoverState={isPopoverOpen}
                setPopoverState={setIsPopoverOpen}
                getSelectedRows={BulkAction}
              />
            </Col>
          </Row>
        </Container>
      )}

      {singleMember && (
        <BulkMembersModal
          selectBeneficiaries={singleMember}
          setSelectBeneficiaries={setSingleMember}
        />
      )}
    </div>
  );
};

export default MemberCreationUpload;

import { Skeleton, Card, Row as AntRow, Col, Divider, Button } from 'antd';

export const PaymentSkeleton = () => {
  return (
    <div style={{ width: 600, margin: '0 auto', padding: 16 }}>
      {/* Header */}

      <AntRow gutter={16} className="d-flex align-items-center">
        <Col span={12}>
          <Skeleton
            paragraph={{ rows: 0, style: { borderRadius: '12px' } }}
            title={{ width: '40%', style: { height: '25px', borderRadius: '12px' } }}
            active
          />
          <Skeleton
            paragraph={{ rows: 0 }}
            title={{ width: '100%', style: { height: '35px' } }}
            active
          />
        </Col>
        <Col span={12} className="me-auto d-flex" style={{ justifyContent: 'flex-end' }}>
          <Skeleton.Image
            active
            style={{ width: '60px', height: '60px', marginRight: '10px' }} // Adjusted marginRight value
          />
        </Col>
      </AntRow>

      <Skeleton paragraph={{ rows: 0 }} title={{ width: '30%' }} active />

      {/* Overview Section */}
      <AntRow gutter={2} className="d-flex align-items-center border-bottom p-0 m-0 ">
        <Col span={4}>
          <Skeleton.Button size="small" block active />
        </Col>
        <Col span={4}>
          <Skeleton.Button size="small" block active />
        </Col>
        <Col span={4}>
          <Skeleton.Button size="small" block active />
        </Col>
      </AntRow>

      {/* Payment Details */}

      {Array(2)
        .fill(0)
        .map((_, index) => (
          <div className="mb-4" key={index}>
            <Skeleton
              className="mt-4"
              paragraph={{ rows: 0 }}
              title={{ width: '20%' }}
              active
            />

            <div className="rounded-3 p-3 border pb-0">
              <AntRow gutter={10} className="d-flex align-items-center">
                <Col span={6}>
                  <Skeleton
                    paragraph={{ rows: 4 }}
                    title={{ width: '90%', className: 'p-0 m-0' }}
                    active
                  />
                </Col>
                <Col span={12}>
                  <Skeleton
                    paragraph={{ rows: 4 }}
                    title={{ width: '90%', className: 'p-0 m-0' }}
                    active
                  />
                </Col>
              </AntRow>
            </div>
          </div>
        ))}
    </div>
  );
};

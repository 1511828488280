import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  GET_APIKEY_REQUEST,
  GET_APIKEY_SUCCESS,
  GET_APIKEY_ERROR,
  DELETE_APIKEY_REQUEST,
  DELETE_APIKEY_SUCCESS,
  DELETE_APIKEY_ERROR,
} from '../reducers/ApiKeyReducer';
import { toastError, toastSuccess } from 'components/UI/toast';

async function getApiKey(params = {}) {
  return await Axios.get('/api-keys');
}
function* handleGetApiKey({ payload }) {
  try {
    const response = yield call(getApiKey, payload);
    if (response) {
      yield put({
        type: GET_APIKEY_SUCCESS,
        data: {
          ...response.data,
          meta: response.meta,
        },
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_APIKEY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteApiKey(params = {}) {
  return await Axios.delete('/api-keys/' + params.code);
}
function* handleDeleteApiKey({ payload }) {
  try {
    const response = yield call(deleteApiKey, payload);
    if (response) {
      yield put({
        type: DELETE_APIKEY_SUCCESS,
        data: response.data,
      });
      toastSuccess(response?.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_APIKEY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_APIKEY_REQUEST, handleGetApiKey),
  takeLatest(DELETE_APIKEY_REQUEST, handleDeleteApiKey),
]);

import {
  ArrowLeftOutlined,
  BankOutlined,
  GroupOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import { ArrowRight, BankIcon, ChevronRight } from 'assets/icons';
import classNames from 'classnames';
import RequestFunds from 'components/FundRequest/RequesterModal/RequestFunds';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import { SendFundsButton } from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import BadgeType from 'components/UI/Table/BadgeType';
import { toastSuccess } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  addFundsToAccount,
  getAccountReauthorizationCode,
  getPaymentLink,
  verifyPaymentReference,
} from 'redux/actions/CompaniesAction';
import { toggleAction } from 'redux/actions/ToggleAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getCurrency } from 'utils/helper';
import { getBudgets, getSingleBudgets } from '../../../redux/actions/BudgetsAction';
import AccountDetailsModal from '../Overview/AccountDetailsModal';
import { ICONS } from '../Overview/components/AccountCard';
import SwapModal from '../Overview/components/SwapModal';
import FundsModal from '../Overview/FundsModal';
import StatementModal from '../Overview/StatementModal';
import './styles.scss';

const SelectedSubAccountHeader = ({
  data,
  handleSwapping,
  openSwapModal,
  canViewDash,
  permissionForOwnersAndManagers,
  statementModal,
  setStatementModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { permissions, isAdmin } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-create', 'budget-edit'],
  });

  // get Subaccount informations and display
  const {
    deactivateBudget: { success: deactivateSuccess },
    activateBudget: { success: activateSuccess },
  } = useSelector(({ budgets }) => budgets);

  const {
    verifyPaymentReference: { data: verifyPaymentReferenceData },
    addFundsToAccount: { loading: isAddingFunds },
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const [company, setCompany] = useState({});

  useEffect(() => {
    if (companyData && companyData.code) setCompany(companyData);
  }, [companyData]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [accountDetailsModal, setAccountDetailsModal] = useState(false);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState(null);
  const [isFundingToggle, setIsFundingToggle] = useState(false);

  const [toggleBalance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fundStep, setFundStep] = useState(1);
  const [insideModalClick, setInsideModalClick] = useState(false);
  const [paymentVerified, setPaymentVerified] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [requestFunds, setRequestFunds] = useState(false);

  useEffect(() => {
    if (deactivateSuccess || activateSuccess) {
      dispatch(getBudgets());
      dispatch(getSingleBudgets({ id: data.code }));
    }
  }, [activateSuccess, deactivateSuccess, data]);

  useEffect(() => {
    if (deactivateSuccess && deleteModal) {
      toastSuccess('Budget deleted successfully');
      setTimeout(() => {
        history.push('/expenses');
        deleteToggleHandler();
      }, 1000);
    }
  }, [deactivateSuccess]);

  const addFunds = (payload) => {
    dispatch(addFundsToAccount(payload));
  };

  const verifyPayment = (reference) => {
    dispatch(verifyPaymentReference({ code: company.code, reference }));
  };

  const initiatePayment = (payload) => {
    dispatch(getPaymentLink({ code: company.code, payload }));
  };

  const getReauthorizationCode = (authorizationCode) => {
    dispatch(getAccountReauthorizationCode({ code: company.code, authorizationCode }));
  };

  useEffect(() => {
    if (verifyPaymentReferenceData) {
      if (verifyPaymentReferenceData?.status === 'success') {
        setPaymentFailed(false);
        setPaymentVerified(true);
      } else {
        setPaymentVerified(false);
        setPaymentFailed(true);
      }
    }
  }, [verifyPaymentReferenceData]);

  useEffect(() => {
    if (location?.state?.fund && canManage) {
      handleSwapping();
    }
  }, [location?.state?.fund]);

  const deleteToggleHandler = () => setDeleteModal(!deleteModal);

  const handlePayment = () => {
    const { name, balance, number, currency, bankName, accountName, code, subaccount } =
      data;
    dispatch(
      toggleAction({
        selectedAccount: {
          name,
          balance,
          number,
          currency,
          bankName,
          accountName,
          code,
          isSubaccount: subaccount,
        },
        queryParams: { source: data?.code },
      }),
    );
  };

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const [isPaymentButtonToggle, setIsPaymentButtonToggle] = useState(false);

  const handlePaymentButtonToggle = () =>
    setIsPaymentButtonToggle(!isPaymentButtonToggle);

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const PaymentActions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            handlePayment();
            handlePaymentButtonToggle();
          }}
        >
          <BankOutlined /> Single payment
        </div>
        <div
          className="actionLink"
          onClick={() => {
            history.push({
              pathname: '/transactions/batch-payment',
              state: { value: data.code, label: data.name, amount: data.amount / 100 },
            });
          }}
        >
          <GroupOutlined /> Batch payments
        </div>
        <div
          className="actionLink"
          onClick={() => {
            handlePayment();
            handlePaymentButtonToggle();
            isSchedule();
          }}
        >
          <ScheduleOutlined /> Schedule payment
        </div>
      </div>
    );
  };

  const goBackToMainAccounts = () => {
    history.goBack();
  };

  const closeDetailsModal = () => {
    setAccountDetailsModal(false);
    setSelectedAccountDetails(null);
  };

  const openModal = (modal) => {
    if (modal === 'request') setRequestFunds(true);
    else setStatementModal(true);
  };

  const openDetailsModal = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedAccountDetails(data);
    setAccountDetailsModal(true);
  };

  const handleFundingToggle = () => {
    setIsFundingToggle(!isFundingToggle);
  };

  const closeHandler = () => {
    if (!insideModalClick) setIsModalOpen(false);
    setFundStep(1);
    setPaymentFailed(false);
    setPaymentVerified(false);
  };

  return (
    <div className="headerBudgets">
      <div className="w-100 pt-5">
        <div
          className="back-click back-button gap-2 d-flex font-bold"
          onClick={goBackToMainAccounts}
          style={{ lineHeight: '20px', cursor: 'pointer', color: '#D28B28' }}
        >
          <ArrowLeftOutlined style={{ fontSize: 16, margin: 'auto 0px' }} />
          <span className="text-sm">Back</span>
        </div>

        <TopBar
          colClass="p-0"
          isRightBar
          breadcrumbs={[
            { title: 'Accounts', action: () => history.push('/accounts') },
            {
              title: canViewDash ? data?.parentBalance?.name : null,
              action: () => history.push(`/accounts/${data?.parentBalance?.code}`),
            },
            {
              title: data?.name,
              action: () => {
                null;
              },
            },
          ].filter((item) => item.title)}
        />

        <div
          style={{ paddingTop: '10px' }}
          className="d-md-flex align-items-start w-100 justify-content-between"
        >
          <div className="my-2 my-lg-0">
            <div>
              <p style={{ marginBottom: 0, color: '#79716B', fontWeight: 500 }}>
                {data?.name}
              </p>
              <div className="d-flex">
                <div className="d-flex me-2 gap-2 align-items-center">
                  {ICONS['NGN']}
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={data?.balance / 100}
                    displayType="text"
                    isNumericString
                    thousandSeparator={true}
                    style={{
                      fontSize: 25,
                      color: '#1C1917',
                      fontWeight: 600,
                      fontStyle: 'normal',
                    }}
                  />
                </div>
                <BadgeType
                  value={{
                    // value: '6824238836',
                    color: '#44403C',
                    custom: (
                      <span
                        onClick={openDetailsModal}
                        className="d-flex align-items-center cursor"
                      >
                        <BankIcon className="me-1" width="16" height="16" />
                        {data?.number}
                        <ChevronRight className="ms-2" width="16" height="16" />
                      </span>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="overview-action gap-3 p-0">
            {(data?.loggedInMember?.designation?.toLowerCase() === 'owner' ||
              isAdmin) && (
              <div
                className={classNames('add-custom align-items-center cursor', {})}
                onClick={handleSwapping}
              >
                <span className="me-2 ">Add funds</span>
              </div>
            )}

            {data?.loggedInMember?.designation?.toLowerCase() === 'manager' && (
              <div
                className={classNames('add-custom align-items-center cursor', {})}
                onClick={() => {
                  handleFundingToggle();
                  openModal('request');
                }}
              >
                <span className="me-2 ">Request for funds</span>
              </div>
            )}

            {permissionForOwnersAndManagers && (
              <CustomPopover
                zIndex="1"
                // content={<Actions type={2} />}
                content={<PaymentActions />}
                showPopover={isButtonToggle}
                clickOutside={handleButtonToggle}
              >
                <SendFundsButton onClick={handleButtonToggle} />
              </CustomPopover>
            )}
          </div>
        </div>
      </div>

      <FundsModal
        closeHandler={closeHandler}
        isModalOpen={isModalOpen}
        setInsideModalClick={setInsideModalClick}
        fundType={toggleBalance}
        fundStep={fundStep}
        selectedAccount={data}
        setFundStep={setFundStep}
        handleBack={() => setFundStep(1)}
        accounts={company.accounts || []}
        initiatePayment={initiatePayment}
        verifyPayment={verifyPayment}
        paymentVerified={paymentVerified}
        paymentFailed={paymentFailed}
        getReauthorizationCode={getReauthorizationCode}
        destination={data?.code}
        addFunds={addFunds}
        isAddingFunds={isAddingFunds}
      />

      <AccountDetailsModal
        details={selectedAccountDetails}
        isModalOpen={accountDetailsModal}
        closeHandler={closeDetailsModal}
      />

      <StatementModal
        isModalOpen={statementModal}
        account={{
          code: data?.code,
          name: data?.name,
          accountName: data?.accountName,
          balance: data?.balance,
          currency: data?.currency,
        }}
        closeHandler={() => setStatementModal(false)}
        canExport={permissionForOwnersAndManagers}
      />

      <SwapModal
        companyCode={company.code}
        accounts={company.accounts}
        defaultSelectedAccount={{
          destination: data?.code,
          balance: data?.parentBalance?.code,
          isDestinationDisabled: true,
        }}
        isOpen={openSwapModal}
        closeHandler={handleSwapping}
        modalHeading={'Add funds'}
      />

      {requestFunds && (
        <RequestFunds
          isOpen={requestFunds}
          toggleHandler={() => setRequestFunds(!requestFunds)}
          source={data?.code}
          bankAccount={data?.code}
          bankAccountName={data?.name}
          topUpCard={false}
          newBudget={false}
          topUpBudget={false}
          payVendor={false}
          selectedRequestChannel={'bank-option'}
        />
      )}
    </div>
  );
};
export default SelectedSubAccountHeader;

import Accounts from 'assets/images/slider/Accounts.png';
import ApprovalRules from 'assets/images/slider/ApprovalRules.png';
import BankSync from 'assets/images/slider/BankSync.png';
import Bills from 'assets/images/slider/Bills.png';
import Budgets from 'assets/images/slider/Budgets.png';
import Cards from 'assets/images/slider/Cards.png';
import Invoices from 'assets/images/slider/Invoices.png';

export const allSlideContents = [
  {
    title: 'Accounts',
    desc: 'Manage your cash accounts, subaccounts, external bank accounts in one place.',
    imgSrc: Accounts,
  },
  {
    title: 'Approval rules',
    desc: 'Enforce your companies expenses and accounting rules.',
    imgSrc: ApprovalRules,
  },
  {
    title: 'Bank sync',
    desc: 'Securely connect your bank account to enable seamless transactions.',
    imgSrc: BankSync,
  },
  {
    title: 'Bills',
    desc: 'Organize, review and pay your suppliers invoices.',
    imgSrc: Bills,
  },
  {
    title: 'Budgets',
    desc: 'Customize your budget to fit your unique business needs.',
    imgSrc: Budgets,
  },
  {
    title: 'Corporate cards',
    desc: 'Spend smarter, faster with our corporate cards.',
    imgSrc: Cards,
  },
  {
    title: 'Customer invoices',
    desc: `Manage your earnings effortlessly. Create, send, and track all invoices you've issued to your customers.`,
    imgSrc: Invoices,
  },
];

const shuffled = allSlideContents.sort(() => 0.5 - Math.random());
export const sliderContents = shuffled.slice(0, 3);

export const stepsObject = (step) => [
  {
    name: 'Sign Up',
    isActive: ['sign-up'].includes(step),
    completed: ['security', 'complete-details', 'solutions'].includes(step),
  },
  {
    name: 'Security',
    isActive: ['security'].includes(step),
    completed: ['complete-details', 'solutions'].includes(step),
  },
  {
    name: 'Company details',
    isActive: ['complete-details'].includes(step),
    completed: ['solutions'].includes(step),
  },
  {
    name: 'Solutions',
    isActive: ['solutions'].includes(step),
    completed: false,
  },
];

import React, { useState } from 'react';
import AccountInfo from 'components/BusinessCard/Card/Bank/AccountInfo';
import MoreActions from './MoreActions';
import TryAgain from 'components/BusinessCard/Card/Bank/TryAgain';
const TransferFunds = ({ closeHandler, setFundStep, handleBack }) => {
  const [tryAgain, setTryAgain] = useState(false);

  const handleBtnClick = () => {
    closeHandler();
  };

  const clickHandler = () => {
    setFundStep(3);
  };
  return (
    <>
      {tryAgain ? (
        <TryAgain goBackToSelectType={handleBack} />
      ) : (
        <>
          <AccountInfo
            title="Choose account"
            subtTitle="Which of your accounts is receiving the funds?"
            hideLink
            handleBtnClick={handleBtnClick}
            setTryAgain={setTryAgain}
            accountType="NGN"
            handleBack={handleBack}
            isMultiple
          />
          <div className="pt-4">
            <MoreActions handleClick={clickHandler} />
          </div>
        </>
      )}
    </>
  );
};
export default TransferFunds;

import { Skeleton } from 'antd';
import classNames from 'classnames';
import React from 'react';

const LoadingReciept = () => {
  return (
    <div className={classNames('receipt-asset-wrapper')}>
      {Array(2)
        .fill(1)
        .map((item, index) => (
          <div
            key={index}
            className="receipt-asset p-0 m-0 bg h-100 lg parent-img-hover position-relative overflow-hidden w-100"
          >
            <Skeleton.Image active style={{ width: '560px', height: '800px' }} />
          </div>
        ))}
    </div>
  );
};

export default LoadingReciept;

import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  DeleteIcon,
  Download02,
  EditIcon,
  LayersTwoIcon,
  RetryIcon,
  SlashCircleIcon,
  UploadIcon,
} from 'assets/icons';
import classNames from 'classnames';
import { Skeleton } from 'antd';
import ConfirmDialog from 'components/ConfirmDialog';
import { VendorsStatusType } from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import { SendFundsButton, ActiveButton } from 'components/UI/CustomButton';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import ExpensesEmptyStateData from 'pages/Expenses/expensesEmptystateData';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Navbar, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchCategories } from 'redux/actions/CategoryAction';
import {
  exportVendors,
  getVendors,
  massUpdateVendor,
  reactivateVendor,
  resendVendorInvite,
} from 'redux/actions/VendorsAction';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import {
  buildExportVendorsData,
  buildVendorsTableData,
  capitalizeFirstLetter,
} from 'utils/helper';
import { exportVendorHeader, vendorColumns } from 'utils/mockData';
import CreateNewVendor from './CreateNewVendor';
import { getAllBanks } from 'redux/actions/PaymentAction';
import { toggleAction } from 'redux/actions/ToggleAction';
import CustomPopover from 'components/UI/Popover';

const VendorsBulkAction = ({ rows, unSetRows }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [numberOfSelectedVendors, setNumberOfSelectedVendors] = useState(0);
  const dispatch = useDispatch();
  const [type, setType] = useState('');

  const {
    massUpdateVendor: { loading: cancelLoading, success: isCancelSuccess },
  } = useSelector(({ vendors }) => vendors);

  const checkFailed = rows.map((item) => item.error);

  useEffect(() => {
    if (isCancelSuccess) {
      setIsSubmit(false);
    }
  }, [isCancelSuccess]);

  const handleSubmit = (action) => {
    if (['delete', 'block'].includes(action)) {
      for (const option of rows) {
        if (['blocked', 'deleted'].includes(option.status.value)) {
          toastError(`One or more vendors has been ${option.status.value} already`);
          return;
        }
      }

      setType(action);
      setNumberOfSelectedVendors(rows.length);
      setIsSubmit(!isSubmit);
    }
  };

  const handleConfirm = (action) => {
    const payload = {
      action,
      vendors: rows.map(({ vendorData: { code } }) => code),
    };
    dispatch(massUpdateVendor(payload));
  };

  return (
    <>
      {!!rows.length && (
        <div className="floating-cta-container mt-2 mx-auto d-flex align-items-center border rounded">
          <div className="selected-information">
            <p className="mb-0">({rows?.length ?? '0'}) selected</p>
          </div>
          <div className="d-flex justify-content-end custom-btn-container">
            <button
              className={classNames('btn border me-2')}
              disabled={!!checkFailed.includes(true)}
              onClick={() => handleSubmit('block')}
            >
              Block ({rows?.length ?? '0'})
            </button>

            <button
              className="btn danger"
              disabled={!!checkFailed.includes(true)}
              onClick={() => handleSubmit('delete')}
            >
              Delete ({rows?.length ?? '0'})
            </button>
          </div>
        </div>
      )}

      <Modal
        show={isSubmit}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} vendor`}
          subTitle={
            <>
              Are you sure you want to {type}
              <span className="fw-bolder"> {numberOfSelectedVendors}</span>
              {numberOfSelectedVendors > 1 ? ' vendors' : ' vendor'}?
            </>
          }
          onConfirm={() => handleConfirm(type)}
          onCancel={() => {
            setIsSubmit(false);
          }}
          isDeleteDialog={true}
          actionBtnText={capitalizeFirstLetter(type)}
          loading={cancelLoading}
        />
      </Modal>
    </>
  );
};

const Vendor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [vendor, setVendor] = useState(null);
  const [filterData, setFilterData] = useState([...VendorsStatusType]);
  // const [isOpen, setIsOpen] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);
  const [isFillData, setIsFillData] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDelete, setIsDelete] = useState(null);
  const [search, setSearch] = useState('');
  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [isVendorOpen, setIsVendorOpen] = useState(false);
  const debouncedValue = useDebounce(search, 600);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [type, setType] = useState('');

  const { permissions } = allPermissions();

  const canView = hasPermission({
    permissions,
    scopes: ['vendor-*', 'vendor-view'],
  });

  const canCreateVendor = hasPermission({
    permissions,
    scopes: ['vendor-*', 'vendor-create'],
  });

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const {
    getVendor: { data: { meta = {}, vendors = [] } = {}, loading },
    massUpdateVendor: { loading: deleteLoading, success: deleteSuccess },
    reactivateVendor: { loading: activateLoading, success: activateSuccess },
    exportVendors: { loading: exportLoading, data: { vendors: exportData = [] } = {} },
  } = useSelector(({ vendors }) => vendors);

  const {
    fetchCategories: { data: { categories = [] } = {} },
  } = useSelector(({ categories }) => categories);
  /* 
  const {
    syncAccountingEntity: { data: syncResponse, loading: syncingEntities },
  } = useSelector(({ integrations }) => integrations);
 */
  const { page = 1, total, hasMore = false, perPage = 50, nextPage = 2 } = meta;

  const toggleHandler = () => {
    setIsVendorOpen(!isVendorOpen);
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  useEffect(() => {
    if (!vendors.length && canView) dispatch(getVendors());

    return () => {
      if (filtered && canView) dispatch(getVendors());
    };
  }, [filtered]);

  useEffect(() => {
    if (!!categories.length) {
      if (!isFillData) {
        setIsFillData(true);

        const data = [];
        categories.forEach((b) => {
          const { code, name } = b;
          data.push({
            value: code,
            label: name,
            isSelected: false,
          });
        });
        setFilterData([{ title: 'Categories', list: data }, ...filterData]);
      }
    }
  }, [categories]);

  const handleFilter = (query) => {
    dispatch(getVendors(query));
    setFilteredQuery(query);
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue && canView) {
      filteredQuery.search = debouncedValue;
      dispatch(getVendors({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canView) {
      delete filteredQuery.search;
      dispatch(getVendors({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleClose = () => {
    if (!isOtherPopoverOpen) return toggleHandler(), setVendor(null), setIsUpdate(false);
  };

  const handleEditClick = (data) => {
    setVendor(data);
    setIsUpdate(true);
    toggleHandler();
  };

  const handleRowClick = (data) => {
    history.push(`/expenses/vendors/profile/${data.code}`);
  };

  useEffect(() => {
    if (deleteSuccess || activateSuccess) {
      setIsDelete(null);
    }
  }, [deleteSuccess, activateSuccess]);

  const handleDeleteClick = () => {
    if (isDelete.action === 'reactivate')
      return dispatch(reactivateVendor(isDelete.code));
    return dispatch(
      massUpdateVendor({ vendors: [isDelete.code], action: isDelete.action }),
    );
  };

  const handleDeleteModal = (vendor, action) => {
    setIsDelete({ ...vendor, action });
  };

  const handleSyncZoho = (vendor) => {
    dispatch(
      syncAccountingEntity({
        codes: [vendor.code],
        platform: 'zoho',
        element: 'vendors',
      }),
    );
  };

  const handleCancelDeleteModal = () => {
    setIsDelete(null);
  };

  const handlePreviousPage = (page) => {
    dispatch(getVendors({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getVendors({ perPage, page, ...filteredQuery }));
  };

  useEffect(() => {
    if (location.state?.isTeam) {
      toggleHandler();
      history.replace({ state: {} });
    }
  }, [location]);

  const actionHandler = (event, type, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    setType(type);
    if (type === 'edit') handleEditClick(data);
    if (type === 'syncZoho') handleSyncZoho(data);
    if (type === 'delete') handleDeleteModal(data, 'delete');
    if (type === 'block') handleDeleteModal(data, 'block');
    if (type === 'resend') dispatch(resendVendorInvite({ email: data.email }));
    if (type === 'reactivate') handleDeleteModal(data, 'reactivate');
    setIsPopoverOpen(true);
  };

  const Actions = ({ list: selectedData }) => {
    return (
      <div className="actions-dialog">
        {selectedData.status.value === 'invited' && (
          <div
            className="actionLink"
            onClick={(event) => actionHandler(event, 'resend', selectedData)}
          >
            <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Resend
            invite
          </div>
        )}

        {selectedData.status.value === 'blocked' && (
          <div
            className="actionLink"
            onClick={(event) => actionHandler(event, 'reactivate', selectedData)}
          >
            <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" />
            Re-activate
          </div>
        )}

        {selectedData.status.value === 'active' && (
          <div
            className="actionLink"
            onClick={(event) => actionHandler(event, 'edit', selectedData)}
          >
            <EditIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Edit
            vendor
          </div>
        )}

        {!selectedData?.isZohoSynced && (
          <div
            className="actionLink"
            onClick={(event) => actionHandler(event, 'syncZoho', selectedData)}
          >
            <UploadIcon stroke="#586068" width={16} height={16} className="mr-4" />
            Sync to Zoho Books
          </div>
        )}

        <div
          className="actionLink svg-danger text-danger me-1"
          onClick={(event) => actionHandler(event, 'delete', selectedData)}
        >
          <DeleteIcon className="mr-4" width={16} height={16} /> Delete vendor
        </div>

        {selectedData.status.value !== 'blocked' && (
          <div
            className="actionLink svg-danger text-danger"
            onClick={(event) => actionHandler(event, 'block', selectedData)}
          >
            <SlashCircleIcon className="mr-4" width={16} height={16} /> Block vendor
          </div>
        )}
      </div>
    );
  };

  const PaymentActions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const handleImportFile = () => {
    history.push('/expenses/vendors/import');
  };

  const vendorsList = () => {
    return (
      <>
        {!vendors.length ? (
          <div>
            {filtered ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no budget vendors for this filter"
                  bodyText="Alter filter to find your vendors"
                  withButton={false}
                />
              </div>
            ) : (
              <ExpensesEmptyStateData
                openVendorModal={toggleHandler}
                showAction={canCreateVendor}
              />
            )}
          </div>
        ) : (
          <Container className="px-0">
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={vendorColumns}
                  data={buildVendorsTableData(vendors)}
                  pagination
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  onRowClick={handleRowClick}
                  floatSelection
                  getSelectedRows={VendorsBulkAction}
                />
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  };

  const toggleActionButton = () => {
    setIsVendorOpen(!isVendorOpen);
  };

  const csvData = [
    exportVendorHeader?.map((item) => capitalizeFirstLetter(item)),
    ...buildExportVendorsData(exportData),
  ];

  const Loader = () => (
    <section className="pt-3 fade-in">
      <div className="d-flex">
        <Skeleton.Button
          active
          shape="square"
          style={{ borderRadius: 8, height: '40px', width: '16rem' }}
        />

        <div className="ms-auto d-flex gap-2">
          {Array.from({ length: 3 }).map((_, i) => (
            <Skeleton.Button
              key={i}
              active
              shape="square"
              style={{ borderRadius: 8, height: '40px', width: '7rem' }}
            />
          ))}
        </div>
      </div>

      <div className="mt-4">
        <Table
          columns={vendorColumns}
          data={buildVendorsTableData(vendors || [])}
          hasCheckBox={false}
          loading
        />
      </div>
    </section>
  );

  return (
    <div className="budgets-wrapper position-relative container mb-5">
      <TopBar
        headerText="Vendors"
        subText="Manage your vendor: Create and make payments to vendors."
        isRightBar
        dropDownBtn={
          <AllowedTo scopes={['transaction-*', 'transaction-create']}>
            <CustomPopover
              zIndex="1"
              content={<PaymentActions />}
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
            >
              <SendFundsButton onClick={handleButtonToggle} />
            </CustomPopover>
          </AllowedTo>
        }
        addButton={canCreateVendor}
        addActionButton={canView && 'Create vendor'}
        toggleActionButton={toggleActionButton}
      />

      <section className="overview-holder" style={{ height: `calc(100vh - 6rem)` }}>
        <PendingOnboardingNotice />

        <TopBar
          flexWrap
          showBarSearch={!!vendors.length || (filtered && !vendors.length)}
          addIcon={!!vendors.length || (filtered && !vendors.length)}
          searchVal={search}
          setSearchVal={setSearch}
          // addOnClick={toggleHandler}
          inputPlaceholder="search for a vendor"
          filterData={filterData}
          handleFilterSelect={(updateVal) => {
            setFilterData(updateVal);
          }}
          addExport={!!vendors.length || (filtered && !vendors.length)}
          csvFileName="Vendors list"
          handleExport={() => {
            dispatch(exportVendors());
          }}
          customAddButton={
            <ActiveButton
              variant="light"
              icon={<Download02 stroke="#57534E" style={{ rotate: '180deg' }} />}
              className="d-flex add-button align-items-center cursor filter-button-wrapper"
              onClick={handleImportFile}
              textClassName="d-md-block d-none"
              text={<Navbar.Text>Import CSV</Navbar.Text>}
            />
          }
          csvData={csvData}
          exportLoading={exportLoading}
          showFilter={!!vendors.length || (filtered && !vendors.length)}
          withOutSearch
          handleFilterApply={handleFilter}
        />

        {loading && !filtered ? <Loader /> : vendorsList()}
      </section>

      {/* <VendorModal
        isOpen={isOpen}
        isUpdate={isUpdate}
        IsOtherPopoverOpen={isOtherPopoverOpen}
        setIsOtherPopoverOpen={setIsOtherPopoverOpen}
        handleClose={handleClose}
        toggleHandler={handleClose}
        data={vendor}
      /> */}

      <Modal
        show={isDelete}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} ${isDelete?.name || ''}`}
          subTitle={`Are you sure you want to ${type} this vendor? This action cannot be undone.`}
          onConfirm={handleDeleteClick}
          loading={deleteLoading || activateLoading}
          onCancel={handleCancelDeleteModal}
          actionBtnText={capitalizeFirstLetter(type)}
          isDeleteDialog={type !== 'reactivate'}
        />
      </Modal>

      <CreateNewVendor
        isOpen={isVendorOpen}
        isUpdate={isUpdate}
        toggleHandler={handleClose}
        data={vendor}
        isNew={true}
      />
    </div>
  );
};

export default Vendor;

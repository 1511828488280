import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PlusIcon, DeleteIcon } from 'assets/icons';

import CurrencyFormat from 'react-currency-format';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomButton from 'components/UI/CustomButton';
import CustomPercentageInput from 'components/UI/CustomPercentageInput';

import dayjs from 'dayjs';
import CreateNewVendor from 'pages/Vendor/CreateNewVendor';

import { getVendors, resetBlockVendor } from 'redux/actions/VendorsAction';
import { createBillAction, updateBill } from 'redux/actions/BillAction';
import { RESET_FLAGS_BILL } from 'redux/reducers/BillReducer';
import { convertNaNToZero, getCurrency } from 'utils/helper';

const BillForm = ({
  code,
  payload,
  assetCode,
  updatePayload,
  removeItem,
  sortProductValue,
  addProduct,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isTriggered, setIsTriggered] = useState(false);
  const [isVendorOpen, setIsVendorOpen] = useState(false);

  const {
    getVendor: { loading, data: { vendors = [] } = {}, success: getSuccess },
    createVendor: { success: createSuccess },
  } = useSelector(({ vendors }) => vendors);

  const {
    createBill: { loading: isLoading, success, data: bill },
    updateBill: { loading: isUpdating, success: updateSuccess },
  } = useSelector(({ bills }) => bills);

  useEffect(() => {
    if (!vendors.length) dispatch(getVendors());
  }, []);

  useEffect(() => {
    if (createSuccess && getSuccess && !loading) {
      const { name: label, code } = vendors[0] || {};

      updatePayload({ target: 'vendor', value: { label, value: code } });
      dispatch(resetBlockVendor('createVendor'));
    }
  }, [createSuccess, getSuccess, loading]);

  useEffect(() => {
    if (success && !isLoading) {
      history.push(`/expenses/bills/review/${bill.code}`);
      dispatch({ type: RESET_FLAGS_BILL, blockType: 'createBill' });
    }
  }, [isLoading, success]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch({ type: RESET_FLAGS_BILL, blockType: 'updateBill' });
      history.push(`/expenses/bills/review/${code}`);
    }
  }, [updateSuccess]);

  const vendorsList = useMemo(
    () =>
      vendors
        ?.map(({ code: value, name: label }) => ({
          value,
          label,
        }))
        .filter((value) => value.label),
    [vendors],
  );

  const amount = payload?.products?.reduce((acc, curr) => {
    return acc + Number(curr.unitPrice) * Number(curr.quantity);
  }, 0);

  const handleValidation = () => {
    setIsTriggered(true);

    const { products, vendor, dueDate } = payload;
    const hasRequiredFields = vendor && dueDate;

    const updatedProducts = products.map((product) => {
      const hasError = !product.name || !product.quantity || !product.unitPrice;
      return {
        ...product,
        hasError: hasError,
      };
    });

    updatePayload({ target: 'products', value: updatedProducts });
    const err = updatedProducts.some((product) => product.hasError) || !hasRequiredFields;
    if (!err) handleCreation();
  };

  const handleCreation = () => {
    // Update to add
    const { vendor, products, balanceDue, reference, vatAmount, ...rest } = payload;

    const finalPayload = {
      ...rest,
      isDraft: true,
      vendor: vendor.value,
      ...(assetCode && { uploads: [assetCode] }),
      ...(reference && { reference }),
      products: products.map((product) => {
        const { hasError, ...rest } = product;
        return {
          ...rest,
          currency: payload.currency,
          unitPrice: product.unitPrice * 100,
        };
      }),
    };

    const action = code
      ? updateBill({ ...finalPayload, code })
      : createBillAction(finalPayload);

    dispatch(action);
  };

  const onPercentageChange = ({ name, value }) => {
    updatePayload({
      target: 'vat',
      value,
    });
  };

  useEffect(() => {
    if (payload.vat && amount)
      updatePayload({
        target: 'vatAmount',
        value: payload.vat * amount,
      });
  }, [amount, payload.vat]);

  return (
    <section className="mt-3">
      <h2 className="section-header">Add a bill</h2>
      <p className="sub-header"> Enter details below</p>

      <div className="mt-4 pt-2 px-1 form-holder">
        <div className="form-item">
          <CustomSelect
            classNamePrefix="invoice-select"
            label="Vendor"
            placeholder="Select vendor"
            onChange={(value) => updatePayload({ target: 'vendor', value })}
            value={payload.vendor}
            options={vendorsList}
            isDisabled={loading}
            isLoading={loading}
            hasError={isTriggered && !payload.vendor}
          />
          <span className="pt-2 d-block sub-text">
            If the vendor isn’t on the database,{' '}
            <span className="link" onClick={() => setIsVendorOpen(true)}>
              create vendor
            </span>
          </span>
        </div>
        <div className="form-item">
          <CustomInput
            type="text"
            value={payload.reference}
            className="custom-input reference"
            label="Unique identifier/reference"
            placeholder="12345"
            onChange={(e) =>
              updatePayload({ target: 'reference', value: e.target.value })
            }
          />
        </div>
        <div className="d-flex gap-4 dates-holder">
          <div className="form-item w-50">
            <CustomDatePicker
              id="invoice-date"
              disabled
              label="Creation date"
              placeholderText="DD / MM / YYYY"
              selected={dayjs().toDate()}
            />
          </div>

          <div className="form-item w-50">
            <CustomDatePicker
              id="invoice-date"
              label="Due date"
              minDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="DD / MM / YYYY"
              hasError={isTriggered && !payload.dueDate}
              selected={payload.dueDate && dayjs(payload.dueDate).toDate()}
              onChange={(value) => updatePayload({ target: 'dueDate', value })}
            />
          </div>
        </div>
        <div className="details-holder">
          <div>
            {payload?.products?.map((product, index) => {
              return (
                <div className="row mb-3 fade-in-up" key={index}>
                  <div className="col-md-6 form-group items">
                    {index === 0 && <label className="form-label">Items</label>}
                    <CustomInput
                      type="text"
                      name="name"
                      placeholder="Flight ticket Air France"
                      value={product.name}
                      hasError={product.hasError && !product.name}
                      onChange={(event) => sortProductValue(index, event)}
                    />
                  </div>

                  <div className="col-md-2 form-group qty">
                    {index === 0 && <label className="form-label">Qty</label>}
                    <CustomInput
                      name="quantity"
                      type="number"
                      value={product.quantity}
                      maxLength="4"
                      placeholder="0"
                      onWheel={(event) => {
                        event.target.blur();
                        event.stopPropagation();
                      }}
                      hasError={product.hasError && !product.quantity}
                      onChange={(event) => sortProductValue(index, event)}
                    />
                  </div>

                  <div className="col-md-3 form-group price">
                    {index === 0 && <label className="form-label">Unit price</label>}
                    <CustomInput
                      hasError={product.hasError && !product.unitPrice}
                      name="unitPrice"
                      type="number"
                      placeholder="₦0.00"
                      isAmount
                      value={product.unitPrice}
                      otherCurrency={false}
                      useCurrency={false}
                      onChange={(event) => sortProductValue(index, event)}
                    />
                  </div>

                  <div
                    className={`col-md-1 d-flex align-items-center delete ${
                      index == 0 ? 'mt-4' : ''
                    }`}
                  >
                    {payload.products.length > 1 && (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeItem(index)}
                      >
                        <DeleteIcon stroke="#D7D3D0" height="18" width="18" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <button
            type="button"
            className="add-item"
            style={{ width: 'fit-content' }}
            onClick={addProduct}
          >
            <PlusIcon stroke="#D28B28" height="14" width="14" /> Add additional item
          </button>
        </div>

        <div className="d-flex justify-content-end mt-4">
          <div className="details-holder sub-total__bill">
            {/* Subtotal Here  */}
            <div className="d-flex border-bottom">
              <p className="inner-text">Subtotal excl. tax</p>
              <p className="ms-auto inner-text">
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={convertNaNToZero(amount)}
                  displayType="text"
                  thousandSeparator={true}
                  isNumericString
                />
              </p>
            </div>
            <div className="pt-3">
              <div className="calc-region">
                <div className="inner-holder">
                  <span className="text">
                    Discount ({convertNaNToZero(payload?.discount / amount)}%)
                  </span>

                  <span className="value">
                    <CurrencyFormat
                      prefix={getCurrency('NGN')}
                      value={-convertNaNToZero(payload.discount || 0) / 100}
                      displayType="text"
                      thousandSeparator={true}
                      isNumericString
                      allowNegative={true}
                    />
                  </span>
                </div>
              </div>

              {/* VAT Here */}
              <div className="calc-region">
                <div className="inner-holder">
                  <span className="text">VAT ({convertNaNToZero(payload?.vat)}%)</span>

                  {!payload?.vatAmount && (
                    <div className="form-group w-100">
                      <CustomPercentageInput
                        placeholder="0%"
                        name="vat"
                        value={payload?.vat}
                        onChange={(value) => onPercentageChange({ name: 'vat', value })}
                      />
                    </div>
                  )}
                  {payload?.vatAmount && (
                    <span className="value">
                      <CurrencyFormat
                        prefix={getCurrency('NGN')}
                        value={convertNaNToZero(payload.vatAmount) / 100}
                        displayType="text"
                        thousandSeparator={true}
                        isNumericString
                      />
                    </span>
                  )}
                </div>
              </div>

              <div className="border-top pt-4 d-flex align-items-center">
                <p className="inner-text">Balance due incl. tax</p>
                <p className="ms-auto inner-text">
                  <CurrencyFormat
                    style={{ fontWeight: 600, fontSize: '20px' }}
                    prefix={getCurrency('NGN')}
                    value={
                      convertNaNToZero(amount) + convertNaNToZero(payload.vatAmount) / 100
                    }
                    displayType="text"
                    thousandSeparator={true}
                    isNumericString
                  />
                </p>
              </div>
            </div>
          </div>

          {/*
        <div className="d-flex gap-4 mt-4">

          <div className="form-group w-100">
            <p className="form-label grey">Balance due</p>
            <div className="amount-holder">
              <CurrencyFormat
                style={{ overflow: 'hidden' }}
                className="value"
                prefix={getCurrency('NGN')}
                value={convertNaNToZero(payload.balanceDue || amount)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            </div>
          </div>
        </div>
        */}
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3" style={{ gap: 12 }}>
        <button
          className="add-custom add-button"
          onClick={() => history.push('/expenses/bills')}
        >
          <span className="ms-1" style={{ fontWeight: 500 }}>
            Cancel
          </span>
        </button>

        <div>
          <CustomButton
            className="add-button"
            onClick={handleValidation}
            loading={isLoading || isUpdating}
            disabled={isLoading | isUpdating}
            style={{ minWidth: '93px' }}
          >
            <span className="ms-1" style={{ fontWeight: 500 }}>
              Continue
            </span>
          </CustomButton>
        </div>
      </div>

      <CreateNewVendor
        isNew={true}
        restrictState={true}
        isOpen={isVendorOpen}
        toggleHandler={() => setIsVendorOpen(false)}
      />
    </section>
  );
};

export default BillForm;

import OnboardingNoticeDialog from 'components/ConfirmDialog/onboarding-notice';
import NotificationsModal from 'components/Notifications/NotificationsModal';
import Sidebar from 'components/Sidebar';
import { toastError, toastSuccess } from 'components/UI/toast';
import BujetiPayModal from 'components/bujetiPayModal';
import ExpenseModal from 'components/bujetiPayModal/expense';
import DefaultAPI from 'pages/DefaultAPI';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { logout } from 'redux/actions/AuthAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import VerifcationRoute from 'utils/VerifcationRoute';
import {
  encryptAndSaveToLocalStorage,
  getUserLandingPage,
  isLoggedIn,
  retrieveAndDecryptFromLocalStorage,
} from 'utils/utility';
import Routes from './Routes';
import classNames from 'classnames';
import { PlusDropDownIcon } from 'assets/icons';
import CustomSelect from 'components/UI/CustomSelect';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import ModalContainer from 'components/ModalContainer';
import { automate as automateAction } from 'redux/actions/AutomationAction';

const OPERATOR_VALUES = {
  SEND_INVOICE: 'send-invoice',
  FUND_BUDGET: 'fund-budget',
  SYNC_DATA: 'sync-data',
  UPLOAD_FILES: 'upload-files',
  PAY_BILL: 'pay-bill',
  APPROVE_REQUEST: 'approve-request',
  SEND_NOTIFICATION: 'send-notification',
};

const renderRoutes = () => {
  const {
    user: { data: { user } = {} },
    loginUser: { data: { user: logedIn } = {} },
  } = useSelector(({ auth }) => auth);

  const { toggle, toggleNotification, toggleOnboardingNotification } = useSelector(
    ({ toggle }) => toggle,
  );

  const { toggleExpense } = useSelector(({ toggleExpense }) => toggleExpense);
  const { automate } = useSelector(({ automate }) => automate);
  const {
    syncAccountingEntity: {
      error: errorAccountingSoftware,
      data: accountingIntegrationData,
    },
  } = useSelector(({ integrations }) => integrations);

  const [dataByTypes, setDataByTypes] = useState([]);

  const getOperatorValue = (val) => {
    if (val.code === OPERATOR_VALUES.SEND_INVOICE)
      setDataByTypes([
        {
          value: 'By Cossi Achille',
          code: 'inv_0LD0393404ND',
          label: 'ARK Holdings N3.4M',
        },
        {
          value: 'By Uchechi Ukandu',
          code: 'inv_0LD0393404ND',
          label: 'Koolboks Limited N400k',
        },
        {
          value: 'By Jay Ike',
          code: 'inv_0LD0393404ND',
          label: 'ItsNet Media N1.4M',
        },
      ]);
    else if (val.code === OPERATOR_VALUES.FUND_BUDGET)
      setDataByTypes([
        {
          value: 'By Samy CHIBA',
          code: 'bdg_PDP9POLAG00',
          label: 'Ikeja Office Renovations',
          amount: null,
        },
        {
          value: 'By Samy CHIBA',
          code: 'bdg_PDP9POLAG00',
          label: 'ABC Transport Deliveries',
          amount: null,
        },
      ]);
    else if (val.code === OPERATOR_VALUES.SYNC_DATA)
      setDataByTypes([
        {
          value: 'Sync your data to Tyms',
          code: '-',
          label: 'To Tyms',
          amount: null,
        },
        {
          value: 'Sync your data to Zoho Books',
          code: '-',
          label: 'To Zoho Books',
          amount: null,
        },
        {
          value: 'Sync your data to QuickBooks',
          code: '-',
          label: 'To QuickBooks',
          amount: null,
        },
      ]);
    else if (val.code === OPERATOR_VALUES.UPLOAD_FILES)
      setDataByTypes([
        {
          value: 'Sync your files to Google Drive',
          code: '-',
          label: 'Upload to Google Drive',
          amount: null,
        },
        {
          value: 'Sync your files to Dropbox',
          code: '-',
          label: 'Upload to Dropbox',
          amount: null,
        },
      ]);
  };

  const [conditions, setConditions] = useState([
    {
      action: null,
      target: null,
      rank: 0,
    },
  ]);

  const addCondition = () => {
    setConditions((prevConditions) => [
      ...prevConditions,
      {
        action: null,
        target: null,
        rank: prevConditions.length,
      },
    ]);
  };

  const updateCondition = (index, field, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[index] = {
      ...updatedConditions[index],
      [field]: value,
    };

    if (field === 'action') {
      getOperatorValue(value, field);
    }

    setConditions(updatedConditions);
  };

  const handleChange = (index, key, value) => {
    updateCondition(index, key, value);
  };

  const [closeModalAutomate, setCloseModalAutomate] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { permissions, loading, companyStatus, onboardingStatus, isAdmin } =
    allPermissions();

  const handleLogout = async () => {
    try {
      const { pathname, search, hash } = window.location;
      if (pathname !== '/account-statement') {
        encryptAndSaveToLocalStorage('redirectPath', `${pathname}${search}${hash}`);
        encryptAndSaveToLocalStorage('lastLogin', user?.email);
      }

      dispatch(logout(true));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (automate) setCloseModalAutomate(false);
  }, [automate]);

  useEffect(() => {
    if (errorAccountingSoftware && accountingIntegrationData) {
      if (accountingIntegrationData.openIntegrationPage) {
        history.push('/settings/integrations', {
          platform: accountingIntegrationData.platform,
        });
      }
    }
  }, [errorAccountingSoftware, accountingIntegrationData]);

  const token = retrieveAndDecryptFromLocalStorage('app-session');
  const tokenTime = retrieveAndDecryptFromLocalStorage('exp-app-session');
  const vendorToken = retrieveAndDecryptFromLocalStorage('vendor-app-session');
  const directorToken = retrieveAndDecryptFromLocalStorage('director-app-session');

  const isToken = Boolean(token);

  const authExp = () => {
    return isToken && new Date().getTime() >= +tokenTime;
  };

  const onAction = () => {
    if (authExp()) {
      handleLogout('action');
      // toastError('Session token expired');
    }
  };

  const onIdle = () => {
    if (authExp()) {
      handleLogout('idle');
      toastError("You've been disconnected due to long period of inactivity");
    }
  };

  //Manage session on interaction
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onAction,
    timeout: 2000,
    throttle: 500,
  });

  const renderRoute = (
    routerProps,
    Component,
    props,
    isPrivate = false,
    isTabRoute = false,
    headerHide,
    routePermission,
    redirect,
    name,
  ) => {
    if (Component) {
      const componentProps = {
        ...routerProps,
        ...props,
      };

      const { pathname: path, state } = routerProps?.location || {};

      if (name === 'vendor-complete-profile' && !vendorToken) {
        return window.open('https://www.bujeti.com/', '_self');
      }

      if (isPrivate) {
        if (authExp()) {
          dispatch(logout(true));
        }

        if (
          routePermission?.length &&
          isLoggedIn() &&
          !hasPermission({ permissions, scopes: routePermission })
        )
          return <Redirect to="/404" />;

        if (!isLoggedIn()) {
          const routeName = ['/', '/login'].includes(path)
            ? '/login'
            : `/login?redirect=${path}`;

          return <Redirect to={routeName} />;
        }

        if (redirect) {
          return <Redirect to={redirect} />;
        }

        return (
          <div className={classNames({ ['section']: !headerHide })}>
            <div
              className={classNames({
                'p-0': headerHide,
                'fade-in': !isTabRoute && !state?.disableFade,
                'main-content': !headerHide,
              })}
            >
              <VerifcationRoute>
                <Component {...componentProps} />
              </VerifcationRoute>
              {toggle && <BujetiPayModal toggle={toggle} />}
              {toggleNotification && (
                <NotificationsModal toggleNotification={toggleNotification} />
              )}
              <Modal
                show={toggleOnboardingNotification}
                centered
                dialogClassName="custom-dialog"
                className="custom-dialog"
              >
                <OnboardingNoticeDialog />
              </Modal>
              {toggleExpense && <ExpenseModal toggle={toggleExpense} />}
              {automate && (
                <>
                  <Modal show={!closeModalAutomate}>
                    <ModalContainer
                      lg
                      onCancel={() => {
                        setCloseModalAutomate(true);
                        dispatch(automateAction());
                      }}
                      actionBtnText={'Save actions'}
                      onConfirm={() => {
                        toastSuccess('Actions saved successfully');
                        setCloseModalAutomate(true);
                        dispatch(automateAction());
                      }}
                      title={'Action to do after payment'}
                      subTitle={''}
                      loading={false}
                      goBack={null}
                    >
                      <div className="content">
                        <div className="card-modal-body">
                          <div className="information-wrapper">
                            <div className="form-normal">
                              {conditions.map((condition, index) => {
                                return (
                                  <>
                                    <Row>
                                      <Col className="col-sm-6 xs-12 flex-1">
                                        <CustomRoleSelect
                                          label={'Next action'}
                                          name={'action'}
                                          placeholder="Select an action"
                                          options={[
                                            {
                                              value: 'Send the selected invoice',
                                              label: 'Send invoice',
                                              code: OPERATOR_VALUES.SEND_INVOICE,
                                            },
                                            {
                                              value: 'Pay the selected bill',
                                              label: 'Pay bill',
                                              code: OPERATOR_VALUES.PAY_BILL,
                                            },
                                            {
                                              value: 'Approve the selected request',
                                              label: 'Approve request',
                                              code: OPERATOR_VALUES.APPROVE_REQUEST,
                                            },
                                            {
                                              value: 'Approve the selected budget',
                                              label: 'Approve budget',
                                              code: OPERATOR_VALUES.FUND_BUDGET,
                                            },
                                            {
                                              value: 'Fund the selected budget',
                                              label: 'Fund budget',
                                              code: OPERATOR_VALUES.FUND_BUDGET,
                                            },
                                            {
                                              value: 'Sync data to accounting software',
                                              label: 'Sync data',
                                              code: OPERATOR_VALUES.SYNC_DATA,
                                            },
                                            {
                                              value: 'Notify the selected person',
                                              label: 'Send notification',
                                              code: OPERATOR_VALUES.SEND_NOTIFICATION,
                                            },
                                            {
                                              value: 'Upload the receipits to the cloud',
                                              label: 'Upload files to the cloud',
                                              code: OPERATOR_VALUES.UPLOAD_FILES,
                                            },
                                          ]}
                                          value={condition.action}
                                          defaultValue={condition.action}
                                          onChange={(val) =>
                                            handleChange(index, 'action', val)
                                          }
                                        />
                                      </Col>
                                      <Col className="col-sm-6 xs-12 flex-1">
                                        <CustomRoleSelect
                                          label={'Destination'}
                                          name={'value'}
                                          placeholder="Select the destination"
                                          options={dataByTypes}
                                          value={condition.target}
                                          defaultValue={condition.target}
                                          onChange={(val) =>
                                            handleChange(index, 'target', val)
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    {conditions.length > 1 &&
                                    index + 1 < conditions.length ? (
                                      <div className="connector-wrapper">
                                        <div className="connector-wrapper-item">
                                          <span className="connector">Then</span>
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })}
                              <div
                                className="add-button condition-cta mt-2"
                                role="button"
                                onClick={addCondition}
                              >
                                <PlusDropDownIcon className="me-1" />{' '}
                                <span>Add Action</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalContainer>
                  </Modal>
                </>
              )}
            </div>
          </div>
        );
      }

      return isLoggedIn() && logedIn && !loading ? (
        <Redirect
          to={getUserLandingPage({
            ...logedIn,
            permissions,
            companyStatus,
            onboardingStatus,
            isAdmin,
          })}
        />
      ) : (
        <Component {...componentProps} />
      ); // eslint-disable-line
    }
    return null;
  };

  return Routes.map((route, index) => {
    return (
      <Route
        key={route.name + index}
        exact={route.exact}
        path={route.path}
        render={(routerProps) =>
          renderRoute(
            routerProps,
            route.component,
            route.props,
            route.isPrivate,
            route.isTabRoute,
            route.headerHide,
            route.permissions,
            route.redirect,
            route.name,
          )
        }
      />
    );
  });
};

const Router = () => <Switch>{renderRoutes()}</Switch>;

const findCurrentRoute = (routes, pathname) => {
  for (const route of routes) {
    if (route.path && typeof route.path === 'string' && route.path.trim() !== '*') {
      const pathRegex = new RegExp(`^${route.path.replace(/:\w+/g, '([^/]+)')}$`);
      if (pathRegex.test(pathname)) {
        return route;
      }
    }
  }

  const wildcardRoute = routes.find((route) => route.path === '*');
  if (wildcardRoute) {
    return wildcardRoute;
  }

  return null;
};

const App = () => {
  const history = useHistory();

  const {
    logout: { success },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (success) {
      history.push('/login');
    }
  }, [success]);

  useEffect(() => {
    if (window.Supademo) {
      window.Supademo(
        '27143d57cbda4e066355d42137891996a410ef14211c028d6fb29844b20b4ddd',
        {
          variables: {
            email: 'achille@bujeti.com', // optional user email
            name: 'Achille', // optional user name
            // add your custom variables here
          },
        },
      );
    } else {
      console.error('Supademo script failed to load.');
    }
  }, []);

  const currentRoute = findCurrentRoute(Routes, location.pathname);
  const { headerHide = false, isPrivate = false } = currentRoute || {};
  const showSidebar = !headerHide && isPrivate;

  return (
    <div className="App">
      <DefaultAPI />

      <div className={classNames({ ['app-layout']: showSidebar })}>
        {showSidebar && (
          <div className="aside">
            <Sidebar />
          </div>
        )}

        <Router />
      </div>
    </div>
  );
};

export default withRouter(App);

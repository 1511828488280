import { CloseOutlined, MinusOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomDays from 'components/UI/CustomDays';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CustomTextarea from 'components/UI/CustomTextarea';
import Modal from 'components/UI/Modal';
import Saving from 'components/UI/ModalSaving/Saving';
import Success from 'components/UI/ModalSaving/Success';
import MultipleSelect from 'components/UI/MultipleSelect';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Col, Row, Modal as BootstrapModal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  createPolicy,
  editPolicy,
  getPolicies,
  getSingleBudgetPolicies,
  pauseSingleBudget,
} from 'redux/actions/PoliciesAction';
import { capitalizeFirstLetter, policiesType } from 'utils/helper';
import './styles.scss';
import { toastError, toastSuccess } from 'components/UI/toast';
import PolicyTypeCard from './components/PolicyTypeCard';
import { PlusIcon } from 'assets/icons';
import DeleteDialog from 'components/DeleteDialog';
import PolicyTypeSelector from './components/PolicyTypeSelector';
import ConfirmDialog from 'components/ConfirmDialog';
import useTextCounter from 'hooks/useTextCounter';

export const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
export const weekends = ['Sat', 'Sun'];

const policyValue = {
  name: '',
  type: '',
  description: '',
  role: '',
  budget: '',
  amount: '',
  currency: '',
  frequency: '',
  days: '',
  status: '',
  policyCode: '',
};

export const RECEIPT_TRANSACTION_TYPE = [
  {
    label: 'All Transactions',
    value: 'all',
  },
  {
    label: 'Above',
    value: 'above',
  },
  {
    label: 'Below',
    value: 'below',
  },
];

const PolicyModal = ({
  isOpen,
  handleClose,
  IsOtherPopoverOpen = () => null,
  setIsOtherPopoverOpen,
  selectPolicy,
  isUpdate,
  singleBudget,
  selectedBudget = {},
}) => {
  if (!isOpen) return <div />;
  const dispatch = useDispatch();
  const { budgetId } = useParams();

  const {
    getPolicyTypes: { data: policyTypes = [] },
    createPolicy: { loading, success },
    editPolicy: { loading: editLoading, success: editSuccess },
    pauseSingleBudget: { loading: pauseLoading, success: pauseSuccess },
  } = useSelector(({ policies }) => policies);
  const [isDays, setIsDays] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const [deletePolicyModal, setDeletePolicyModal] = useState(false);

  const [types, setTypes] = useState([]);

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const { budgets = [] } = budgetData;

  const [policy, setPolicy] = useState(policyValue);

  const handleChange = ({ name, value }) => {
    setPolicy({ ...policy, [name]: value });
  };

  const filterPolicyType = policyTypes.filter(
    (item) => item.code != policiesType.INDIVIDUAL_ALLOCATION,
  );

  // if edit = ture or create = false
  const Update = isUpdate && selectPolicy;

  // set input state if update = true
  useEffect(() => {
    if (Update) {
      const {
        policyData: {
          name,
          description,
          budget,
          type,
          types,
          period,
          maxAmount,
          currency,
          frequency,
        },
        status,
        assignedBudget,
        code,
      } = selectPolicy;
      // compare array
      const checkWeekends = JSON.stringify(weekends) === JSON.stringify(period);
      const checkWeekdays = JSON.stringify(weekdays) === JSON.stringify(period);

      // find days type if weekends or weekdays
      const days = checkWeekends
        ? { label: 'Weekends (Sat-Sun)', value: 'weekends' }
        : checkWeekdays
        ? { label: 'Weekdays (Mon-Fri)', value: 'weekdays' }
        : { value: 'custom', label: 'Custom...' };

      if (singleBudget) {
        // setPolicy if on Selected budget page
        setPolicy({
          ...policy,
          status: { value: status.value, label: capitalizeFirstLetter(status.value) },
          policyCode: code,
          budget: { label: assignedBudget.value, value: assignedBudget.code },
        });
      } else {
        // setPolicy if on Policy page
        setPolicy({
          ...policy,
          name,
          description,
          budget: budget.map(({ name: label, code: value }) => ({ label, value })),
          type: { label: type?.name, value: type?.code },
          types,
          days,
          amount: maxAmount / 100,
          currency,
          status: { value: status.value, label: capitalizeFirstLetter(status.value) },
          frequency: frequency && {
            value: frequency,
            label: capitalizeFirstLetter(frequency),
          },
        });

        // if weekdays && weekends is false set custom days
        if (!checkWeekdays && !checkWeekdays) setIsDays(period);
      }
    } else {
      if (Object.keys(selectedBudget).length)
        return setPolicy({ ...policy, budget: [selectedBudget] });
    }
  }, []);

  useEffect(() => {
    if (success || editSuccess || pauseSuccess) {
      if (singleBudget) {
        dispatch(getSingleBudgetPolicies({ budget: budgetId }));
      } else {
        dispatch(getPolicies());
      }
      if (editSuccess) toastSuccess('Policy updated successfully');
      handleClose();
      setConfirmModal(false);
    }
  }, [success, editSuccess, pauseSuccess]);

  const getPolicyTypesData = (data) => {
    setPolicy({ ...policy, ...data });
  };

  const handleDescriptionChange = (value) => {
    setPolicy({
      ...policy,
      description: value,
    });
  };
  const toggleConfirmModal = () => {
    setConfirmModal(true);
  };

  const handleSubmit = () => {
    if (!singleBudget) {
      if (!policy?.name) return toastError('Please enter the policy name');
      if (policy.name.length < 5) return toastError('Policy name is too short');
      if (!policy.description) return toastError('Please enter a description');
      if (policy.description.length < 15)
        return toastError('Description words must be more than 15 characters');
      const budget = policy.budget.length
        ? policy.budget.map((item) => item.value)
        : undefined;
      const payload = {
        name: policy.name,
        amountRange: policy?.amountRange,
        budget,
        description: policy.description,
        type: policy.type,
        period: policy?.period,
        status: Update ? policy.status?.value : undefined,
      };

      if (policy?.frequency?.length) {
        payload.frequency = policy.frequency;
      }
      if (policy?.currency) {
        payload.currency = policy?.currency;
      }

      if (policy?.receiptAmount?.condition) {
        payload.receiptAmount = {
          condition: policy?.receiptAmount?.condition,
          value: policy?.receiptAmount?.value,
        };
      }
      if (Update) {
        dispatch(editPolicy({ ...payload, code: selectPolicy.code }));
      } else {
        dispatch(createPolicy(payload));
      }
    } else {
      const payload = {
        status: policy.status.value,
        code: policy.policyCode,
        budgetCode: budgetId,
      };
      dispatch(pauseSingleBudget(payload));
    }
  };

  // filter budget on selected currency type
  const filteredBudget = budgets
    .filter(({ currency, status }) =>
      policy.currency
        ? currency === policy.currency && status === 'active'
        : status === 'active',
    )
    .map((budget) => ({
      value: budget.code,
      label: budget.name,
    }));

  const handleSelectAllBudget = (budgets) => {
    const budgetArray = policy?.budget?.length === filteredBudget?.length ? [] : budgets;
    setPolicy({ ...policy, budget: budgetArray });
  };

  const onHandlePolicyTypeAction = (type, value) => {
    if (type === 'remove') {
      setDeletePolicyModal(true);
    }
  };

  const handlePolicyModal = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setDeletePolicyModal(false);
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    policy?.description,
    '500',
    handleDescriptionChange,
  );

  const handleCloseConfirmModal = (event) => {
    event.stopPropagation();
    setConfirmModal(false);
  };

  // caution change message condition
  const changeMsg = singleBudget
    ? '* Changes applied to this policy will impact this related budget'
    : '* Changes applied to this policy will impact all related budgets';

  // header title
  const title = Update ? 'Edit budget policy' : 'Create budget policy';

  const isLengthEqual = policy?.budget.length === filteredBudget.length;

  const policyForm = () => (
    <div className="beneficiaries">
      <h2 className="title text-black">{title}</h2>

      {Update && (
        <Row className="mb-3">
          <CustomSelect
            label="Status"
            placeholder="Set Status"
            name="status"
            options={[
              { value: 'active', label: 'Active' },
              { value: 'pause', label: 'Pause' },
            ]}
            onChange={(val) => handleChange({ name: 'status', value: val })}
            value={policy.status}
          />
          {policy.days.value === 'Custom' && (
            <CustomDays days={isDays} setDays={setIsDays} className="mt-2" />
          )}
        </Row>
      )}
      {!singleBudget && (
        <>
          <Row className="mb-3 ">
            <CustomInput
              label="Policy name *"
              placeholder="Enter policy name"
              type="text"
              name="name"
              onChange={({ target: { name, value } }) => handleChange({ name, value })}
              value={policy.name}
              maxLength="50"
            />
          </Row>

          <Row className="mb-3 ">
            <CustomTextarea
              label="Description *"
              placeholder="Enter policy description"
              rowSize={3}
              name="description"
              value={text || policy?.description}
              onChange={handleCharChange}
              maxLength="500"
              showCounter={true}
              charCount={charCount}
            />
          </Row>

          <PolicyTypeSelector
            policy={selectPolicy?.policyData ?? policy}
            isEditing={Update?.code ? true : false}
            policyTypes={policyTypes}
            typesConstant={policiesType}
            setIsOtherPopoverOpen={setIsOtherPopoverOpen}
            getPolicyTypesData={getPolicyTypesData}
            types={types}
            setTypes={setTypes}
          />

          <Row className="mb-3">
            <MultipleSelect
              placeholder="Select budgets"
              label="Budgets"
              name="budget"
              isDisabled={Object.keys(selectedBudget).length}
              options={filteredBudget}
              onChange={(val) => handleChange({ name: 'budget', value: val })}
              value={policy.budget}
              extendComponent={
                <span className="d-flex align-items-center gap-2">
                  {isLengthEqual ? <MinusOutlined /> : <PlusOutlined />}
                  {isLengthEqual
                    ? 'Unselect all existing budgets'
                    : 'Select all existing budgets'}
                </span>
              }
              selectAllBudgets={() => handleSelectAllBudget(filteredBudget)}
            />
          </Row>
        </>
      )}

      {Update && (
        <div className="mb-3">
          <p className="edit-policy-notice">{changeMsg}</p>
        </div>
      )}

      <div className="modal-footer mt-3">
        <CustomButton
          onClick={handleClose}
          disabled={loading || pauseLoading}
          fullWidth={true}
          className="custom-button ghost-button"
        >
          Cancel
        </CustomButton>
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          loading={loading || pauseLoading}
          disabled={loading || pauseLoading}
          onClick={Update ? toggleConfirmModal : handleSubmit}
        >
          {Update ? 'Apply changes' : 'Save'}
        </CustomButton>
      </div>
    </div>
  );

  const isLoading = loading || pauseLoading;

  return (
    <div>
      <Modal show={isOpen}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={handleClose}>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </div>
          </div>
          <div className="card-modal-body">{isLoading ? <Saving /> : policyForm()}</div>
        </div>
      </Modal>
      {confirmModal && (
        <BootstrapModal
          show={confirmModal}
          centered
          dialogClassName="custom-dialog"
          className="custom-dialog"
        >
          <ConfirmDialog
            title="Apply changes"
            subTitle="Are you sure you want to apply this change?"
            isDeleteDialog={false}
            onCancel={handleCloseConfirmModal}
            onConfirm={handleSubmit}
            loading={editLoading}
          />
        </BootstrapModal>
      )}
    </div>
  );
};
export default PolicyModal;

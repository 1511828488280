import { ReactComponent as GrayBgCalender } from 'assets/icons/background-calender.svg';
import { CancelICon } from 'assets/icons/index';
import classNames from 'classnames';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomSelect from 'components/UI/CustomSelect';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getNumerical, recurrencePaymentOption } from 'utils/helper';
import './styles.scss';
import { toastError } from 'components/UI/toast';
import CustomInput from 'components/UI/CustomInput';

const initailState = {
  value: undefined,
  currency: '',
};

const InputDialog = ({
  onClose,
  loading,
  onSubmit,
  title,
  label,
  defaultValue,
  currency,
}) => {
  const [inputValue, setInputValue] = useState({ ...initailState, currency });

  // handle submit
  const handleSubmit = () => {
    if (inputValue.value === defaultValue) return onClose();

    if (currency)
      return onSubmit({
        amount: getNumerical(inputValue.value),
        currency: inputValue.currency,
      });
    return onSubmit(inputValue.value);
  };
  //

  const onChange = ({ target: { value, rawValue } }) => {
    setInputValue({
      value: value,
      currency,
    });
  };
  return (
    <div>
      <div className="mandate-modal-overlay" onClick={onClose} />
      <div className="mandate-modal-wrapper position-relative">
        <div className="mandate-modal-body invoice payment-schedule">
          <i onClick={onClose} className="cancel-icon">
            <CancelICon width="10" height="10" />
          </i>
          <div className="w-100">
            <div className="mt-0 w-100 mandate-wrapper ">
              <div className="p-4">
                <h4 className="text-start header">{title}</h4>
              </div>

              <div className="payment__schedule-wrapper rounded-0 px-4">
                <div className="form-normal w-100 mx-0 px-0">
                  <div className="mb-2 mx-0 px-0">
                    <CustomInput
                      isBujetiPay={true}
                      label={label}
                      isAmount={!!currency}
                      placeholder="100,000.00"
                      type="text"
                      name="amount"
                      onChange={onChange}
                      value={defaultValue}
                    />
                  </div>
                </div>
              </div>

              <div className="px-4 py-3 d-flex justify-content-end gap-3 ms-auto w-50">
                <button disabled={loading} className="cancel-button " onClick={onClose}>
                  Cancel
                </button>

                <button
                  className="confirm-button"
                  // loading={loading}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputDialog;

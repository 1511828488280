import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.less';
import 'bootstrap/dist/css/bootstrap.min.css';
import './baseStyles.scss';
import configureStore, { history } from './redux/store';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';
import Intercom from '@intercom/messenger-js-sdk';
import { createPortal } from 'react-dom';

if (['production', 'development'].includes(process.env.REACT_APP_ENV)) {
  Sentry.init({
    dsn: 'https://74d029d2c3f942b1a2a9d039a0ce5ce7@o1324358.ingest.sentry.io/6582667',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({ maskAllText: true }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const initialState = {};

export const store = configureStore(initialState);
export const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

const CustomToastPortal = () => {
  const portalTarget = document.getElementById('toast-root') || document.body;

  return createPortal(
    <ToastContainer
      containerId="custom-toast"
      position="bottom-center"
      transition={Slide}
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      limit={2}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />,
    portalTarget,
  );
};

const RootIndex = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <CustomToastPortal />
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

if (process.env.NODE_ENV === 'production') {
  root.render(
    <React.StrictMode>
      <RootIndex />
    </React.StrictMode>,
  );
} else {
  root.render(<RootIndex />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const { InfoCircle02, XcloseIcon } = require('assets/icons');
const { default: MailTo } = require('../MailTo/Mailto');

const Banner = ({ bannerVisible, setBannerVisible, header, type = 'bills', hasCopy }) => {
  const handleClose = () => {
    setBannerVisible(false);
    localStorage.setItem('bannerDismissed' + type, 'true');
  };

  return (
    <>
      {bannerVisible && (
        <div className="banner">
          <div className="d-flex gap-2">
            <span>
              <InfoCircle02 className="mt-1" />
            </span>

            <span className="text">
              {header ||
                'You can also send an email and have it reflect in your drafts. Send to'}
              <span className="ps-1">
                <MailTo type={type} hasCopy={hasCopy} />
              </span>
            </span>
          </div>

          <span className="close-icon" onClick={handleClose}>
            <XcloseIcon width="16" height="16" stroke="#79716B" />
          </span>
        </div>
      )}
    </>
  );
};

export default Banner;

import CardView from 'components/CardView';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import {
  buildCardsTableData,
  buildSpendsTableData,
  capitalizeFirstLetter,
} from 'utils/helper';
import { columnsCards, columnsSpends } from 'utils/mockData';
import {
  CardStatusType,
  CardType,
  CurrencyType,
} from '../../components/FilterModal/FilterHelper';
import { useDebounce } from '../../hooks/useDebounce';
import {
  cancelSubscription,
  deleteSubscription,
  getSpends,
} from '../../redux/actions/SpendAction';
import Loader from './Loader';
import CardsEmptyState from './CardsEmptyState';
import CardsTableModal from './CardsTableModal';
import { useLocation } from 'react-router-dom';
import { getQueryParams, updateStatus } from 'utils/utility';

import { toastError } from 'components/UI/toast';
import {
  CreditCardLock,
  EyeIcon,
  SlashCircleIcon,
  TrashIcon,
  UserIcon02,
} from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import { useHistory } from 'react-router-dom';
import CustomSelect from 'components/UI/CustomSelect';

const SpendTable = ({ toggleHandler }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeView, setActiveView] = useState('list');
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [selectCards, setSelectCards] = useState(null);
  const payerCode = query.get('payer');

  const [filterData, setFilterData] = useState([
    ...CardType,
    ...CardStatusType,
    ...CurrencyType,
  ]);

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({});
  const [search, setSearch] = useState(null);
  const [value, setValue] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [isFillData, setIsFillData] = useState(false);
  const [doNotReload, setDoNotReload] = useState(false);

  const debouncedValue = useDebounce(search, 300);

  const {
    getSpends: { data: spends = [], meta = {}, loading, success },
    cancelSubscription: {
      loading: cancellingSubscription,
      success: subscriptionCancelled,
    },
    deleteSubscription: { loading: deletingSubscription, success: subscriptionDeleted },
  } = useSelector(({ spends }) => spends);

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const { page, total, hasMore, perPage, nextPage } = meta;

  useEffect(() => {
    if (!spends?.length) dispatch(getSpends());
    return () => {
      if (filtered || location?.search) dispatch(getSpends({}));
    };
  }, [filtered, location?.search]);

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(getSpends({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(getSpends({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  useEffect(() => {
    if (location?.search && userData?.user?.code) {
      const status = getQueryParams(location?.search, 'status');
      if (status) {
        dispatch(getSpends({ payer: payerCode }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
  }, [success]);

  useEffect(() => {
    if (subscriptionCancelled || subscriptionDeleted) {
      closeModal();
      dispatch(getSpends());
    }
  }, [subscriptionCancelled, subscriptionDeleted]);

  /*  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(getSpends());
      closeModal();
    }
  }, [, isDeleteSuccess]); */

  const actionHandler = (event, action, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    if (action === 'activate') return handleRowClick(data);
    setActionType(action);
    setSelectedData(data);
    setShowModal(true);
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setShowModal(false);
    setSelectedData(null);
    setActionType(null);
  };

  const handleFilter = (query) => {
    setFilteredQuery(query);
    dispatch(getSpends(query));
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleRowClick = (row) => {
    return history.push(`/cards/subscriptions/${row.code}`);
  };

  const handlePreviousPage = (page) => {
    dispatch(getSpends({ perPage, page, payer: payerCode, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getSpends({ perPage, page, payer: payerCode, ...filteredQuery }));
  };

  useEffect(() => {
    if (!spends.length) dispatch(getSpends());
  }, []);

  const onHandleToggleView = (option) => {
    setActiveView(option);
  };

  const Actions = ({ list }) => {
    return (
      <div className="actions-dialog">
        {['active', 'blocked'].includes(list?.status?.value.toLowerCase()) && (
          <>
            <div className="actionLink" onClick={() => handleRowClick(list)}>
              <EyeIcon width="16" height="16" stroke="#57534E" className="mr-3" />
              View subscription
            </div>
          </>
        )}

        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'cancel', list)}
        >
          <SlashCircleIcon width="16" height="16" stroke="#57534E" className="mr-4" />{' '}
          Cancel subscription
        </div>
        <div
          className="actionLink svg-danger text-danger"
          onClick={(event) => actionHandler(event, 'delete', list)}
        >
          <TrashIcon width="16" height="16" className="mr-4" /> Delete subscription
        </div>
      </div>
    );
  };

  const handleAction = () => {
    if (actionType === 'delete') {
      dispatch(deleteSubscription({ code: selectedData?.code }));
    }
    if (actionType === 'cancel') {
      dispatch(cancelSubscription({ code: selectedData?.code }));
    }
  };

  const handleSelectChange = (val) => setValue(val);

  const handleChange = ({ name, value }) => {
    setHolder({
      [name]: value,
    });
  };

  const handleMore = (perPage) => {
    setTimeout(() => {
      if (!loading) {
        dispatch(
          getMyCardsAction({
            owner: userData?.user?.code,
            payer: payerCode,
            perPage,
            ...filteredQuery,
          }),
        );
        isFiltered.current = true;
      }
    }, 1500);
  };

  const show = !!spends?.length || (filtered && !spends?.length);

  if (!doNotReload && (!userData?.user?.code || (loading && !filtered)))
    return <Loader columns={columnsSpends} data={buildSpendsTableData(spends || [])} />;

  return (
    <div>
      <PendingOnboardingNotice />

      <TopBar
        showBarSearch={show}
        searchVal={search}
        inputPlaceholder="Search by vendor"
        showFilter={show}
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        setSearchVal={setSearch}
        handleFilterApply={handleFilter}
        handleToggleView={onHandleToggleView}
      />
      {spends.length > 0 ? (
        <>
          <Container className="px-0 my-4">
            <Row>
              <Col xs={12}>
                <Table
                  hasCheckBox={false}
                  isCustomWidth={true}
                  stringType={false}
                  columns={columnsSpends}
                  data={buildSpendsTableData(spends)}
                  onRowClick={handleRowClick}
                  pagination
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <div>
          {isFiltered.current ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="You have no subscriptions matching this criteria"
                buttonLabel="Go back"
                bodyText="You currently don't have any subscriptions matching that criteria"
                onClickHandler={() => dispatch(getSpends(query))}
                withButton={true}
              />
            </div>
          ) : (
            <CardsEmptyState toggleHandler={toggleHandler} />
          )}
        </div>
      )}

      <Modal
        show={showModal}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(actionType)} subscription`}
          subTitle={
            <>
              {actionType !== 'reassign' &&
                `Are you sure you want to ${actionType} this subscription?`}
              {actionType === 'delete' && (
                <>
                  <br />
                  This action cannot be undone.
                </>
              )}
            </>
          }
          onConfirm={handleAction}
          loading={false}
          onCancel={closeModal}
          isDeleteDialog={actionType === 'delete'}
        />
      </Modal>
    </div>
  );
};

export default SpendTable;

import { Container } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';
import { ReactComponent as AlertTrangle } from '../../../assets/icons/alert-triangle.svg';
import './index.scss';
const AlertBox = ({ message, header }) => {
  return (
    <>
      <Container className="px-0">
        <div className="budget__balance-notice">
          <div className="alert-triangle-container d-flex align-items-center justify-content-center border rounded-1 p-1 border-orange">
            <AlertTrangle style={{ height: '15px', width: '25px' }} />
          </div>
          <div>
            <h4>{header}</h4>
            {message}
          </div>
        </div>
      </Container>
    </>
  );
};

export default AlertBox;

import React, { useState, useEffect } from 'react';
import './styles.scss';
import { ReactComponent as Confirm } from '../../assets/icons/confirm.svg';
import { XcloseIcon } from 'assets/icons';
import cs from 'classnames';
import PinField from 'react-pin-field';

const InputDialog = ({
  onCancel,
  onConfirm,
  title = 'Type in',
  subTitle = '',
  loading,
  dialogIcon,
  handleChange = null,
  isAmount = null,
  actionBtnText,
  inputLength = 4,
  style,
  label,
  disabled,
  placeholder,
  type = 'text',
  name,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value.length === 4) onConfirm(value);
  }, [value]);

  return (
    <div>
      <div className="confirm-modal-overlay" onClick={onCancel} />
      <div className="confirm-modal-wrapper position-relative">
        <div className="confirm-modal-body p-0" style={{ ...style }}>
          <i onClick={onCancel} className="cancel-icon">
            <XcloseIcon width="20" height="20" />
          </i>
          <div className="pt-4 px-4 pb-5 w-100">
            <span>{dialogIcon ? dialogIcon : <Confirm />}</span>
            <h2 className="text-start mt-2">{title}</h2>
            {subTitle && <p className="text-start">{subTitle}</p>}
            <div className="form-normal d-flex justify-content-between pt-2">
              <PinField
                autoComplete={'one-time-code'}
                className="pin-field "
                dir="ltr"
                pattern="[0-9]"
                type="password"
                length={inputLength}
                onComplete={(value) => setValue(value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InputDialog;

import { all } from 'redux-saga/effects';

import AuthSaga from './AuthSaga';
import BudgetsSaga from './BudgetsSaga';
import VendorsSaga from './VendorsSaga';
import AssetsSaga from './AssetsSaga';
import CardSaga from './CardSaga';
import BeneficiariesSaga from './BeneficiariesSaga';
import TransactionsSaga from './TransactionsSaga';
import ProfileSaga from './ProfileSaga';
import CompaniesSaga from './CompaniesSaga';
import ReimbursementsSaga from './ReimbursementsSaga';
import ToggleSaga from './ToggleSaga';
import ToggleExpenseSaga from './ToggleExpenseSaga';
import PaymentSaga from './PaymentSaga';
import NotificationSaga from './NotificationSaga';
import CategorySaga from './CategorySaga';
import PoliciesSaga from './PoliciesSaga';
import ApprovalSaga from './ApprovalSaga';
import AnalyticsSaga from './AnalyticsSaga';
import TeamsSaga from './TeamsSaga';
import RolesSaga from './RolesSaga';
import FeesSaga from './FeesSaga';
import BillingSaga from './BillingSaga';
import InvoiceSaga from './InvoiceSaga';
import AccountStatementSaga from './AccountStatementSaga';
import SettlementAccountSaga from './SettlementAccountSaga';
import BvnRequestSaga from './BvnRequestSaga';
import IntegrationsSaga from './IntegrationsSaga';
import ReferralsSaga from './ReferralsSaga';
import Reports from './ReportsSaga';
import UtilsSaga from './UtilsSaga';
import Requests from './RequestsSaga';
import MonoConnect from './MonoAccountLinkingSaga';
import MemberProfileSaga from './MemberProfileSaga';
import VendorProfileSaga from './VendorProfileSaga';
import ProgressUploadSaga from './ProgressUploadSaga';
import SpendSaga from './SpendSaga';
import BillSaga from './BillSaga';
import AutomationSaga from './AutomationSaga';
import ApiKeySaga from './ApiKeySaga';

export function* sagas() {
  yield all([
    AuthSaga,
    BudgetsSaga,
    VendorsSaga,
    AssetsSaga,
    CardSaga,
    BeneficiariesSaga,
    TransactionsSaga,
    ProfileSaga,
    CompaniesSaga,
    ReimbursementsSaga,
    ToggleSaga,
    ToggleExpenseSaga,
    PaymentSaga,
    NotificationSaga,
    PoliciesSaga,
    CategorySaga,
    ApprovalSaga,
    AnalyticsSaga,
    TeamsSaga,
    RolesSaga,
    FeesSaga,
    BillingSaga,
    InvoiceSaga,
    AccountStatementSaga,
    SettlementAccountSaga,
    BvnRequestSaga,
    IntegrationsSaga,
    ReferralsSaga,
    Reports,
    UtilsSaga,
    Requests,
    MonoConnect,
    MemberProfileSaga,
    VendorProfileSaga,
    ProgressUploadSaga,
    SpendSaga,
    BillSaga,
    AutomationSaga,
    ApiKeySaga,
  ]);
}

import React from 'react';
import './styles.scss';
import {
  getColor,
  truncateText,
  categoryBadgeMap,
  statusIconAliaser,
  capitalizeFirstLetter,
} from 'utils/helper';
import cs from 'classnames';
import { Badge } from 'react-bootstrap';

const colorAliaser = (status) => {
  if (!status) return;

  const match = categoryBadgeMap.find(({ statuses }) =>
    statuses.some((s) => s.toLowerCase() === status?.toLowerCase()),
  );

  if (!match) {
    const firstLetter = status[0].toUpperCase();
    return (
      categoryBadgeMap.find(({ statuses }) => statuses.includes(firstLetter))?.styles ||
      categoryBadgeMap[categoryBadgeMap.length - 1].styles
    );
  }

  return match.styles;
};

const sortColors = (isStatus, status) => {
  if (isStatus) return getColor(status);
  return colorAliaser(status).color;
};

const BadgeType = ({ value, isBadge = true, margin, concealIcon }) => {
  const status = value?.value?.toLowerCase() || 'uncategorized';
  const color = isBadge ? value?.color || sortColors(value?.isStatus, status) : '#44403C';
  const isUncategorized = !value?.value || status === 'uncategorized';

  return (
    <section>
      <div className="d-flex align-items-center">
        <span
          style={
            !value?.isStatus
              ? {
                  backgroundColor: colorAliaser(status)?.bg ?? color + '10',
                  border: `0.6px ${isUncategorized ? 'dashed' : 'solid'} ${
                    colorAliaser(status)?.border ?? color + '42'
                  }`,
                }
              : {}
          }
          className={cs('badge-wrapper', {
            'mr-3 mb-1': margin,
            'is-status': value?.isStatus,
          })}
        >
          <Badge
            className="text-capitalize badges-inherit"
            style={{ color, padding: '4px 8px' }}
          >
            {value?.custom ? (
              value?.custom
            ) : (
              <>
                {value?.icon}
                {value?.count}

                {value?.isStatus && !concealIcon && (
                  <span style={{ marginRight: 4 }}>
                    {statusIconAliaser(value?.value)}
                  </span>
                )}

                {capitalizeFirstLetter(
                  truncateText(isBadge ? value?.value : value, 0, 25),
                )}

                {value?.rightIcon}
              </>
            )}
          </Badge>
        </span>
        {((typeof value?.number === 'number' && value?.number > 0) ||
          (typeof value?.number !== 'number' && value?.number)) && (
          <span className="ps-2 silver-color">{value.number}</span>
        )}
      </div>

      {/* Subtext Here */}
      {value?.subText && (
        <span
          className="pt-2"
          style={{ fontSize: 12, color: '#79716B', position: 'relative', top: 2 }}
        >
          {value?.subText}
        </span>
      )}
    </section>
  );
};
export default BadgeType;

import Intercom from '@intercom/messenger-js-sdk';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { get_user_profile } from 'redux/actions/AuthAction';
import { allPermissions } from 'utils/AllowedTo';
import { hiddenChatRoutes, retrieveAndDecryptFromLocalStorage } from 'utils/utility';

const DefaultAPI = () => {
  const token = retrieveAndDecryptFromLocalStorage('app-session');
  const tokenExp = parseInt(retrieveAndDecryptFromLocalStorage('exp-app-session'));
  const { pathname } = useLocation();
  const userName = localStorage.getItem('user');

  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);
  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  // Stack tracing with additional information

  if (process.env.NODE_ENV === 'production') {
    const userFeedback = {
      name: `${data?.user?.firstName} ${data?.user?.lastName}` || '-',
      id: data?.user?.code || '-',
      company: data?.user?.company?.name || '-',
      email: data?.user?.email || '-',
    };
    Sentry.setUser(userFeedback);

    if (!hiddenChatRoutes.includes(pathname)) {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: data?.user?.code ?? '-',
        name: userName ?? 'There',
        email: data?.user?.email ?? '-',
      });
    }
  }

  // end stack tracing

  const { isAdmin, onboardingStatus } = allPermissions();

  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    if (token && tokenExp) {
      dispatch(get_user_profile());
    }
  }, [token, tokenExp]);

  useEffect(() => {
    if (companyData?.status) {
      if (onboardingStatus === 'approved') {
        localStorage.setItem('onboardingAlert', false && isAdmin);
      } else {
        localStorage.setItem('onboardingAlert', true && isAdmin);
      }

      localStorage.setItem('completedBusinessInfo', !!companyData?.address && isAdmin);
    }
  }, [companyData?.status]);
  return <></>;
};
export default DefaultAPI;

/**
 *
 * @param {function} handleClose: a callback function to close the drawer
 * @param {boolean} status: the status of the transaction
 * @returns
 */

import { ArrowDownIcon, PencilIcon } from 'assets/icons';
import ImgCard from 'components/UI/ImgCard';
import BadgeType from 'components/UI/Table/BadgeType';
import { Link, useHistory } from 'react-router-dom';
import { FormattedCurrency, getColor, getCurrency, STATUS } from 'utils/helper';
import './index.scss';
import { scrollHook } from './scroll-hook';
import { ReactComponent as Edit } from 'assets/icons/Edit.svg';

const PaymentHeader = ({
  status,
  receipient,
  moneyData,
  badgeIcon = null,
  recipientLink = null,
  fontSize,
  iconSize,
  scrollSize,
  imageIcon: ImageIcon = ArrowDownIcon,
  isRequest,
  createdByMe = false,
  onClick,
}) => {
  const history = useHistory();

  return (
    <div className="payment-header">
      <BadgeType
        value={{
          value: status,
          color: getColor(status),
          icon: badgeIcon,
          isStatus: true,
        }}
      />

      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginTop: '1.5px' }}
      >
        <div className="d-flex align-items-center gap-2">
          <div
            className={`amount-value m-0 p-0 ${
              status === STATUS.PENDING && createdByMe ? 'cursor parent-hover' : ''
            }`}
            style={{ fontSize }}
            onClick={createdByMe ? onClick : null}
          >
            <FormattedCurrency
              className="details-amount font-semibold"
              value={moneyData?.amount}
              prefix={getCurrency(moneyData?.currency)}
            />
            {status === STATUS.PENDING && createdByMe && (
              <span className="icon-hover" style={{ color: '#D28B28', fontSize: 14 }}>
                <PencilIcon stroke="#D28B28" width="14" height="14" /> Edit
              </span>
            )}
          </div>
          {scrollSize > 95 && (
            <div
              className="d-flex align-items-center text-sm text-grey gap-2 receiver"
              style={{ opacity: scrollSize > 95 ? 1 : 0 }}
            >
              <p className="m-0">{isRequest ? 'Requested by' : 'To'}</p>
              <div
                className="d-flex cursor"
                onClick={recipientLink ? () => history.push(recipientLink) : null}
              >
                <ImgCard initials={receipient && receipient[0].trim()} />
              </div>
              {recipientLink ? (
                <Link to={recipientLink} target="_blank" className="recipient-link">
                  <span>{receipient}</span>
                </Link>
              ) : (
                <span>{receipient}</span>
              )}
            </div>
          )}
        </div>

        <div
          className="transfer-in-icon lg"
          style={{
            height: `${iconSize}rem`,
            width: `${iconSize}rem`,
            borderRadius: `${iconSize / 2.6}rem`,
          }}
        >
          <ImageIcon height={25} width={25} />
        </div>
      </div>
      <div
        className="d-flex align-items-center text-sm text-grey gap-2 receiver"
        style={{
          translate: `${Math.min(scrollSize, 35)}% -${scrollSize}%`, // Updated line
          opacity: scrollSize > 95 ? 0 : 1 - (scrollSize / 100) * 1,
          display: scrollSize > 95 ? 'none' : 'flex',
          transitionDuration: '300',
          transition: 'ease-in-out',
        }}
      >
        <p className="m-0">{isRequest ? 'Requested by' : 'To'}</p>
        <div
          className="d-flex cursor"
          onClick={recipientLink ? () => history.push(recipientLink) : null}
        >
          <ImgCard initials={receipient && receipient[0].trim()} />
        </div>
        {recipientLink ? (
          <Link to={recipientLink} target="_blank" className="recipient-link">
            <span>{receipient}</span>
          </Link>
        ) : (
          <span>{receipient}</span>
        )}
      </div>
    </div>
  );
};

export default PaymentHeader;

import { useHistory } from 'react-router-dom';

import EmptyState from 'components/UI/EmptyState';
import CustomButton from 'pages/Profile/Components/CustomButton';

import '../index.scss';
import { File06 } from 'assets/icons';
import { Upload, Send, File } from 'assets/icons/empty-state-icons';
import BillsImage from '../../../assets/images/emptystate/bills.svg';
import MailTo from '../../../components/UI/MailTo/Mailto';

const BillsEmptyState = () => {
  const history = useHistory();

  const routeTo = () => {
    const url = process.env.REACT_APP_BILLS_DEMO_URL;
    if (url) {
      return window.open(url, '_blank');
    }
  };

  const mainData = {
    title: 'Review and manage your bills with ease.',
    body: (
      <div style={{ width: '85%' }}>
        {/* Manage your bills, upload or send your details to our email at */}
        Manage your bills, track and pay your debts from one place.
        {/* <span className="ps-1">
          <MailTo />
        </span> */}
        See how it works
        <span onClick={routeTo} className="inner-link">
          {' '}
          here
        </span>
        .
      </div>
    ),

    image: BillsImage,
    action: () => (
      <CustomButton
        className={'dark-button d-flex align-items-center gap-2 w-auto'}
        style={{ padding: '10px 16px', borderRadius: 8 }}
        onClick={() => history.push('bills/create')}
      >
        <File06 />
        <span className="ms-1" style={{ fontWeight: 500 }}>
          Pay a bill
        </span>
      </CustomButton>
    ),
  };

  const childrenData = [
    {
      title: 'Upload your invoice',
      body: 'Upload your invoice and validate your details before submission',
      actionTitle: 'View more',
      icon: <Upload />,
    },

    {
      title: 'Forward your invoice to our mail',
      body: 'Copy our email and send your invoice. It will show up in your dratfs',
      actionTitle: 'View more',
      icon: <Send />,
    },
    {
      title: 'Track your expenses',
      body: 'Adequately track and manage your bills across categories ',
      actionTitle: 'View more',
      icon: <File />,
    },
  ];

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default BillsEmptyState;

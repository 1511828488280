import AssetViewer from 'components/AssetViewer';
import ConfirmDialog from 'components/ConfirmDialog';
import { PolicyStatusType } from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import ExpensesEmptyStateData from 'pages/Expenses/expensesEmptystateData';
import EditPolicy from 'pages/Policy/EditPolicy';
import ViewPolicy from 'pages/Policy/ViewPolicy';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { downloadAsset } from 'redux/actions/AssetsAction';
import {
  deletePolicy,
  deletePolicyDocument,
  editPolicy,
  fetchSinglePolicy,
  getPolicies,
} from 'redux/actions/PoliciesAction';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';
import {
  RESET_BLOCK_POLICIES,
  RESET_FLAGS_POLICIES,
} from 'redux/reducers/PoliciesReducer';
import { getDateRangeFromTitle } from 'utils';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { buildPolicyTableData, capitalizeFirstLetter } from 'utils/helper';
import { policyColumns } from 'utils/mockData';
import SearchLoader from './Components/SearchLoader';

const CategoryPolicies = ({
  setIsOpen,
  isOpen,
  handleSelect,
  queryParams,
  tabKey,
  categoryData,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { permissions } = allPermissions();
  const { policyCode } = useParams();
  const canCreate = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-create'],
  });
  const canView = hasPermission({
    permissions,
    scopes: ['policy-view'],
  });
  const canEdit = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-edit'],
  });
  const canDelete = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-delete'],
  });
  const [filterData, setFilterData] = useState([...PolicyStatusType]);
  const [data, setData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [actionType, setActionType] = useState('');
  const [asset, setAsset] = useState(null);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const [filteredQuery, setFilteredQuery] = useState({ ...queryParams });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [assetViewer, setAssetViewer] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const {
    getPolicies: { data: { policies = [], meta = {} } = {}, loading, success },
    deletePolicy: { loading: deleteLoading, success: deleteSuccess },
    policyAssets: { data: policyAssets },
    deletePolicyDocument: {
      loading: deleteDocumentLoading,
      success: deleteDocumentSuccess,
    },
    editPolicy: { loading: editLoading, success: editSuccess },
    addSinglePolicyDocument: { success: addSingleDocumentSuccess },
  } = useSelector(({ policies }) => policies);

  const { page = 1, total, hasMore = false, perPage = 50, nextPage = 2 } = meta;

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    // uploadProgress,
    downloadAsset: { success: downloadSuccess, data: downloadData },
  } = useSelector(({ assets }) => assets);

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (policyCode) {
      dispatch(fetchSinglePolicy(policyCode));
      setShowViewModal(true);
    }
  }, [policyCode]);

  useEffect(() => {
    if (addSingleDocumentSuccess) {
      dispatch(getPolicies({ status: 'active', ...filteredQuery }));
      dispatch({
        type: RESET_FLAGS_POLICIES,
        blockType: 'addSinglePolicyDocument',
      });
    }
    if (downloadSuccess) {
      handleSingle();
    }
  }, [addSingleDocumentSuccess, downloadSuccess]);

  const handleSingle = async () => {
    try {
      const response = await fetch(`${downloadData?.asset?.url}`, { cache: 'no-store' });
      const blobUrl = URL.createObjectURL(await response.blob());

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = downloadData?.asset?.name;
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (policyAssets) {
      setAssetViewer(true);
      setSelectedAsset(policyAssets[0]);
    }
  }, [policyAssets]);

  useEffect(() => {
    if (editSuccess) {
      dispatch(getPolicies({ status: 'active', ...filteredQuery }));
      setShowEditModal(false);
      setData(null);
      setOpenModal(false);
      dispatch({
        type: RESET_FLAGS_POLICIES,
        blockType: 'editPolicy',
      });
    }
  }, [editSuccess, success]);

  useEffect(() => {
    if (canView && tabKey === 'policies')
      dispatch(getPolicies({ status: 'active', ...filteredQuery }));

    return () => {
      if (filtered)
        if (canView) {
          dispatch(getPolicies({ status: 'active', ...filteredQuery }));
        }
    };
  }, [filtered]);

  const handleFilter = (query) => {
    dispatch(getPolicies({ status: 'active', ...filteredQuery, ...query }));
    setFilteredQuery({ ...filteredQuery, ...query });
    isFiltered.current = !!Object.keys(query).length;
  };

  const clearFilters = () => {
    setFilteredQuery({ ...queryParams });
    setFilterData([...PolicyStatusType]);
  };

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue && canView) {
      filteredQuery.search = debouncedValue;
      dispatch(
        getPolicies({ status: 'active', ...filteredQuery, search: debouncedValue }),
      );
    }
    if (!debouncedValue && filtered && canView) {
      delete filteredQuery.search;
      dispatch(getPolicies({ status: 'active', ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleRowClick = ({ policyData }) => {
    if (['archived', 'deleted'].includes(policyData.status)) {
      dispatch(fetchSinglePolicy(policyData.code));
      setShowViewModal(true);
    } else {
      handleEditClick({ policyData });
    }
  };

  const handleEditClick = ({ policyData }) => {
    dispatch(fetchSinglePolicy(policyData.code));
    setShowEditModal(true);
  };

  useEffect(() => {
    if (deleteSuccess) {
      setData(null);
      setShowDeleteModal(false);
      dispatch(getPolicies({ status: 'active', ...filteredQuery }));
      dispatch({
        type: RESET_FLAGS_POLICIES,
        blockType: 'deletePolicy',
      });
    }
    if (deleteDocumentSuccess) {
      if (asset?.property !== null) handleRemove(asset.property);
      setAsset(null);
      setShowDeleteModal(false);
      dispatch({
        type: RESET_FLAGS_POLICIES,
        blockType: 'deletePolicyDocument',
      });
    }
  }, [deleteSuccess, deleteDocumentSuccess]);

  const toggleAssetViewer = () => {
    setAssetViewer(!assetViewer);
  };

  const handleDeleteClick = () => {
    if (deleteType === 'policy') dispatch(deletePolicy(data));
    else if (deleteType === 'document') dispatch(deletePolicyDocument(asset));
  };

  const handleDownload = (assetCode) => {
    dispatch(downloadAsset(assetCode));
  };

  const handleActionClick = () => {
    if (actionType === 'disable')
      dispatch(editPolicy({ code: data.code, status: 'inactive' }));
    if (actionType === 'enable')
      dispatch(editPolicy({ code: data.code, status: 'active' }));
    else if (actionType === 'archive')
      dispatch(editPolicy({ code: data.code, status: 'archived' }));
  };

  const handleOpenModal = (actionType, data) => {
    setActionType(actionType);
    setData(data.policyData);
    setOpenModal(true);
  };

  const handleDeleteModal = (deleteType, data) => {
    setDeleteType(deleteType);
    if (deleteType === 'policy') setData(data.policyData);
    else if (deleteType === 'document') setAsset(data);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    if (deleteType === 'policy') setData(null);
    if (deleteType === 'document') setAsset(null);
    setDeleteType('');
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    setData(null);
    setActionType('');
    setOpenModal(false);
  };

  const handleDuplicateModal = ({ policyData }) => {
    dispatch(fetchSinglePolicy(policyData.code));
    history.push(`/compliances/categories/${queryParams?.category}/policies/create`);
  };

  const handlePreviousPage = (page) => {
    dispatch(getPolicies({ perPage, page, status: 'active', ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getPolicies({ perPage, page, status: 'active', ...filteredQuery }));
  };

  const actionHandler = (event, type, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (type === 'edit') handleEditClick(data);
    if (type === 'delete') handleDeleteModal('policy', data);
    if (type === 'duplicate') handleDuplicateModal(data);
    if (type === 'disable') handleOpenModal(type, data);
    if (type === 'enable') handleOpenModal(type, data);
    if (type === 'archive') handleOpenModal(type, data);
    setIsPopoverOpen(true);
  };

  const Actions = ({ list: selectedData }) => {
    const {
      policyData: { status },
    } = selectedData;
    return (
      <div className="policy-actions actions-dialog">
        {canEdit && (
          <>
            {!['deleted', 'archived'].includes(status) && (
              <div
                className="actionLink"
                onClick={(event) => actionHandler(event, 'edit', selectedData)}
              >
                Edit
              </div>
            )}

            {canCreate && (
              <div
                className="actionLink"
                onClick={(event) => actionHandler(event, 'duplicate', selectedData)}
              >
                Duplicate
              </div>
            )}

            {canEdit && !['deleted', 'archived'].includes(status) && (
              <div
                className="actionLink"
                onClick={(event) =>
                  actionHandler(
                    event,
                    `${
                      selectedData?.policyData?.status === 'active' ? 'disable' : 'enable'
                    }`,
                    selectedData,
                  )
                }
              >
                {selectedData?.policyData?.status === 'active' ? 'Disable' : 'Enable'}
              </div>
            )}

            {canEdit && !['deleted', 'archived'].includes(status) && (
              <div
                className="actionLink"
                onClick={(event) => actionHandler(event, 'archive', selectedData)}
              >
                Archive
              </div>
            )}
          </>
        )}

        {canDelete && status !== 'deleted' && (
          <div
            className="actionLink svg-danger text-danger"
            onClick={(event) => actionHandler(event, 'delete', selectedData)}
          >
            Delete
          </div>
        )}
      </div>
    );
  };

  const policyList = () => {
    return (
      <>
        {!policies.length && !loading ? (
          <div>
            {filtered ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no policies for this filter"
                  bodyText="Alter filter to see policies."
                  withButton={true}
                  buttonLabel="View all policies"
                  onClickHandler={() => {
                    clearFilters();
                    dispatch(getPolicies({ ...queryParams }));
                  }}
                />
              </div>
            ) : (
              <ExpensesEmptyStateData
                openVendorModal={toggleHandler}
                openPolicyModal={() =>
                  history.push({
                    pathname: `/compliances/categories/${queryParams?.category}/policies/create`,
                    state: {
                      trigger: 'category',
                      operator: 'is',
                      operands: [
                        {
                          category: {
                            name: categoryData?.name,
                            code: categoryData?.code,
                            isFixed: true,
                          },
                        },
                      ],
                    },
                  })
                }
                handleSelect={handleSelect}
                showAction={canCreate}
              />
            )}
          </div>
        ) : (
          <Container className="px-0">
            <Row className="py-2">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={policyColumns}
                  data={buildPolicyTableData(policies, canCreate)}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  onRowClick={handleRowClick}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  type="budgets"
                  modify={canEdit || canDelete || canCreate}
                  loading={loading}
                  hasAvatar={false}
                />
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  };

  const show = !!policies?.length || (filtered && !policies?.length);

  return (
    <div className="budgets-wrapper position-relative mb-5">
      <Container className="policy-page">
        {loading && !filtered ? (
          <SearchLoader />
        ) : (
          <TopBar
            showBarSearch={show}
            searchVal={search}
            setSearchVal={setSearch}
            withOutSearch
            inputPlaceholder="search for policies"
            filterData={filterData}
            handleFilterSelect={(updateVal) => {
              setFilterData(updateVal);
            }}
            withDate
            exportLoading={true}
            showFilter={show}
            clearFilters={clearFilters}
            handleFilterApply={handleFilter}
          />
        )}

        {policyList()}
      </Container>

      {showEditModal && <EditPolicy setShowEditModal={setShowEditModal} />}

      {showViewModal && (
        <ViewPolicy setShowViewModal={setShowViewModal} handleDownload={handleDownload} />
      )}

      <Modal
        show={showDeleteModal}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`Delete ${deleteType === 'document' ? 'policy document' : 'policy'}`}
          subTitle={`Are you sure you want to delete this ${
            deleteType === 'document' ? 'document' : 'policy'
          }? This action cannot be undone.`}
          onConfirm={handleDeleteClick}
          loading={deleteDocumentLoading || deleteLoading}
          onCancel={handleCloseDeleteModal}
        />
      </Modal>

      <Modal
        show={openModal}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(actionType)} policy`}
          subTitle={`Are you sure you want to ${actionType} this policy?`}
          onConfirm={handleActionClick}
          loading={editLoading}
          onCancel={handleCloseModal}
          isDeleteDialog={actionType !== 'enable'}
          actionBtnText={`${capitalizeFirstLetter(actionType)}`}
        />
      </Modal>

      <Modal
        show={assetViewer}
        scrollable={true}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
        contentClassName="custom-content"
        onHide={() => {
          dispatch({ type: RESET_BLOCK_POLICIES, blockType: 'policyAssets' });
          setSelectedAsset(null);
          setAssetViewer(false);
        }}
      >
        <AssetViewer
          toggleVisibility={toggleAssetViewer}
          data={policyAssets}
          selectedAsset={selectedAsset}
          singleData={policyAssets?.[0]}
        />
      </Modal>
    </div>
  );
};

export default CategoryPolicies;

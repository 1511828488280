export const StatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'invited',
        label: 'Invited',
        isSelected: false,
      },
      {
        value: 'inactive',
        label: 'Disabled',
        isSelected: false,
      },
    ],
  },
];

export const RolesType = [
  {
    title: 'Role',
    list: [
      {
        value: 'admin',
        label: 'Admin',
        isSelected: false,
      },
      // {
      //   value: 'manager',
      //   label: 'Manager',
      //   isSelected: false,
      // },
      {
        value: 'employee',
        label: 'Employee',
        isSelected: false,
      },
    ],
  },
];

export const SubAccountRoleType = [
  {
    title: 'Role',
    list: [
      {
        value: 'Owner',
        label: 'Owner',
        isSelected: false,
      },
      {
        value: 'Manager',
        label: 'Manager',
        isSelected: false,
      },
    ],
  },
];

export const CurrencyType = [
  {
    title: 'Currency',
    list: [
      {
        value: 'NGN',
        label: 'Naira (₦)',
        isSelected: false,
        disabled: false,
      },
      {
        value: 'USD',
        label: 'US Dollar ($)',
        isSelected: false,
        disabled: false,
      },
      // {
      //   value: 'EUR',
      //   label: 'Euro (€)',
      //   isSelected: false,
      // },
    ],
  },
];

export const CardType = [
  {
    title: 'Type',
    list: [
      {
        value: 'Virtual',
        label: 'Virtual',
        isSelected: false,
      },
      {
        value: 'flash',
        label: 'Flash',
        isSelected: false,
      },
      {
        value: 'Physical',
        label: 'Physical',
        isSelected: false,
      },
    ],
  },
];

export const CardStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'pending',
        label: 'Pending',
        isSelected: false,
      },
      {
        value: 'processing',
        label: 'Processing',
        isSelected: false,
      },
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'inactive',
        label: 'Disabled',
        isSelected: false,
      },
      {
        value: 'frozen',
        label: 'Disabled',
        isSelected: false,
      },
    ],
  },
];

export const TransactionStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'processing',
        label: 'Processing',
        isSelected: false,
      },
      {
        value: 'pending',
        label: 'Pending approval',
        isSelected: false,
      },
      {
        value: 'success',
        label: 'Success',
        isSelected: false,
      },
      {
        value: 'approved',
        label: 'Approved',
        isSelected: false,
      },
      {
        value: 'scheduled',
        label: 'Scheduled',
        isSelected: false,
      },
      {
        value: 'failed',
        label: 'Failed',
        isSelected: false,
      },
    ],
  },
];

export const SubAccountDirection = [
  {
    title: 'Direction',
    type: 'radio',
    list: [
      {
        value: 'out',
        label: 'Payout',
        isSelected: false,
      },
      {
        value: 'in',
        label: 'Payin',
        isSelected: false,
      },
    ],
  },
];

export const ScheduledTransactionStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'completed',
        label: 'Completed',
        isSelected: false,
      },
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'pause',
        label: 'Pause',
        isSelected: false,
      },
      {
        value: 'cancelled',
        label: 'Cancelled',
        isSelected: false,
      },
    ],
  },
];

export const ReimbursementStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'reviewer',
        label: 'Need my review',
        isSelected: true,
      },
      {
        value: 'pending',
        label: 'Pending',
        isSelected: true,
      },
      {
        value: 'approved',
        label: 'Approved',
        isSelected: false,
      },
      {
        value: 'paid',
        label: 'Paid',
        isSelected: false,
      },
      {
        value: 'deleted',
        label: 'Deleted',
        isSelected: false,
      },
      {
        value: 'declined',
        label: 'Declined',
        isSelected: false,
      },
    ],
  },
];

export const BudgetsStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'inactive',
        label: 'Inactive',
        isSelected: false,
      },
      // {
      //   value: 'deleted',
      //   label: 'Deleted',
      //   isSelected: false,
      // },
      // {
      //   value: 'low',
      //   label: 'Low',
      //   isSelected: false,
      // },
    ],
  },
];

export const PolicyStatusType = [
  {
    title: 'Status',
    type: 'radio',
    list: [
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'inactive',
        label: 'Inactive',
        isSelected: false,
      },
      {
        value: 'archived',
        label: 'Archived',
        isSelected: false,
      },
      // {
      //   value: 'deleted',
      //   label: 'Deleted',
      //   isSelected: false,
      // },
      // {
      //   value: 'low',
      //   label: 'Low',
      //   isSelected: false,
      // },
    ],
  },
];

export const CategorizationStatusType = [
  {
    title: 'Status',
    type: 'radio',
    list: [
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'inactive',
        label: 'Inactive',
        isSelected: false,
      },
    ],
  },
];

export const VendorsStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'inactive',
        label: 'Inactive',
        isSelected: false,
      },
      {
        value: 'blocked',
        label: 'Blocked',
        isSelected: false,
      },
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'invited',
        label: 'Invited',
        isSelected: false,
      },
      {
        value: 'deleted',
        label: 'Deleted',
        isSelected: false,
      },
    ],
  },
];

export const activeFilterCount = (filters) => {
  if (!filters || !filters.list) return 0;
  return filters.filter((element) => element.list.some(({ isSelected }) => isSelected))
    .length;
};

export const ApprovalRequestStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'pending',
        label: 'Pending',
        isSelected: true,
      },
      {
        value: 'approved',
        label: 'Approved',
        isSelected: false,
      },
      {
        value: 'declined',
        label: 'Declined',
        isSelected: false,
      },
    ],
  },
];

export const ScheduledInvoiceStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },
      {
        value: 'completed',
        label: 'Completed',
        isSelected: false,
      },
      {
        value: 'draft',
        label: 'Draft',
        isSelected: false,
      },
    ],
  },
];

export const InvoiceStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'pending',
        label: 'Pending',
        isSelected: false,
      },
      {
        value: 'paid',
        label: 'Paid',
        isSelected: false,
      },

      {
        value: 'active',
        label: 'Scheduled',
        isSelected: false,
      },

      {
        value: 'declined',
        label: 'Declined',
        isSelected: false,
      },

      {
        value: 'draft',
        label: 'Draft',
        isSelected: false,
      },

      {
        value: 'overdue',
        label: 'Overdue',
        isSelected: false,
      },

      {
        value: 'verifying',
        label: 'In review',
        isSelected: false,
      },
    ],
  },
];

export const customerStatusType = [
  {
    title: 'Status',
    list: [
      {
        value: 'active',
        label: 'Active',
        isSelected: false,
      },

      {
        value: 'deleted',
        label: 'Archived',
        isSelected: false,
      },
    ],
  },

  {
    title: 'Type',
    list: [
      {
        value: 'individual',
        label: 'Individual',
        isSelected: false,
      },

      {
        value: 'business',
        label: 'Business',
        isSelected: false,
      },
    ],
  },

  {
    title: 'Invoice status',
    list: [
      {
        value: 'unpaid',
        label: 'Unpaid invoices',
        isSelected: false,
      },
    ],
  },
];

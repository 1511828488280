import { ReactComponent as Trash } from 'assets/icons/trashcan.svg';
import AssetViewer from 'components/AssetViewer';
import CustomDrawer from 'components/UI/CustomDrawer';
import DrawerHeader from 'components/UI/CustomDrawer/components/DrawerHeader';
import Loading from 'components/UI/Loading';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  deleteReimbursements,
  getReimbursements,
  getSingleReimbursement,
} from 'redux/actions/ReimbursementsAction';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';
import DeclineReimbursement from './DeclineReimbursement';
import ReimbursementDetails from './ReimbursementDetails';
import DeleteDialog from 'components/DeleteDialog';
import { XcloseIcon } from 'assets/icons';
import { PaymentSkeleton } from 'components/UI/CustomDrawer/components/payment-skeleton';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import classNames from 'classnames';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import { STATUS } from 'utils/helper';

const ReimbursementModal = ({
  selectReimbursement,
  setSelectReimbursement,
  setAssetData,
  setSelectedAsset,
}) => {
  if (!selectReimbursement) return;
  const [step, setStep] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const { reimbursementCode } = useParams();
  const history = useHistory();

  const [assetViewer, setAssetViewer] = useState(false);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const parentRef = useRef(null);

  const toggleAssetViewer = () => setAssetViewer(!assetViewer);
  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const {
    reimbursementsData: {
      code,
      status,
      approvalRequest = {},
      user: reimbursementUser,
    } = {},
  } = selectReimbursement || {};

  const createByMe = user?.code === reimbursementUser?.code;

  const {
    approveReimbursement: {
      loading: loadingApprove,
      success: successApprove,
      error: errorApprove,
    },
    declineReimbursement: {
      loading: loadingDecline,
      success: successDecline,
      error: errorDecline,
    },
    deleteReimbursement: { loading: loadingDelete, success: successDelete },
    getSingleReimbursement: {
      data: singleData = {},
      loading: singleLoading,
      success: successData,
    },
  } = useSelector(({ reimbursement }) => reimbursement);
  const {
    reviewRequest: { loading: loadingApproval, success: successApproval },
  } = useSelector(({ approval }) => approval);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
  } = useSelector(({ payments }) => payments);

  const { approvals: completedApprovals = [] } = approvalRequest || {};

  useEffect(() => {
    if (!singleLoading && selectReimbursement?.reimbursementsData?.code) {
      dispatch(getSingleReimbursement(selectReimbursement?.reimbursementsData?.code));
    }
  }, [selectReimbursement]);

  useEffect(() => {
    if (successData && ['approved'].includes(singleData?.data?.reimbursement?.status))
      setCanMakePayment(true);
  }, [successData, singleData]);

  const toggleHandler = () => {
    setStep(step - 1);
  };
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const handleAssetViewer = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const setReceiptList = (list) => {
    setAssetData && setAssetData(list);
  };

  const handleClose = () => {
    setSelectedAsset(null);
    setAssetData && setAssetData(null);
    dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'reimbursementReceipts' });
    if (reimbursementCode) history.push('/requests/reimbursements');
    if (isLoaded) {
      dispatch(getReimbursements());
    }
    if (step === 2 && !isLoaded) {
      toggleHandler();
    } else {
      setSelectReimbursement(null);
      setStep(1);
      setAssetViewer(false);
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'getSingleReimbursement' });
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'approveReimbursement' });
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'declineReimbursement' });
    }
    toggleAssetViewer(false);
    setIsLoaded(false);
  };

  const paymentSuccess = !loadingPayNow && successPayNow;

  useEffect(() => {
    if (paymentSuccess) {
      setIsLoaded(true);
      handleClose();
      dispatch(getReimbursements());
    }
  }, [successPayNow, loadingPayNow]);

  useEffect(() => {
    if (successDelete) {
      handleClose();
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'deleteReimbursement' });
      dispatch(getReimbursements());
    }
  }, [successDelete]);

  const approveSuccess = !loadingApprove && successApprove;
  const declineSuccess = !loadingDecline && successDecline;

  const approveError = !loadingApprove && !!errorApprove;
  const declineError = !loadingDecline && errorDecline;

  useEffect(() => {
    if (approveSuccess || declineSuccess || successApproval) {
      setIsLoaded(true);
      dispatch(getSingleReimbursement(selectReimbursement?.reimbursementsData?.code));
    }
    if (approveError || declineError) {
      setIsLoaded(false);
      setSelectReimbursement(selectReimbursement);
    }
  }, [
    loadingApprove,
    successApprove,
    errorApprove,
    loadingDecline,
    successDecline,
    errorDecline,
    successApproval,
  ]);

  if (!selectReimbursement) return <div />;

  const renderCard = () => {
    switch (step) {
      case 1:
        return (
          <ReimbursementDetails
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            setAssetViewer={handleAssetViewer}
            selectReimbursement={selectReimbursement}
            handleClose={handleClose}
            singleData={singleData?.data}
            getReceiptList={setReceiptList}
            canMakePayment={canMakePayment}
            setCanMakePayment={setCanMakePayment}
            parentRef={parentRef}
          />
        );
      case 2:
        return (
          <DeclineReimbursement
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            selectReimbursement={{
              ...selectReimbursement,
              reimbursementsData: singleData?.data?.reimbursement,
            }}
            setSelectReimbursement={setSelectReimbursement}
            hideTitle
          />
        );
      default:
        return;
    }
  };

  const attemptDelete = () => {
    setDeleting(!deleting);
  };

  const handleDelete = () => {
    dispatch(deleteReimbursements(code));
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          onClick={() => {
            handleTogglePopover();
            attemptDelete();
          }}
          className="actionLink"
        >
          <Trash className="mr-4" /> <span>Delete request</span>
        </div>
      </div>
    );
  };

  const loadingState =
    singleLoading || loadingApproval || loadingApprove || !singleData?.data;

  // This is for the asset viewer to handle multple asset download

  return (
    <>
      <ModalContainerWrap
        parentRef={parentRef}
        isWhite={step === 2}
        modalName={step === 2 ? 'Decline reimbursement' : 'Reimbursement details'}
        onClose={handleClose}
        action={step !== 2 && status !== STATUS.DELETED && createByMe && <Actions />}
        isPopoverOpen={isPopoverOpen}
        handleTogglePopover={handleTogglePopover}
        loader={<PaymentSkeleton />}
        loading={loadingState}
        goBack={step === 2 && !isLoaded ? () => setStep(1) : null}
      >
        {renderCard()}
      </ModalContainerWrap>

      {deleting && (
        <DeleteDialog
          title={`Delete reqeust`}
          subTitle="Are you sure you want to delete this request? This action cannot be undone"
          onCancel={() => setDeleting(false)}
          onDelete={handleDelete}
          styles={{ width: 400 }}
          isLoading={loadingDelete}
        />
      )}
    </>
  );
};

export default ReimbursementModal;

export const transactionTypeObj = {
  type: {
    value: 'type',
    label: 'Type',
    secondOption: [
      { value: 'is', label: 'is exactly' },
      { value: 'not:is', label: 'is not' },
    ],
    thirdOption: [
      { value: 'all', label: 'All' },
      { value: 'payment', label: 'Payment' },
      { value: 'reimbursement', label: 'Reimbursement' },
    ],
  },
  amount: {
    value: 'amount',
    label: 'Amount',
    secondOption: [
      { value: 'eq', label: 'is equal' },
      { value: 'gt', label: 'is greater than' },
      { value: 'lt', label: 'is lower than' },
      { value: 'gte', label: 'is equal or greater than' },
      { value: 'lte', label: 'is equal or lower than' },
    ],
  },
  account: {
    value: 'account',
    label: 'Account',
    secondOption: [
      { value: 'is', label: 'is exactly' },
      { value: 'not:is', label: 'is not' },
      { value: 'any', label: 'is any of' },
      { value: 'not:any', label: 'is none of' },
    ],
  },
  budget: {
    value: 'budget',
    label: 'Budget',
    secondOption: [
      { value: 'is', label: 'is exactly' },
      { value: 'not:is', label: 'is not' },
      { value: 'any', label: 'is any of' },
      { value: 'not:any', label: 'is none of' },
    ],
  },
  category: {
    value: 'category',
    label: 'Category',
    secondOption: [
      { value: 'is', label: 'is exactly' },
      { value: 'not:is', label: 'is not' },
      { value: 'any', label: 'is any of' },
      { value: 'not:any', label: 'is none of' },
    ],
  },
  vendor: {
    value: 'vendor',
    label: 'Vendor',
    secondOption: [
      { value: 'is', label: 'is exactly' },
      { value: 'not:is', label: 'is not' },
      { value: 'any', label: 'is any of' },
      { value: 'not:any', label: 'is none of' },
    ],
  },
  team: {
    value: 'team',
    label: 'Team',
    secondOption: [
      { value: 'is', label: 'is exactly' },
      { value: 'not:is', label: 'is not' },
      { value: 'any', label: 'is any of' },
      { value: 'not:any', label: 'is none of' },
    ],
  },
  invoice: {
    value: 'invoice',
    label: 'Invoice',
    secondOption: [
      { value: 'is', label: 'is exactly' },
      { value: 'not:is', label: 'is not' },
      { value: 'any', label: 'is any of' },
      { value: 'not:any', label: 'is none of' },
    ],
  },
};

export const categoryOptions = [
  {
    value: 'type',
    label: 'Type',
  },
  {
    value: 'account',
    label: 'Account',
  },
  {
    value: 'amount',
    label: 'Amount',
  },
  {
    value: 'budget',
    label: 'Budget',
  },
  {
    value: 'category',
    label: 'Category',
  },
  {
    value: 'vendor',
    label: 'Vendor',
  },
];

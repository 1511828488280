import React from 'react';
import ModalHeader from './ModalHeader';
import classNames from 'classnames';

const ModalContainerWrap = ({
  goBack,
  isWhite,
  onClose,
  modalName,
  action,
  isPopoverOpen,
  handleTogglePopover,
  parentRef,
  loader,
  loading,
  children,
}) => {
  return (
    <div
      className={classNames('h-100 position-relative')}
      style={{ background: isWhite ? '#fff' : '#fafaf9' }}
    >
      <ModalHeader
        goBack={goBack}
        modalName={modalName}
        onClose={onClose}
        action={action}
        isPopoverOpen={isPopoverOpen}
        handleTogglePopover={handleTogglePopover}
      />

      <div className="transaction-details h-100 overflow-scroll" ref={parentRef ?? null}>
        <div className="content d-flex flex-column h-100">
          {loading ? (
            <div className="d-flex  bg-white" style={{ height: '100vh' }}>
              {loader}
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalContainerWrap;

import { Link } from 'react-router-dom';
import { ArrowUpRightIcon } from 'assets/icons';
import './styles.scss';
import copy from 'copy-text-to-clipboard';
import { toast } from 'react-toastify';
import Loading from 'components/UI/Loading';
import { toastSuccess } from 'components/UI/toast';
import cs from 'classnames';

/**
 * @param {String} title: the title of the item
 * @param {String} value: the value of the item
 * @param {String} isUnderline: this adds a text-underline style
 * @param {isLink} isLink: this adds a arrow icon to signify that it's a link
 * @returns
 */

const ItemInfo = ({
  title,
  value,
  icon,
  isUnderline,
  isCopy,
  isLink,
  withAction,
  action,
  isLoading,
  hideValue,
  withBadge,
  flex = 0,
  hideLinkIcon,
  onClick,
}) => {
  return (
    <section className="item-info d-flex align-items-center">
      <h6
        className={cs(`item-title text-sm font-medium color-grey-500 text-nowrap w-fit`)}
        style={{ flex: `${flex} 0 140px` }}
      >
        {title}
      </h6>
      <div className={`d-flex gap-2 parent-hover ${isUnderline ? 'underline' : ''}`}>
        {withBadge && <span>{icon}</span>}
        {!isLink && !isCopy && !hideValue && (
          <span
            className={cs('color-grey-900 font-medium item-value item-size', {
              cursor: !!onClick,
            })}
            onClick={onClick}
          >
            {value}
          </span>
        )}
        {withAction && !isLink && (
          <span onClick={action} className="cursor">
            {isLoading ? (
              <Loading color="#D28B28" size={20} />
            ) : (
              <span className="icon-hover">{icon}</span>
            )}
          </span>
        )}

        {isLink && !withAction && (
          <Link to={isLink} target="_blank" className="link item-value">
            <span className="color-grey-900 font-medium item-value item-size">
              {value}
            </span>
            {!hideLinkIcon && (
              <span className="icon-hover">
                <ArrowUpRightIcon stroke="#D28B28" width="16" height="16" />
              </span>
            )}
          </Link>
        )}
        {isLink && withAction && (
          <>
            <Link to={isLink} target="_blank" className="item-value ">
              <span className="color-grey-900 font-medium item-value item-size">
                {value}
              </span>
            </Link>
            <span onClick={action} className="cursor">
              {isLoading ? (
                <Loading color="#D28B28" size={20} />
              ) : (
                <span className="icon-hover">{icon}</span>
              )}
            </span>
          </>
        )}
        {isCopy && (
          <>
            <span
              className="color-grey-900 font-medium item-value item-size cursor"
              onClick={() => {
                if (isCopy) {
                  copy(value);
                  toastSuccess(`${title} copied successfully`);
                }
                if (onClick) onClick(value);
              }}
            >
              {value}
              <span className="icon-hover">{icon}</span>
            </span>
          </>
        )}
      </div>
    </section>
  );
};

export default ItemInfo;

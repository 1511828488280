import { useState } from 'react';

import { Tooltip } from 'antd';
import CustomPopover from 'components/UI/Popover';
import CurrencyFormat from 'react-currency-format';
import { SendFundsButton } from 'components/UI/CustomButton';

import {
  ArrowUpIcon,
  ArrowDownIcon,
  TransactionsIcon,
  InfoCircle,
  BankNoteO1Icon,
  LayersTwoIcon,
  CalendarPlusIcon,
  DotsVertical,
  ChevronDown,
  ChevronUp,
  FileCheck,
} from 'assets/icons';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleAction } from 'redux/actions/ToggleAction';

import AllowedTo from 'utils/AllowedTo';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';
import { BankFilled, FileDoneOutlined, UnorderedListOutlined } from '@ant-design/icons';

const FormattedCurrency = ({ value, prefix }) => {
  const formattedValue = value / 100;

  return (
    <CurrencyFormat
      prefix={prefix}
      value={formattedValue}
      displayType="text"
      thousandSeparator={true}
      isNumericString
      renderText={(formattedValue) => {
        const [integerPart, decimalPart] = formattedValue.split('.');
        return (
          <span>
            {integerPart}
            {decimalPart && <span style={{ color: '#BFBFBD' }}>.{decimalPart}</span>}
          </span>
        );
      }}
    />
  );
};

const Balances = ({ balances = {}, openStatement, defaultCurrency = 'NGN' }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [slideDirection, setSlideDirection] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isDisabled = (index) => {
    return activeIndex === index;
  };

  const openStatementModal = () => {
    setIsPopoverOpen(false);
    openStatement();
  };

  const handleStatementToggle = () => setIsPopoverOpen(!isPopoverOpen);

  const AccountActions = () => {
    const navigateHandler = (screenType) => () => {
      history.push({
        pathname: screenType,
        state: { isModal: true },
      });
    };
    return (
      <div className="actions-dialog">
        <AllowedTo scopes={['statement-view', 'statement-*', 'statement-export']}>
          <div onClick={openStatementModal} className="actionLink">
            <FileCheck width={16} height={16} stroke="#79716B" className="ms-1 me-2" />{' '}
            Generate statement
          </div>
        </AllowedTo>
      </div>
    );
  };

  const handleArrowClick = (index) => {
    if (isDisabled(index)) return;

    setSlideDirection('slide-out');

    setTimeout(() => {
      setActiveIndex(index);
      setSlideDirection('slide-in');
    }, 200);
  };

  return (
    <section>
      <div className="d-flex w-100 overview-balance-wrap">
        <div className="d-flex w-100 justify-content-center">
          <div className="overview-balances__holder px-3 py-4 align-items-center">
            <p className="text">
              Total balance ({defaultCurrency})
              <Tooltip
                overlayClassName="balance-tip"
                title={
                  <span style={{ fontSize: 12 }}>
                    The sum of your accounts, budgets and cards balances
                  </span>
                }
              >
                <span className="icon-animate">
                  <InfoCircle
                    style={{ fontSize: '14px', paddingTop: '2px' }}
                    fill="#BFBFBD"
                  />
                </span>
              </Tooltip>
            </p>

            <p className="value">
              <FormattedCurrency
                value={balances?.totalBalances?.['NGN'] || 0}
                prefix={getCurrency(defaultCurrency)}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="grid-cols-3 w-100 px-lg-3 border-top">
        <div className="balance-card py-3 fw-medium d-flex align-items-center justify-content-between">
          <div className="px-lg-0 px-4">
            <p className="text m-0 mb-1 text-sm text-gray" style={{ color: '#7F7F7D' }}>
              Accounts
            </p>
            <FormattedCurrency
              value={balances?.summary?.['NGN'] || 0}
              prefix={getCurrency(defaultCurrency)}
            />
          </div>
          <div className="me-2">
            <CustomPopover
              zIndex="1"
              content={<AccountActions />}
              showPopover={isPopoverOpen}
              clickOutside={handleStatementToggle}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleStatementToggle();
                }}
                className="cursor"
              >
                <DotsVertical />
              </div>
            </CustomPopover>
          </div>
        </div>
        <div className="balance-card py-3 fw-medium d-flex align-items-center justify-content-between">
          <div className="px-lg-0 px-4">
            <p className="text m-0 mb-1 text-sm text-gray" style={{ color: '#7F7F7D' }}>
              Budgets
            </p>
            <FormattedCurrency
              value={balances?.budgetSummary?.['NGN'] || 0}
              prefix={getCurrency(defaultCurrency)}
            />
          </div>
        </div>
        <div className="py-3 fw-medium d-flex align-items-center justify-content-between">
          <div className="px-lg-0 px-4">
            <p
              className="text-sm text-gray d-flex gap-1 align-items-center m-0 mb-1"
              style={{ color: '#7F7F7D' }}
            >
              Cards
              <span className={`inner-currency__holder ${slideDirection}`}>
                <span>({activeIndex === 1 ? 'NGN' : 'USD'})</span>
              </span>
            </p>
            <div className="d-flex align-items-center">
              <div className={`value-holder ${slideDirection}`}>
                <span className="value">
                  <FormattedCurrency
                    value={
                      activeIndex === 1
                        ? balances?.cardBalances?.['NGN'] || 0
                        : balances?.cardBalances?.['USD'] || 0
                    }
                    prefix={getCurrency(activeIndex === 1 ? 'NGN' : 'USD')}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="ms-auto d-flex gap-3 align-items-center">
            <div className="d-flex gap-1 align-items-center">
              <span
                className={`indicator-arrow-holder p-1 ${isDisabled(1) ? 'dormant' : ''}`}
                onClick={() => handleArrowClick(1)}
              >
                <ChevronUp
                  height="16"
                  width="16"
                  color={isDisabled(1) ? '#BFBFBD' : '#fff'}
                />
              </span>

              <span
                className={`indicator-arrow-holder p-1 ${isDisabled(2) ? 'dormant' : ''}`}
                onClick={() => handleArrowClick(2)}
              >
                <ChevronDown
                  height="16"
                  width="16"
                  color={isDisabled(2) ? '#BFBFBD' : '#fff'}
                />
              </span>
            </div>

            <div className="d-flex flex-column ms-auto align-items-center gap-1">
              {Array.from({ length: 2 }).map((_, i) => (
                <span
                  key={i + 1}
                  className={`indicator-dot ${i + 1 === activeIndex ? 'active' : ''}`}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Balances;

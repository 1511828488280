import React from 'react';

import './styles.scss';
import Loading from '../Loading';

const CustomTextarea = React.forwardRef(
  (
    {
      label,
      name,
      placeholder,
      onChange,
      value,
      maxLength,
      rowSize = 5,
      defaultValue,
      charCount = 0,
      showCounter = false,
      icon,
      isLoading,
      action,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="form-group parent-hover">
        {label && (
          <div className="d-flex align-items-center justify-content-between">
            <label className="textareaLabel form-label">{label}</label>
            {showCounter && (
              <span className="length-counter">{`${charCount}/${maxLength}`}</span>
            )}

            {icon && (
              <span onClick={action} className="cursor mb-2">
                {isLoading ? (
                  <Loading color="#D28B28" size={20} />
                ) : (
                  <span className="icon-hover">{icon}</span>
                )}
              </span>
            )}
          </div>
        )}
        <textarea
          ref={ref}
          className="form-control"
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          maxLength={maxLength}
          rows={rowSize}
          defaultValue={defaultValue}
          {...props}
        ></textarea>
      </div>
    );
  },
);

export default CustomTextarea;

import { ReactComponent as Trash } from 'assets/icons/trashcan.svg';
import classNames from 'classnames';
import DeleteDialog from 'components/DeleteDialog';
import { PaymentSkeleton } from 'components/UI/CustomDrawer/components/payment-skeleton';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  deleteRequests,
  getRequests,
  getSingleRequest,
} from 'redux/actions/RequestsAction';
import { RESET_BLOCK_REQUESTS } from 'redux/reducers/RequestsReducer';
import { allPermissions } from 'utils/AllowedTo';
import DeclineRequest from './DeclineRequest';
import RequestDetails from './RequestDetails';
import RequestMoreDetails from './RequestMoreDetails';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import { STATUS } from 'utils/helper';

const ReviewerModal = ({
  selectRequest,
  setSelectRequest,
  setAssetData,
  setSelectedAsset,
}) => {
  if (!selectRequest) return;
  const [step, setStep] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const { requestCode } = useParams();
  const history = useHistory();

  const [assetViewer, setAssetViewer] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const parentRef = useRef(null);

  const { isAdmin } = allPermissions();

  const toggleAssetViewer = () => setAssetViewer(!assetViewer);
  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const { requestData: { code = '', status, user: requestUser } = {} } =
    selectRequest || {};

  const createdByMe = user?.code === requestUser?.code;

  const {
    approveRequest: {
      loading: loadingApprove,
      success: successApprove,
      error: errorApprove,
    },
    declineRequest: {
      loading: loadingDecline,
      success: successDecline,
      error: errorDecline,
    },
    updateRequest: { success: updateSuccess, error, loading: updateLoading },
    deleteRequest: { loading: loadingDelete, success: successDelete },
    getSingleRequest: {
      data: singleData = {},
      loading: singleLoading,
      success: successData,
    },
  } = useSelector(({ requests }) => requests);

  const {
    reviewRequest: {
      success: approvalSuccess,
      error: approvalReviewError,
      loading: loadingApproval,
    },
  } = useSelector(({ approval }) => approval);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
  } = useSelector(({ payments }) => payments);

  useEffect(() => {
    if (!singleLoading && selectRequest?.requestData?.code) {
      dispatch(getSingleRequest(selectRequest?.requestData?.code));
    }
  }, [selectRequest?.requestData?.code]);

  useEffect(() => {
    if (successData && ['approved'].includes(selectRequest?.requestData?.status))
      setCanMakePayment(true);
  }, [successData, singleData]);

  const toggleHandler = () => {
    setStep(step - 1);
  };
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const handleAssetViewer = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const setReceiptList = (list) => {
    setAssetData && setAssetData(list);
  };

  const handleClose = () => {
    setCanMakePayment(false);
    setSelectedAsset(null);
    setAssetData && setAssetData(null);
    dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'requestReceipts' });
    if (requestCode) history.push('/requests/funds');
    if (isLoaded) {
      dispatch(getRequests());
    }
    if (step === 2 && !isLoaded) {
      toggleHandler();
    } else {
      setSelectRequest(null);
      setAssetViewer(false);
      dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'getSingleRequest' });
      dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'approveRequest' });
      dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'declineRequest' });
    }
    toggleAssetViewer(false);
    setIsLoaded(false);
  };

  useEffect(() => {
    if (successDelete) {
      handleClose();
    }
  }, [successDelete]);

  const paymentSuccess = !loadingPayNow && successPayNow;

  useEffect(() => {
    if (paymentSuccess) {
      setIsLoaded(true);
      handleClose();
      dispatch(getRequests());
    }
  }, [successPayNow, loadingPayNow]);

  const approveSuccess = !loadingApprove && successApprove;
  const declineSuccess = !loadingDecline && successDecline;

  const approveError = !loadingApprove && !!errorApprove;
  const declineError = !loadingDecline && errorDecline;

  useEffect(() => {
    if (approveSuccess || declineSuccess || updateSuccess || approvalSuccess) {
      if (!updateSuccess) setIsLoaded(true);
      if (selectRequest?.requestData?.code)
        dispatch(getSingleRequest(selectRequest?.requestData?.code));
    }
    if (approveError || declineError || error || approvalReviewError) setIsLoaded(false);
  }, [
    loadingApprove,
    successApprove,
    errorApprove,
    loadingDecline,
    successDecline,
    errorDecline,
    updateSuccess,
    approvalSuccess,
    approvalReviewError,
    selectRequest?.requestData?.code,
  ]);

  if (!selectRequest) return <div />;
  const renderCard = () => {
    switch (step) {
      case 1:
        return (
          <RequestDetails
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            setAssetViewer={handleAssetViewer}
            selectRequest={selectRequest}
            handleClose={handleClose}
            singleData={singleData?.data}
            getReceiptList={setReceiptList}
            hideHeader={setHideHeader}
            canMakePayment={canMakePayment}
            setCanMakePayment={setCanMakePayment}
            parentRef={parentRef}
          />
        );
      case 2:
        return (
          <DeclineRequest
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            selectRequest={singleData?.data?.fundRequest}
            userCode={user?.code}
            onClose={handleClose}
            step={step}
            hideTitle
          />
        );
      case 3:
        return (
          <RequestMoreDetails
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            selectRequest={singleData?.data?.fundRequest}
            userCode={user?.code}
            hideTitle
          />
        );
      default:
        return;
    }
  };

  const attemptDelete = () => {
    setDeleting(!deleting);
  };

  const handleDelete = () => {
    dispatch(deleteRequests(code));
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          onClick={() => {
            handleTogglePopover();
            attemptDelete();
          }}
          className="actionLink text-danger svg-danger"
        >
          <Trash className="mr-4" /> <span>Delete request</span>
        </div>
      </div>
    );
  };

  const loadingState =
    singleLoading || loadingApproval || loadingApprove || !singleData?.data;

  const modalTitle = {
    1: 'Request details',
    2: 'Decline request',
    3: 'Request more information',
  };

  return (
    <>
      <ModalContainerWrap
        parentRef={parentRef}
        isWhite={step > 1}
        modalName={modalTitle[step]}
        onClose={handleClose}
        action={
          step === 1 &&
          [STATUS.PENDING, STATUS.DRAFT].includes(status) &&
          (createdByMe || isAdmin) && <Actions />
        }
        isPopoverOpen={isPopoverOpen}
        handleTogglePopover={handleTogglePopover}
        loader={<PaymentSkeleton />}
        loading={loadingState}
        goBack={step > 1 && !isLoaded ? () => setStep(1) : null}
      >
        {renderCard()}
      </ModalContainerWrap>

      {deleting && (
        <Modal
          show={deleting}
          centered
          dialogClassName="custom-dialog"
          className="custom-dialog"
        >
          <DeleteDialog
            title={`Delete reqeust`}
            subTitle="Are you sure you want to delete this request? This action cannot be undone"
            onCancel={() => setDeleting(false)}
            onDelete={handleDelete}
            styles={{ width: 400 }}
            isLoading={loadingDelete}
          />
        </Modal>
      )}
    </>
  );
};

export default ReviewerModal;

import RequestFund from 'components/FundRequest/Requester';
import ManageFundRequest from 'components/FundRequest/Reviewer';
import EmployeeReimbursement from 'components/ReimbursementView/Employee';
import ManageReimbursement from 'components/ReimbursementView/Manager';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getReimbursements } from 'redux/actions/ReimbursementsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

const Requests = () => {
  const dispatch = useDispatch();
  const locationArray = location.pathname.split('/');
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);

  const { permissions } = allPermissions();
  const { reimbursementCode } = useParams();

  useEffect(() => {
    if (reimbursementCode) setKey('reimbursements');
  }, []);

  const {
    getReimbursement: { data: { meta = {} } = {}, data = {} },
  } = useSelector(({ reimbursement }) => reimbursement);
  const { total } = meta;
  const { reimbursements = [] } = data;
  const totalRequest = null;

  const canViewAdmin = hasPermission({
    permissions,
    scopes: ['org-*', 'reimbursement-*', 'access-full', 'request-approve'],
  });

  const canCreate = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-create', 'reimbursement-edit'],
  });

  const canView = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-view'],
  });

  useEffect(() => {
    if (!reimbursements.length && canViewAdmin)
      dispatch(getReimbursements({ status: ['pending'] }));
  }, []);

  const toggleHandler = () => {
    if (!isOtherPopoverOpen) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    if (tabKey !== 'requests') {
      history.push(`/requests/${tabKey}`);
    } else {
      history.push(`/requests`);
    }
  };

  return (
    <div className="position-relative">
      <TopBar
        headerText="Requests"
        subText="Handle all expense requests: reimbursements, fund requests, and salary advances."
        addButton={canCreate}
        dropDownBtn={
          <CustomButton className="add-button" onClick={toggleHandler}>
            Claim a reimbursement
          </CustomButton>
        }
      />
      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-2"
            >
              <Tab
                eventKey="reimbursements"
                title={
                  <div className="d-flex">
                    Reimbursements
                    <div className="count">
                      <span className="m-auto">{total || 0}</span>
                    </div>
                  </div>
                }
                tabClassName="new-tab"
              >
                {canViewAdmin && (
                  <ManageReimbursement
                    isOtherPopoverOpen={isOtherPopoverOpen}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    isOpen={isOpen}
                    toggleHandler={toggleHandler}
                    reimbursementCode={reimbursementCode}
                  />
                )}
                {!canViewAdmin && (
                  <EmployeeReimbursement
                    isOtherPopoverOpen={isOtherPopoverOpen}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    isOpen={isOpen}
                    toggleHandler={toggleHandler}
                    reimbursementCode={reimbursementCode}
                  />
                )}
              </Tab>

              <Tab
                eventKey="funds"
                title={
                  <div className="d-flex">
                    Fund requests
                    <div className="count">
                      <span className="m-auto">{totalRequest || 0}</span>
                    </div>
                  </div>
                }
                tabClassName="new-tab"
              >
                {canViewAdmin && (
                  <ManageFundRequest
                    isOtherPopoverOpen={isOtherPopoverOpen}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    isOpen={isOpen}
                    toggleHandler={toggleHandler}
                    reimbursementCode={reimbursementCode}
                  />
                )}
                {!canViewAdmin && (
                  <RequestFund
                    isOtherPopoverOpen={isOtherPopoverOpen}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    isOpen={isOpen}
                    toggleHandler={toggleHandler}
                    reimbursementCode={reimbursementCode}
                  />
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Requests;

import { useSelector } from 'react-redux';
import { toastSuccess } from 'components/UI/toast';
import { CopyIcon02 } from 'assets/icons/index';

const MailTo = ({ hasCopy = false, type }) => {
  const {
    getCompany: { data, loading },
  } = useSelector(({ companies }) => companies);

  const route = type ? data?.bujetiBillingMail : data?.bujetiMail;
  const copyToClipboard = () => {
    navigator.clipboard.writeText(route);
    toastSuccess('Copied to clipboard successfully!');
  };

  return (
    <>
      {loading ? (
        <span className="dots fw-medium" style={{ color: '#d28b28' }}></span>
      ) : (
        <span>
          <a href={`mailto:${route}`} className="fw-medium" style={{ color: '#d28b28' }}>
            {route}
          </a>

          {hasCopy && (
            <span onClick={copyToClipboard} className="ml-1">
              <CopyIcon02 />
            </span>
          )}
        </span>
      )}
    </>
  );
};

export default MailTo;

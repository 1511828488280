import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBillingHistoryAction,
  getCompanySubscription,
} from 'redux/actions/BillingActions';

import './billings.styles.scss';
import { Checkbox, Col, Row } from 'antd';
import CustomButton from 'components/UI/CustomButton';
import { deleteApiKey, getApiKey } from 'redux/actions/ApiKeyAction';
import { toastSuccess } from 'components/UI/toast';

const Developers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState();
  const pdfDownloadRef = useRef(null);

  const {
    getApiKey: { data: apiKey },
  } = useSelector(({ apiKeys }) => apiKeys);

  useEffect(() => {
    dispatch(getApiKey());
  }, []);

  const downloadPDF = () => {
    // event?.stopPropagation();
    // event?.preventDefault();
    pdfDownloadRef?.current?.click();
  };

  const onDownloadClick = (list) => {
    setList(list);
    downloadPDF();
  };

  const handleKeyDisable = () => {
    dispatch(deleteApiKey({ code: apiKey.code }));
  };

  return (
    <section className="billing-wrapper fade-in mt-4 pt-2">
      <Row className="gap-5">
        <Col>
          <div className="billing-email-address">
            <h1>Your API Key</h1>
            <p>Use this API key to call the endpoints provided in the documentation</p>
            <div className="email-address">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 6.99994C15 6.48812 14.8047 5.9763 14.4142 5.58579C14.0237 5.19526 13.5118 5 13 5M13 13C16.3137 13 19 10.3137 19 7C19 3.68629 16.3137 1 13 1C9.68629 1 7 3.68629 7 7C7 7.27368 7.01832 7.54308 7.05381 7.80704C7.11218 8.24118 7.14136 8.45825 7.12172 8.59559C7.10125 8.73865 7.0752 8.81575 7.00469 8.9419C6.937 9.063 6.81771 9.18229 6.57913 9.42087L1.46863 14.5314C1.29568 14.7043 1.2092 14.7908 1.14736 14.8917C1.09253 14.9812 1.05213 15.0787 1.02763 15.1808C1 15.2959 1 15.4182 1 15.6627V17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H5V17H7V15H9L10.5791 13.4209C10.8177 13.1823 10.937 13.063 11.0581 12.9953C11.1843 12.9248 11.2613 12.8987 11.4044 12.8783C11.5417 12.8586 11.7588 12.8878 12.193 12.9462C12.4569 12.9817 12.7263 13 13 13Z"
                  stroke="#6D6F6B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {apiKey?.key?.substring(0, 10)}••••••••••••••{apiKey?.key?.substring(22)}
            </div>
            <div className="d-flex gap-2">
              <CustomButton
                loading={false}
                className="submit-button mt-4"
                htmlType="submit"
                withoutBg={true}
                onClick={handleKeyDisable}
              >
                Disable key
              </CustomButton>
              <CustomButton
                loading={false}
                className="submit-button mt-4"
                htmlType="submit"
                onClick={async () => {
                  if ('clipboard' in navigator) {
                    await navigator.clipboard.writeText(apiKey?.key);
                    toastSuccess('Copied successfully');
                  }
                }}
              >
                Copy key
              </CustomButton>
            </div>
          </div>
        </Col>
        <Col>
          <div className="billing-email-address">
            <h5>Scope</h5>
            <p>The actions this key is allowed to take on your behalf</p>
            <div style={{ display: 'inline-grid' }}>
              <Checkbox onChange={(event) => null} checked={true}>
                List transactions
              </Checkbox>
              <Checkbox onChange={(event) => null} checked={false} disabled={true}>
                List budgets
              </Checkbox>
              <Checkbox onChange={(event) => null} checked={false} disabled={true}>
                List funding
              </Checkbox>
              <Checkbox onChange={(event) => null} checked={false} disabled={true}>
                List accounts
              </Checkbox>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Developers;

import { XcircleBorder } from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import CustomTextarea from 'components/UI/CustomTextarea';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { cancelPlan, getCompanySubscription } from 'redux/actions/BillingActions';
import { getFormattedDate } from 'utils/helper';

const reasonData = [
  { name: 'Not using it enough', ischecked: false },
  {
    name: 'Switching to another tool',
    ischecked: false,
  },
  { name: 'Cost (too expensive or budget cuts)', ischecked: false },
  { name: 'Too difficult to use', ischecked: false },
  {
    name: 'Missing features',
    ischecked: false,
  },
  {
    name: 'Other',
    ischecked: false,
  },
];
const CancelSubscriptionModal = ({ isOpen, onCancel, setIsOpen, isLoading, code }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [reasons, setReasons] = useState(reasonData);
  const [otherReasons, setOtherReasons] = useState('');

  const {
    cancelPlan: { loading, success },
  } = useSelector(({ billing }) => billing);
  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;
  useEffect(() => {
    if (success) {
      setIsOpen(false);
      setStep(1);
      dispatch(getCompanySubscription());
    }
  }, [success]);

  const onHandleCheck = (index) => {
    setReasons((prevReasons) => {
      const updatedReasons = prevReasons.map((reason, i) => ({
        ...reason,
        ischecked: i === index ? !reason.ischecked : false,
      }));
      return updatedReasons;
    });
  };

  const handleNext = () => {
    const isAnyReasonChecked = reasons.some((reason) => reason.ischecked);

    if (!isAnyReasonChecked) return toastError('Please select a reason');
    setStep(2);
  };

  const handleSubit = () => {
    const findrReason = reasons.find((item) => item.ischecked).name;
    const reason = findrReason.toLowerCase() === 'other' ? otherReasons : findrReason;
    dispatch(cancelPlan({ reason, code }));
  };

  const handleCancel = () => {
    setIsOpen(false);
    setStep(1);
    setReasons(reasonData);
  };
  const reasonView = (
    <ConfirmDialog onCancel={handleCancel} style={{ width: 470 }}>
      <span className="w-100">
        <XcircleBorder />
      </span>

      <div className="mt-3 w-100 heading-confirm ">
        <h5>Why do you want to cancel?</h5>
        <h6>
          We&apos;re sorry you&apos;re thinking of leaving us. <br />
          <br />
          What&apos;s your primary reason for cancelling your subscription? We&apos;d like
          to have your feedback to improve Bujeti.
        </h6>
      </div>

      <main className="w-100  heading-confirm pb-4">
        <div className="reason-list">
          {reasons?.map((reason, index) => {
            return (
              <div
                className="list-item d-flex align-items-center"
                key={reason.name}
                onClick={() => onHandleCheck(index)}
              >
                <div className={`checkbox ${reason.ischecked ? 'checked' : 'unchecked'}`}>
                  {reason.ischecked && (
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.33317 1.5L3.74984 6.08333L1.6665 4"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                {reason.name}
              </div>
            );
          })}
        </div>
        {reasons[5].ischecked && (
          <div className="mt-3">
            <CustomTextarea
              row={5}
              name="reason"
              onChange={({ target }) => setOtherReasons(target.value)}
              value={otherReasons}
              placeholder="Anything else you'd like us to know? Share some feedback with us..."
            />
          </div>
        )}
      </main>

      <div className="my-4 w-100">
        <footer>
          <button onClick={handleCancel}>Keep your plan</button>
          <button onClick={handleNext}>Continue</button>
        </footer>
      </div>
    </ConfirmDialog>
  );

  const confirmReasonView = (
    <ConfirmDialog onCancel={handleCancel} style={{ width: 470 }}>
      <span className="w-100">
        <XcircleBorder />
      </span>

      <div className="mt-3 w-100 heading-confirm " style={{ border: 0 }}>
        <h5>Cancel subscription</h5>
        <h6>
          You&apos;ll still enjoy all the features of your current plan until{' '}
          <span className="fw-medium">
            {userData?.user?.company?.paymentPlan?.expiryDate
              ? getFormattedDate(userData?.user?.company?.paymentPlan?.expiryDate)
              : '-'}
          </span>
          . You&apos;ll be downgraded to Starter plan and will lose access to premium
          features. <br />
          <br />
          You can resubscribe or switch to another plan anytime.
        </h6>
      </div>

      <div className="mt-2 mb-4 w-100">
        <footer className="danger">
          <button onClick={() => setStep(1)}>Back</button>
          <button disabled={loading} onClick={handleSubit}>
            {loading ? <Loading size={18} /> : 'Yes, cancel plan'}
          </button>
        </footer>
      </div>
    </ConfirmDialog>
  );
  return (
    <Modal show={isOpen} centered dialogClassName="custom-dialog batch-payment-confirm">
      {step === 1 ? reasonView : confirmReasonView}
    </Modal>
  );
};

export default CancelSubscriptionModal;

import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import './styles.scss';
import { ReactComponent as BankIcon } from '../../assets/icons/bank-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as UnCheckedIcon } from '../../assets/icons/unchecked.svg';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'components/UI/CustomButton';
import LinkAccountModal from './ManageBank/LinkAccountModal';
import {
  defaultBeneficiaryBank,
  deleteBeneficiaryBank,
} from 'redux/actions/BeneficiariesAction';
import classNames from 'classnames';
import ConfirmDialog from 'components/ConfirmDialog';

const BankAccounts = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const toggleButton = () => {
    setIsOpen(!isOpen);
  };
  const [isDelete, setIsDelete] = useState(null);

  const {
    getBeneficiaryBank: { data: getbankData = [] },
    defaultBeneficiaryBank: { loading: loadingDefault },
    deleteBeneficiaryBank: { loading: loadingDelete, success: deleteSuccess },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const getUserBank = getbankData.filter((item) => item.ownerType === 'user');

  const handleDefault = (code) => {
    const payload = {
      code,
      defaultBank: true,
    };
    dispatch(defaultBeneficiaryBank(payload));
  };

  const handleDelete = (code) => {
    setIsDelete(code);
  };

  const handleDeleteClick = () => {
    dispatch(deleteBeneficiaryBank(isDelete));
  };

  useEffect(() => {
    if (deleteSuccess) {
      setIsDelete(null);
    }
  }, [deleteSuccess]);

  return (
    <Row className="information-wrapper">
      <div className="bank-account-wrap">
        <div className="py-3 d-flex justify-content-between ">
          <div>
            <h2 className="fs-4 fw-bolder">Bank accounts</h2>
            <p>The selected bank account will be used to send you your reimbursement </p>
          </div>
          <div>
            <CustomButton className="add-button" onClick={toggleButton}>
              Add bank account
            </CustomButton>
          </div>
        </div>
        <Col md={6}>
          {getUserBank.map((item, index) => {
            return (
              <>
                {item.status === 'active' ? (
                  <div className="account-label py-4" key={index}>
                    <BankIcon className="icon-wrap" />
                    <div className="text-wrap">
                      <h3>{item?.bankName}</h3>
                      <p>
                        {item?.accountName} - {`${item?.number}`}
                      </p>
                    </div>
                    <div className="check-wrap">
                      <CheckIcon />
                    </div>
                  </div>
                ) : (
                  <div className="account-label-unset">
                    <BankIcon className="icon-wrap" />
                    <div className="text-wrap">
                      <h3>{item?.bankName}</h3>
                      <p>
                        {item?.accountName} - {`${item?.number}`}
                      </p>
                      <div className="d-flex gap-2 py-2 align-items-center">
                        <div
                          className={classNames('cursor', {
                            ['isDisabled']: loadingDefault,
                          })}
                          onClick={() => handleDefault(item.code)}
                        >
                          Set as default
                        </div>
                        <div
                          className={classNames('cursor text-danger', {
                            ['isDisabled']: loadingDefault,
                          })}
                          onClick={() => handleDelete(item.code)}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                    <div className="check-wrap">
                      <UnCheckedIcon />
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </Col>
      </div>

      <LinkAccountModal isOpen={isOpen} handleClose={toggleButton} />

      <Modal
        show={isDelete}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title="Delete bank account"
          subTitle="Are you sure you want to delete this account? This action cannot be undone."
          onConfirm={handleDeleteClick}
          loading={loadingDelete}
          onCancel={() => setIsDelete(null)}
        />
      </Modal>
    </Row>
  );
};

export default BankAccounts;

import Success from 'components/UI/ModalSaving/Success';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BankIcon, ChevronRight } from 'assets/icons';

import MonoConnect from '@mono.co/connect.js';
import cs from 'classnames';
import {
  FundDollarIcon,
  SwitchHorizontal,
} from 'pages/Dashboard/OverviewTab/FundsModal/SelectFunds';

const SelectFunds = ({
  setFundStep,
  fundType,
  isLinked = false,
  setMonoAuthCode,
  initiatePayment,
  verifyPayment,
  paymentVerified,
  paymentFailed,
  getReauthorizationCode,
  reauthorizationCode,
  addFunds,
  destination,
}) => {
  const [isFunding, setIsFunding] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [successfulFunding, setSuccessfulFunding] = useState(paymentVerified);
  const [failedFunding, setFailedFunding] = useState(paymentFailed);
  const [account, setAccount] = useState(null);
  const [currency, setCurrency] = useState('NGN');
  const [amount, setAmount] = useState(0);

  const location = useLocation();
  const [searchParams] = useState(new URLSearchParams(location.search));

  const dataArray = [
    {
      image: <BankIcon />,
      headerText: 'Show account details',
      subHeaderText: 'Wire your fund to this account',
      tag: 'virtual',
    },
    {
      image: <SwitchHorizontal />,
      headerText: 'From own accounts/balances',
      subHeaderText: 'Move money from your other accounts',
      tag: '',
    },
  ];

  const dollarArray = [
    {
      image: <BankIcon />,
      headerText: 'By bank transfer',
      subHeaderText: 'Wire your fund to this account',
      tag: 'virtual',
    },
    {
      image: <FundDollarIcon />,
      headerText: 'By converting naira to dollar',
      subHeaderText: 'Topup your USD balance from your Naira balance',
      tag: '',
    },
  ];
  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => {
        setIsFunding(false);
      },
      onSuccess: ({ code }) => {
        setMonoAuthCode(code);
        monoInstance.close();
      },
      key: process.env.REACT_APP_MONO_CONNECT_KEY,
    });

    monoInstance.setup();
    return monoInstance;
  }, []);

  const mapData = fundType === 0 ? dataArray : dollarArray;

  const handleClick = (num) => () => {
    setFundStep(num);
    if (num > 0) {
      if (num === 2 && !isLinked) monoConnect.open();
      else setFundStep(6);
    }
  };

  const setBankAccount = (account) => {
    const { requiresReauth } = account;
    if (requiresReauth) {
      getReauthorizationCode(account.value);
    } else setAccount(account);
  };

  const openPayment = () => {
    const payload = {
      currency,
      amount: amount * 100,
      account: account.value,
      source: account.value,
      destination,
    };
    if (account.isLinked) initiatePayment(payload);
    else addFunds(payload);
  };

  useEffect(() => {
    if (reauthorizationCode) {
      monoConnect.reauthorise(reauthorizationCode);
    }
  }, [reauthorizationCode]);

  useEffect(() => {
    if (searchParams.get('funding') === 'true') {
      const status = searchParams.get('status');
      if (status !== 'successful') {
        setFailed(true);
      } else if (searchParams.get('reference')) {
        setIsFunding(false);
        setIsVerifying(true);
        verifyPayment(searchParams.get('reference'));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    setIsVerifying(false);
    if (paymentFailed) {
      setSuccessfulFunding(false);
      setFailedFunding(true);
    } else if (paymentVerified) {
      setFailedFunding(false);
      setSuccessfulFunding(true);
    }
  }, [paymentFailed, paymentVerified]);

  // handle input state changes

  const PickerScreen = () => (
    <div className="selectFundsWrapper send-funds">
      <h2 className="caption"> Add Funds</h2>
      <span className="sub-text">How do you want to add the funds?</span>

      <section className="cardWrapper">
        {mapData.map((item, index) => {
          return (
            <div
              key={index}
              className={cs('balance-card-wrapper', { ['disabled']: index === 2 })}
              onClick={handleClick(index)}
            >
              <div className="pe-3">{item.image}</div>
              <div>
                <div className="d-flex align-items-center">
                  <span className="balance-title">{item.headerText}</span>
                </div>
                <span className="balance-sub-title">{item.subHeaderText}</span>
              </div>

              <span className="ms-auto chevron">
                <ChevronRight stroke="#A0A09E" />
              </span>
            </div>
          );
        })}
      </section>
    </div>
  );

  const VerifyingMessage = () => (
    <Success title="Thank you!" message="Your funding is being processed..." />
  );
  const SuccessMessage = () => (
    <Success title="Successful!" message="Your balance have been funded..." />
  );
  const FailureMessage = () => (
    <Success title="Failed!" message="Your funding was not successful, try again!" />
  );
  //
  return (
    <>
      {isVerifying
        ? VerifyingMessage()
        : successfulFunding
        ? SuccessMessage()
        : failedFunding
        ? FailureMessage()
        : PickerScreen()}
    </>
  );
};
export default SelectFunds;

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { BankNoteO1Icon, ChevronDown, DownloadIcon, EditIcon } from 'assets/icons';
import { ReactComponent as Confirm } from 'assets/icons/confirm.svg';
import { ReactComponent as Delete } from 'assets/icons/slash-circle.svg';
import classNames from 'classnames';
import AssetViewer from 'components/AssetViewer';
import ConfirmDialog from 'components/ConfirmDialog';
import { TransactionStatusType } from 'components/FilterModal/FilterHelper';
import DeclineRequest from 'components/FundRequest/ReviewerModal/DeclineRequest';
import ReceiptPdf from 'components/ReceiptPdf';
import Table from 'components/Table';
import Timeline from 'components/Timeline';
import TopBar from 'components/TopBar';
import TransactionDetails from 'components/TransactionModal/TransactionDetails';
import ReceiptList from 'components/UI/CustomDrawer/components/ReceiptList';
import Loading from 'components/UI/Loading';
import ModalComponent from 'components/UI/Modal/ModalComponent';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import CustomPopover from 'components/UI/Popover';
import { toastSuccess } from 'components/UI/toast';
import { PDFDownloadLink } from 'components/UIHooks/JsPDF';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import NoData from 'pages/Cash/Overview/components/NoData';
import { approvalState } from 'pages/Transactions/approvalState';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import {
  createBulkPayment,
  getBatchPayment,
  getSingleBatchPayment,
  payNowAction,
  updateBatchPaymentAsset,
} from 'redux/actions/PaymentAction';
import {
  getAllTransactionAsset,
  getSingleTransaction,
} from 'redux/actions/TransactionsAction';
import { RESET_BLOCK_APPROVAL } from 'redux/reducers/ApprovalReducer';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import {
  buildSingleTransactionTableData,
  capitalizeFirstLetter,
  getCurrency,
} from 'utils/helper';
import { PreviewBatchPaymentList } from 'utils/mockData';
import { formatNumThousandSeparator } from 'utils/utility';
import DeclinedAlert from './DeclinedAlert';
import DownloadExcelStatement from './DownloadExcelStatement';
import './styles.scss';

const BulkApprovalAction = ({ rows }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const [type, setType] = useState('');
  const [payNow, setPayNow] = useState(true);
  const [declineRequest, setDeclineRequest] = useState(false);
  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);
  const [reason, setReason] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    reviewRequest: { success: isMultipleReviewSuccess, loading: isMultipleReviewLoading },
  } = useSelector(({ approval }) => approval);
  const {
    getSingleBatchPayment: { data, loading },
    createBulkPayment: { loading: createBulkLoading, success: createBulkSuccess },
  } = useSelector(({ payments }) => payments);

  const { canApprovePay, yourTurnToApprove } = approvalState({
    data: data,
    user,
  });

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  useEffect(() => {
    if (isMultipleReviewSuccess || createBulkSuccess) {
      setIsSubmit(false);
      setIsLoaded(true);
    }
  }, [isMultipleReviewSuccess, createBulkSuccess]);

  const handleSubmit = (action, reason = undefined) => {
    setType(action);
    setIsSubmit(!isSubmit);
    setReason(reason);
  };

  const selectedRows = rows
    ?.filter((item) => item?.transactionData?.status === 'pending')
    ?.map((item) => item?.code);

  const payable =
    data?.status === 'approved' &&
    rows.filter((item) => item?.transactionData?.status === 'approved');
  const approvable = rows.filter((item) => item?.transactionData?.status === 'pending');
  const isApprovable = rows.some((item) => item?.transactionData?.status === 'pending');

  const handleConfirm = (status) => {
    if (status === 'pay') {
      const payload = {
        items: payable?.map((item) => ({
          code: item?.transactionData?.code,
          source: item?.transactionData?.balance?.code,
        })),
      };
      dispatch(createBulkPayment(payload));
      return;
    }

    const payload = {
      code: data?.approvalRequest?.code,
      actionLater: !payNow,
      status: status === 'decline' ? 'declined' : 'approved',
      transactionsToDecline: status === 'decline' ? selectedRows : undefined,
      transactionsToApprove: status !== 'decline' ? selectedRows : undefined,
      reason: reason ? reason : undefined,
    };

    dispatch(reviewRequest(payload));
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            setPayNow(false);
            handleSubmit('approve');
            handleButtonToggle();
          }}
        >
          Approve only
        </div>
      </div>
    );
  };

  const getTotalAmount = () => {
    return rows?.reduce((acc, curr) => acc + curr?.unformatedAmount, 0);
  };

  return (
    <>
      {!!rows?.length && (
        <div className="floating-cta-container mt-2 mx-auto d-flex align-items-center border rounded">
          <div className="selected-information">
            <p className="mb-0">{rows.length} selected</p>
            <p className="mb-0">
              ₦{formatNumThousandSeparator(getTotalAmount())}
              total
            </p>
          </div>
          <div className="cta-divider"></div>

          <div className="d-flex justify-content-end custom-btn-container">
            {!!payable?.length && (
              <button
                className={classNames('btn border me-2')}
                onClick={() => {
                  handleSubmit('pay');
                }}
              >
                Make payment
              </button>
            )}

            {yourTurnToApprove && !!isApprovable && (
              <>
                <button
                  className={classNames('btn border me-2')}
                  onClick={() => {
                    setDeclineRequest(true);
                  }}
                >
                  Decline
                </button>

                <div className="d-flex">
                  <button
                    onClick={() => {
                      setPayNow(canApprovePay);
                      handleSubmit(canApprovePay ? 'Approve and pay' : 'Approve only');
                    }}
                    className={classNames(
                      'px-3 nowrap dropdown-btn action-btn text-white',
                      {
                        ['rounded-3 px-4']: !canApprovePay,
                      },
                    )}
                  >
                    {canApprovePay ? 'Approve and pay' : 'Approve only'}
                  </button>
                  {canApprovePay && (
                    <CustomPopover
                      placement="bottom-end"
                      id="invoice-schedule"
                      zIndex="9999"
                      content={<Actions />}
                      showPopover={isButtonToggle}
                      clickOutside={handleButtonToggle}
                    >
                      <button className="dropdown-btn" onClick={handleButtonToggle}>
                        <ChevronDown
                          color="#ffffff"
                          className={`icon ${isButtonToggle && 'is-toggled'}`}
                        />
                      </button>
                    </CustomPopover>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <Modal
        show={isSubmit}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} transaction(s)`}
          subTitle={
            <>
              Are you sure you want to {type}
              <span className="fw-bolder">
                {' '}
                {type === 'pay' ? payable?.length : approvable?.length}
              </span>{' '}
              transaction?
            </>
          }
          dialogIcon={type === 'decline' ? <Delete /> : <Confirm />}
          onConfirm={() => handleConfirm(type)}
          onCancel={() => {
            setIsSubmit(false);
          }}
          isDeleteDialog={type === 'decline'}
          actionBtnText={capitalizeFirstLetter(type)}
          loading={isMultipleReviewLoading || createBulkLoading}
        />
      </Modal>

      <ModalComponent active={!!declineRequest} scroll={false}>
        <ModalContainerWrap
          isWhite
          modalName="Decline request"
          onClose={() => {
            setDeclineRequest(false);
          }}
        >
          <DeclineRequest
            onClose={() => {
              setDeclineRequest(false);
            }}
            getReason={handleSubmit}
            approvalCode={data?.approvalRequest?.code}
            userCode={user?.user?.code}
            isLoaded={isLoaded}
            kebabAction
            hideTitle
          />
        </ModalContainerWrap>
      </ModalComponent>
    </>
  );
};

const BatchApproval = () => {
  const history = useHistory();
  const { code } = useParams();
  const dispatch = useDispatch();
  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [search, setSearch] = useState('');
  const [filterData, setFilterData] = useState([...TransactionStatusType]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [ruleCode, setRuleCode] = useState(null);
  const [assetViewer, setAssetViewer] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(false);
  const [receiptCode, setReceiptCode] = useState({ receipt: '' });
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const [payNow, setPayNow] = useState(true);
  const [declineRequest, setDeclineRequest] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [reason, setReason] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loadActions, setLoadActions] = useState(false);

  const activeTxCode = useRef(null);

  const {
    reviewRequest: { loading: loadingReview, success: successReview },
  } = useSelector(({ approval }) => approval);

  const {
    transactionReceipts: { data: receiptAssets, loading: loadingReceipt },
    getSingleTransaction: {
      data: singleTransaction = {},
      error,
      success: successSingleTransaction,
    },
  } = useSelector(({ transaction }) => transaction);

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const {
    getSingleBatchPayment: { data, loading },
    updateBatchPaymentAsset: { loading: updateLoading, success: updateSuccess },
    createBulkPayment: { success: createBulkSuccess },
    payNowAction: { success: payNowSuccess, loading: payNowLoading },
  } = useSelector(({ payments }) => payments);

  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);

  const {
    selectedTableRows: { selectedRows },
  } = useSelector(({ table }) => table);

  useEffect(() => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: {
        selectedRows: [],
        type: 'batch',
      },
    });
  }, []);

  const handleRowSelect = useCallback((rowData) => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: { selectedRows: rowData, type: 'batch' },
    });
  }, []);

  const filteredData = (query) =>
    data?.transactions?.filter((item) => {
      const statusMatch = query?.status ? query?.status?.includes(item?.status) : true;
      const searchMatch = search
        ? item?.recipient?.name?.toLowerCase().includes(search.toLowerCase())
        : true;
      return statusMatch && searchMatch;
    });

  useEffect(() => {
    setTransactions(filteredData(filteredQuery));
  }, [search]);

  const tableColumns = useMemo(() => {
    return PreviewBatchPaymentList;
  }, []);

  const tableData = useMemo(() => {
    return buildSingleTransactionTableData(transactions);
  }, [transactions]);

  const handleFilter = (query) => {
    setFilteredQuery(query);
    setTransactions(filteredData(query));
  };

  useEffect(() => {
    if (code?.startsWith('btc_')) dispatch(getSingleBatchPayment(code));
    else history.push('/transactions/batch');
  }, []);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const generatePdfSuccess = async (event, instance) => {
    event?.stopPropagation();
    if (instance.loading || !instance.url) return;
    toastSuccess('Receipt downloaded');
  };

  useEffect(() => {
    if (singleTransaction?.data) setLoadActions(true);
  }, [singleTransaction?.data]);

  const fetchSingleData = useCallback(
    (data) => {
      const { code = '' } = data?.transactionData || {};

      activeTxCode.current = code;
      if (activeTxCode.current) dispatch(getSingleTransaction(code));
    },
    [activeTxCode.current],
  );

  const TableActions = useCallback(
    ({ list: selectedData }) => {
      const status = selectedData?.transactionData?.status?.toLowerCase();

      useMemo(() => {
        if (selectedData?.transactionData?.code !== activeTxCode.current) {
          setLoadActions(false);
          if (['success'].includes(status)) fetchSingleData(selectedData);
        }
      }, []);

      if (!loadActions && ['success'].includes(status))
        return (
          <div className="p-2 export-wrap" style={{ transform: 'translateX(0%)' }}>
            <div className="spinner-3 mx-auto" style={{ width: '30px' }}></div>
          </div>
        );

      return (
        <div className="actions-dialog">
          {!selectedData?.reason && !['approved', 'success'].includes(status) ? (
            <div
              className="actionLink"
              onClick={(event) => {
                setIsPopoverOpen(false);
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              No options available
            </div>
          ) : (
            <>
              {['approved'].includes(status) && data?.status === 'approved' && (
                <div
                  className="actionLink"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    actionHandler(event, 'pay', selectedData?.transactionData);
                  }}
                >
                  <BankNoteO1Icon style={{ marginLeft: '-4px', marginRight: '6px' }} />{' '}
                  Make payment
                </div>
              )}

              {['success'].includes(status) && (
                <PDFDownloadLink
                  document={
                    <ReceiptPdf
                      data={{
                        ...selectedData?.transactionData,
                        senderAccount: singleTransaction?.data?.senderAccount,
                      }}
                      companyData={companyData}
                    />
                  }
                  fileName={`Transaction receipt for ${selectedData?.description}.pdf`}
                  style={{
                    textDecoration: 'none',
                    height: 40,
                    display: 'flex',
                    width: '100%',
                    fontFamily: 'Inter var !important',
                    color: '#212529',
                    alignItems: 'center',
                  }}
                  className="actionLink"
                  onClick={(event, instance) => generatePdfSuccess(event, instance)}
                >
                  <DownloadIcon className="mr-4" width={16} height={16} />
                  Download receipt
                </PDFDownloadLink>
              )}

              {selectedData?.reason && (
                <div
                  className="actionLink"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setReason(selectedData?.reason);
                    setIsPopoverOpen(false);
                  }}
                >
                  <EditIcon stroke="#79716B" width={16} height={16} className="mr-4" />{' '}
                  View reason
                </div>
              )}
            </>
          )}
        </div>
      );
    },
    [singleTransaction?.data?.code, loadActions],
  );

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([...TransactionStatusType]);
  };

  const openRule = (code) => {
    setRuleCode(code);
  };

  const toggleAssetViewer = () => setAssetViewer(!assetViewer);

  const viewImgUrl = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  useEffect(() => {
    if (successReview || createBulkSuccess || payNowSuccess) {
      setConfirmModal(false);
      setIsLoaded(true);
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'reviewRequest' });
      dispatch(getSingleBatchPayment(code));
      dispatch(getBatchPayment());
    }
  }, [successReview, createBulkSuccess, payNowSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      setReceiptCode({ receipt: '' });
      dispatch(getSingleBatchPayment(code));
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (data?.transactions?.length) {
      setTransactions(data?.transactions);
    }
  }, [data?.transactions]);

  useEffect(() => {
    if (!!receiptCode?.receipt?.length) {
      dispatch(
        updateBatchPaymentAsset({
          code: data?.code,
          receipt: receiptCode?.receipt,
        }),
      );
    }
  }, [receiptCode?.receipt?.length]);

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setSelectedOption(value);
    setType(type.toLowerCase());

    if (!['approve_schedule', 'decline_request'].includes(type))
      return setConfirmModal(true);
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setConfirmModal(false);
  };

  const handleConfirm = ({ schedule }) => {
    const code = selectedOption?.code;
    if (['approve and pay', 'approve', 'approve_schedule'].includes(type)) {
      handleApproveRequest({
        schedule,
        code: selectedOption?.approvalRequest?.code,
      });
    }
    if (type === 'pay') {
      dispatch(payNowAction({ code }));
    }
  };

  const handleApproveRequest = ({ schedule, code }) => {
    dispatch(
      reviewRequest({
        code,
        status: 'approved',
        actionLater: schedule ? undefined : !payNow,
        schedule,
      }),
    );
  };

  const { canApprovePay, approvers, yourTurnToApprove } = approvalState({
    data: data,
    user,
  });

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
  };

  const closeApprovalRule = () => setRuleCode(null);

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => {
            setPayNow(false);
            actionHandler(event, 'approve', data);
            handleButtonToggle();
          }}
        >
          Approve only
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (data?.receipts?.length) {
      dispatch(getAllTransactionAsset(data?.receipts));
    }

    return () => {
      dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'transactionReceipts' });
    };
  }, [data?.receipts?.length]);

  // if (loading) return <Loading isPage color="#D28B28" />;

  const paidLabel = data?.totalPaidAmount > 0 ? 'Total paid' : 'Total to be paid';

  const totalTransactions =
    data?.totalPaidAmount > 0 ? data?.totalPaidAmount : data?.amountToBePaid;

  return (
    <div
      className="d-flex w-100 gap-4 position-relative justify-content-between"
      style={{ minHeight: '100vh' }}
    >
      <Modal
        show={assetViewer}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <div className="position-relative" style={{ width: '742px' }}>
          <div
            onClick={toggleAssetViewer}
            className="position-absolute cursor end-0 text-white"
            style={{ transform: 'translate(0%, 60%)', zIndex: 1000 }}
          >
            X Close
          </div>
          {assetViewer && (
            <AssetViewer
              toggleVisibility={toggleAssetViewer}
              data={receiptAssets}
              selectedAsset={selectedAsset}
              singleData={{ user: data?.payer, type: 'Batch Payment' }}
            />
          )}
        </div>
      </Modal>
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <div className="py-5 batch_payment-container h-100 w-100">
          <div
            className="back-click mb-4"
            onClick={() => {
              if (history.length > 1) {
                history.goBack(-1);
              } else {
                history.push('/transactions/batch');
              }
            }}
          >
            <ArrowLeftOutlined />
            Back
          </div>
          <div className="d-flex mb-4 align-items-center justify-content-between">
            <h1 className="headerText">{capitalizeFirstLetter(data?.name)}</h1>
            {data?.status === 'success' && (
              <div>
                <DownloadExcelStatement data={data} />
              </div>
            )}
          </div>
          <div className="review-batch-table w-100">
            <div className="review-batch-table_header w-100 d-md-flex justify-content-between align-items-center">
              <div>
                <h6>
                  {paidLabel}:{' '}
                  <CurrencyFormat
                    value={totalTransactions / 100}
                    displayType="text"
                    thousandSeparator={true}
                    prefix={getCurrency(data?.currency)}
                  />
                </h6>
                <p className="mb-0">
                  Review the list of {data?.transaction_count || 0} transactions requested
                  by {data?.payer?.firstName} {data?.payer?.lastName}
                </p>
              </div>

              {yourTurnToApprove && (
                <div className="d-sm-flex justify-content-center align-items-center gap-2">
                  <button
                    disabled={!!selectedRows?.length}
                    className="btn border me-2"
                    style={{ padding: '10px 20px' }}
                    onClick={(event) => {
                      setDeclineRequest(true);
                      actionHandler(event, 'decline_request', data);
                    }}
                  >
                    Decline all
                  </button>

                  <div className="d-flex ms-2 w-100">
                    <button
                      disabled={!!selectedRows?.length}
                      onClick={(event) => {
                        setPayNow(canApprovePay);
                        actionHandler(event, 'approve and pay', data);
                      }}
                      className={classNames(
                        'px-3 nowrap dropdown-btn action-btn text-white',
                        {
                          ['rounded-3 px-4']: !canApprovePay,
                        },
                      )}
                    >
                      {canApprovePay ? 'Approve and pay' : 'Approve only'}
                    </button>
                    {canApprovePay && (
                      <CustomPopover
                        placement="bottom-start"
                        id="invoice-schedule"
                        zIndex="9999"
                        content={<Actions />}
                        showPopover={isButtonToggle}
                        clickOutside={handleButtonToggle}
                      >
                        <button
                          disabled={!!selectedRows?.length}
                          className="dropdown-btn"
                          onClick={handleButtonToggle}
                        >
                          <ChevronDown
                            color="#ffffff"
                            className={`icon ${isButtonToggle && 'is-toggled'}`}
                          />
                        </button>
                      </CustomPopover>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="review-batch-filter">
              <TopBar
                showFilter
                setSearchVal={setSearch}
                searchVal={search}
                withOutSearch
                showBarSearch
                inputPlaceholder="Search"
                filterData={filterData}
                handleFilterSelect={(updateVal) => {
                  setFilterData(updateVal);
                }}
                clearFilters={clearFilters}
                handleFilterApply={handleFilter}
              />
            </div>
            <div className="table-wrapper">
              {transactions?.length > 0 ? (
                <>
                  <Table
                    columns={tableColumns}
                    data={tableData}
                    onRowClick={handleRowClick}
                    popoverAction={TableActions}
                    popoverState={isPopoverOpen}
                    setPopoverState={setIsPopoverOpen}
                    onRowSelect={handleRowSelect}
                    extendRow={
                      <tr className="w-100 total-amount-wrapper">
                        <td></td>
                        <td className="fw-semibold">Total amount</td>
                        <td className="fw-semibold">
                          <CurrencyFormat
                            value={data?.amount / 100}
                            displayType="text"
                            thousandSeparator={true}
                            prefix={getCurrency(data?.currency)}
                          />
                        </td>
                        {Array(7)
                          .fill(null)
                          .map((_, index) => (
                            <td key={index}></td>
                          ))}
                      </tr>
                    }
                  />
                </>
              ) : (
                <div className="tabinnerWrapper border rounded-bottom-3">
                  <NoData
                    headerText={`You have no transactions for this filter`}
                    bodyText="Alter filter to see transactions"
                    withButton={false}
                  />
                </div>
              )}
              {selectedRows?.length > 0 && <BulkApprovalAction rows={selectedRows} />}
            </div>

            <div className="approvals-flow-wrapper mt-4">
              <h1 className="mb-3">Approval flow</h1>

              <Timeline
                data={approvers}
                multipleRule={false}
                goToRule={openRule}
                code={'code'}
                pageFrom={`/requests/funds`}
              />
            </div>

            <div className="attachment mt-4">
              <h3>Receipt or attachment</h3>
              {loadingReceipt && !receiptAssets?.length && (
                <Loading className="mt-1" color="#D28B28" size={24} />
              )}
              <ReceiptList
                showLabel={false}
                receiptAssets={receiptAssets}
                onChange={(value) =>
                  setReceiptCode({
                    receipt: value?.map((item) => item.assetCode),
                  })
                }
                loading={updateLoading}
                removeFile={!receiptCode.receipt}
                viewImgUrl={viewImgUrl}
              />
            </div>
          </div>
        </div>
      )}

      <ModalComponent
        active={!!selectedRow}
        onClick={() => setSelectedRow(null)}
        scroll={false}
      >
        <TransactionDetails
          selectTransaction={selectedRow}
          setSelectTransaction={setSelectedRow}
        />
      </ModalComponent>

      <Modal
        show={confirmModal}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} transaction`}
          subTitle={`Are you sure you want to ${type} this transaction?`}
          onConfirm={handleConfirm}
          onCancel={closeModal}
          isDeleteDialog={type === 'cancel'}
          actionBtnText="Confirm"
          loading={loadingReview || payNowLoading}
        />
      </Modal>

      <Modal
        show={!!reason}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <DeclinedAlert onClose={() => setReason(null)} reason={reason} />
      </Modal>

      <ModalComponent active={!!declineRequest} scroll={false}>
        <ModalContainerWrap
          isWhite
          modalName="Decline request"
          onClose={() => {
            setDeclineRequest(false);
          }}
        >
          <DeclineRequest
            onClose={() => {
              setDeclineRequest(false);
            }}
            approvalCode={selectedOption?.approvalRequest?.code}
            userCode={user?.user?.code}
            isLoaded={isLoaded}
            kebabAction
            hideTitle
          />
        </ModalContainerWrap>
      </ModalComponent>
    </div>
  );
};

export default BatchApproval;

const LoadingSkeleton = () => {
  return (
    <div className="py-5 batch_payment-container h-100 w-100">
      <div
        className="back-click mb-4"
        onClick={() => history.push('/transactions/batch')}
      >
        <Skeleton.Button
          active
          className="rounded-2"
          size={25}
          style={{ width: '180px' }}
        />
      </div>

      <Skeleton.Button
        active
        className="rounded-2"
        size={35}
        style={{ width: '180px' }}
      />

      <div className="review-batch-table w-100 mt-5">
        <div className="review-batch-table_header w-100 d-md-flex justify-content-between align-items-center">
          <div className="d-flex flex-column gap-2">
            <Skeleton.Input
              active
              size={20}
              className="rounded-2"
              style={{ width: '100px' }}
            />
            <Skeleton.Input
              active
              size={20}
              className="rounded-2"
              style={{ width: '180px' }}
            />
          </div>
        </div>
        <div className="review-batch-filter">
          <div className="d-flex gap-2 justify-content-between">
            <Skeleton.Button
              active
              className="rounded-2"
              size={35}
              style={{ width: '180px' }}
            />

            <Skeleton.Button
              active
              className="rounded-2"
              size={35}
              style={{ width: '180px' }}
            />
          </div>
        </div>
        <div className="table-wrapper">
          <div className="d-flex">
            <Skeleton.Input
              active
              className="rounded-bottom-2 w-100"
              size={35}
              style={{ height: 200 }}
            />
          </div>
        </div>

        <div className="approvals-flow-wrapper mt-4 d-flex gap-2 flex-column">
          <Skeleton.Input active className="rounded-2 w-25" size={20} />
          <Skeleton.Input active className="rounded-2 w-25" size={20} />
        </div>

        <div className="attachment mt-4 d-flex gap-2 flex-column">
          <Skeleton.Input active className="rounded-2 w-25" size={20} />

          <Skeleton.Button active shape="circle" className="rounded-100" />
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import './style.less';
import { ArrowLeftOutlined } from '@ant-design/icons';

const AuthFormWrapper = ({ children }) => {
  return (
    <div className="auth-form-wrapper">
      <div className="contents">{children}</div>
    </div>
  );
};

const AuthFormHeader = ({
  title,
  subtitle,
  backButton,
  goBack,
  backbuttonName = 'Back',
}) => {
  return (
    <div className="form-header" style={{ margin: ' 30px auto 0px', maxWidth: '30rem' }}>
      {backButton && (
        <div className="back-line mb-3 cursor" onClick={goBack}>
          <div className=" gap-2 d-flex align-items-center">
            <ArrowLeftOutlined />
            <span>{backbuttonName}</span>
          </div>
        </div>
      )}
      <h1 style={{ fontSize: '28px' }}>{title}</h1>
      {subtitle && (
        <h4 className="text-lc mt-2" style={{ fontSize: '14px' }}>
          {subtitle}{' '}
        </h4>
      )}
    </div>
  );
};

export { AuthFormWrapper, AuthFormHeader };

import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Modal from 'components/UI/Modal';
import { useEffect, useState } from 'react';

import { fetchCategories } from 'redux/actions/CategoryAction';
import { getVendors } from 'redux/actions/VendorsAction';

import {
  createApprovalRule,
  editApprovalRule,
  fetchApprovalRule,
  fetchApprovers,
  fetchTransactionTypes,
} from 'redux/actions/ApprovalAction';

import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/UI/Loading';
import { capitalizeFirstLetter } from 'utils/helper';

import ApprovalSelect from 'components/UI/CustomSelect/ApprovalSelect';
import { categoryOptions, transactionTypeOptions } from './approvalData';

import { toastError } from 'components/UI/toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getBalances } from 'redux/actions/BudgetsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { addNewApproverLevel } from '../../../redux/actions/ApprovalAction';
import ConditionRowComponent from './conditionRowComponent';
import './styles.scss';

const MAX_APPROVALS_LEVEL = 3;

const defaultFormData = {
  name: '',
  rules: [
    {
      type: 'when transaction',
      name: '',
      'condition-one': {
        options: categoryOptions,
      },
      'condition-two': {
        options: [{ value: 'is', label: 'is exactly' }],
        selectedValue: {},
      },
      'condition-three': {
        options: [],
        selectedValue: {},
      },
    },
  ],
  approvers: [
    {
      rank: '',
      threshold: '',
      reviewers: [],
    },
  ],
};

const ApprovalForm = ({
  onHandleToggle,
  isEdit = false,
  IsOtherPopoverOpen = () => null,
  setIsOtherPopoverOpen,
  selectedRule,
  isEditing,
  data,
  setData,
  getApprovalLevelPayload,
  fetchingApprovers,
}) => {
  const thresholdOptions = [
    { value: 'all', label: 'All of' },
    { value: 'any', label: 'Any of' },
  ];

  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [vendorsData, setVendorsData] = useState([]);
  const [budgetsData, setBudgetsData] = useState([]);
  const [accountsData, setAccountData] = useState([]);
  const [thirdOption, setThirdOptions] = useState({
    account: null,
    budget: null,
    vendor: null,
    category: null,
    type: null,
  });
  const [transactionTypesList, setTransactionTypesList] = useState([]);
  const [approvalData, setApproval] = useState(null);
  const [editedData, setEditedData] = useState({
    name: '',
    condition: {
      oldConditions: [],
      newConditions: [{ trigger: '', operator: '', operands: [], code: '' }],
    },
    approver: {
      oldApprovers: [],
      newApprovers: [{ code: '', approvers: [] }],
    },
  });

  const {
    fetchApprovers: { data: approversData, loading: isFetchingApprovers },
    fetchApprovalRules: {},
    fetchTransactionTypes: {
      data: transactionTypes,
      loading: isFetchingTransactionTypes,
    },
    createApprovalRule: {
      loading: isCreatingApprovalRule,
      success: isApprovalRuleCreated,
    },
    editApprovalRule: { loading: isRuleEditing, success: isEdited },
    addNewApproverLevel: { loading: isAddingApprovers, success: isApproversAdded },
  } = useSelector(({ approval }) => approval);

  const {
    fetchCategories: { data: categoryData, success: isCatSuccess },
    createCategories: { loading: isCatLoading },
  } = useSelector(({ categories }) => categories);

  const {
    deleteCondition: { loading, isConditionDeleting, success: isConditionDeleted },
  } = useSelector(({ approval }) => approval);

  const {
    getBudget: { data: budgetData },
    getBalances: { data: { balances: accountData = [] } = {} },
    getTransferableBalance: { data: budgetBalance = {}, loading: loadingTransferbalance },
  } = useSelector(({ budgets }) => budgets);

  const {
    getVendor: { loading: vendorLoading, data: vendorData },
  } = useSelector(({ vendors }) => vendors);

  const { permissions } = allPermissions();
  const canviewVendor = hasPermission({
    permissions,
    scopes: ['vendor-*', 'vendor-view'],
  });

  const onHandleNameChange = (event) => {
    if (isEditing) {
      setData({ ...data, name: event.target.value });
      setEditedData({ ...editedData, name: event.target.value });
    } else {
      setData({ ...data, name: event.target.value });
    }
  };

  const onAddNewConditions = () => {
    const { rules } = data;
    rules.push({
      type: 'and',
      name: '',
      'condition-one': {
        options: [],
      },
      'condition-two': {
        options: [{ value: 'is', label: 'is exactly' }],
        selectedValue: {},
      },
      'condition-three': {
        options: [],
        selectedValue: {},
      },
    });
    if (isEditing) {
      setEditedData({
        ...editedData,
        condition: {
          ...editedData.condition,
          newConditions: [...editedData.condition.newConditions, {}],
        },
      });
    }
    setConditionOptionsForRules(rules);
  };

  const onAddNewApprovalMembers = () => {
    setData({
      ...data,
      approvers: [
        ...data?.approvers,
        {
          rank: '',
          threshold: '',
          reviewers: [],
        },
      ],
    });
    if (isEditing) {
      setEditedData({
        ...editedData,
        approver: {
          ...editedData.approver,
          newApprovers: [...editedData.approver.newApprovers, {}],
        },
      });
    }
  };

  /** @param {typeof data.rules} rules */
  const setConditionOptionsForRules = (rules, update = true) => {
    let availableOptions = categoryOptions.slice();
    let amountOperators = transactionTypeOptions['amount'].secondOption.slice();
    let genericOperators = transactionTypeOptions['type'].secondOption.slice();
    for (let index = 0; index < rules.length; ++index) {
      rules[index]['condition-one'].options = availableOptions.slice();

      const { name } = rules[index];
      if (name === 'amount') {
        rules[index]['condition-two'].options = amountOperators.slice();

        if (!rules[index]['condition-two'].selectedValue) {
          rules[index]['condition-two'].selectedValue = amountOperators[0];
        }
        const selectedOperator = rules[index]['condition-two'].selectedValue;
        amountOperators = amountOperators.filter((operator) => {
          return selectedOperator.value !== operator.value;
        });
      }

      let operatorsWithAny = transactionTypeOptions[name]?.secondOption?.slice();
      if (['budget', 'vendor', 'category', 'account'].includes(name)) {
        rules[index]['condition-two'].options = operatorsWithAny.slice();

        if (!rules[index]['condition-two'].selectedValue) {
          rules[index]['condition-two'].selectedValue = operatorsWithAny[0];
        }
        const selectedOperator = rules[index]['condition-two'].selectedValue;
        operatorsWithAny = operatorsWithAny.filter((operator) => {
          return selectedOperator.value !== operator.value;
        });
      }

      if (!['amount', 'budget', 'vendor', 'category', 'account'].includes(name)) {
        rules[index]['condition-two'].options = genericOperators.slice();

        if (!rules[index]['condition-two'].selectedValue) {
          rules[index]['condition-two'].selectedValue = genericOperators[0];
        }
        const selectedOperator = rules[index]['condition-two'].selectedValue;
        genericOperators = genericOperators.filter((operator) => {
          return selectedOperator.value !== operator.value;
        });
      }

      availableOptions = availableOptions.filter((option) => {
        if (!['amount'].includes(option.value)) return name !== option.value;
        return amountOperators.length;
      });
    }

    if (update) setData({ ...data, rules });
    return rules;
  };

  const setConditionName = (typeValue, index) => {
    const type = typeValue.value;
    let rules = data?.rules;
    let selectedItem = rules[index];
    selectedItem['condition-two'].selectedValue =
      type === 'amount' ? null : { value: 'is', label: 'is exactly' };
    selectedItem.name = type;
    selectedItem['condition-one'].selectedValue = typeValue;
    if (isEditing) {
      setConditionOptionsForRules(rules);
      const newConditions = getIsEditingConditionName(index, type, typeValue);
      setEditedData({
        ...editedData,
        condition: {
          ...editedData.condition,
          newConditions,
        },
      });
    } else {
      setConditionOptionsForRules(rules);
    }
    computeThirdConditionsByIndex(type, index);
  };

  const getOptionValue = (value, index, conditionLevel) => {
    let rules = data?.rules;
    let selectedItem = rules[index];
    selectedItem[`condition-${conditionLevel}`].selectedValue = value;

    if (conditionLevel === 'two' && selectedItem.name === 'amount') {
      const nextAmountRule = rules.find((rule, ruleIndex) => {
        return ruleIndex > index && rule.name === 'amount';
      });
      if (nextAmountRule) {
        nextAmountRule['condition-two'].selectedValue = null;
        rules = setConditionOptionsForRules(rules, false);
      }
    }

    // clear third condition when condition two changes
    if (
      conditionLevel === 'two' &&
      ['budget', 'vendor', 'category', 'account'].includes(selectedItem.name)
    ) {
      const conditionTwoType = selectedItem['condition-two'].selectedValue.value;
      selectedItem[`condition-three`].selectedValue = conditionTwoType === 'is' ? {} : [];
    }

    if (isEditing) {
      let newOptionValue = [];
      let conditionLength = editedData?.condition?.newConditions?.length;
      let approvalDataConditionLength = approvalData?.conditions?.length;
      let selectedConditionCode = '';
      if (conditionLevel === 'two' && selectedItem.name === 'amount') {
        newOptionValue = getIsEditingOptionValue(index, '', 'three');
        selectedConditionCode = getCodeByIndex(index);
      }

      if (conditionLength > approvalDataConditionLength) {
        newOptionValue = getIsEditingOptionValue(index, value, conditionLevel);
      } else {
        newOptionValue = getIsEditingOptionValue(index, value, conditionLevel);
        selectedConditionCode = getCodeByIndex(index);
      }
      setEditedData({
        ...editedData,
        condition: {
          ...editedData.condition,
          oldConditions: [...editedData.condition.oldConditions, selectedConditionCode],
          newConditions: newOptionValue,
        },
      });
    }
    setData({ ...data, rules });
  };

  const onChangeApproverThreshold = (thresholdValue, index, rank) => {
    let approvers = data?.approvers;
    let selectedItem = approvers[index];
    selectedItem.threshold = thresholdValue.value;
    selectedItem.rank = index + 1;
    setData({ ...data, approvers });
    if (isEditing) {
      handleIsEditingApproversThreshold(thresholdValue, index, rank);
    }
  };

  const onHandleGetSelectedApprovers = (list, index, rank) => {
    let approvers = data?.approvers;
    let selectedItem = approvers[index];
    selectedItem.reviewers = list;
    if (isEditing) {
      setData({ ...data, approvers });
      handleIsEditingApprovers(list, index, rank);
    } else {
      setData({ ...data, approvers });
    }
  };

  const getExisitingUserCode = (rank) => {
    if (data.approvers.length <= approvalData.approved_by.length) {
      return approvalData.approved_by
        .find((item) => item.rank === rank)
        .approvers.map((user) => {
          return user.user.code;
        });
    }
  };

  const handleIsEditingApprovers = (list, index, rank) => {
    let oldApproverDataCode = null;
    let newUser = { approvers: [] };
    let existingUser = getExisitingUserCode(rank);
    let { oldApprovers, newApprovers } = editedData.approver;
    const { approved_by } = approvalData;
    let approverLevelByRank = approved_by.find((item) => item.rank === rank);
    let listOfOldApprovers = approverLevelByRank;
    if (data.approvers.length <= approved_by.length) {
      if (list.length < listOfOldApprovers.approvers.length) {
        oldApproverDataCode = approverLevelByRank.approvers.find((item) => {
          return !list.includes(item.user.code);
        }).code;
      } else if (list.length >= approverLevelByRank.approvers.length) {
        for (let i = 0; i < list.length; i++) {
          if (list[i] !== existingUser[i]) {
            newUser.approvers.push(list[i]);
            newUser.code = approverLevelByRank.code;
          }
        }
      }
    }
    if (newUser.approvers.length) {
      newApprovers[index] = newUser;
    }
    if (oldApproverDataCode) {
      oldApprovers = [...new Set([...oldApprovers, oldApproverDataCode])];
    }

    setEditedData({
      ...editedData,
      approver: {
        ...editedData.approver,
        oldApprovers,
        newApprovers,
      },
    });
  };

  const handleIsEditingApproversThreshold = (thresholdValue, index, rank) => {
    const { newApprovers, oldApprovers } = editedData.approver;
    if (data.approvers.length <= approvalData.approved_by.length) {
      const updatedList = newApprovers.map((approver, approverIndex) => {
        if (index === approverIndex) {
          return {
            ...approver,
            threshold: thresholdValue.value,
            code: approvalData.approved_by.find((item) => item.rank === rank).code,
          };
        }
        return approver;
      });
      setEditedData({
        ...editedData,
        approver: {
          ...editedData.approver,
          newApprovers: updatedList,
        },
      });
    }
  };

  const onHandleAmountChange = (event, index) => {
    const { value, rawValue } = event.target;
    const rules = data?.rules;
    let selectedItem = rules[index];
    selectedItem['condition-three'].amount = rawValue ?? value;
    setData({ ...data, rules });
    if (isEditing) {
      let code = '';
      let newConditions = [];
      const amountValue = { value, rawValue };
      if (editedData.condition.newConditions.length > approvalData.conditions.length) {
        newConditions = getIsEditingOptionValue(index, amountValue, 'three');
      } else {
        code = getCodeByIndex(index);
        newConditions = getIsEditingOptionValue(index, amountValue, 'three');
      }
      setEditedData({
        ...editedData,
        condition: {
          ...editedData.condition,
          oldConditions: [...editedData.condition.oldConditions, code],
          newConditions,
        },
      });
    }
  };

  const getIsEditingOptionValue = (index, value, conditionLevel) => {
    let newConditions = [];
    let editedDataConditions = editedData.condition.newConditions;
    const selectedData = data.rules[index];
    const selectedDataName = selectedData.name;
    let amountOperator = '';

    if (selectedDataName === 'amount') {
      amountOperator =
        selectedData['condition-two'].selectedValue.value === 'above' ? 'gt' : 'lt';
    }
    newConditions = editedDataConditions.map((condition, conditionIndex) => {
      if (conditionIndex === index) {
        return {
          trigger: selectedDataName,
          operator:
            selectedDataName === 'amount'
              ? amountOperator
              : selectedData['condition-two'].selectedValue.value,
          operands:
            selectedDataName === 'amount'
              ? typeof value.value === 'number'
                ? Number(value.rawValue) * 100 ?? Number(value.value) * 100
                : selectedData['condition-three'].amount * 100
              : selectedDataName === 'type'
              ? [selectedData['condition-three'].selectedValue.feature_name]
              : selectedData['condition-two'].selectedValue.value === 'any'
              ? selectedData['condition-three'].selectedValue.map((item) => item.code) //for any
              : [selectedData['condition-three'].selectedValue.code],
        };
      }
      return condition;
    });

    return newConditions;
  };

  const getCodeByIndex = (index) => {
    const selectedCode = approvalData.conditions.find(
      (item, itemIndex) => itemIndex === index,
    ).code;
    return selectedCode;
  };
  const getIsEditingConditionName = (index, type, typeValue) => {
    const editedDataConditions = editedData.condition.newConditions;
    let selectedData = data.rules[index];
    const newConditions = editedDataConditions.map((condition, conditionIndex) => {
      if (conditionIndex === index) {
        return {
          trigger: selectedData.name,
          operator: selectedData['condition-two'].selectedValue.value,
          operands: [],
        };
      }
      return condition;
    });
    return newConditions;
  };

  const computeThirdConditionsByIndex = (type, index) => {
    let rules = data?.rules;
    let selectedItem = rules.find((item, itemIndex) => itemIndex === index);
    if (type === 'category') {
      selectedItem['condition-three'] = {
        selectedValue: null,
        options: categories,
      };
      selectedItem['condition-two'] = {
        ...selectedItem['condition-two'],
        options: transactionTypeOptions['category'].secondOption,
      };
    } else if (type === 'vendor') {
      selectedItem['condition-three'] = {
        selectedValue: null,
        options: vendorsData,
      };
      selectedItem['condition-two'] = {
        options: transactionTypeOptions['vendor'].secondOption,
        ...selectedItem['condition-two'],
      };
    } else if (type === 'budget') {
      selectedItem['condition-three'] = {
        selectedValue: null,
        options: budgetsData,
      };
      selectedItem['condition-two'] = {
        options: transactionTypeOptions['budget'].secondOption,
        ...selectedItem['condition-two'],
      };
    } else if (type === 'account') {
      selectedItem['condition-three'] = {
        selectedValue: null,
        options: accountsData,
      };
      selectedItem['condition-two'] = {
        options: transactionTypeOptions['account'].secondOption,
        ...selectedItem['condition-two'],
      };
    } else if (type === 'type') {
      selectedItem['condition-three'] = {
        options: transactionTypesList,
        selectedValue: {},
      };
      selectedItem['condition-two'] = {
        options: transactionTypeOptions['type'].secondOption,
        ...selectedItem['condition-two'],
      };
    } else if (type === 'amount') {
      selectedItem['condition-three'] = {
        amount: '',
      };
    }

    rules[index] = selectedItem;
    setData({
      ...data,
      rules,
    });
  };

  useEffect(() => {
    if (!accountData.length) dispatch(getBalances());
  }, []);

  useEffect(() => {
    if (!categoryData) dispatch(fetchCategories());
    else if (categoryData) {
      const newData = categoryData?.categories?.map((category) => {
        return {
          value: category.slug,
          label: category.name,
          code: category.code,
        };
      });
      setCategories(newData);
      setThirdOptions({ ...thirdOption, category: newData });
    }
  }, [isCatSuccess]);

  useEffect(() => {
    if (!vendorData && canviewVendor) dispatch(getVendors());
    else if (vendorData) {
      const newData = vendorData?.vendors?.map((vendor) => {
        return {
          value: vendor?.name,
          label: vendor?.name,
          code: vendor?.code,
        };
      });
      setVendorsData(newData);
      setThirdOptions({ ...thirdOption, vendor: newData });
    }
  }, [vendorData]);

  useEffect(() => {
    if (budgetData) {
      const newData = budgetData?.budgets?.map((budget) => {
        return {
          value: budget?.name,
          label: budget?.name,
          code: budget?.code,
        };
      });
      setBudgetsData(newData);
      setThirdOptions({ ...thirdOption, budget: newData });
    }
  }, [budgetData]);

  useEffect(() => {
    if (accountData) {
      const newData = accountData?.map((account) => {
        return {
          value: account?.name,
          label: account?.name,
          code: account?.code,
        };
      });
      setAccountData(newData);
      setThirdOptions({ ...thirdOption, account: newData });
    }
  }, [accountData]);

  useEffect(() => {
    if (!transactionTypes) {
      dispatch(fetchTransactionTypes());
    } else if (transactionTypes) {
      const newData = transactionTypes.map((type) => {
        return {
          ...type,
          label: type.name,
          value: type.feature_name,
        };
      });
      setTransactionTypesList(newData);
      setThirdOptions({
        ...thirdOption,
        type: newData,
      });
    }
  }, [transactionTypes]);

  useEffect(() => {
    if (!approversData) dispatch(fetchApprovers());
  }, [approversData]);

  useEffect(() => {
    if (selectedRule && categories && vendorsData) {
      populateApprovalConditions(selectedRule);
      setApproval(selectedRule);
      generateEditedData(selectedRule);
    }
  }, [
    selectedRule,
    transactionTypesList,
    categories,
    vendorsData,
    budgetsData,
    accountsData,
  ]);

  const populateApprovalConditions = (selectedRule) => {
    const existingApprovers = generateTheApprovers(selectedRule);
    const rules = generateNewApprovalRules(selectedRule.conditions);

    setData({
      ...data,
      name: selectedRule.name,
      rules: setConditionOptionsForRules(rules, false),
      approvers: existingApprovers,
    });
  };

  //this is function is for when an approval is being edited
  const generateTheApprovers = (approvers) => {
    const newApprovers = approvers.approved_by
      .map((approvalFlow) => {
        return {
          rank: approvalFlow.rank,
          threshold: approvalFlow.approvers_threshold,
          reviewers: approvalFlow.approvers.map(({ user: { code } }) => code),
        };
      })
      .sort((level_one, level_two) => level_one.rank - level_two.rank);
    return newApprovers;
  };

  const getOperand = (value) => {
    switch (value) {
      case 'is':
        return { value: 'is', label: 'is exactly' };
      case 'any':
        return { value: 'any', label: 'is any of' };
      case 'not:is':
        return { value: 'not:is', label: 'is not' };
      case 'not:eq':
        return { value: 'not:eq', label: 'is not' };
      case 'not:any':
        return { value: 'not:eq', label: 'is none of' };
      case 'gt':
        return { value: 'above', label: 'is above' };
      case 'lt':
        return { value: 'below', label: 'is below' };
    }
  };

  const getOperandCategoryThird = (value, rulesOperands) => {
    switch (value) {
      case 'is':
        return {
          code: rulesOperands.code,
          label: rulesOperands.name,
          value: rulesOperands.name,
        };
      default:
        return condition.operands.map(({ category }) => ({
          code: category.code,
          label: category.name,
          value: category.name,
        }));
    }
  };

  const generateNewApprovalRules = (rules) => {
    return rules.map((condition, index) => {
      let type = condition.trigger;
      const rulesOperands =
        type === 'amount' ? condition : condition.operands[0][condition.trigger];
      if (type === 'amount') {
        return {
          type: index === 0 ? 'When transaction' : 'And',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: 'Amount', value: 'amount' },
          },
          'condition-two': {
            options: transactionTypeOptions['amount'].secondOption,
            selectedValue: getOperand(rulesOperands.operator),
          },
          'condition-three': {
            amount: rulesOperands.operands[0].operand / 100,
          },
        };
      }

      if (type === 'category') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: transactionTypeOptions['category'].secondOption,
            selectedValue: getOperand(condition.operator),
          },
          'condition-three': {
            options: categories,
            selectedValue: getOperandCategoryThird(rulesOperands.operator, rulesOperands),
          },
        };
      }

      if (type === 'type') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: [{ value: 'is', label: 'is exactly' }],
            selectedValue: { value: 'is', label: 'is exactly' },
          },
          'condition-three': {
            options: transactionTypesList,
            selectedValue: {
              code: rulesOperands.code,
              label: rulesOperands.name,
              value: rulesOperands.name,
              feature_name: rulesOperands.feature_name,
            },
          },
        };
      }

      if (type === 'vendor') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: transactionTypeOptions['vendor'].secondOption,
            selectedValue:
              condition.operator === 'is'
                ? { value: 'is', label: 'is exactly' }
                : { value: 'any', label: 'is any of' },
          },
          'condition-three': {
            options: vendorsData,
            selectedValue:
              rulesOperands.operator === 'is'
                ? {
                    code: rulesOperands.code,
                    label: rulesOperands.name,
                    value: rulesOperands.slug,
                  }
                : condition.operands.map(({ vendor }) => ({
                    code: vendor.code,
                    label: vendor.name,
                    value: vendor.code,
                  })),
          },
        };
      }

      if (type === 'budget') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: transactionTypeOptions['budget'].secondOption,
            selectedValue: getOperand(condition.operator),
          },
          'condition-three': {
            options: budgetsData,
            selectedValue:
              rulesOperands.operator === 'is'
                ? {
                    code: rulesOperands.code,
                    label: rulesOperands.name,
                    value: rulesOperands.slug,
                  }
                : condition.operands.map(({ budget }) => ({
                    code: budget.code,
                    label: budget.name,
                    value: budget.code,
                  })),
          },
        };
      }

      if (type === 'account') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: transactionTypeOptions['account'].secondOption,
            selectedValue: getOperand(condition.operator),
          },
          'condition-three': {
            options: accountsData,
            selectedValue:
              rulesOperands.operator === 'is'
                ? {
                    code: rulesOperands.code,
                    label: rulesOperands.name,
                    value: rulesOperands.slug,
                  }
                : condition.operands.map(({ account }) => ({
                    code: account.code,
                    label: account.name,
                    value: account.code,
                  })),
          },
        };
      }
    });
  };

  const getApprovalThreshold = (threshold) => {
    if (['all', -1].includes(threshold)) return { value: 'all', label: 'All of' };
    else if (['any', 1].includes(threshold)) return { value: 'any', label: 'Any of' };
    return '';
  };

  const generateEditedData = (data) => {
    const emptyObject = {},
      emptyApproversObject = {};
    const conditions = Array.from({ length: data.conditions.length }, () => emptyObject);
    const approvers = Array.from(
      { length: data.approved_by.length },
      () => emptyApproversObject,
    );

    setEditedData({
      ...editedData,
      name: data?.name,
      condition: {
        oldConditions: [],
        newConditions: conditions,
      },
      approver: {
        oldApprovers: [],
        newApprovers: approvers,
      },
    });
  };

  const createConditionsForPayload = (data) => {
    const conditions = data.map((condition) => {
      if (condition.name === 'amount') {
        let operatorValue =
          condition['condition-two'].selectedValue?.value === 'above'
            ? 'gt'
            : condition['condition-two'].selectedValue?.value === 'below'
            ? 'lt'
            : '';
        const amount = condition['condition-three']?.amount;
        return {
          trigger: condition.name,
          operator: operatorValue,
          operands: amount === '' ? '' : Number(amount) * 100,
        };
      }

      const checkThirdCondtion = !!condition['condition-three']?.selectedValue?.length
        ? condition['condition-three']?.selectedValue?.map((item) => item.code)
        : [condition['condition-three'].selectedValue?.code];

      return {
        trigger: condition.name,
        operator: condition['condition-two'].selectedValue?.value?.toLowerCase(),
        operands:
          condition.name !== 'type'
            ? checkThirdCondtion.filter((operand) => operand !== undefined)
            : [condition['condition-three'].selectedValue?.feature_name].filter(
                (operand) => operand !== undefined,
              ),
      };
    });

    return conditions;
  };

  const onSubmitForm = () => {
    let requestPayload = {};
    const conditions = createConditionsForPayload(data.rules);
    requestPayload = {
      name: data?.name,
      conditions,
      reviews: data?.approvers,
    };

    if (checkForEmptyValue(requestPayload)) return toastError('All fields are required');
    dispatch(createApprovalRule(requestPayload));
  };

  const removeEmptyObject = (arr) => {
    return arr.filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
      return false;
    });
  };

  const onSubmiteEditedForm = () => {
    let payload = {
      name: data?.name,
      conditions: createConditionsForPayload(data.rules),
      reviews: data?.approvers,
    };

    if (checkForEmptyValue(payload)) {
      return toastError('All fields are required');
    } else {
      let requestPayload = { code: approvalData.code };
      const {
        condition: { oldConditions, newConditions },
        approver: { oldApprovers, newApprovers },
        name,
      } = editedData;
      if (name !== approvalData.name) requestPayload.name = name;
      const updatedOldConditions = [...new Set([...oldConditions])].filter(
        (item) => item,
      );
      const updatedNewConditions = removeEmptyObject(newConditions);

      const updatedNewApprovers = removeEmptyObject(newApprovers);
      requestPayload = {
        ...requestPayload,
        condition: {
          oldConditions: updatedOldConditions,
          newConditions: updatedNewConditions,
        },
        approver: {
          oldApprovers,
          newApprovers: updatedNewApprovers,
        },
      };

      if (data.approvers.length > approvalData.approved_by.length) {
        const newApprover = data.approvers.slice(
          approvalData.approved_by.length,
          data.approvers.length,
        );
        if (checkForEmptyValue(newApprover))
          return toastError('Please select approvers for this level');
        const newApproverLevel = {
          code: approvalData.code,
          approvers: newApprover,
        };
        dispatch(addNewApproverLevel(newApproverLevel));
      }
      if (
        !updatedOldConditions.length &&
        !updatedNewConditions.length &&
        !oldApprovers.length &&
        !updatedNewApprovers.length &&
        data.approvers.length <= approvalData.approved_by.length &&
        name === approvalData.name
      ) {
        return toastError("You didn't make any edit");
      }
      if (requestPayload.name) {
        if (checkForEmptyValue(requestPayload))
          return toastError('All fields are required');
        dispatch(editApprovalRule(requestPayload));
      }
      if (
        requestPayload.condition.newConditions ||
        requestPayload.condition.oldConditions
      ) {
        dispatch(editApprovalRule(requestPayload));
      }
    }
  };

  //this is used in place of proper form validation
  //would need refactoring to only use a form library with the need for this logic
  const checkForEmptyValue = (payload) => {
    let error = [];
    for (const key in payload) {
      if (Array.isArray(payload[key])) {
        payload[key].forEach((item) => {
          for (const itemKey in item) {
            if (Array.isArray(item[itemKey])) {
              if (item[itemKey].length == 0) {
                error.push(true);
              } else {
                error.push(false);
              }
            } else if (item[itemKey] !== '') {
              error.push(false);
            } else if (item[itemKey] === '') {
              error.push(true);
            }
          }
        });
      } else if (payload[key] !== '') {
        continue;
      }
    }
    return error.includes(true);
  };

  const onRemoveConditionItem = (index, type) => {
    let newData = data?.[type]?.filter((datum, datumIndex) => datumIndex !== index);
    if (isEditing) {
      const selectedItem = approvalData.conditions[index];
      if (selectedItem?.code) {
        setEditedData({
          ...editedData,
          condition: {
            ...editedData.condition,
            oldConditions: [...editedData.condition.oldConditions, selectedItem.code],
          },
        });
      } else {
        newData = data?.[type]?.filter((datum, datumIndex) => datumIndex !== index);
      }
    }
    setData({
      ...data,
      [type]: newData,
    });
  };

  const onRemoveApprovalLevel = (rank, index) => {
    if (data.approvers.length === 1) {
      return toastError("You can't delete all approval level");
    }

    if (!isEditing) {
      const newData = data?.approvers?.filter(
        (datum, datumIndex) => datumIndex !== index,
      );
      setData({
        ...data,
        approvers: newData,
      });
    } else {
      const rankExist = approvalData.approved_by.find(
        (approval) => approval.rank === rank,
      );
      if (isEditing && rankExist) {
        const selectedItemCode = approvalData.approved_by.find(
          (approval) => approval.rank === rank,
        )?.code;
        let payload = {
          approver: selectedItemCode,
          code: approvalData.code,
        };
        getApprovalLevelPayload(payload);
      } else if (isEditing && !rankExist) {
        const newData = data?.approvers?.filter(
          (datum, datumIndex) => datumIndex !== index,
        );

        setData({
          ...data,
          approvers: newData,
        });
      }
    }
  };

  useEffect(() => {
    if (isApprovalRuleCreated || isEdited || isApproversAdded) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [isApprovalRuleCreated, isEdited, isApproversAdded]);

  let users =
    approversData
      ?.map((item) => {
        return item.users;
      })
      .flat(1) ?? [];

  const allReviewers = data.approvers
    .map((approver) => {
      return approver?.reviewers ?? [];
    })
    .flat(1);
  const unselectedUsers = users.filter((user) => {
    return !allReviewers.includes(user.code);
  });

  const approverAvailableUsers = data.approvers.map((approver) => {
    const approverReviewers = approver?.reviewers ?? [];
    return approverReviewers
      .map((userCode) => users.find((user) => user.code === userCode))
      .concat(unselectedUsers);
  });

  return (
    <div className="information-wrapper">
      <form onSubmit={onSubmitForm}>
        <div>
          <h2>{isEditing ? `Edit approval rule` : 'Create approval rules'}</h2>
        </div>
        <Row className="mb-3">
          <CustomInput
            label="Rule name"
            placeholder="Enter name"
            text="text"
            name="approvalName"
            id="name"
            onChange={onHandleNameChange}
            value={data?.name}
          />
        </Row>
        <Row className="mb-3 rule-styles">
          <h6>Rule conditions</h6>
          <span>
            Set the triggers and rules of the approval flow
            <br />
            <br />
            Example: When transaction`s amount is above N50,000, it has to be approved by
            any of Samy or Achille
          </span>
        </Row>
        <Row className="mb-3">
          <ConditionRowComponent
            rules={data?.rules}
            setConditionName={setConditionName}
            removeCondition={onRemoveConditionItem}
            getOperatorValue={getOptionValue}
            getAmount={onHandleAmountChange}
            getOperand={getOptionValue}
          />

          <span
            className="condition-cta mt-2"
            onClick={
              data?.rules.length === 6
                ? () => toastError("You can't add more than 6 conditions")
                : onAddNewConditions
            }
          >
            Add new condition{' '}
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 1.5V8.5M1.5 5H8.5"
                stroke="#9DA4AE"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Row>
        <Row className="mb-3">
          <div className="approval-wrapper mt-4">
            <span className="conditions-label">Has to be approved by</span>
            {data?.approvers?.map((approver, index) => {
              return (
                <>
                  <div className="conditions-wrapper" key={index}>
                    <span
                      className="conditions-labelRemove-text ps-ab"
                      onClick={() => onRemoveApprovalLevel(approver.rank, index)}
                    >
                      Remove
                    </span>

                    <div className="d-flex gap-2">
                      <div className="flex-1">
                        <CustomSelect
                          name="category"
                          placeholder="Select"
                          options={thresholdOptions}
                          value={getApprovalThreshold(approver?.threshold)}
                          onChange={(thresholdValue) =>
                            onChangeApproverThreshold(
                              thresholdValue,
                              index,
                              approver.rank,
                            )
                          }
                        />
                      </div>
                      <div className="flex-2">
                        <ApprovalSelect
                          approvers={approver?.reviewers ?? []}
                          users={approverAvailableUsers[index]}
                          setApprovers={(data) =>
                            onHandleGetSelectedApprovers(data, index, approver.rank)
                          }
                          loading={fetchingApprovers}
                        />
                        {''}{' '}
                      </div>
                    </div>
                  </div>
                  {data?.approvers.length > 1 ? (
                    <div className="connector-wrapper">
                      <span className="connector">Then</span>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>

          <span
            className="condition-cta mt-2"
            onClick={
              data?.approvers?.length === MAX_APPROVALS_LEVEL
                ? () =>
                    toastError(
                      `You can only have ${MAX_APPROVALS_LEVEL} levels of approval`,
                    )
                : onAddNewApprovalMembers
            }
          >
            Add new approver{' '}
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 1.5V8.5M1.5 5H8.5"
                stroke="#9DA4AE"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Row>
      </form>

      <div className="modal-footer mt-3">
        <CustomButton
          onClick={onHandleToggle}
          disabled={
            isCreatingApprovalRule ||
            isRuleEditing ||
            isConditionDeleting ||
            isAddingApprovers
          }
          fullWidth={true}
          className="custom-button ghost-button"
        >
          Cancel
        </CustomButton>
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          loading={
            isCreatingApprovalRule ||
            isRuleEditing ||
            isConditionDeleting ||
            isAddingApprovers
          }
          disabled={
            isCreatingApprovalRule ||
            isRuleEditing ||
            isConditionDeleting ||
            isAddingApprovers
          }
          onClick={isEditing ? onSubmiteEditedForm : onSubmitForm}
        >
          {isEditing ? 'Edit approval rule' : 'Create approval'}
        </CustomButton>
      </div>
    </div>
  );
};

const FetchingLoader = () => {
  return (
    <div>
      <Loading />
    </div>
  );
};

export default function ApprovalModal(props) {
  const {
    isOpen,
    isEdit = false,
    isEditing,
    handleClose,
    clearSelectedRule,
    getApprovalLevelPayload,
    selectedRuleCode,
  } = props;

  if (!isOpen) {
    return <div />;
  }

  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState(defaultFormData);
  const [approvalData, setApprovalData] = useState(null);
  const {
    fetchApprovers: { data: approversData, loading: isFetchingApprovers },
    fetchTransactionTypes: { loading: isFetchingTransactionTypes },
    fetchApprovalRule: {
      data: approvalRuleData,
      loading: isSingleFetching,
      success: isSingleFetched,
    },
  } = useSelector(({ approval }) => approval);

  const {
    fetchCategories: { loading: isCatLoading },
  } = useSelector(({ categories }) => categories);

  const {
    getVendor: { loading: vendorLoading },
  } = useSelector(({ vendors }) => vendors);

  const onHandleToggle = () => {
    setData(defaultFormData);
    handleClose();
    clearSelectedRule();
    if (history?.location?.state?.previousUrl) {
      history.push({
        pathname: `${history?.location?.state?.previousUrl}`,
      });
    }
  };

  useEffect(() => {
    if (selectedRuleCode) {
      dispatch(fetchApprovalRule(selectedRuleCode));
    } else {
      setApprovalData(null);
    }
  }, [selectedRuleCode]);

  useEffect(() => {
    if (isSingleFetched && approvalRuleData) {
      setApprovalData(approvalRuleData);
    } else {
      setApprovalData(null);
    }
  }, [isSingleFetched, approvalRuleData]);

  return (
    <div>
      <Modal show={isOpen} onClose={onHandleToggle}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={onHandleToggle}>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </div>
          </div>
          <div className="card-modal-body">
            {isSingleFetching ? (
              <FetchingLoader />
            ) : (
              <ApprovalForm
                {...props}
                isEditing={isEditing}
                data={data}
                setData={setData}
                selectedRule={approvalData}
                getApprovalLevelPayload={getApprovalLevelPayload}
                fetchingApprovers={isFetchingApprovers}
                onHandleToggle={onHandleToggle}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

import { EditPencilIcon, TrashIcon } from 'assets/icons';
import { TransactionLogo } from 'assets/images';
import ConfirmDialog from 'components/ConfirmDialog';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { jsPDF } from 'components/UIHooks/JsPDF';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  deleteCategories,
  exportCategories,
  fetchCategories,
} from 'redux/actions/CategoryAction';
import { buildCategoriesTableData, buildExportCategoriesData } from 'utils/helper';
import { categoryColumns, exportCategoriesColumns } from 'utils/mockData';
import CategoryEmptyState from './Components/CategoryEmptyState';
import EditCategory from './EditCategory';
import EditSubcategory from './EditSubcategory';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';
import { getDateRangeFromTitle } from 'utils';
import Search from 'antd/lib/transfer/search';
import SearchLoader from './Components/SearchLoader';

const SubcategoryTable = ({
  queryParams,
  handleSelect = () => null,
  tabKey,
  categoryData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    fetchCategories: { data: { categories = [], meta = {} } = {}, loading },
    exportCategories: {
      data: { categories: exportData = [] } = {},
      loading: exportLoading,
    },
    deleteCategories: { loading: loadingDelete, success: deleteSuccess },
    updateCategories: { success: updateSuccess },
  } = useSelector(({ categories }) => categories);

  const { page = 1, total, hasMore = false, perPage = 50, nextPage = 2 } = meta;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({ ...queryParams });
  const [selectedData, setSelectedData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [type, setType] = useState('');

  const columns = useMemo(() => categoryColumns, [categories]);
  const rows = useMemo(
    () => buildCategoriesTableData(categories, 'subcategory'),
    [categories],
  );

  useEffect(() => {
    if (deleteSuccess) {
      setSelectedData(null);
      setShowDeleteModal(false);
    }
    if (updateSuccess) {
      dispatch(fetchCategories({ ...filteredQuery }));
      setSelectedData(null);
      setShowEditModal(false);
    }
  }, [deleteSuccess, updateSuccess]);

  useEffect(() => {
    if (queryParams?.parent && tabKey === 'subcategories') {
      dispatch(fetchCategories({ ...queryParams }));
    }
  }, [queryParams?.parent, location?.state?.categoryCode]);

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchCategories({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(fetchCategories({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
      // history.replace({ state: {} });
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handlePreviousPage = (page) => {
    dispatch(fetchCategories({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(fetchCategories({ perPage, page, ...filteredQuery }));
  };

  const actionHandler = (event, type, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    const { code, type: catType } = data;
    setType(catType);
    if (type === 'delete') {
      setSelectedData({ code });

      setShowDeleteModal(true);
    }
    if (type === 'edit') {
      setSelectedData(data.data);

      setShowEditModal(true);
    }
    setIsPopoverOpen(true);
  };

  const handleDeleteClick = () => {
    dispatch(deleteCategories({ id: selectedData?.code, params: { ...filteredQuery } }));
  };

  const TableActions = ({ list: selectedData }) => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'edit', selectedData)}
        >
          <EditPencilIcon /> Edit
        </div>
        <div
          className="actionLink svg-danger text-danger"
          onClick={(event) => actionHandler(event, 'delete', selectedData)}
        >
          <TrashIcon width="16" height="16" className="me-0" /> Delete
        </div>
      </div>
    );
  };

  const handleFilter = (query) => {
    const queryPayload = {
      ...filteredQuery,
      ...query,
    };

    setFilteredQuery(queryPayload);

    dispatch(fetchCategories(queryPayload));
    isFiltered.current = !!Object.keys(query).length;
  };

  const clearFilters = () => {
    setFilteredQuery({ ...queryParams });
    setFilterData([]);
  };

  const handleRowClick = (row) => {
    const { data } = row;
    history.push({
      pathname: `/compliances/categories/${row.code}/details`,
      state: {
        name: row?.name,
        description: row?.description === '-' ? null : row?.description,
        code: row?.code,
        parent: {
          name: categoryData?.name,
          description: categoryData?.description,
          code: categoryData?.code,
        },
      },
    });
  };

  const handleExport = () => {
    dispatch(exportCategories({ ...filteredQuery, search: debouncedValue ?? undefined }));
  };

  const handleExportPlatform = (platform) => {
    dispatch(exportCategories({ ...filteredQuery, search: debouncedValue ?? undefined }));
  };

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const columnStyles = {};
    const title = `${categoryData?.name || ''} Subcategories Report`;
    const headers = [
      exportCategoriesColumns
        .filter((item) => item.Header !== 'Files')
        .map((item) => item.Header),
    ];

    columns.forEach((column, index) => {
      columnStyles[index] = { cellWidth: 60 }; // Adjust the width as needed
    });

    const body = buildExportCategoriesData(exportData);

    let content = {
      startY: 80,
      head: headers,
      body,
      columnStyles,
    };

    doc.addImage(TransactionLogo, 'JPEG', marginLeft, 25, 90, 50.5);
    // doc.text(title, 80, 40);
    doc.autoTable(content);
    doc.save(`${categoryData?.name || ''} Subcategories.pdf`);
  };
  const csvData = [
    exportCategoriesColumns
      .filter((item) => item.Header !== 'Files')
      .map((item) => item.Header),
    ...buildExportCategoriesData(exportData),
  ];

  const show = !!categories?.length || (filtered && !categories?.length);

  return (
    <div className="budgets-wrapper position-relative mb-5">
      <Container>
        {loading && !filtered ? (
          <SearchLoader hasExport={true} />
        ) : (
          <TopBar
            inputPlaceholder="Search"
            showBarSearch={show}
            searchVal={search}
            setSearchVal={setSearch}
            handleFilterApply={handleFilter}
            showFilter={show}
            addExport={show}
            withDate
            hasEditButton={false}
            withOutSearch={true}
            clearFilters={clearFilters}
            handleExport={handleExport}
            handleExportPlatform={handleExportPlatform}
            csvData={csvData}
            exportPDF={exportPDF}
            csvFileName={`${categoryData?.name || ''} Subcategories Report.csv`}
            loading={loading}
            hasAvatar={false}
          />
        )}

        {!categories.length && !loading ? (
          <div>
            {isFiltered.current ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText={`You have no subcategory for this filter`}
                  bodyText="Alter filter to see subcategory"
                  withButton={true}
                  buttonLabel="View all subcategories"
                  onClickHandler={() => {
                    clearFilters();
                    dispatch(fetchCategories({ ...queryParams }));
                  }}
                />
              </div>
            ) : (
              <CategoryEmptyState parentCode={queryParams?.parent} type="subcategory" />
            )}
          </div>
        ) : (
          <Container className="px-0">
            <Row className="py-2">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={columns}
                  data={rows}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  exportLoading={exportLoading}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  onRowClick={handleRowClick}
                  popoverAction={TableActions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  // type="budgets"
                  modify={true}
                  loading={loading}
                  hasAvatar={false}
                />
              </Col>
            </Row>
          </Container>
        )}
      </Container>

      {showEditModal && type === 'category' && (
        <EditCategory selectedData={selectedData} setShowEditModal={setShowEditModal} />
      )}
      {showEditModal && type === 'subcategory' && (
        <EditSubcategory
          selectedData={selectedData}
          setShowEditModal={setShowEditModal}
        />
      )}

      <Modal
        show={showDeleteModal}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`Delete ${type}`}
          subTitle={`Are you sure you want to delete this ${type}? This action cannot be undone.`}
          onConfirm={handleDeleteClick}
          loading={loadingDelete}
          onCancel={() => setShowDeleteModal(false)}
        />
      </Modal>
    </div>
  );
};

export default SubcategoryTable;
